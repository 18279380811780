import {
  configureStore,
  createSerializableStateInvariantMiddleware,
  Tuple,
  combineReducers,
} from "@reduxjs/toolkit";
import { PublishStreamReducer } from "./Reducers/PublishStreamReducer";
import * as Sentry from "@sentry/react";

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  ignoredPaths: ['PublishStreamReducer.publishStreamObj.obj', 'payload.obj', 'publishStreamObj.obj', 'PublishStreamReducer.obj', 'PublishStreamReducer.payload.obj', 'publishStreamObj.payload.obj', 'PublishStreamReducer.publishStreamObj.payload.obj']
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    const transformedState = {
      ...state?.PublishStreamReducer
    };
    Object.assign(transformedState, {
      obj: null,
      subscribeToken: null,
      token: null,
      peoples: null,
      // channels: null
    })
    return transformedState;
  },
});

const store = configureStore({
  reducer: combineReducers({
    PublishStreamReducer: PublishStreamReducer,
  }),
  middleware: () => new Tuple(serializableMiddleware),
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export default store;
