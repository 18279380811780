
import { useState } from 'react';
import emptyPollsIcon from '../../assets/images/empty-polls.svg';
import pollsIcon from '../../assets/images/polls-icon-blue.png';
import deleteIcon from '../../assets/images/delete-icon.svg';
import editIcon from '../../assets/images/edit-icon.svg';
import backIcon from '../../assets/images/back-icon.svg';

export const AttendeePolls = () => {

// Settings popup tabs
const [activeTab, setActiveTab] = useState(1);
const [isCreatePollsOpen, setCreatePollsOpen] = useState(false);

const handleTabClick = (tabIndex: React.SetStateAction<number>) => {
    setActiveTab(tabIndex);
};

const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
};
const toggleCreatePollsPopup = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setCreatePollsOpen(!isCreatePollsOpen);
};



             return (
            <div className="Polls-area" style={{display: 'none'}}>
                <div className="sidebar-tabs-header">
                    <div className="tabs flex sidebar-tabs">
                        <button className={`flex flex-center ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
                            Active
                        </button>
                        <button className={`flex flex-center ${activeTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>
                            Closed
                        </button>
                    </div>
                </div>

                <div className="polls-content-area">
                    <div className="tab-content">
                        {activeTab === 1 && 
                            <div className='polls-inner-container thin-scrollbar'>
                                {/* empty Polls */}
                                <div className="empty-area-msg flex-center">
                                    <img src={emptyPollsIcon} alt="" />
                                    <h4>Polls</h4>
                                    <p>You’ve no active polls right now. Please publish from the saved/closed polls.</p>
                                </div>

                                <h4 className="tabs-inner-heading">Active Polls</h4>
                                <div className="polls-info-main attendee-polls-preview">
                                    <h3>How would you rob a bank <span></span></h3>
                                    <ul>
                                        <li className='flex low-vote'>
                                            <label className='radio-input'>
                                                <input
                                                type="radio"
                                                value="option1"
                                                checked={selectedOption === 'option1'}
                                                onChange={handleOptionChange}
                                                />
                                            </label>
                                            <p>
                                                <span>Go straight in with a gun <span className='live-votes'>12%</span></span>
                                                <div className='polls-percntage'>
                                                    <span style={{width: '30%'}}></span>
                                                </div>
                                            </p>
                                            
                                        </li>
                                        <li className='flex high-vote'>
                                            <label className='radio-input'>
                                                <input
                                                type="radio"
                                                value="option2"
                                                checked={selectedOption === 'option2'}
                                                onChange={handleOptionChange}
                                                />
                                            </label>
                                            <p>
                                                <span>Seduce the lady clerk to do your bidding <span className='live-votes'>12%</span></span>
                                                <div className='polls-percntage'>
                                                    <span style={{width: '70%'}}></span>
                                                </div>
                                            </p>
                                        </li>
                                        <li className='flex low-vote'>
                                            <label className='radio-input'>
                                                <input
                                                type="radio"
                                                value="option3"
                                                checked={selectedOption === 'option3'}
                                                onChange={handleOptionChange}
                                                />
                                            </label>
                                            <p>
                                                <span>Hack the bank managers phone and blackmail him <span className='live-votes'>12%</span></span>
                                                <div className='polls-percntage'>
                                                    <span style={{width: '7%'}}></span>
                                                </div>
                                            </p>
                                        </li> 
                                        <li className='flex low-vote'>
                                            <label className='radio-input'>
                                                <input
                                                type="radio"
                                                value="option4"
                                                checked={selectedOption === 'option4'}
                                                onChange={handleOptionChange}
                                                />
                                            </label>
                                            <p>
                                                <span>Get back to your 9-5 job <span className='live-votes'>12%</span></span>
                                                <div className='polls-percntage'>
                                                    <span style={{width: '10%'}}></span>
                                                </div>
                                            </p>
                                        </li>
                                    </ul>
                                    <button type='button' className='btn disabled-button'>Submit</button>
                                </div>
                            </div>
                        }

                        {activeTab === 2 && 
                            <div className='polls-inner-container thin-scrollbar'>

                                <h4 className="tabs-inner-heading">Closed Polls</h4>
                                <div className="polls-info-main">
                                    <h3>How would you rob a bank <span></span></h3>
                                    <ul>
                                        <li className='flex low-vote'>
                                            <p>Go straight in with a gun
                                                <div className='polls-percntage'>
                                                    <span style={{width: '30%'}}></span>
                                                </div>
                                            </p>
                                            <span>12% Voted</span>
                                        </li>
                                        <li className='flex high-vote'>
                                            <p>Seduce the lady clerk to do your bidding
                                                <div className='polls-percntage'>
                                                    <span style={{width: '70%'}}></span>
                                                </div>
                                            </p>
                                            <span>50% Voted</span>
                                        </li>
                                        <li className='flex low-vote'>
                                            <p>Hack the bank managers phone and blackmail him
                                                <div className='polls-percntage'>
                                                    <span style={{width: '7%'}}></span>
                                                </div>
                                            </p>
                                            <span>7% Voted</span>
                                        </li>
                                        <li className='flex low-vote'>
                                            <p>Get back to your 9-5 job
                                                <div className='polls-percntage'>
                                                    <span style={{width: '10%'}}></span>
                                                </div>
                                            </p>
                                            <span>10% Voted</span>
                                        </li>
                                    </ul>
                                    <div className="flex votes-info">
                                        <span className='flex flex-center total-views'>202 views</span>
                                        162/202 voted
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                
            </div>
            );
        }
// export default Polls;