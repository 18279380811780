import React, { useState } from "react";
import "../../assets/styles/main.scss";

export const ConfirmationPopup = ({
  isEndSessionOrLeave,
  isConfirmationOpen,
  onCloseConfirmationPopUp,
  SessionHandle,
  isLiveRoom,
  isAdmin
}) => {
  if (!isConfirmationOpen) {
    return;
  }

  //we are in live room

  if(isLiveRoom){

    if(isAdmin){
      return (
        <div
          className={`modal confirmation-popup ${
            isConfirmationOpen ? "active" : ""
          }`}
        >
          <div className="modal-background"></div>
          <div className="modal-container with-header">
            <div className="modal-header">
              <h3 className="flex">
                {isEndSessionOrLeave === "EndSession"
                  ? "Confirm End Session?"
                  : "Leave Session?"}
              </h3>
              <span className="close-btn" onClick={onCloseConfirmationPopUp}>
                &times;
              </span>
            </div>
            <div className="modal-content flex">
              <div className="confirmation-info">
                {isEndSessionOrLeave === "EndSession" ? (
                  <p>
                    Ending the session will immediately disconnect all participants. Once ended, no one will be able to rejoin. Are you sure you want to proceed?
                  </p>
                ) : (
                  <p>
                    Are you sure you want to leave the session? The Session will continue. You can join back at anytime.
                  </p>
                )}
              </div>
            </div>
            <div className="modal-footer flex">
              <div className="flex">
                <button
                  type="button"
                  className="default-btn"
                  onClick={onCloseConfirmationPopUp}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="warning-button"
                  onClick={()=>{
                    SessionHandle()}}
                >
                  {isEndSessionOrLeave === "EndSession"
                    ? "Yes, End Session"
                    : "Leave Session"}
                </button>
              </div>
            </div>
          </div>
        </div>
      );

    }else{
      return (
        <div
          className={`modal confirmation-popup ${
            isConfirmationOpen ? "active" : ""
          }`}
        >
          <div className="modal-background"></div>
          <div className="modal-container with-header">
            <div className="modal-header">
              <h3 className="flex">
                {isEndSessionOrLeave === "EndSession"
                  ? "End session?"
                  : "Leaving So Soon?"}
              </h3>
              <span className="close-btn" onClick={onCloseConfirmationPopUp}>
                &times;
              </span>
            </div>
            <div className="modal-content flex">
              <div className="confirmation-info">
                {isEndSessionOrLeave === "EndSession" ? (
                  <p>
                    Are you sure you want to end the session? This will end the
                    session for all participant's.
                  </p>
                ) : (
                  <p>
                   The session will stay active, and you can rejoin whenever you're ready. Do you still want to leave?
                  </p>
                )}
              </div>
            </div>
            <div className="modal-footer flex">
              <div className="flex">
                <button
                  type="button"
                  className="default-btn"
                  onClick={onCloseConfirmationPopUp}
                >
                  {isEndSessionOrLeave === "EndSession"
                    ? "Cancel"
                    : "No, Stay Here"}
                </button>
                <button
                  type="button"
                  className="warning-button"
                  onClick={()=>{
                    SessionHandle()}}
                >
                  {isEndSessionOrLeave === "EndSession"
                    ? "End session"
                    : "Yes, Leave Session"}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    

  }

  //we are in green room

  if(!isLiveRoom){

    return (
      <div
        className={`modal confirmation-popup ${
          isConfirmationOpen ? "active" : ""
        }`}
      >
        <div className="modal-background"></div>
        <div className="modal-container with-header">
          <div className="modal-header">
            <h3 className="flex">
            Leave Green Room?
            </h3>
            <span className="close-btn" onClick={onCloseConfirmationPopUp}>
              &times;
            </span>
          </div>
          <div className="modal-content flex">
            <div className="confirmation-info">
                <p>
                Are you sure you want to leave the Green Room? The Green Room will remain active, and you can rejoin anytime.
                </p>
            </div>
          </div>
          <div className="modal-footer flex">
            <div className="flex">
              <button
                type="button"
                className="default-btn"
                onClick={onCloseConfirmationPopUp}
              >
                Cancel
              </button>
              <button
                type="button"
                className="warning-button"
                onClick={()=>{
                  SessionHandle()}}
              >
               Leave Green Room
              </button>
            </div>
          </div>
        </div>
      </div>
    );


  }

  // return (
  //   <div
  //     className={`modal confirmation-popup ${
  //       isConfirmationOpen ? "active" : ""
  //     }`}
  //   >
  //     <div className="modal-background"></div>
  //     <div className="modal-container with-header">
  //       <div className="modal-header">
  //         <h3 className="flex">
  //           {isEndSessionOrLeave === "EndSession"
  //             ? "End session?"
  //             : "Leave Session?"}
  //         </h3>
  //         <span className="close-btn" onClick={onCloseConfirmationPopUp}>
  //           &times;
  //         </span>
  //       </div>
  //       <div className="modal-content flex">
  //         <div className="confirmation-info">
  //           {isEndSessionOrLeave === "EndSession" ? (
  //             <p>
  //               Are you sure you want to end the session? This will end the
  //               session for all participant's.
  //             </p>
  //           ) : (
  //             <p>
  //               Are you sure you want to leave the session? The Session will continue. You can join back at anytime.
  //             </p>
  //           )}
  //         </div>
  //       </div>
  //       <div className="modal-footer flex">
  //         <div className="flex">
  //           <button
  //             type="button"
  //             className="default-btn"
  //             onClick={onCloseConfirmationPopUp}
  //           >
  //             Cancel
  //           </button>
  //           <button
  //             type="button"
  //             className="warning-button"
  //             onClick={()=>{
  //               SessionHandle()}}
  //           >
  //             {isEndSessionOrLeave === "EndSession"
  //               ? "End session"
  //               : "Leave Session"}
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};
