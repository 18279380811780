import React, { useState } from 'react'
import infoIcon from "../../assets/images/info-icon-sm.svg";
import closeIcon from "../../assets/images/close-icon.svg";

const MakeAttendeeDeclineMessage = ({showCannotMakeAttendeeMessage,setShowCannotMakeAttendeeMessage,attendeeLink}) => {
    const [buttonText, setButtonText] = useState('Copy Attendee Link');


    const handleCopy = () => {
        navigator.clipboard.writeText(attendeeLink)
          .then(() => {
            setButtonText('Copied!');
            setTimeout(() => {
              setButtonText('Copy Link'); // Revert text after 2 seconds
            }, 3000);
          })
          .catch((err) => {
            console.error('Failed to copy the text: ', err);
          });
      };
    
  return (
   
   
    <div className="flex flex-center toaster-mg attendee-alert">
        <div className="img-wrapper flex flex-center">
            <img src={infoIcon} alt="" />
        </div>
        <div className="message-text flex flex-center">
            <p><span>Action Not Possible:</span> This user joined directly as a Co-Host via a unique link. To switch their role to Attendee, please send them an Attendee invitation and have them re-join using that link.
                <a href="#" className='close' onClick={()=>setShowCannotMakeAttendeeMessage(false)}>
                        <img src={closeIcon} alt="" />
                </a>
            </p>
            <button type="button" className="primary-button w-full btn-md" onClick={handleCopy}>
            {buttonText}
            </button>
        </div>
    </div>
  )
}

export default MakeAttendeeDeclineMessage