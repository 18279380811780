import React from 'react';

const AttendeeLimitExceeded = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center', // Horizontally center
      alignItems: 'center', // Vertically center
      height: '100vh', // Make the container fill the viewport height
      backgroundColor: '#f4f4f4', // Optional: to see the background
    }}>
      <div style={{
        backgroundColor: '#ff4d4d',
        color: 'white',
        padding: '20px',
        textAlign: 'center',
        fontSize: '1.5em',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
        margin: '0 auto',
      }}>
        Attendee limit exceeded
      </div>
    </div>
  );
};

export default AttendeeLimitExceeded;
