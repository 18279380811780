import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThankYou } from "../ThankYou/ThankYou";


export default function Thanks() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [streamname, setStreamName] = useState("");
  const [accountId, setAccountId] = useState("");
  const [token, setToken] = useState("");

  const handleChange = (event: any) => {
    setName(event.target.value);
  };

  const handleChangeStreamName = (event: any) => {
    setStreamName(event.target.value);
  };

  const handleChangeAccountId = (event: any) => {
    setAccountId(event.target.value);
  };

  const handleChangeToken = (event: any) => {
    setToken(event.target.value);
  };

  const submit = () => {
    navigate("/publish", {
      state: {
        name: name,
        streamname: streamname,
        accountId: accountId,
        token: token,
      },
    });
  };

  return <>
  <ThankYou/>
  </>
}
