export const ADD_CONNECTION = "ADD_CONNECTION";
export const VIDEO_TOGGLE = "VIDEO_TOGGLE";
export const AUDIO_TOGGLE = "AUDIO_TOGGLE";
export const ADD_USERNAME = "ADD_USERNAME";
export const MIRROR_CAMERA_TOGGLE = "MIRROR_CAMERA_TOGGLE";
export const UPDATEVIDEOQUALITY = "UPDATEVIDEOQUALITY";

export const UPDATE_SELECTED_DEVICE_ID = "UPDATE_SELECTED_DEVICE_ID";
export const UPDATE_SELECTED_LABEL = "UPDATE_SELECTED_LABEL";

export const SPEAKER_SELECTED_LABEL = "SPEAKER_SELECTED_LABEL";
export const SPEAKER_SELECTED_DEVICE_ID = "SPEAKER_SELECTED_DEVICE_ID";
export const CAMERA_ID = "CAMERA_ID";
export const CAMERA_LABEL = "CAMERA_LABEL";

export const UPDATE_IS_LIVE = "UPDATE_IS_LIVE"; // Add this line
export const UPDATE_IS_TIMER = "UPDATE_IS_TIMER"; // Add this line
export const UPDATE_IS_LIVEGO = "UPDATE_IS_LIVEGO"; // Add this line
export const UPDATE_IS_SCREEN = "UPDATE_IS_SCREEN"; // Add this line
export const UPDATE_IS_INVITEPOPUP = "UPDATE_IS_INVITEPOPUP"; // Add this line
export const UPDATE_IS_SETTINGPOPUP = "UPDATE_IS_SETTINGPOPUP"; // Add this line
export const ATTENDEE_LIST = "ATTENDEE_LIST"; // Add this line
export const PUBLIC_CHAT = "PUBLIC_CHAT"; // Add this line
export const CHAT_PANEL = "CHAT_PANEL"; // Add this line
export const PRIVATE_CHAT = "PRIVATE_CHAT"; // Add this line
export const SIDE_BAR = "SIDE_BAR"; // Add this line

export const ADD_PEOPLES = "ADD_PEOPLES";
export const ADD_NEW_PEOPLE = "ADD_NEW_PEOPLE";
export const ADD_NUMBER_OF_PEOPLES = "ADD_NUMBER_OF_PEOPLES";
export const REMOVE_PEOPLE = "REMOVE_PEOPLE";

export const PARTIPANT_MIC_STATUS = "PARTIPANT_MIC_STATUS";

export const UPDATE_NOISE_REDUCTION = "UPDATE_NOISE_REDUCTION";
export const UPDATE_CAMERA = "UPDATE_CAMERA";
export const PARTIPANT_VIDEO_STATUS = "PARTIPANT_VIDEO_STATUS";
export const UPDATE_SCREEN_OTHER = "UPDATE_SCREEN_OTHER";

export const UPDATE_ACTIVE_POLL_STATUS = "UPDATE_ACTIVE_POLL_STATUS";
export const UPDATE_ACTIVE_OFFER_STATUS = "UPDATE_ACTIVE_OFFER_STATUS";
export const SHOW_ACTIVE_OFFER_NOIFICATION = "SHOW_ACTIVE_OFFER_NOIFICATION";
export const SHOW_ACTIVE_POLL_NOIFICATION = "SHOW_ACTIVE_POLL_NOIFICATION";

export const UPDATE_POLLS_ACTIVE_TAB = "UPDATE_POLLS_ACTIVE_TAB";
export const UPDATE_OFFERS_ACTIVE_TAB = "UPDATE_OFFERS_ACTIVE_TAB";

export const UPDATE_ATTENDEE_DATA_FOR_ALL = "UPDATE_ATTENDEE_DATA_FOR_ALL";
export const MUTE_TOGGLE = "MUTE_TOGGLE";
export const CAMERA_TOGGLE = "CAMERA_TOGGLE";
export const WHO_IS_SHARING_SCREEN = "WHO_IS_SHARING_SCREEN";
export const SCREENSHARESOURCEID = "SCREENSHARESOURCEID";
export const UPDATERECORD = "UPDATERECORD";

export const UPDATE_CONFERENCE_ID = "UPDATE_CONFERENCE_ID";
export const UPDATE_MIC = "UPDATE_MIC";
 
export const IS_SCREEN_SHARED_BY_YOU='IS_SCREEN_SHARED_BY_YOU'
export const UPDATE_IS_CANVAS_STREAM = "UPDATE_IS_CANVAS_STREAM";
export const UPDATE_IS_SCREEN_SHARE_WINDOW_MUTED = "UPDATE_IS_SCREEN_SHARE_WINDOW_MUTED";
export const UPDATE_CURRENT_SLIDE = "UPDATE_CURRENT_SLIDE";
export const UPDATE_CURRENT_FILEID = "UPDATE_CURRENT_FILEID";

export const IS_SHOW_ERROR="IS_SHOW_ERROR"
