import { useEffect, useRef, useState } from "react";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import { useDispatch, useSelector } from "react-redux";
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";
import refreshIcon from "../../assets/images/refresh-dark-icon.png";
import hideShowIcon from "../../assets/images/slide-hide-show.svg";
import { RootState } from "../../Store/Reducers/PublishStreamReducer";
import { updateCurrentSlide, updateIsCanvasStream, updateIsScreen } from "../../Store/Actions/PublishStreamAction";
import { url } from "inspector";
import { isMobile, isTablet, isDesktop, useMobileOrientation, withOrientationChange, isSafari } from 'react-device-detect';

interface props {
  imgList?: string[],
  currentSlide: number
}

export const DocShareViewer = ({ imgList, currentSlide }) => {
  const stateData = useSelector(
      (state: RootState) => state.PublishStreamReducer
  );
  const [loadingImage, setLoadingImage] = useState(false);
  const currentMainSlideRef = useRef<any>([])
  const orientation = useMobileOrientation();
  const [currImgIdx, setCurrImgIdx] =  useState<number>(0) 
  const [fitFill, setFitFill] =  useState<boolean>(true) 
  const maxImgIdx =  useRef<number>(1)
  const dispatch = useDispatch();
  const [isPotraitMode, setIsPotraitMode] = useState(false);
  const [mobilePortraitFitFill, setMobilePortraitFitFill] = useState(true);

  const apiRes = {
    "thumb_urls": imgList && imgList.thumb_urls? imgList.thumb_urls : [],
    "image_urls": imgList && imgList.image_urls? imgList.image_urls : [],
    "file_id": imgList && imgList.file_id
  }
  maxImgIdx.current = apiRes.image_urls.length 

  useEffect(() => {
    loadImage(currentSlide)
  }, [currentSlide])

  useEffect(() => {
    const img = new Image()
    img.onload = (event) => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      if (height > width) {
        if(isMobile && orientation.isLandscape) setFitFill(true)
        if(isMobile && orientation.isPortrait) setFitFill(true)
      }else {
        if(isMobile && orientation.isLandscape) setFitFill(true)
        if(isMobile && orientation.isPortrait) setFitFill(false)
      }
    }
    img.src = apiRes.image_urls[currentSlide];
  }, [orientation.orientation])

  const loadImage = (idx: number) => {
    if (idx < 0 || idx >= maxImgIdx.current) return;
    setCurrImgIdx(idx);
  };

  useEffect(() => {
    checkMode(0)
  }, [])

  useEffect(() => {
    checkMode(currentSlide)
  }, [currImgIdx])

  const checkMode = (idx: number) => {
    const img = new Image()
    img.onload = (event) => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      if (height > width) {
        setIsPotraitMode(true)
      }else {
        setIsPotraitMode(false)
      }
    }
    // if(!isMobile) setFitFill(true)
    // else if(isMobile && orientation.isPortrait) setFitFill(false)
    // else setFitFill(true)
    img.src = apiRes.image_urls[idx];
  }

  const fitStyle = {
    height: '100%', 
    width: 'auto'
  }
  const fillStyle = {
    width: '100%',
  }

  return (
    <div className="presenter-column screen-sharing-user-fullscreen">
      <div className="ew-video-wrapper">
        <div className="ew-live-video">
            <div className="ppt-slideshare" style={{ flex: '0 0 100%', overflow:  fitFill? 'hidden' : 'auto', justifyContent: 'center' }}>
                { apiRes.image_urls.map((img, i) => {
                    return (
                    <div key={i} style={ (currImgIdx === i )? { alignContent: 'center', alignItems: "center", position: 'relative', height: '100%' } : { display : "none", position: 'relative', height: '100%', alignContent: 'center' } }>                             
                        <img ref={ref => {currentMainSlideRef.current[i] = ref}} style={ fitFill ? fitStyle : fillStyle } src={img} ></img>
                        {/* <div ref={ref => {currentMainSlideRef.current[i] = ref}} style={ (currImgIdx === i )? { display : "flex", backgroundImage: img } : { display : "none", backgroundImage: img } } ></div> */}                            
                    </div>
                    )
                })}
                {((isMobile && (orientation.isLandscape || (isPotraitMode && orientation.isPortrait))) || (!isMobile && isPotraitMode)) && <div className="presenter-options-dropdown">
                  <span
                    className="flex flex-center"
                    onClick={() => {setFitFill(!fitFill)}}
                  >
                    <img src={hideShowIcon} alt="" />
                  </span>
                </div>}
            </div>
        </div>
      </div>
    </div>
  );
};
