import { Center, Spinner } from "@chakra-ui/react";
import React, { memo } from "react";
import { useEffect, useRef, useState } from "react";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";
import fitScreenIcon from "../../assets/images/fit-screen-icon.png";
import fillScreenIcon from "../../assets/images/fill-screen-icon.png";
import refreshIcon from "../../assets/images/refresh-icon.svg";
import refreshDarkIcon from "../../assets/images/refresh-dark-icon.png";
import verticallyDotsIcon from "../../assets/images/vertically-dots.svg";
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";
import { useSelector } from "react-redux";
import muteIcon from '../../assets/images/microphone-slash.svg';
import { isMobile } from "react-device-detect";

interface props {
  name: string;
  streams: any;
  sourceId: string;
  screenSharesourceId: string;
  mirror: boolean;
  videoTrack: boolean;
  remoteTrackSources: any;
  muteAllStream: boolean;
  recorder?: boolean;
 
}

export const ParticipentForAttendeeScreen = ({
  name,
  streams,
  sourceId,
  mirror,
  videoTrack,
  remoteTrackSources,
  muteAllStream,
  recorder,

}: // camera,
props) => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [activeSpeakerNames, setActiveSpeakerNames] = useState<string[]>([]);
  const [mutedSources, setMutedSources] = useState<Set<string>>(new Set());
const [isMutedUser, setIsMutedUser] = useState(false);
  // Initialise video

  //video speaker update
  function attachSinkId(element: HTMLAudioElement, sinkId: string) {
    if ("sinkId" in element) {
      (element as any)
        .setSinkId(sinkId)
        .then(() => {
        })
        .catch((error: Error) => {
          let errorMessage: string = error.message;
          if (error.name === "SecurityError") {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error.message}`;
          }
        });
    } else {
      console.warn("Browser does not support output device selection.");
      // Handle unsupported browser
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      attachSinkId(
        videoRef.current,
        stateData?.publishStreamObj?.speakerdeviceId
      );
    }
  }, [stateData?.publishStreamObj?.speakerdeviceId]);

  // Initialise video
  const messageRef = useRef(true);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
    }
  }, [remoteTrackSources]);

  //speaker volume change  start
  useEffect(() => {
    const newVolume = stateData?.publishStreamObj?.speakerVolume;
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  }, [stateData?.publishStreamObj?.speakerVolume]);
  //end speaker volume change

  // const getInitials = (name) => {
  //   const words = name.split(" ");
  //   const firstInitial = words[0]?.charAt(0).toUpperCase();
  //   const lastInitial =
  //     words.length > 1 ? words[words.length - 1].charAt(0).toUpperCase() : "";
  //   return `${firstInitial}${lastInitial}`;
  // };
  const getInitials = (name) => {
    if (!name || typeof name !== "string" || name.trim() === "") {
      return "ew"; // Return "UN" for invalid or empty input
    }
  
    const words = name.trim().split(/\s+/); // Trim and split the name by spaces
    const getValidInitial = (word) =>
      word.match(/[A-Za-z]/) ? word.match(/[A-Za-z]/)[0].toUpperCase() : ""; // Extract the first alphabetic character
  
    const firstInitial = words[0] ? getValidInitial(words[0]) : ""; // Safely get the first valid initial
    const lastInitial =
      words.length > 1 ? getValidInitial(words[words.length - 1]) : ""; // Get the last valid initial if there are multiple words
  
    const initials = `${firstInitial}${lastInitial}`; // Combine the initials
  
    return initials || "ew"; // Return "UN" if no valid initials are found
  };
  
  const displayName = name === "main" ? "Host" : name;
  const initials = displayName && getInitials(displayName);

  // const [loadingVideo, setLoadingVideo] = useState(true);


  const checkMuted = () => {};

  const [isFillContent, setIsFillContent] = useState(false);

  // const handleClick = () => {
  //   setIsFillContent(!isFillContent);
  // };

  const reLoad = () => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
    }
  };

  const randomValue1 = Math.random();
  const randomValue2 = Math.random();

  // Presenter dropdwon
  const dropdownRef = useRef(null);
  const [presenterMenuOpen, setPresenterMenuOpen] = useState(false);

  const handlePresenterDropdownClick = () => {
    setPresenterMenuOpen(!presenterMenuOpen);
  };

  const handleClickOutsideDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setPresenterMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDropdown);


    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserNodesRef = useRef<Map<string, AnalyserNode>>(new Map());
  const detectionIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const audioData = useRef(new Uint8Array(128)); // Adjust size based on `fftSize`

   useEffect(() => {
    if(!isMobile){
      const audioContext = new (window.AudioContext)();
      const analyserNodes = new Map<string, AnalyserNode>();
      const audioData = new Uint8Array(128); // Adjust size based on `fftSize`
      let detectionInterval: NodeJS.Timeout | null = null;
      // const newMutedSources = new Set<string>();
    
      if (remoteTrackSources instanceof Map) {
        Array.from(remoteTrackSources).forEach(([sourceId, { mediaStream }]) => {
          const audioTracks = mediaStream.getAudioTracks();
          if (audioTracks.length > 0) {
            const source = audioContext.createMediaStreamSource(mediaStream);
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 256; // Adjust for granularity
            source.connect(analyser);
            analyserNodes.set(sourceId, analyser);
          }
        });
    
        const detectActiveSpeakers = () => {
          const volumeThreshold = 2000; // Lower value for sensitivity
          const activeSpeakers: string[] = [];
        
          analyserNodes.forEach((analyser, sourceId) => {
            analyser.getByteFrequencyData(audioData);
            const volume = audioData.reduce((sum, value) => sum + value, 0);
            // console.log("volume", volume);
        
            // Add users with volume above the threshold
            if (volume > volumeThreshold) {
              activeSpeakers.push(sourceId);
            }
          });
        
          // Update the list of active speakers
          if (activeSpeakers.length > 0) {
            const speakerNames = activeSpeakers.map((id) => id.split(":")[0]);
            setActiveSpeakerNames(speakerNames); // Adjust state to handle an array of names
          } else {
            setActiveSpeakerNames([]); // Clear active speakers if none are speaking
          }
        };
    
        if (analyserNodes.size > 0) {
          detectionInterval = setInterval(detectActiveSpeakers, 100);
        }
      }
    
      return () => {
        if (detectionInterval) clearInterval(detectionInterval);
        analyserNodes.forEach((_, sourceId) => analyserNodes.delete(sourceId));
        audioContext.close();
      };

    }
    }, [remoteTrackSources,muteAllStream]);



    useEffect(() => {
      // Initialize AudioContext if not already initialized
      if (isMobile) {
        if (!audioContextRef.current) {
          audioContextRef.current = new (window.AudioContext)();
        }
    
        const audioContext = audioContextRef.current;
    
        // Resume the AudioContext if it is suspended
        if (audioContext.state === "suspended") {
          const resumeAudioContext = () => {
            audioContext.resume().then(() => {
              // console.log("AudioContext resumed");
              // Remove event listener after resuming
              document.removeEventListener("click", resumeAudioContext);
              document.removeEventListener("touchstart", resumeAudioContext);
            }).catch((error) => {
              console.error("Error resuming AudioContext:", error);
            });
          };
    
          // Attach an event listener to resume the AudioContext on user interaction
          document.addEventListener("click", resumeAudioContext);
          document.addEventListener("touchstart", resumeAudioContext);
        }
    
        if (remoteTrackSources instanceof Map) {
          // Add new media streams to the analyser if not already added
          Array.from(remoteTrackSources).forEach(([sourceId, { mediaStream }]) => {
            if (!analyserNodesRef.current.has(sourceId)) {
              const audioTracks = mediaStream.getAudioTracks();
              if (audioTracks.length > 0) {
                const source = audioContext.createMediaStreamSource(mediaStream);
                const analyser = audioContext.createAnalyser();
                analyser.fftSize = 256; // Adjust for granularity
                source.connect(analyser);
                analyserNodesRef.current.set(sourceId, analyser);
              }
            }
          });
    
          // Detect active speakers
          const detectActiveSpeakers = () => {
            const volumeThreshold = 1000; // Lower value for sensitivity
            const activeSpeakers: string[] = [];
            // console.log("analyserNodesRef.current", analyserNodesRef.current);
            analyserNodesRef.current.forEach((analyser, sourceId) => {
              analyser.getByteFrequencyData(audioData.current);
              const volume = audioData.current.reduce((sum, value) => sum + value, 0);
              // console.log("Volume for", sourceId, ":", volume);
    
              // Add users with volume above the threshold
              if (volume > volumeThreshold) {
                activeSpeakers.push(sourceId);
              }
            });
    
            // Update the list of active speakers
            if (activeSpeakers.length > 0) {
              const speakerNames = activeSpeakers.map((id) => id.split(":")[0]);
              setActiveSpeakerNames(speakerNames); // Adjust state to handle an array of names
            } else {
              setActiveSpeakerNames([]); // Clear active speakers if none are speaking
            }
          };
    
          // Start detection interval if not already running
          if (!detectionIntervalRef.current) {
            detectionIntervalRef.current = setInterval(detectActiveSpeakers, 100);
          }
        }
      }
    }, [remoteTrackSources, muteAllStream, isMobile]);
    
   useEffect(()=>{
   
   
     stateData?.publishStreamObj?.peoples?.map((p) => {
   
       if(p?.state?.userName === sourceId && (p?.state?.isAdmin || p?.state?.isPresentor)){
   
         if(p?.state?.mic === false){
          //  console.log('mic off',p.state.userName);
           setIsMutedUser(true);
         }else{
          //  console.log('mic on',p?.state?.userName);
           setIsMutedUser(false);
   
       }
   
     }
   }
   )
       
    
   
   },[stateData?.publishStreamObj?.peoples])

  return (
    <div
      className={`presenter-column ${
        isFillContent ? "fill-content" : "fit-content"
      } ${!videoTrack ? " cam-off" : ""}
         ${activeSpeakerNames.includes(sourceId) ? "active-speaker" : ""}`}
    >
      <div className="ew-video-wrapper">
        {/* {loadingVideo && videoTrack && (
          <div className="flex flex-center loader-bg">
            <div className="blur-bg"></div>
            <div className="flex flex-center section-loader">
              <img src={blueCircleLoader} alt="" />
              <span>Loading...</span>
            </div>
          </div>
        )} */}

        {muteAllStream == true && (
          <div className="presenter-options-dropdown" ref={dropdownRef}>
            <span
              className="flex flex-center"
              onClick={handlePresenterDropdownClick}
            >
              <img src={verticallyDotsIcon} alt="" />
            </span>
            {presenterMenuOpen && (
              <ul>
                <li className="flex" onClick={reLoad}>
                  <img src={refreshDarkIcon} alt="" />
                  <span>Refresh</span>
                </li>
                {/* <li className="flex" onClick={handleClick}>
                <img
                  src={isFillContent ? fitScreenIcon : fillScreenIcon}
                  alt=""
                />
                <span>
                  {isFillContent ? "Fit to screen" : "Fill to screen"}
                </span>
              </li> */}
              </ul>
            )}
          </div>
        )}
        {!videoTrack && (
          <div className="ew-presenter-profile flex flex-center">
            <div className="img-wrapper flex flex-center">{initials}</div>
            <div className="ew-presenter-title">
              <div className="name">{name == "main" ? "Host" : name}</div>
            </div>
          </div>
        )}
        <div className="ew-live-video">
          <video
            controls={false}
            autoPlay
            loop
            ref={videoRef}
            id={sourceId}
            muted={muteAllStream}
            onError={() => {
              videoRef.current?.error &&
                console.error(`Video player error: ${videoRef.current?.error}`);
            }}
            // onLoadStart={() => setLoadingVideo(true)}
            // onPlaying={() => {
            //   setLoadingVideo(false);
            //   messageRef.current = false;
            // }}
            onStalled={() => {
              console.error("Video is on stalled");
            }}
            // onWaiting={() => setLoadingVideo(true)}
            playsInline
            style={{
              transform:
                mirror &&
                stateData?.publishStreamObj?.camera &&
                sourceId == stateData?.publishStreamObj?.userName
                  ? "scaleX(-1)"
                  : "",
              // display: camera ? "" : "none",
            }}
          ></video>
        </div>

        {!recorder && (
          <div
            className="presenter-name flex flex-center"
            style={{
              display: !!videoTrack ? "" : "none",
            }}
          >
            {sourceId == "main" ? "Host" : sourceId}

            {isMutedUser && (
              <span className="flex flex-center user-speaker-status mute">
                <img className="mute" src={muteIcon} alt="mute icon" />
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ParticipentForAttendeeScreen);
