import React, { useState } from 'react';
import '../../assets/styles/main.scss';

import mp4Icon from '../../assets/images/mp4-icon.png';
import listNextIcon from '../../assets/images/list-next.png';
import uploadIcon from '../../assets/images/upload-icon.png';
import pptIcon from '../../assets/images/ppt-icon.png';
import pdfIcon from '../../assets/images/pdf-icon.png';
import checkedIcon from '../../assets/images/checked-icon.png';



export const ShareFilePopup = ({ isShareFileOpen, onShareFileClose }) => {

    const [selectedFile, setSelectedFile] = useState(null);

    // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle form submission (optional)
  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedFile) {
      console.log('Selected file:', selectedFile);
      // You can upload the file to a server here
    } else {
      console.log('No file selected');
    }
  };

  // Tabs
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
    
    return (

      <div className={`modal file-share-popup video-share ${isShareFileOpen ? 'active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-container with-header">
            <div className="flex modal-header">
                <h3 className='flex'>Share Files</h3>
                {/* Tab Buttons */}
                <div className="tabs sharing-tabs flex flex-center">
                    <button className={`flex ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>
                      Select from Library
                    </button>
                    <button className={`flex ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
                      Upload a New Presentation File
                    </button>
                </div>
                <span className="close-btn" onClick={onShareFileClose}>&times;</span>
            </div>
            {/* <form onSubmit={handleSubmit}> */}
                <div className="modal-content flex">

                  {/* Tab Content */}
                  <div className="tab-content" style={{ marginTop: '20px' }}>
                    {activeTab === 0 && 
                      <div className='library-tab'>
                        <div className='library-search'>
                          <input type="search" className='search-input' placeholder='Search' />
                        </div>

                        <div className="library-listing">
                          <ul className='flex'>
                            <li className='flex flex-center active'>
                              <img src={checkedIcon} className='checked' alt="" />
                              <span className='img'>
                                <img src={pptIcon} alt="" />
                              </span>
                              <span className='file-name'>Live-Demo-webinar.mp4</span>
                            </li>
                            <li className='flex flex-center'>
                              <span className='img'>
                                <img src={pdfIcon} alt="" />
                              </span>
                              <span className='file-name'>Live-Demo-webinar.mp4</span>
                            </li>
                            <li className='flex flex-center'>
                              <span className='img'>
                                <img src={pdfIcon} alt="" />
                              </span>
                              <span className='file-name'>Live-Demo-webinar.mp4</span>
                            </li>
                            <li className='flex flex-center'>
                              <span className='img'>
                                <img src={pptIcon} alt="" />
                              </span>
                              <span className='file-name'>Live-Demo-webinar.mp4</span>
                            </li>
                            <li className='flex flex-center'>
                              <span className='img'>
                                <img src={pptIcon} alt="" />
                              </span>
                              <span className='file-name'>Live-Demo-webinar.mp4</span>
                            </li>
                            <li className='flex flex-center'>
                              <span className='img'>
                                <img src={pptIcon} alt="" />
                              </span>
                              <span className='file-name'>Live-Demo-webinar.mp4</span>
                            </li>
                            <li className='flex flex-center'>
                              <span className='img'>
                                <img src={pdfIcon} alt="" />
                              </span>
                              <span className='file-name'>Live-Demo-webinar.mp4</span>
                            </li>
                          </ul>
                        </div>

                        <div className="flex flex-center library-pagination">
                          <ul className='flex'>
                            <li className='flex flex-center previous'><span><img src={listNextIcon} alt="" /></span></li>
                            <li className='flex flex-center'><span>1</span></li>
                            <li className='flex flex-center'><span>2</span></li>
                            <li className='flex flex-center'><span>3</span></li>
                            <li className='flex flex-center'><span>4</span></li>
                            <li className='flex flex-center'><span>5</span></li>
                            <li className='flex flex-center'><span>...</span></li>
                            <li className='flex flex-center'><span>13</span></li>
                            <li className='flex flex-center'><span><img src={listNextIcon} alt="" /></span></li>
                          </ul>
                        </div>
                      </div>
                    }

                    {activeTab === 1 && 
                      <div className='upload-tab'>
                        <div className="file-share-info">
                            {/* <label htmlFor="">Select a local file</label> */}
                            <div className="flex flex-center file-upload">
                                    <img src={uploadIcon} alt="" />                                    
                                    <label htmlFor="file-input" className='flex'>Select or drag a file from your computer to upload.
                                      <span>Supported formats: JPG, PNG, PDF, PPT.</span>
                                      <span>Max size: 10MB per file.</span>
                                    </label>
                                    <span className='select-file'>
                                      <input
                                      type="file"
                                      id="file-input"
                                      onChange={handleFileChange}
                                      />
                                      <label htmlFor="">Choose File</label>
                                    </span>
                                    {selectedFile && (
                                    <p><span>Selected file:</span> {selectedFile.name}</p>
                                    )}
                            </div>
                        </div>
                      </div>
                    }
                  </div>

                  
                    
                </div>
                <div className="modal-footer flex">
                    <div className="flex">
                        <button type="button" className="default-btn" onClick={onShareFileClose}>Cancel</button>
                        <button type="button" className="primary-button">Share</button>
                    </div>
                </div>
            {/* </form> */}
        </div>
    </div>
    );
};
