import { useEffect, useState } from 'react';

const usePermissionListener = () => {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    const checkPermissions = async () => {
      try {
        // Check if Permissions API is available (Chrome, some versions of Edge, etc.)
        if (navigator.permissions) {
          const cameraPermission = await navigator.permissions.query({ name: 'camera' as PermissionName });
          const microphonePermission = await navigator.permissions.query({ name: 'microphone' as PermissionName });

          // Handle initial permission state
          if (cameraPermission.state === 'denied' || microphonePermission.state === 'denied') {
            setShowPopup(true);
          }

          // Set up listeners for permission changes
          cameraPermission.onchange = () => {
            if (cameraPermission.state === 'denied') {
              setShowPopup(true);
            } else {
              // window.location.reload();
            }
          };

          microphonePermission.onchange = () => {
            if (microphonePermission.state === 'denied') {
              setShowPopup(true);
            } else {
              // window.location.reload();
            }
          };
        } else {
          // Fallback approach for browsers without Permissions API (like Safari)
          // const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
          // if (!stream) {
          //   setShowPopup(true);
          // }
        }
      } catch (error) {
        // If permissions are denied or an error occurs (e.g., in Safari)
        // setShowPopup(true);
        // const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        // if (!stream) {
        //   setShowPopup(true);
        // }
        console.error('Error checking permissions:', error);
      }
    };

    checkPermissions();
  }, []);

  return { showPopup, setShowPopup };
};

export default usePermissionListener;
