import React, { useState } from "react";
import "../../assets/styles/main.scss";

export const GenericConfirmMessagePopup = ({
  onAnswerPositive,
  onAnswerNegative,
  positiveButtonText,
  negativeButtontext,
  onCloseConfirmationPopUp,
  msgHeader,
  msgText
}) => {
  return (
    <div
      className={`modal confirmation-popup active`}
    >
      <div className="modal-background"></div>
      <div className="modal-container with-header">
        <div className="modal-header">
          <h3 className="flex">
            { msgHeader }
          </h3>
          <span className="close-btn" onClick={onCloseConfirmationPopUp}>
            &times;
          </span>
        </div>
        <div className="modal-content flex">
          <div className="confirmation-info">
              <p>
                { msgText }
              </p>
          </div>
        </div>
        <div className="modal-footer flex">
          <div className="flex">
            <button
              type="button"
              className="default-btn"
              onClick={onAnswerNegative}
            >
              { negativeButtontext }
            </button>
            <button
              type="button"
              className="warning-button"
              onClick={onAnswerPositive}
            >
              { positiveButtonText }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
