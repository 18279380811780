// export function userName(name: string) {
//   let arr = name.split(" ");
//   let result = "";
//   for (let i = 0; i < arr.length; i++) {
//     result += arr[i].charAt(0).toUpperCase();
//   }
//   return result;
// }

export function userName(name: string) {
  let arr = name.split(" ");
  let result = "";

  if (arr.length > 0) {
    result += arr[0].charAt(0).toUpperCase(); // First initial
  }

  if (arr.length > 1) {
    result += arr[arr.length - 1].charAt(0).toUpperCase(); // Last initial
  }

  return result;
}

export const getInitials = (name:any) => {
  if (!name || typeof name !== "string" || name.trim() === "") {
    return "ew"; // Return "UN" for invalid or empty input
  }

  const words = name.trim().split(/\s+/); // Trim and split the name by spaces
  const getValidInitial = (word) =>
    word.match(/[A-Za-z]/) ? word.match(/[A-Za-z]/)[0].toUpperCase() : ""; // Extract the first alphabetic character

  const firstInitial = words[0] ? getValidInitial(words[0]) : ""; // Safely get the first valid initial
  const lastInitial =
    words.length > 1 ? getValidInitial(words[words.length - 1]) : ""; // Get the last valid initial if there are multiple words

  const initials = `${firstInitial}${lastInitial}`; // Combine the initials

  return initials || "ew"; // Return "UN" if no valid initials are found
};

