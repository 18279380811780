import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useLayoutEffect,
  useMemo,
} from "react";
import axios from 'axios';
import { usePubNub } from "pubnub-react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";

// Importing Assets
import sendIcon from "../../assets/images/send-msg-icon.svg";
import noChatMsg from "../../assets/images/empty-chat.svg";
import msgReply from "../../assets/images/msg-reply-icon.svg";
import msgReplyHovered from "../../assets/images/msg-reply-hovered-icon.svg";
import msgDelete from "../../assets/images/msg-delete-icon.svg";
import msgDeleteHovered from "../../assets/images/msg-delete-hovered-icon.svg";
import closeIcon from "../../assets/images/close-icon.svg";
import backIcon from '../../assets/images/close-icon.svg';
import { MESSAGE_TYPE, MSG_ENM } from "../../libs/signal/signalEnum";
import { updateIsSideBar } from "../../Store/Actions/PublishStreamAction";
import downArrow from '../../assets/images/down-arrow-icon.svg';
import ChatsContainer from "./ChatsContainer";
import { allChatUrl, deleteChatUrl } from "../../config";
import { current } from "@reduxjs/toolkit";
import { getInitials } from "../../libs/Users/UsersName";
import { isMobileDevice } from "../../libs/UtilityFunctions/UtilFunctions";
interface props {
  userName?: string;
  handleMessageEventData?: any;
  indexValue?: number;
  crossIconHandler?: ()=>void;
}

const ChatComponent = forwardRef((props:props, ref) => {
  // Redux

 
  const dispatch = useDispatch();
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const isPublicChat = stateData.publishStreamObj.isPublicChat;
  // User Roles
  const isAdmin = stateData.publishStreamObj.isAdmin;
  const isModerator = stateData.publishStreamObj.isModerator;
  const isPresenter = stateData.publishStreamObj.isPresenter;
  const isAttendee = stateData.publishStreamObj.isAttendee;
  const currentUser = stateData.publishStreamObj.userName;
  const currentUserUUID = stateData.publishStreamObj.userUUID;
  const [isFocused, setIsFocused] = useState(false);
  // State Management
  const [userName, setUserName] = useState(
    stateData?.publishStreamObj?.userName
  );
  const editableDivRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  // const [channels] = useState([
  //   `${stateData.publishStreamObj.webinarid}`
  // ]);
  const [channels] = useState([
    `${
      "Chat." +
      stateData.publishStreamObj.webinarid +
      "." +
      stateData.publishStreamObj.sessionId
    }`,
  ]);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [lastTimetoken, setLastTimetoken] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [replyingTo, setReplyingTo] = useState(null);
  const [occupantUUIDs, setOccupantUUIDs] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [mentionedUser, setMentionedUser] = useState("");
  const [uuid, setuuid] = useState([]);
  const [mentionedUUID, setMentionedUUID] = useState(null);
  const [channelMetadata, setChannelMetadata] = useState<any>();
  const [channelMetadataPrivate, setChannelMetadataPrivate] = useState<any>();
  const channelName = `chatModeration.${stateData.publishStreamObj.webinarid}.${stateData.publishStreamObj.sessionId}`;
  const isFirstTimeScrolled = useRef<boolean>(false)
  const [showNewMessageWhenNotOnBottom,setShowNewMessageWhenNotOnBottom]=useState(false)
  const lastTimeTokenRef = useRef(null)
  //Hnadle message event start
  useImperativeHandle(ref, () => ({
    handleMessageHandler(event) {
      handleMessage(event);
    },
  }));
  // End

  // PubNub Initialization
  const pubnub = usePubNub();

  // Ref for scroll control
  const contentRef = useRef(null);
  // Fetch user list from Redux state
  const userlist = stateData.publishStreamObj.peoples;

  const [isDeletePopup,setIsdeletePopup]=useState("")

  const [isDeleting,setIsDeleting]=useState(false)
  const [isMobile, setIsMobile] = useState(false);


  // Calculat chat dynamic height
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
 
  useEffect(() => {
    // Function to update the height
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setIsLandscape(window.innerWidth > window.innerHeight);
    };
 
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
 
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);


  // Effect for setting occupantUUIDs from user list
  useEffect(() => {
    // Create a Map to store unique usernames with their corresponding UUIDs
    const uniqueUserMap = new Map();
  
    // Iterate over the user list
    for (const user of userlist) {
      if (user.state && user.state.userName && user.state.uuid) {
        // If the username doesn't already exist in the Map, add it
        if (!uniqueUserMap.has(user.state.userName)) {
          uniqueUserMap.set(user.state.userName, user.state.uuid);
        }
      }
    }
  
    // Convert the Map into an array of objects with name and uuid
    const updatedUsernames = [...uniqueUserMap.entries()].map(([name, uuid]) => ({ name, uuid }));
    // Update the state with the unique usernames and UUIDs
    setOccupantUUIDs(updatedUsernames);
  }, [userlist]);

  useEffect(() => {
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    const div = editableDivRef.current;
    if (div) {
      div.addEventListener("focus", handleFocus);
      div.addEventListener("blur", handleBlur);
    }

    return () => {
      if (div) {
        div.removeEventListener("focus", handleFocus);
        div.removeEventListener("blur", handleBlur);
      }
    };
  }, []);
  const placeholderMessage = useMemo(() => {
    if (isAttendee && !stateData.publishStreamObj.isChatPanel) {
      return "Chat is disabled by admin";
    } else if (!isFocused && !message) {
      return "Type your message here...";
    } else {
      return "";
    }
  }, [isAttendee, stateData.publishStreamObj.isChatPanel, isFocused, message]);



  // useEffect(() => {
  //   const uniqueUserUUIDs = new Set(uuid);
  //   const updatedUUIDs = [...uniqueUserUUIDs];

  //   for (const user of userlist) {
  //     if (user && user.uuid) {
  //       if (!uniqueUserUUIDs.has(user.uuid)) {
  //         updatedUUIDs.push(user.uuid);
  //         uniqueUserUUIDs.add(user.uuid);
  //       }
  //     }
  //   }

  //   setuuid(updatedUUIDs);
  // }, [userlist]);
  // Callback for handling incoming messages
  const handleMessage = useCallback((event) => {

    if (event.type == MESSAGE_TYPE.DELETE_MESSAGE) {
      setMessages((prevMessages) =>
        prevMessages.filter((message) => message.timetoken !== event.timeToken)
      );
      return;
    }

    if (
      event.publisher !== stateData.publishStreamObj.userUUID &&
      contentRef.current.scrollTop + contentRef.current.clientHeight <
        contentRef.current.scrollHeight
    ) {
      const isMentionedUser =
        event?.message?.meta.mentionedUUID &&
        stateData.publishStreamObj.userUUID ==
          event?.message?.meta.mentionedUUID;

      const isAdminOrModerator =
        stateData.publishStreamObj.isAdmin ||
        stateData.publishStreamObj.isModerator;

      const isPublicMessageWithoutMention =
        event?.message?.meta.msgType === MESSAGE_TYPE.PUBLIC &&
        !event?.message?.meta.mentionedUUID;

      const isPrivateMessageForNonAttendee =
        event?.message?.meta.msgType === MESSAGE_TYPE.PRIVATE &&
        !stateData.publishStreamObj.isAttendee;

      const shouldProcessMessage =
        isMentionedUser ||
        isAdminOrModerator ||
        isPublicMessageWithoutMention ||
        isPrivateMessageForNonAttendee;

      if (shouldProcessMessage) {
        setShowNewMessageWhenNotOnBottom(true);
      }
    } else {
      setShowNewMessageWhenNotOnBottom(false);
      // scrollToBottom()
    }
    const { message, timetoken } = event;
    const text = typeof message === "string" ? message : message.text;
    setMessages((prevMessages) => [
      ...prevMessages,
      { text, meta: message.meta, replyingTo: message.replyingTo, timetoken },
    ]);
  }, []);

  // Extract mentioned user from the message
  const extractMentionedUser = (message) => {
    const matches = message.match(/(\w+)/); // Match username without @
    return matches ? matches[1] : null;
  };
  const extractMentionedUserUUID = (message) => {
    // const matches = message.match(/@(\w+)/); // Match @username
    const matches = message.match(/(\w+)/); // Match username without @


    if (matches) {
      const username = matches[1];

      const user = userlist.find((user) => user?.state?.userName === username);
      if (user) {
        return user.uuid;
      } else {
        console.warn(`User not found for username: ${username}`);
      }
    }

    return null;
  };




  const [isSending, setIsSending] = useState(false);

const handleSendMessage = async (message) => {

    // const mentionedUser = extractMentionedUser(message);
    // setMentionedUser(mentionedUser);
    // const mentionedUserUUID = extractMentionedUserUUID(message);
    // setMentionedUUID(mentionedUserUUID);
    if (message !== placeholderMessage && message.trim() != "") {
      if (!isSending) {
        setIsSending(true);
        await sendMessage(message);
        setIsSending(false);
      }
    }
};

  const sendMessage = async (message) => {
    if (message) {
      // Prepare data for API request
      const apiData = {
        webinar_id: stateData.publishStreamObj.webinarid, // current webinar Id
        sid: stateData.publishStreamObj.sessionId, // current session Id
        chat_data: {
          senderInfo: {
            user_id: isAdmin
              ? "0"
              : isModerator || isPresenter
              ? stateData.publishStreamObj.userKey
              : stateData.publishStreamObj.attendeeData, // Replace with actual attendee key if needed
            user_name: userName, // Sender name
          },
          msg: message, // Message string
          is_cohost: isPresenter ? 1 : 0, // for cohost if sender is co-host 1 otherwise 0
          person_to: replyingTo
          ? isAdmin
          ? "0"
          : isModerator || isPresenter
          ? stateData.publishStreamObj.userKey
          : stateData.publishStreamObj.attendeeData
          :"-1", // for everyone -1; adjust as needed
          is_reply: replyingTo ? 1 : 0, // if Reply 1 otherwise 0
          is_reply_message : "",
          is_mod: isModerator ? 1 : 0, // for moderator if sender is moderator 1 otherwise 0
          is_green_room: stateData.publishStreamObj.isLiveGo ? 0 : 1, // send 1 if user is in green room, 0 otherwise
          msg_identity:replyingTo ? replyingTo.meta.msgIdentity:""
        },
      };
  
      try {
        // Call the API to store the message
        const response = await axios.post(allChatUrl, apiData);
      
  
        // Extract the msg_identity from the API response
        const msgIdentity = response.data.data.msg_identity;
        
  
        // Prepare the message to be published
        const newMessage = {
          text: message,
          meta: {
            msgType:
              !isPublicChat && stateData.publishStreamObj.isAttendee
                ? MESSAGE_TYPE.PRIVATE
                : MESSAGE_TYPE.PUBLIC,

            userUUID: currentUserUUID,
            mentionedUser,
            mentionedUUID,

            userName,
            role: isAdmin
              ? "Admin"
              : isModerator
              ? "Moderator"
              : isPresenter
              ? "Presenter"
              : "Attendee",
            msgIdentity, // Add msgIdentity here
          },
          replyingTo: replyingTo
            ? {
                text: replyingTo.text,
                userName: replyingTo.meta?.userName,
                role: replyingTo.meta?.role,
                timetoken: replyingTo.timetoken,
                msgIdentity: replyingTo.meta.msgIdentity, // Ensure this is set correctly
              }
            : null,
        };
  
        // Publish the message with PubNub
        await pubnub.publish({
          channel: `${
            "Chat." +
            stateData.publishStreamObj.webinarid +
            "." +
            stateData.publishStreamObj.sessionId
          }`,
          message: newMessage,
        });
        setMessage("");
        setReplyingTo(null);
         setMentionedUUID("")
         if (editableDivRef.current) {
          editableDivRef.current.innerHTML = ""; // Clear the contentEditable div
        }
      } catch (error) {
        console.error('Error storing message or publishing:', error);
      }
    }
  };

  const fetchMessages = (startTimetoken = null, count: number) => {
    pubnub.fetchMessages(
      {
        channels: [
          `${
            "Chat." +
            stateData.publishStreamObj.webinarid +
            "." +
            stateData.publishStreamObj.sessionId
          }`,
        ],
        count,
        start: startTimetoken,
      },
      (status, response) => {
        if (status.statusCode === 200) {
          const fetchedMessages =
            response.channels[
              `${
                "Chat." +
                stateData.publishStreamObj.webinarid +
                "." +
                stateData.publishStreamObj.sessionId
              }`
            ];
          if (fetchedMessages.length > 0) {
            const formattedMessages = fetchedMessages.map((msg) => ({
              text: msg.message.text,
              meta: msg.message.meta
                ? {
                    // isPrivate: msg.message.meta.isPrivate,
                    // mentionedUserUUID: msg.message.meta.mentionedUserUUID,
                    msgType:msg.message.meta.msgType,
                    userUUID: msg.message.meta.userUUID,
                    mentionedUser: msg.message.meta.mentionedUser,
                    userName: msg.message.meta.userName,
                    role: msg.message.meta.role,
                    msgIdentity: msg.message.meta.msgIdentity,
                    mentionedUUID:msg.message.meta.mentionedUUID
                  }
                : {},
              timetoken: msg.timetoken,
              replyingTo: msg.message.replyingTo
                ? {
                  msgType:msg.message.meta.msgType,
                    text: msg.message.replyingTo.text,
                    userName: msg.message.replyingTo.userName,
                    role: msg.message.replyingTo.role,
                    timetoken: msg.message.replyingTo.timetoken,
                    msgIdentity: msg.message.replyingTo.msgIdentity,
                  }
                : null,
            }));

            if (startTimetoken) {
              setMessages((prevMessages) => [
                ...formattedMessages,
                ...prevMessages,
              ]);
            } else {
              setMessages(formattedMessages);
            }

            if (fetchedMessages.length > 0) {
              // setLastTimetoken(fetchedMessages[0].timetoken);
              lastTimeTokenRef.current = fetchedMessages[0].timetoken;
            }
          }
        } else {
          console.error("Failed to fetch messages:", status);
          setLoadingMore(false);
        }
      }
    );
  };

  // Load initial messages on mount
  useEffect(() => {
    editableDivRef.current.focus()
    fetchMessages(null, 100);
  }, []);
  const isCurrentUserMentioned1 = (msg) => {
    const isMentioned = msg.meta.mentionedUserUUID === currentUserUUID;
    return isMentioned;
  };
  // Load more messages
  const loadMoreMessages = () => {

    if (lastTimeTokenRef.current && !loadingMore) {
      const { current } = contentRef;
      const previousScrollHeight = current ? current.scrollHeight : 0;
      setLoadingMore(true);
      fetchMessages(lastTimeTokenRef.current, 5);
      setTimeout(() => {
        if (current) {
          const newScrollHeight = current.scrollHeight;
          current.scrollTop = newScrollHeight - previousScrollHeight;
        }
      }, 1000);
    }
  };

  // Handle key press for sending messages
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // sendMessage(message);
      event.preventDefault();
      handleSendMessage(editableDivRef.current.innerText)
    }
  };

  

  // Delete a message
  const deleteMessage = async (timetoken) => {

    setIsDeleting(true)
    const messageToDelete = messages.find(
      (message) => message.timetoken === timetoken
    );
        // Prepare data for the API request
        const apiData = {
          webinar_id: stateData.publishStreamObj.webinarid, // Replace with your actual webinar ID
          user_id: isAdmin
          ? "0"
          : isModerator || isPresenter
          ? stateData.publishStreamObj.userKey
          : stateData.publishStreamObj.attendeeData, // Replace with your actual user ID
          msg_identity: messageToDelete.meta.msgIdentity, // Message identity to be deleted
        };
    
        // Call the API to delete the message
        await axios.post(deleteChatUrl, apiData);
    
    try {
      const result = await pubnub.deleteMessages({
        channel: channels[0],
        start:(BigInt(timetoken)- BigInt(1)).toString(),
        end: timetoken,
      });

      setIsdeletePopup(null)

      pubnub.signal({
        message: {
          type:MESSAGE_TYPE.DELETE_MESSAGE,
          timeToken:timetoken
        },
        channel: channels[0],
      });
      setIsDeleting(false)

      // setMessages((prevMessages) =>
      //   prevMessages.filter((message) => message.timetoken !== timetoken)
      // );
    } catch (status) {
      setIsDeleting(false)

      console.log("error ",status)
    }
  };

  

  // Handle scroll to load more messages
  const handleScroll = useCallback(
    debounce(() => {

      const { current } = contentRef;  

      if (current) {
        const scrollTop = current.scrollTop;
        const scrollHeight = current.scrollHeight;
        const clientHeight = current.clientHeight;

        // Update scroll position
        // current(scrollTop);

        // Check if the user is at the bottom
        if (scrollTop + clientHeight >= scrollHeight) {
          setShowNewMessageWhenNotOnBottom(false)
        }
      }




      if (current && current.scrollTop === 0) {
        loadMoreMessages();
      }
    }, 1000),
    [loadMoreMessages]
  );





  // Scroll to the bottom of the chat
  const scrollToBottom = () => {
    const { current } = contentRef;
    if (current) {

      current.scrollTop = current.scrollHeight;
       setShowNewMessageWhenNotOnBottom(false)
       
    }
  };

  const scrollToBottomFirstTime = () => {
    if(!isFirstTimeScrolled.current){
      scrollToBottom();
      isFirstTimeScrolled.current=true
    }
  }

  const handleDivChange = () => {
    const currentText = editableDivRef.current.innerText;
    handleInputChange({ target: { value: currentText } });
  };

  // Handle input change for message input
  const handleInputChange = (e) => {
    let message = e.target.value;
    setMessage(message);

    if(stateData?.publishStreamObj.isPrivateChat){
    let messageArr = message.split(" ");
    let lastWord = messageArr[messageArr.length - 1];

    if (lastWord.length > 0 && lastWord[0] === "@" ) {
      let keyword = lastWord.slice(1); // Remove '@' symbol
      let suggestedMembers = getMemberSuggestions(keyword);
      setSuggestions(suggestedMembers);
    } else {
      setSuggestions([]); // Clear suggestions if no '@' is present
    }
  }
  };

  // Get member suggestions for mentions
  const getMemberSuggestions = (keyword) => {
    // Filter the occupantUUIDs based on the keyword
    return occupantUUIDs.filter((userData) => {
      return userData && userData.name.toLowerCase().includes(keyword.toLowerCase()) && userData.uuid !== stateData.publishStreamObj.userUUID;
    });
  };

  // Handle suggestion click for mentions
  const handleSuggestionClick = (user) => {


    setMentionedUUID(user.uuid)
    if (editableDivRef.current) {
      const currText = editableDivRef.current?.innerHTML;

      if (currText) {
        const newText = currText.replace(/@\w+/g, '').replace('@', '');
        editableDivRef.current.innerHTML = newText;
        editableDivRef.current.focus();
        const mentionNode = document.createElement('span');
        mentionNode.textContent = `${user.name}`;
        mentionNode.style.color = 'blue';
        mentionNode.style.fontWeight = 'bold';
        editableDivRef.current.appendChild(mentionNode);

        const zeroWidthSpaceNode = document.createTextNode('\u200B');
        editableDivRef.current.appendChild(zeroWidthSpaceNode);

        // set cursor zeroWidthSpaceNode mention
        const newRange = document.createRange();
        newRange.setStartAfter(zeroWidthSpaceNode);
        newRange.collapse(true);
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(newRange);
        }

        setSuggestions([]);
      }
    }
    // Clear suggestions after clicking on a suggestion
    
  };

  const handleKeyDown = (e) => {
   
    const selection = window.getSelection();
    if (e.key === "Enter") {
      // sendMessage(message);
      e.preventDefault();
      handleSendMessage(editableDivRef.current.innerText)
    }else if (e.key === 'Backspace' && selection) {
      if (selection.rangeCount) {
        const range = selection.getRangeAt(0);
        const node = range.startContainer;

        // Check if the cursor is at the zero-width space
        if (node.nodeType === Node.TEXT_NODE) {
          const mentionNode = node.parentNode; // Get the parent (mention span)

          // Check if the parent node is a mention
          if ((mentionNode as Element)?.tagName === 'SPAN' && (mentionNode as HTMLElement).style.color === 'blue' ) {
            e.preventDefault(); // Prevent default backspace behavior
            const prevSibling = mentionNode.previousSibling;

            // Remove the mention node
            mentionNode?.parentNode?.removeChild(mentionNode);

            // Move the cursor to the previous sibling (if it exists)
            if (prevSibling) {
              const newRange = document.createRange();
              newRange.setStartAfter(prevSibling);
              newRange.collapse(true);
              selection.removeAllRanges();
              selection.addRange(newRange);
            }
          }
        }
      }
    }
  };

  // Set up event listeners and cleanup on mount/unmount
  useEffect(() => {
    const { current } = contentRef;
    if (current) {
      current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (current) {
        current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [contentRef]);

  // Set up PubNub listener and subscription
  

  // Handle loading more messages state
  useEffect(() => {
    if (loadingMore) {
      setLoadingMore(false);
    }

  }, [messages]);


  useLayoutEffect(() => {
    const { current } = contentRef;
    if (current && !showNewMessageWhenNotOnBottom && !loadingMore) {
      scrollToBottom()
    }
  }, [messages]);




 

  // Helper functions to get role-based class names and text
  const getRoleClassName = (role) => {
    switch (role) {
      case "Admin":
        return "host-msg";
      case "Moderator":
      case "Presenter":
        return "co-host-msg";
      case "Attendee":
        return "";
      default:
        return "";
    }
  };

  const getRoleClassName2 = (role) => {
    switch (role) {
      case "Admin":
        return "host";
      case "Moderator":
      case "Presenter":
        return "co-host";
      case "Attendee":
        return "";
      default:
        return "";
    }
  };

  const getRoleText = (role) => {
    switch (role) {
      case "Admin":
        return "(Host)";
      case "Moderator":
        return "(Moderator)";
      case "Presenter":
        return "(Co-host)";
      case "Attendee":
        return "";
      default:
        return "";
    }
  };

  // Close the chat component
  // const handleClose = () => {
  //   setIsVisible(false);
  // };

  const convertToClickableLinks = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.split(urlPattern).map((part, i) => {
      if (urlPattern.test(part)) {
        return (
          <a
            key={i}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'blue' }} // optional styling
          >
            {part}
          </a>
        );
      }
      return part; // return non-URL text as it is
    });
  };

  const highlightMentionedUser = (text, mentionedUserName, muuid) => {
    // Utility function to escape special characters in regex
    function escapeRegExp(string) {
      return string?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
    }
  
    // If `mentionedUserName` is provided, prioritize it
    if (mentionedUserName) {
      const escapedMentionedUserName = escapeRegExp(mentionedUserName); // Escaped name
      const parts = text?.split(new RegExp(`(${escapedMentionedUserName})`, 'gi')) || [];
  
      return (
        <>
          {parts.map((part, i) => (
            <span
              key={i}
              style={{
                color: part.trim() === mentionedUserName ? 'red' : 'black',
                fontWeight: part.trim() === mentionedUserName ? 'bold' : 'normal',
              }}
            >
              {convertToClickableLinks(part)}
            </span>
          ))}
        </>
      );
    }
  
    // If no `mentionedUserName`, check for `muuid`
    if (muuid) {
      const name = getUserNameFromId(muuid); // Get the name from `muuid`
      if (name) {
        const escapedName = escapeRegExp(name); // Escaped name
        const parts1 = text?.split(new RegExp(`(${escapedName})`, 'gi')) || [];
  
        return (
          <>
            {parts1.map((part, i) => (
              <span
                key={i}
                style={{
                  color: part.trim() === name ? 'blue' : 'black',
                }}
              >
                {convertToClickableLinks(part)}
              </span>
            ))}
          </>
        );
      }
    }
  
    // Default fallback if no matches
    return <p>{convertToClickableLinks(text)}</p>;
  };
  
  // const getInitials = (name) => {
  //   const words = name.split(/\s+/);
  //   const firstInitial = words[0]?.[0].toUpperCase();
  //   const lastInitial =
  //     words.length > 1 ? words[words.length - 1][0].toUpperCase() : "";
  //   return `${firstInitial}${lastInitial}`;
  // };

  

  const getUserNameFromId=(uuid)=>{

    return occupantUUIDs.filter((userData) => {
      return userData &&  userData.uuid == uuid;
    }).map((user)=>user.name)[0];

  }

  const getShouldProcessMessage = (msg, index) => {
    let mentionedUserName;

    const isMentionedUser =
      msg.meta.mentionedUUID &&
      stateData.publishStreamObj.userUUID == msg.meta.mentionedUUID;

    if (isMentionedUser) {
      mentionedUserName = getUserNameFromId(msg.meta.mentionedUUID);
    }

    const isAdminOrModerator =
      stateData.publishStreamObj.isAdmin ||
      stateData.publishStreamObj.isModerator;

    const isPublicMessageWithoutMention =
      msg.meta.msgType === MESSAGE_TYPE.PUBLIC && !msg.meta.mentionedUUID;

    const isPrivateMessageForNonAttendee =
      msg.meta.msgType === MESSAGE_TYPE.PRIVATE &&
      !stateData.publishStreamObj.isAttendee;

    const isSelfSendMessage = userName === msg.meta?.userName;

    const shouldProcessMessage =
      isMentionedUser ||
      isAdminOrModerator ||
      isPublicMessageWithoutMention ||
      isPrivateMessageForNonAttendee ||
      isSelfSendMessage;

    return shouldProcessMessage;
  };


  const calculateHeight = () => {

    if(isMobile){
      if(isLandscape) {
        if(stateData.publishStreamObj.isAttendee ||stateData.publishStreamObj.isModerator){

          if (stateData?.publishStreamObj?.isPrivateChat) {
            return windowHeight - 120 - 24;//not
          }else{
            return windowHeight - 120
  
          }
  
  
        }else{
  
          if (stateData?.publishStreamObj?.isPrivateChat) {
            return windowHeight - 120 - 24;
          }else{
            return windowHeight - 120
          }
  
        }
      }

      else{
        if(stateData.publishStreamObj.isAttendee ||stateData.publishStreamObj.isModerator){

          if (stateData?.publishStreamObj?.isPrivateChat) {
            return windowHeight - 190 - 24;//not
          }else{
            return windowHeight - 190
  
          }
  
  
        }else{
  
          if (stateData?.publishStreamObj?.isPrivateChat) {
            return windowHeight - 190 - 24;
          }else{
            return windowHeight - 190
          }
  
        }
      }

    }else{
      if(stateData.publishStreamObj.isAttendee ||stateData.publishStreamObj.isModerator){

        if (stateData?.publishStreamObj?.isPrivateChat) {
          return windowHeight - 120 - 24;//
        }else{
          return windowHeight - 120

        }


      }else{

        if (stateData?.publishStreamObj?.isPrivateChat) {
          return windowHeight - 190 - 24;
        }else{
          return windowHeight - 190
        }

      }

    }
  };

  // Usage example:
  // const initials = msg.meta?.userName && getInitials(msg.meta?.userName);

  return (
    <>
      <div
        className={`chat-sidebar-area ${
          !stateData?.publishStreamObj?.isPrivateChat
            ? "private-chat-disable"
            : ""
        }`}
      >
        <div
          className="sidebar-header"
          onClick={() => {
            dispatch(updateIsSideBar(false));
            props.crossIconHandler()
            // stateData.publishStreamObj.sideBarActive=false
          }}
        >
          <a href="#">
            <img src={backIcon} alt="" />
          </a>
          Chat
        </div>
        <div
          className="chat-thread"
          ref={contentRef}
          style={{
            height: calculateHeight()
          }}
        >
          {!messages.some((msg, index) =>
            getShouldProcessMessage(msg, index)
          ) ? (
            <div className="empty-area-msg flex-center">
              <img src={noChatMsg} alt="No Chat Messages" />
              <h4>Chat</h4>
              <p>Type a message to jump straight into a conversation</p>
            </div>
          ) : (
            messages.map((msg, index) => {
              let mentionedUserName;

              const isMentionedUser =
                msg.meta.mentionedUUID &&
                stateData.publishStreamObj.userUUID == msg.meta.mentionedUUID;

              if (isMentionedUser) {
                mentionedUserName = getUserNameFromId(msg.meta.mentionedUUID);
              }

              const isAdminOrModerator =
                stateData.publishStreamObj.isAdmin ||
                stateData.publishStreamObj.isModerator;

              const isPublicMessageWithoutMention =
                msg.meta.msgType === MESSAGE_TYPE.PUBLIC &&
                !msg.meta.mentionedUUID;

              const isPrivateMessageForNonAttendee =
                msg.meta.msgType === MESSAGE_TYPE.PRIVATE &&
                !stateData.publishStreamObj.isAttendee;

              const isSelfSendMessage = userName === msg.meta?.userName;

              const shouldProcessMessage =
                isMentionedUser ||
                isAdminOrModerator ||
                isPublicMessageWithoutMention ||
                isPrivateMessageForNonAttendee ||
                isSelfSendMessage;

              if (shouldProcessMessage) {
                return (
                  <ChatsContainer
                    scrollToBottom={scrollToBottomFirstTime}
                    key={index}
                  >
                    <div
                      key={index}
                      className={`flex chat-message ${
                        userName === msg.meta?.userName
                          ? `sent  
                        ${
                          msg.replyingTo &&
                          `reply-msg ${getRoleClassName2(msg.replyingTo?.role)}`
                        }`
                          : `received ${getRoleClassName(msg.meta?.role)}`
                      }  ${
                        msg.replyingTo &&
                        `replied-msg ${getRoleClassName2(msg.replyingTo?.role)}`
                      }`}
                    >
                      {userName === msg.meta?.userName ? (
                        <div className="flex message-box">
                          <div className="username">
                            <span className="msg-time">
                              {/* {new Date(parseInt(msg.timetoken) / 10000).toLocaleTimeString()} */}
                              {new Date(
                                parseInt(msg.timetoken) / 10000
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </div>
                          <div className="message-text">
                            {msg.replyingTo && (
                              <div className="replying-on-msg">
                                <div className="username">
                                  {msg.replyingTo.userName}
                                  {/* <span className='position co-host'> {msg.replyingTo.role && `(${msg.replyingTo.role})`}</span>
                                   */}
                                  <span className="position co-host">
                                    {" "}
                                    {msg.replyingTo.role !== "Attendee" &&
                                      `(${msg.replyingTo.role})`}
                                  </span>
                                  <span className="msg-time">
                                    {" "}
                                    {new Date(
                                      parseInt(msg.replyingTo.timetoken) / 10000
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </span>
                                </div>
                                <div className="message-text">
                                  <p>{msg.replyingTo.text}</p>
                                </div>
                              </div>
                            )}

                            {/* <p>{msg.text}</p> */}

                            {highlightMentionedUser(
                              msg.text,
                              mentionedUserName,
                              msg.meta.mentionedUUID
                            )}
                            <div className="chat-action">
                              <a
                                href="#"
                                className="delete-chat"
                                onClick={() => setIsdeletePopup(msg.timetoken)}
                              >
                                <img
                                  src={msgDelete}
                                  alt=""
                                  className="default"
                                />
                                <img
                                  src={msgDeleteHovered}
                                  alt=""
                                  className="hovered"
                                />
                              </a>
                              <a
                                href="#"
                                className="reply-chat"
                                onClick={() => setReplyingTo(msg)}
                              >
                                <img
                                  src={msgReply}
                                  alt=""
                                  className="default"
                                />
                                <img
                                  src={msgReplyHovered}
                                  alt=""
                                  className="hovered"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <>
                            {shouldProcessMessage && (
                              <>
                                <div className="user">
                                  <span className="flex flex-center name-tag">
                                    {/* {msg.meta?.userName &&
                                                                      msg.meta?.userName
                                                                        .split(/\s+/)
                                                                        .map(
                                                                          (word) => word && word[0].toUpperCase()
                                                                        )
                                                                        .join("")} */}
                                    {msg.meta?.userName &&
                                      getInitials(msg.meta?.userName)}
                                  </span>
                                </div>
                                <div className="flex message-box">
                                  <div className="username">
                                    {msg.meta?.userName}
                                    <span className="position">
                                      {getRoleText(msg.meta?.role)}
                                    </span>
                                    <span className="msg-time">
                                      {" "}
                                      {new Date(
                                        parseInt(msg.timetoken) / 10000
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                    </span>
                                  </div>
                                  <div className="message-text">
                                    {msg.replyingTo && (
                                      <div className="replying-on-msg">
                                        <div className="username">
                                          {msg.replyingTo.userName}
                                          <span className="position co-host">
                                            {" "}
                                            {msg.replyingTo.role !==
                                              "Attendee" &&
                                              `(${msg.replyingTo.role})`}
                                          </span>
                                          <span className="msg-time">
                                            {" "}
                                            {new Date(
                                              parseInt(
                                                msg.replyingTo.timetoken
                                              ) / 10000
                                            ).toLocaleTimeString([], {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            })}
                                          </span>
                                        </div>
                                        <div className="message-text">
                                          <p>{msg.replyingTo.text}</p>
                                        </div>
                                      </div>
                                    )}
                                    {highlightMentionedUser(
                                      msg.text,
                                      mentionedUserName,
                                      msg.meta.mentionedUUID
                                    )}

                                    <div className="chat-action">
                                      <a
                                        href="#"
                                        className="reply-chat"
                                        onClick={() => setReplyingTo(msg)}
                                      >
                                        <img
                                          src={msgReply}
                                          alt=""
                                          className="default"
                                        />
                                        <img
                                          src={msgReplyHovered}
                                          alt=""
                                          className="hovered"
                                        />
                                      </a>
                                      {!isAttendee && (
                                        <a
                                          href="#"
                                          className="delete-chat"
                                          onClick={() =>
                                            setIsdeletePopup(msg.timetoken)
                                          }
                                        >
                                          <img
                                            src={msgDelete}
                                            alt=""
                                            className="default"
                                          />
                                          <img
                                            src={msgDeleteHovered}
                                            alt=""
                                            className="hovered"
                                          />
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        </>
                      )}
                    </div>
                  </ChatsContainer>
                );
              }
            })
          )}
        </div>

        <div className="sticky-message-footer">
          {isAttendee && !stateData.publishStreamObj.isChatPanel ? (
            <></>
          ) : (
            <div className="flex flex-center private-msg">
              <p>For private message, press “@” to mention users</p>
              {/* <a href="#">
              <img src={closeIcon} alt="" />
          </a> */}
            </div>
          )}
          {replyingTo && (
            <div className="msg-to-reply">
              <div className="chat-message">
                <div className="flex message-box">
                  <div className="username">
                    {replyingTo.meta?.userName}
                    {/* <span className="position">    {replyingTo.meta && `(${replyingTo.meta?.role})`}</span> */}
                    <span className="position">
                      {replyingTo.meta?.role !== "Attendee" &&
                        `(${replyingTo.meta?.role})`}
                    </span>

                    <span className="msg-time">
                      {" "}
                      {new Date(
                        parseInt(replyingTo.timetoken) / 10000
                      ).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                      {/* {msg.replyingTo.timetoken} */}
                    </span>
                  </div>
                  <div className="message-text">
                    <p>{replyingTo.text}</p>
                  </div>
                </div>
              </div>
              <a
                href="#"
                className="cancel-reply"
                onClick={() => setReplyingTo(null)}
              >
                <img src={closeIcon} alt="" />
              </a>
            </div>
          )}
        </div>
      </div>

      {showNewMessageWhenNotOnBottom && (
        <div className="new-message-notify">
          <span
            className="flex flex-center"
            onClick={() => {
              scrollToBottom();
            }}
          >
            <img src={downArrow} alt="" /> New Messages
          </span>
        </div>
      )}
      <div
        className={`flex chat-message-input ${
          isAttendee && !stateData.publishStreamObj.isChatPanel
            ? "chat-disabled"
            : ""
        }`}
      >
        {/* {isAttendee && !stateData.publishStreamObj.isChatPanel ?(
        <span className="disabled-text">Chat is disabled by admin</span>):( */}

        <>
          <div
            ref={editableDivRef}
            contentEditable={
              !(
                stateData.publishStreamObj.isAttendee &&
                !stateData.publishStreamObj.isChatPanel
              )
            }
            className={`input-sm ${
              stateData.publishStreamObj.isAttendee &&
              !stateData.publishStreamObj.isChatPanel
                ? "disabled"
                : ""
            }`}
            placeholder={
              isAttendee && !stateData.publishStreamObj.isChatPanel
                ? "Chat is disabled by admin"
                : "Type your message here..."
            }
            onInput={handleDivChange} // Handles text changes
            onKeyDown={handleKeyDown}
            suppressContentEditableWarning={true}
            style={{ width: 300 }} // React's warning for contentEditable
          >
            {placeholderMessage}
          </div>
          <div className="private-chat-user-list">
            {/* <input
      type="search"
      className="input-sm"
      placeholder="Search users..."
    /> */}
            <ul>
              {suggestions.map((member, index) => (
                <li key={index} onClick={() => handleSuggestionClick(member)}>
                  {member.name}
                </li>
              ))}
            </ul>
          </div>
          <button
            className="button primary-button icon"
            // onClick={() => sendMessage(message)}
            onClick={() => handleSendMessage(editableDivRef?.current.innerText)}
            disabled={isSending}
          >
            <img src={sendIcon} alt="Send Message" />
          </button>
        </>

        {/* // )} */}
      </div>

      {isDeletePopup && (
        <div className="modal confirmation-popup delete-confirmation thin-scrollbar">
          <div className="modal-background"></div>
          <div className="modal-container with-header">
            <div className="modal-header">
              <h3>Delete Message</h3>
            </div>
            <div className="modal-content flex">
              <div className="confirmation-info">
                <p>Are you sure you want to delete this message?</p>
              </div>
            </div>

            <div className="modal-footer">
              <div className="flex">
                <button
                  type="button"
                  className={`default-btn btn-sm ${
                    isDeleting ? "disabled-button" : ""
                  }`}
                  onClick={() => setIsdeletePopup(null)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`warning-button btn-sm ${
                    isDeleting ? "disabled-button" : ""
                  }`}
                  onClick={() => deleteMessage(isDeletePopup)}
                >
                  {isDeleting ? "Deleting...." : "Delete"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default ChatComponent;
