import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { Settingpopup } from "./designer/Settingpopup/Settingpopup";
import { LiveScreenDesign } from "./designer/Livescreen/Livescreen";
import { AttendeeScreenDesign } from "./designer/Attendeescreen/Attendeescreen";
import { ThankYou } from "./designer/ThankYou/ThankYou";
import { ForbiddenError } from "./designer/ForbiddenError/ForbiddenError";

import { Provider } from "react-redux";
import store from "./Store/Store";
import { AttendeeMain } from "./components/Attendee/AttendeeMain";
import { Moderator } from "./components/Moderator/Moderator";
import { Admin } from "./components/Presentor/Admin";
import { Presenter } from "./components/Presentor/Presenter";
import Thanks from "./components/join/thanks";
import { ToastContainer } from "react-toastify";
import usePermissionListener from "./libs/hooks/PermissionListener ";
import { AccessibilityPopup } from "./components/Presentor/AccessibilityPopup";
import { useNetworkStatus } from "./libs/hooks/NetworkStabilityListner";
import { RecordingLayout } from "./designer/RecordingLayout/RecordingLayout";
import { baseUrl } from "./config";
import { ErrorBoundary } from "@sentry/react";
import { Closed } from "./components/join/Closed";
import { Recorder } from "./components/Attendee/Recorder";

// function App() {
const App: React.FC = () => {
  const { showPopup, setShowPopup } = usePermissionListener();
  const networkStatus = useNetworkStatus();
  return (
    <ErrorBoundary>
    <BrowserRouter basename="/live-room">
      <Provider store={store}>
        <ToastContainer />
        <AccessibilityPopup showPopup={showPopup} setShowPopup={setShowPopup} />
        <Routes>
          <Route path="/Thanks" element={<Thanks />} />
          <Route path="/closed" element={<Closed />} />
          <Route path="/webinar-live-go" element={<Admin />} />
          <Route path="/attendee" element={<AttendeeMain />} />
          <Route path="/recorder" element={<Recorder />} />
          <Route path="/moderator/" element={<Moderator />} />
          <Route path="/presenter/" element={<Presenter />} />

          {/* designer routes   */}
          <Route path="/design/popup" element={<Settingpopup />} />
          <Route path="/design/greenRoom" element={<LiveScreenDesign />} />
          <Route path="/design/attendee" element={<AttendeeScreenDesign />} />
          <Route path="/design/thankyou" element={<ThankYou />} />
          <Route path="/design/recording" element={<RecordingLayout />} />
          <Route path="/design/forbidden" element={<ForbiddenError />} />
        </Routes>
      </Provider>
    </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
