import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateIsLive } from "../../Store/Actions/PublishStreamAction";
import { updateIsTimer } from "../../Store/Actions/PublishStreamAction";
import infoIcon from "../../assets/images/info-icon.png";
import { RootState } from "../../Store/Reducers/PublishStreamReducer";
import { usePubNub } from "pubnub-react";

const LaunchEventPopUp = ({setShowLaunchPopup}) => {
  const pubnubobj = usePubNub();
  const stateData = useSelector(
    (state: RootState) => state.PublishStreamReducer
  );
  const dispatch = useDispatch();
  const handleIsLiveClickCross = () => {

    setShowLaunchPopup(false)
    // dispatch(updateIsLive(false));
  };
  const handleIsTimerClick = () => {

    console.log("stateData.publishStreamObj.webinarid",stateData.publishStreamObj.webinarid)
    dispatch(updateIsLive(true));
    const ChatchannelName =
    "Webinar." +
    stateData.publishStreamObj.webinarid +
    "." +
    stateData.publishStreamObj.sessionId;

  pubnubobj.objects.setChannelMetadata({
    channel: ChatchannelName,
    data: {
      name: "Webinar",
      description: "Webinar Status",
      custom: {
        webinarId: stateData.publishStreamObj.webinarid,
        sessionId: stateData.publishStreamObj.sessionId,
        satus: "liveroom",
      },
    },
  });
    setShowLaunchPopup(false)


    dispatch(updateIsTimer(true));
    dispatch(updateIsLive(false));
  };
  return (
    <div>
      <div className="modal-background"></div>
      <div className="modal-container with-header">
        <div className="modal-header">
          <h3>Launch the Event?</h3>
          <span className="close-btn" onClick={handleIsLiveClickCross}>
            &times;
          </span>
        </div>
        <div className="modal-content flex launch-event-info">
          <ul className="flex">
            <li>
              People in waiting Area will automatically be redirected to join
              the webinar once you go live.
            </li>
            <li>
              Make sure you have created desired offers and polls before going live.
            </li>
            {/* <li>Your chats will be cleared once you are live.</li> */}
          </ul>
          <span className="co-host-info">
            <img src={infoIcon} alt="" />
            You can invite upto 3 Co-host during a single session
          </span>

          <button
            type="button"
            className="primary-button"
            onClick={handleIsTimerClick}
          >
            Go Live
          </button>
        </div>
      </div>
    </div>
  );
};

export default LaunchEventPopUp;
