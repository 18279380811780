interface CanvasProps {
  videoRef?: any;
  canvasRef?: any;
  muted?: boolean;
  camera?: boolean;
}

export const CanvasDisplay = ({
  videoRef,
  canvasRef,
  muted,
  camera,
}: CanvasProps) => {
  return (
    <>
      <video
        style={{ display: camera ? "" : "none" }}
        autoPlay
        playsInline
        id="myVidPlayer"
        test-id="videoView"
        // muted={muted}
        muted={true}
        ref={videoRef}
      >
        <span>
          <svg
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.49015 5.5898C8.00427 5.5898 8.42152 5.17254 8.42152 4.65842C8.42152 4.14431 8.00427 3.72705 7.49015 3.72705H4.41196C3.89785 3.72705 3.48059 4.14431 3.48059 4.65842C3.48059 5.17254 3.89785 5.5898 4.41196 5.5898H7.49015Z"
              fill="white"
            />
            <path
              d="M18.8415 1.00318C18.5463 0.838323 18.183 0.847636 17.8962 1.02739L14.4156 3.20122C14.1865 1.68494 12.8863 0.515137 11.3077 0.515137H3.83432C2.09452 0.515137 0.678833 1.93082 0.678833 3.67063V5.82862C0.678833 6.34273 1.09609 6.75999 1.61021 6.75999C2.12432 6.75999 2.54158 6.34273 2.54158 5.82862V3.67063C2.54158 2.9572 3.12182 2.37788 3.83432 2.37788H11.3086C12.022 2.37788 12.6013 2.95813 12.6013 3.67063V10.329C12.6013 11.0424 12.0211 11.6218 11.3086 11.6218H3.83432C3.12089 11.6218 2.54158 11.0415 2.54158 10.329V9.00925C2.54158 8.49514 2.12432 8.07788 1.61021 8.07788C1.09609 8.07788 0.678833 8.49514 0.678833 9.00925V10.329C0.678833 12.0688 2.09452 13.4845 3.83432 13.4845H11.3086C12.8882 13.4845 14.1884 12.3138 14.4166 10.7975L17.8962 12.9722C18.0471 13.0663 18.2184 13.1138 18.3898 13.1138C18.5454 13.1138 18.7 13.0747 18.8415 12.9974C19.1377 12.8335 19.3212 12.5215 19.3212 12.1824V1.8172C19.3212 1.47911 19.1377 1.1671 18.8415 1.00318ZM17.4584 10.5022L14.4641 8.63019V5.36852L17.4584 3.49739V10.5022Z"
              fill="white"
            />
          </svg>
        </span>
      </video>
      <canvas
        ref={canvasRef}
        id="myVidPlayer"
        test-id="videoView"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      />
    </>
  );
};
