import React, { useRef, useState, useEffect } from 'react';
import useCanCaptureStream from '../../libs/hooks/CanCaptureStream';
import { useDispatch, useSelector } from 'react-redux';
import PublishStream from '../../libs/DolbyStreaming/PublishStream';
import { updateIsScreen, updateISScreenSharedByYou } from '../../Store/Actions/PublishStreamAction';
import { usePubNub } from 'pubnub-react';

import fileDeleteIcon from "../../assets/images/msg-delete-icon.svg";
import uploadIcon from '../../assets/images/upload-icon.png';
import store from '../../Store/Store';
import { getRequestWithAuth } from '../../libs/APIs/apiCall';
import ShareVideoFromLibrary from './ShareVideoFromLibrary';
import ShareVideoFromLibraryVideos from './ShareVideoFromLibraryVideos';
import { learnMorePlaybackUrl } from '../../config';
// Adjust the import path as necessary

export const ShareFilePopup = ({ isShareFileOpen, onShareFileClose,handleLocalVideoStream, onShareFileCancel }) => {

  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();
 
  let obj: PublishStream = stateData?.publishStreamObj?.obj;
  const isScreenShared=stateData.publishStreamObj.isScreen
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canCaptureStream = useCanCaptureStream();
  const [videoURL, setVideoURL] = useState<string | null>(null); // Store the video URL
  const [activeTab, setActiveTab] = useState(0);
  const [inputVideoURL, setInputVideoURL] = useState<string | null>(null); // State to store the video URL from input
  const handleVideoURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputVideoURL(event.target.value);
  };
  const handleTabClick = (tabIndex) => {
    setSelectedFile(null)
    setActiveTab(tabIndex);
  };
  //pubnub start
  const pubnubobj = usePubNub();

    const [selectedFile, setSelectedFile] = useState(null);

    // Handle file selection
  // const handleFileChange = (event) => {
  //   setSelectedFile(event.target.files[0]);
  // };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file); // Only set the file, don't handle streaming here
  };

  useEffect(()=>{

    if(!isScreenShared && videoURL){
      videoRef.current.src=null;
    }else{

    }

  },[isScreenShared])


  const handleAddStreamingFile = async() => {
    console.log(stateData?.publishStreamObj?.isScreenSharedByYou)
    // dispatch(updateISScreenSharedByYou(false))
   
  
    if (selectedFile) {
      let newVideoURL;
      console.log("type of",typeof selectedFile )
      if((typeof selectedFile === "string")){
       
        newVideoURL = selectedFile;

      }else{
        newVideoURL = URL.createObjectURL(selectedFile);
      }
     
      // obj.startLocalVideoShare(newVideoURL); // Start sharing video file
      // setVideoURL(newVideoURL); // Update video URL state
      //  dispatch(updateIsScreen(true));
    
      handleLocalVideoStream(newVideoURL)
      const WebinarchannelName = `Webinar.${stateData.publishStreamObj.webinarid}.${stateData.publishStreamObj.sessionId}`;
      pubnubobj.signal({
        message: {
          name: stateData?.publishStreamObj?.userName,
          screen: true,
        },
        channel: WebinarchannelName,
      });
  
      // videoRef.current.onloadeddata = () => {
      //   const stream = (videoRef.current as any).captureStream?.();
      //   if (stream) {

        
      //     // Use the Dolby API to send the stream
      //       obj.startLocalVideoShare(stream); // Replace with actual Dolby method
      //     console.log("Stream captured and sent to Dolby:", stream);
      //   } else {
      //     console.error("Failed to capture video stream.");
      //   }
      // };
      onShareFileClose();
    } else {
      console.log('No file selected.');
    }
  };


  
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.src = videoURL || ''; // Set video source
      const playVideo = () => {
        videoRef.current?.play().catch(error => {
          console.error("Error playing video:", error); // Handle any errors while trying to play
        });
      };
     
      // Add event listener for when video is loaded and ready to play
      videoRef.current.addEventListener('loadeddata', playVideo);

      // Cleanup: remove event listener on component unmount or when videoURL changes
      return () => {
        videoRef.current?.removeEventListener('loadeddata', playVideo);
      };
    }
  }, [videoURL]); // Run this effect when videoURL changes

  // Check if videoRef is set after component mounts
  useEffect(() => {
    if (videoRef.current) {
      console.log("Video reference is set:", videoRef.current);
    } else {
      console.error("Video reference is not set.");
    


    }
  }, [videoURL]); // Run this effect when videoURL changes
  
    
  useEffect(() => {
    if (isShareFileOpen) {
      // Clear the selected file and video URL when the popup opens
      setSelectedFile(null);
      setVideoURL(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Reset the file input value
      }
    }
  }, [isShareFileOpen]);


    return (
      <>
        <div className={`modal file-share-popup video-share ${isShareFileOpen ? 'active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-container with-header">
                <div className="flex modal-header">
                    <h3 className='flex'>Share Video</h3>
                    {/* Tab Buttons */}
                    <div className="tabs sharing-tabs flex flex-center">
                        <button className={`flex ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>
                        Share from Videos
                        </button> 
                        <button className={`flex ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
                        Share from Recordings
                        </button> 
                         <button className={`flex ${activeTab === 2? 'active' : ''}`} onClick={() => handleTabClick(2)}>
                          Share a local video
                        </button>
                    </div>
                    <span className="close-btn" onClick={onShareFileCancel}>&times;</span>
                </div>
                    <div className="modal-content thin-scrollbar flex">

                      <div className="tab-content" style={{ marginTop: '20px' }}>
                       {(()=>{
                        switch (activeTab) {
                          case 0:
                            return  <ShareVideoFromLibraryVideos   setSelectedFile={setSelectedFile}/>
                            
                          case 1:
                            return  <ShareVideoFromLibrary   setSelectedFile={setSelectedFile}/>
                            
                          case 2:
                            return <div className='upload-tab'>
                            <div className="file-share-info">
                                {/* <label htmlFor="">Select a local file</label> */}
                                <div className="flex flex-center file-upload">
                                        <img src={uploadIcon} alt="" />                                    
                                        <label htmlFor="file-input" className='flex'>Select a video from your computer
                                          {/* <span>(MP4 format only)</span> */}
                                        </label>
                                        <span className='select-file'>
                                            <input
                                              type="file"
                                              id="file-input"
                                              ref={fileInputRef}
                                              // style={{ display: 'none' }}
                                              // accept=".mp4, .mov,video/mp4, video/quicktime"
                                              accept="video/mp4, video/webm, video/ogg,  video/mpeg, video/quicktime"
                                              onChange={handleFileSelect}
                                            
                                            />     
                                          <label htmlFor="">Choose File</label>
                                        </span>
                                        {selectedFile && (
                                          <>
                                            <p><span>Selected file:</span> {selectedFile.name}</p>
                                            {/* <button type="button" className="remove-file-btn" onClick={() => setSelectedFile(null)}>
                                              <img src={fileDeleteIcon} alt="" />
                                            </button> */}
                                          </>                                       
                                        
                                        )}
                                </div>
                            </div>
                          </div>  

                        
                          default:
                            break;
                        }
                       })()}
                      </div>

                      
                        
                    </div>
                    <div className="modal-footer flex">
                        <div className="flex">
                        <div className="note" style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%'
                    }}>
                    <label style={{
                      width: 'auto',
                      background: '#eaffd7',
                      margin: '0 0 7px 0',
                      padding: '5px 10px',
                      borderRadius: '4px',
                      fontSize: '14px'
                    }}><strong>Note:</strong> Ensure file permissions are updated to avoid playback issues during the webinar. <a href={learnMorePlaybackUrl }target="_blank" style={{ color: '#007bff', textDecoration: 'none' }}>
                    Learn More
                  </a></label></div>
                            <button type="button" className="default-btn" onClick={onShareFileCancel}>Cancel</button>
                            {/* <button type="button" className= {`primary-button ${!selectedFile?"btn disabled-button":""}`}
                                disabled={!selectedFile} 
                                onClick={handleAddStreamingFile}>
                                  Share
                            </button> */}
                            <button
                              type="button"
                              className={`primary-button ${(!selectedFile ) ? "btn disabled-button" : ""}`}
                              disabled={!selectedFile && !inputVideoURL}
                              onClick={handleAddStreamingFile}
                            >
                              Share
                            </button>

                        </div>
                    </div>
                {/* </form> */}
            </div>
          
        </div>
        </>
    );
};
