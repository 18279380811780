import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PPTPDFSelectUpload from '../select-upload-ppt-popup/select-upload-ppt-popup';
// Adjust the import path as necessary
import Select from 'react-select';
import { awsPFileS3AccessBucket, awsPFileS3AccessId, awsPFileS3AccessKey, awsPFileS3AccessRegion, getPFIleUrl, listPFIlesUrl, savePFIlesUrl } from '../../config';
import { getRequestWithAuth, postRequest, postRequestWithAuth } from '../../libs/APIs/apiCall';
import { RootState } from '../../Store/Reducers/PublishStreamReducer';
import * as Sentry from '@sentry/react';
import '../../assets/styles/main.scss';

import mp4Icon from '../../assets/images/mp4-icon.png';
import listNextIcon from '../../assets/images/list-next.png';
import uploadIcon from '../../assets/images/upload-icon.png';
import pptIcon from '../../assets/images/ppt-icon.png';
import pdfIcon from '../../assets/images/pdf-icon.png';
import checkedIcon from '../../assets/images/checked-icon.png';
import AWS from 'aws-sdk';
import { usePubNub } from 'pubnub-react';

const S3_BUCKET = awsPFileS3AccessBucket

AWS.config.update({
  accessKeyId: awsPFileS3AccessId,
  secretAccessKey: awsPFileS3AccessKey,
  region: awsPFileS3AccessRegion,
});

const s3 = new AWS.S3();

export const SelectPPTPopup = ({ closeSharePPTPopupClose, handlePPTFileSelect, cancelSharePPTPopup }) => {
  const stateData = useSelector((state: RootState) => state.PublishStreamReducer); 
  const [selectedFile, setSelectedFile] = useState(null);
  const [showUploadOld, setShowUploadOld] = useState(true);
  type selectFileOptionsType = {
    file_id: string, file_name: string, status: number, processTime: number, 
    uploadTime: number, file_page_count: number, v1: boolean, file_extn: string, file_url: string, 
    file_url_id: string
  }
  const [selectFileOptions, setSelectFileOptions] = useState<selectFileOptionsType[]>([])
  const [fullSelectFileOptions, setFullSelectFileOptions] = useState<selectFileOptionsType[]>([])
  const [selectFileOptionIndices, setSelectFileOptionIndices] = useState<number[]>([])
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
  const [searchtext, setSearchtext] = useState('');  
  const [file, setFile] = useState(null);
  const file_id = useRef(null);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [showCheck, setShowCheck] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showChoose, setShowChoose] = useState(true);
  const checkInterval = useRef<NodeJS.Timer>(null)
  const current_channel = useRef<string>('');
  const [conversionProgress, setConversionProgress] = useState(0);
  const [showConversionProgress, setShowConversionProgress] = useState(false);
  const current_progress = useRef<number>(0);
  const current_uploaded_file = useRef<string>(null);

  
  const decodeJWT = () => {
    const ew_jwt_token = localStorage.getItem("ew_jwt_token");
    if(ew_jwt_token) {
      const parts = ew_jwt_token.split('.');
      if (parts.length !== 3) {
        Sentry.captureMessage('JWT-invalid- '+ew_jwt_token);
      }  
      const payload = parts[1];
      const decodedPayload = JSON.parse(atob(payload.replace(/-/g, '+').replace(/_/g, '/')))
      if(decodedPayload && decodedPayload.data && decodedPayload.data.user_id) return decodedPayload.data.user_id
    }
    Sentry.captureMessage('JWT-not-found- '+ew_jwt_token);
    return null
  }

  const handleAddStreamingFile = () => {
    if (selectedFile) {    
      handlePPTFileSelect(selectedFile)
      closeSharePPTPopupClose();
    } else {
      console.log('No file selected.');
    }
  }; 

  const handleAddCurrentStreamingFile = () => {
    if (current_uploaded_file.current) {    
      handlePPTFileSelect(current_uploaded_file.current)
      closeSharePPTPopupClose();
    } else {
      console.log('No file uploaded');
    }
  };

  const cancelStreamingFile = () => {
    setSelectedFile(null)
    closeSharePPTPopupClose();
  }; 

  const onPFileProcessed = async () => {
    await fetchPFileList()
  }

  useEffect(() => {
    const total = selectFileOptions.length
    const indices = Math.ceil(total/10)
    const arr = []
    for (let index = 0; index < indices; index++) {
      arr.push(index)
    }
    setSelectFileOptionIndices(arr)
    setMaxIndex(indices)
    setSelectedIndex(0)
  }, [selectFileOptions]);
    
  const fetchPFileList = async() => {
    try {
      let user_id
      if(stateData?.publishStreamObj?.isAdmin){
        user_id = decodeJWT()
      }else if(stateData?.publishStreamObj?.isPresenter) {
        user_id = stateData?.publishStreamObj?.adminUserId
      }
      if(!user_id) {
        setMessage('Could not verify user identification, please contact admin');
        Sentry.captureMessage('USRID-invalid-fetch- '+stateData?.publishStreamObj?.webinarid+' '+stateData?.publishStreamObj?.sessionId);
        return
      }
      const res = 
      await postRequestWithAuth(listPFIlesUrl, {
        user_id: +user_id
      });

      if (res?.status == "error") {
        // setErrorMessage(res.message);
        // setLoader(false);
        return;
      }
      if(res.data && res.data.records){
        setFullSelectFileOptions(res.data.records)
        setSelectFileOptions(res.data.records)
      }
    } catch (error) {
    //   setErrorMessage(error.message);
    //   setLoader(false);
    }
  }

  const handleFileChange = (event) => {
    setMessage('');    
    setShowProgress(false)
    setShowConversionProgress(false)
    setProgress(0)
    setConversionProgress(0)
    current_progress.current = 0
    if(!event.target.files || !event.target.files[0]) return
    setFile(event.target.files[0]);
    if(selectFileOptions.some(f => f.file_name === event.target.files[0].name.toLowerCase())) {
      setMessage('You aleady have a file with same name!');
      setShowUpload(false)
      return
    }
    const maxSizeInBytes = 150 * 1024 * 1024; // 100 MB        
    if (event.target.files[0].size > maxSizeInBytes) {
      setMessage('File size exceeds the 150 MB limit.')
      setShowUpload(false)
      return
    }    
    setShowUpload(true)
  };

  const handleUpload = (e) => {
    e.preventDefault()
    if (!file) {
      setMessage('Please select a file to upload.');
      return
    }
    // const user_id = +localStorage.getItem("ew_user_id");
    let user_id
    if(stateData?.publishStreamObj?.isAdmin){
      user_id = decodeJWT()
    }else if(stateData?.publishStreamObj?.isPresenter) {
      user_id = stateData?.publishStreamObj?.adminUserId
    }
    if(!user_id) {
      setMessage('Could not verify user identification, please contact admin');
      Sentry.captureMessage('USRID-invalid-upload- '+stateData?.publishStreamObj?.webinarid+' '+stateData?.publishStreamObj?.sessionId);
      return
    }
    current_uploaded_file.current = null
    setMessage('Uploading, please wait...');
    setShowUpload(false)
    // setShowChoose(false)
    setShowProgress(true)
    setShowConversionProgress(false)
    setProgress(0)
    setConversionProgress(0)
    current_progress.current = 0
    const webinar_id = stateData?.publishStreamObj?.webinarid ? +stateData?.publishStreamObj?.webinarid : 0
    const extn = getFileExtension(file.name)
    const uniqueKey = `${user_id}/${Date.now()}.${extn}`;
    file_id.current = uniqueKey
    const params: AWS.S3.PutObjectRequest = {
      Bucket: S3_BUCKET,
      Key: uniqueKey,
      Body: file,
      ContentType: file.type,
      CacheControl: 'max-age=86400',
      ContentDisposition: file.type === 'application/pdf' ? `inline; filename="${file.name}"` : `attachment; filename="${file.name}"`
    };

    s3.upload(params, async (error, data) => {
      if (error) {
        setMessage('Upload failed.');
        Sentry.captureMessage('PPT-upload-err - ' + JSON.stringify({name: file.name, error }));
        setShowUpload(false)
        // setShowChoose(true)
        setShowProgress(false)
        setShowConversionProgress(false)
        setProgress(0)
        setConversionProgress(0)
        current_progress.current = 0
        setFile(null)
      } else {
        // Sentry.captureMessage('PPT-uploaded - ' + JSON.stringify({name: file.name, data }));
        setMessage('Upload complete, processing...');
        try {
          const res = await postRequestWithAuth(savePFIlesUrl, {
            // user_id: +stateData?.publishStreamObj?.userAccountId
            webinar_id: webinar_id,
            user_id: user_id,
            file_id: uniqueKey,
            file_name: file.name.toLowerCase(),
          });
  
          if (res?.status == "error") {
            // setErrorMessage(res.message);
            // setLoader(false);
            return;
          }
          setShowUpload(false)
          // setShowChoose(true)
          setShowProgress(false)
          setProgress(0)
          setShowCheck(true)
          // setFile(null)
          setShowConversionProgress(true)
          setConversionProgress(0)
          current_progress.current = 0
          // checkInterval.current = setInterval(() => {
          //   handleCheckStatus(file_id.current)
          // }, 3000);
          setMessage('Upload complete, processing...');
          current_channel.current = user_id+'-'+uniqueKey
          pubnubobj.subscribe({
            channels: [current_channel.current]
          });    
          pubnubobj.addListener({
            message: (event) => {
                if(event.publisher === 'file-conversion-user' && event.channel === current_channel.current) {
                  if(event.message && event.message['success']) {
                    const p = event.message['progress']
                    if(p > conversionProgress && p > current_progress.current) {
                      current_progress.current = p
                      setConversionProgress(p)
                    }
                    if(p === 100) {
                      setMessage('File processed successfully')
                      setConversionProgress(100)
                      current_progress.current = 100
                      onPFileProcessed()
                      current_uploaded_file.current = uniqueKey
                    }
                  }
                  else{
                    setMessage('File processing failed')
                    setShowConversionProgress(false)
                    onPFileProcessed()
                  }
                }
            },
          });
        } catch (error) {
        //   setErrorMessage(error.message);
        //   setLoader(false);
        }
      }
    }).on('httpUploadProgress', async (progressEvent) => {
        const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        setProgress(percent);
    });
  };

  const handleCheckStatus = async(e) => {
    e.preventDefault()
    setShowCheck(false)
    setMessage('Checking status...');
    try {
      const res = 
      await getRequestWithAuth(getPFIleUrl+encodeURIComponent(file_id.current))

      if (res?.status == "error") {
        return;
      }
      if(res.data){
        if(res.data.status === 1 && res.data.image_urls && res.data.image_urls.length > 0) {
          clearInterval(checkInterval.current)
          onPFileProcessed()
          setMessage('Processing complete, check file in the library');
        }
        else {
          setTimeout(() => {
            setShowCheck(true)
            setMessage('Still processing...');
          }, 3000);
        }
      }
    } catch (error) {

    }
  }

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    if(activeTab===0) onPFileProcessed()
  }, [activeTab]);

  const getFileExtension = (key) => {
    const extn = key.split('.')
    return extn[extn.length - 1]
  }

  const onSearchTextChanged = (e) => {
    const txt = e.target.value
    if(e.target.value == ''){
      setSearchtext('')
      setSelectFileOptions(fullSelectFileOptions)
    }else{
      const tempList = fullSelectFileOptions.filter(e => e.file_name.includes(txt))
      setSearchtext(e.target.value)
      setSelectFileOptions(tempList)
    }
  }

  const pubnubobj = usePubNub();

  // useEffect(() => {
  //   return () => {
  //     pubnubobj.unsubscribeAll();
  //   };
  // }, []);

  return (  
    <div className={`modal file-share-popup video-share active`}>
      <div className="modal-background"></div>
      <div className="modal-container with-header">
          <div className="flex modal-header">
              <h3 className='flex'>Share Files</h3>
              {/* Tab Buttons */}
              <div className="tabs sharing-tabs flex flex-center">
                  <button className={`flex ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>
                    Select from Library
                  </button>
                  <button className={`flex ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
                    Upload a New File
                  </button>
              </div>
              <span className="close-btn" onClick={cancelSharePPTPopup}>&times;</span>
          </div>
          {/* <form onSubmit={handleSubmit}> */}
              <div className="modal-content flex thin-scrollbar">

                {/* Tab Content */}
                <div className="tab-content " style={{ marginTop: '20px' }}>
                  {activeTab === 0 && 
                    <div className='library-tab'>
                      <div className='library-search'>
                        <input onChange={onSearchTextChanged} type="search" className='search-input' placeholder='Search' />
                      </div>

                      <div className="library-listing">
                        <ul className='flex'> 
                          { selectFileOptions.filter((e,i) => (i >= selectedIndex*10 && i < (selectedIndex+1) * 10)).map((e, i) => {
                              return (
                                <li key={i} className={(selectedFile === e.file_id) ? 'flex flex-center active' : 'flex flex-center' } onClick={() => {if(e.status === 0 ) return; setSelectedFile(e.file_id)}}>
                                  {(selectedFile === e.file_id) && <img src={checkedIcon} className='checked' alt="" />}
                                  {(e.status === 0 ) && 
                                    // <img src={uploadIcon} className='upload' alt="" />
                                    <div className="in-progress-loading">
                                      <div className="progress progress-striped active">
                                        <div role="progressbar progress-striped" style={{width: '100%'}} className="progress-bar"><span>In Progress</span></div>
                                      </div>
                                    </div>
                                  }
                                  <span className='img'>
                                    {(!e.v1 && (getFileExtension(e.file_id) === 'ppt' || getFileExtension(e.file_id) === 'pptx')) &&<img src={pptIcon} alt="" />}
                                    {(!e.v1 && getFileExtension(e.file_id) === 'pdf') &&<img src={pdfIcon} alt="" />}
                                    {/* {(e.v1 && (getFileExtension(e.file_name) === 'ppt' || getFileExtension(e.file_name) === 'pptx')) &&<img src={pptIcon} alt="" />}
                                    {(e.v1 && getFileExtension(e.file_name) === 'pdf') &&<img src={pdfIcon} alt="" />} */}
                                    {(e.v1 && (e.file_extn.toLowerCase() === 'ppt' || e.file_extn.toLowerCase() === 'pptx')) &&<img src={pptIcon} alt="" />}
                                    {(e.v1 && (e.file_extn.toLowerCase() === 'doc' || e.file_extn.toLowerCase() === 'docx')) &&<img src={pptIcon} alt="" />}
                                    {(e.v1 && e.file_extn.toLowerCase() === 'pdf') &&<img src={pdfIcon} alt="" />}
                                    {(e.v1 && e.file_extn.toLowerCase() === 'jpg') &&<img style={{height: 'auto', maxWidth: '100%'}} src={e.file_url} alt="" />}
                                    {(e.v1 && e.file_extn.toLowerCase() === 'png') &&<img style={{height: 'auto', maxWidth: '100%'}} src={e.file_url} alt="" />}
                                    {(e.v1 && e.file_extn.toLowerCase() === 'bmp') &&<img style={{height: 'auto', maxWidth: '100%'}} src={e.file_url} alt="" />}
                                    {(e.v1 && e.file_extn.toLowerCase() === 'gif') &&<img style={{height: 'auto', maxWidth: '100%'}} src={e.file_url} alt="" />}
                                  </span>
                                  <span className='file-name'>{e.file_name}</span>
                                </li>
                              )
                          })}
                        </ul>
                      </div>

                      <div className="flex flex-center library-pagination">
                        <ul className='flex flex-center'>
                          <li onClick={() => {if(selectedIndex < 1) return; selectedIndex > 0 ? setSelectedIndex(selectedIndex - 1) : setSelectedIndex(0)}} className='flex flex-center previous'><span><img src={listNextIcon} alt="" /></span></li>
                          { selectFileOptionIndices.map((e, i) => {
                              return (
                                <li key={i} onClick={() => setSelectedIndex(e)} className={ selectedIndex === i ? 'flex flex-center active': 'flex flex-center' }><span>{e+1}</span></li>
                              )
                          })}
                          {/* <li className='flex flex-center'><span>...</span></li> */}
                          <li onClick={() => {if(selectedIndex >= (maxIndex-1)) return;  selectedIndex < maxIndex ? setSelectedIndex(selectedIndex + 1) : setSelectedIndex(maxIndex-1)}} className='flex flex-center'><span><img src={listNextIcon} alt="" /></span></li>
                        </ul>
                      </div>
                    </div>
                  }

                  {activeTab === 1 && 
                    <div className='upload-tab'>
                      <div className="file-share-info">
                          {/* <label htmlFor="">Select a local file</label> */}
                          <div className="flex flex-center file-upload">
                              <img src={uploadIcon} alt="" />                                    
                              <label htmlFor="file-input" className='flex'>Select or drag a file from your computer to upload.
                                <span>Supported formats: PDF, PPT, PPTX.</span>
                                <span>Max size: 150MB per file.</span>
                              </label>
                              <span className='select-file'>
                              <label htmlFor="cf">Choose File</label>
                              {<input id='cf' type="file" accept=".pdf,.ppt,.pptx" onChange={handleFileChange} />}
                              {showUpload && file && <button id='uf' style={{ backgroundColor: '#007bff',
                              color: 'white'}} onClick={handleUpload}>Upload</button>}
                              {/* <p>{message}</p> */}
                              {/* {showProgress && <progress value={progress} max="100" />} */}
                              {/* {showCheck && <button onClick={handleCheckStatus}>Check Status</button>} */}
                              </span>
                              <div className='file-progress'>
                                <p>{message}</p>
                                {showProgress && <progress value={progress} max="100" />}
                                {showConversionProgress && <progress value={conversionProgress} max="100" />}
                              </div>
                              {file && (
                              <p className='selected-file'><span>Selected file:</span> {file.name}</p>
                              )}
                          </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="modal-footer flex">
                  {<div className="flex">
                    {/* <label>Note: Please use Chrome browser when sharing PPT/PDF files</label> */}
                    <div className="note" style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%'
                    }}>
                    <label style={{
                      width: 'auto',
                      background: '#eaffd7',
                      margin: '0 0 7px 0',
                      padding: '5px 10px',
                      borderRadius: '4px',
                      fontSize: '14px'
                    }}><strong>Note:</strong> Please use Chrome browser when sharing PPT/PDF files</label></div>
                    <button type="button" className="default-btn" onClick={cancelSharePPTPopup}>Cancel</button>
                    {(activeTab === 0) && <button type="button" className={selectedFile ? "primary-button" : "disabled-button"} disabled={!selectedFile} 
                      onClick={handleAddStreamingFile}>Share
                    </button>}
                    {(activeTab === 1) && <button type="button" className={current_uploaded_file.current ? "primary-button" : "disabled-button"} disabled={!current_uploaded_file.current} 
                      onClick={handleAddCurrentStreamingFile}>Share
                    </button>}
                  </div>}
              </div>
          {/* </form> */}
      </div>
    </div>
  );

  };
