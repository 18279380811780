import React, { useState } from 'react';
import '../../assets/styles/main.scss';

export const ConfimationPopToAttendeeToJoinAsCohost= ({ newUrl,isConfirmationOpen, onCloseConfirmationPopUp}) => {

    
    
    return (

        <div className={`modal confirmation-popup ${isConfirmationOpen ? 'active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-container with-header">
                <div className="modal-header">
                    <h3 className='flex'>Join as Co-host</h3>
                    <span className="close-btn" onClick={onCloseConfirmationPopUp}>&times;</span>
                </div>
                <div className="modal-content flex">
                    <div className="confirmation-info">
                        <p>Admin is inviting you to join as Co-host.</p>
                    </div>
                </div>
                <div className="modal-footer flex">
                    <div className="flex">
                        <button type="button" className="default-btn" onClick={onCloseConfirmationPopUp}>Cancel</button>
                        <button type="button" className="warning-button" onClick={()=> window.location.href = newUrl.toString()}>Join As Co-host</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
