import React, { useEffect, useState } from 'react';
import '../../assets/styles/main.scss';
import ewLogo from '../../assets/images/ew-logo-full.svg';
import thankYou from '../../assets/images/thank-you-img.png';
import classNames from 'classnames';

export const ThankYou = () => {

    const [isMobile, setIsMobile] = useState(false);
    
    // For mobile scss
    useEffect(() => {
        // Function to detect if the device is mobile
        const checkIfMobile = () => {
          const userAgent = navigator.userAgent || navigator.vendor;
          if (/android/i.test(userAgent)) {
            setIsMobile(true);
          } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            setIsMobile(true);
          } else {
            setIsMobile(false);
          }
        };
    
        // Initial check
        checkIfMobile();
      }, []);

    return (
        <div className={classNames(
            'thankyou-page',
            { '': true, 'mobile': isMobile }
          )}>
            <div className="container flex flex-center">
                <div className="ew-logo">
                    <img src={ewLogo} alt="" />
                </div>

                <div className="thankyou-info flex flex-center">
                    <div className="thankyou-img">
                        <img src={thankYou} alt="thank you" />
                    </div>

                    <div className="thankyou-content">
                        <h2>Thank You</h2>
                        <p>Thank you for attending the Webinar. We appreciate your time and participation.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
