export const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const mins = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${hrs}:${mins}:${secs}`;
  };


 export  const getUrlForEmail = async (channelName, email,obj) => {
    try {
      // Fetch the existing metadata
      const result = await obj.objects.getChannelMetadata({ channel: channelName });
      let currentUrls = [];
  
      // Check if the `urls` array exists in the metadata
      if (result.data && result.data.custom && result.data.custom.urls) {
        currentUrls = JSON.parse(result?.data?.custom?.urls?.toString()); // Parse to convert string to array
      }
  
      // Find the URL that contains the specific email
      const matchingUrl = currentUrls?.find(url => url.includes(email));
  
      if (matchingUrl) {
        console.log(`Email "${email}" found in URL: ${matchingUrl}`);
      } else {
        console.log(`Email "${email}" not found in any URLs.`);
      }
  
      return matchingUrl || null; // Return the matching URL or null if not found
    } catch (error) {
      console.error(`Failed to check email in URLs for channel ${channelName}:`, error);
      return null;
    }
  };



  export const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return true;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return true;
    }
    return false;
  };