export const signalEnum = {
  ENDSESSION: "endSession",
  MIC_STATUS: "micStatus",
};

export function getChatChannelName(webinarId, sessionId) {
  return "Chat." + webinarId + "." + sessionId;
}

export function getPeopleChannelName(webinarId, sessionId) {
  return "People." + webinarId + "." + sessionId;
}

export function getWebinarChannelName(webinarId, sessionId) {
  return "Webinar." + webinarId + "." + sessionId;
}

export function getScreenShareChannelName(webinarId, sessionId) {
  return "ScreenShare." + webinarId + "." + sessionId;
}

export function getChatModerationChannelName(webinarId, sessionId) {
  return "chatModeration." + webinarId + "." + sessionId;
}

export function getMakeAttendeeChannelName(webinarId, sessionId) {
  return "MakeAttendee." + webinarId + "." + sessionId;
}
export const MSG_ENM = {
  MAKE_COHOST: "makeCohost",
  MAKE_ATTENDEE: "makeAttendee",
  BLOCK: "block",
  TOGGLE_MIC: "toggle_Mic",
  TOGGLE_CAMERA: "togglr_Camera",
  STOP_SCREEN_SHARE_FOR_PRESENTER: "STOP_SCREEN_SHARE_FOR_PRESENTER",
  REMOVE:"REMOVE"
};

export const MESSAGE_TYPE = {
  PRIVATE: "private",
  PUBLIC: "public",
  DELETE_MESSAGE:"deleteMessage"
};
