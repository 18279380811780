import { useEffect, useState } from "react";
import facebook from "../../assets/images/facebook-icon.svg";
import xIcon from "../../assets/images/x-icon.svg";
import lIcon from "../../assets/images/linkdin-sm-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateisInvitePopUp } from "../../Store/Actions/PublishStreamAction";

// interface props {
//   invitetogglePopup: () => void;
// }

// Extend the Window interface for the FB object
declare global {
  interface Window {
    FB: {
      init: (options: {
        appId: number;
        status: boolean;
        cookie: boolean;
        xfbml: boolean;
        version: string;
      }) => void;
      ui: (
        params: {
          method: string;
          display: string;
          link: string;
        },
        callback?: (response: any) => void
      ) => void;
      getLoginStatus: (callback: (response: any) => void) => void;
      login: (
        callback: (response: any) => void,
        options?: {
          scope: string;
          return_scopes?: boolean;
          auth_type?: string;
          enable_profile_selector?: boolean;
          profile_selector_ids?: string;
        }
      ) => void;
      logout: (callback?: (response: any) => void) => void;
      // Add more methods as needed
    };
  }
}

declare global {
  interface Window {
    twttr?: {
      ready: (callback: () => void) => void;
    };
  }
}

export default function Invite() {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();
  const attendeeLink = stateData.publishStreamObj.attendeeLink;
  const moderatorLink = stateData.publishStreamObj.moderatorLink;
  const presentorLink = stateData.publishStreamObj.presentorLink;

  const [isPopup2Open, setPopup2Open] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("Invitation");
  const TabContent = {
    1: attendeeLink,
    2: presentorLink,
    3: moderatorLink,
  };

  const [body, setBody] = useState(`You are invited to join a webinar now.
Join from PC, Mac, Linux(Chrome/Firefox recommended), iOS or Android:
${TabContent[activeTab]}
`);
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");

  useEffect(() => {
    const loadFacebookSDK = () => {
      // Check if the SDK script already exists
      if (document.getElementById("facebook-jssdk")) return;

      // Create a new script element
      const js = document.createElement("script");
      js.id = "facebook-jssdk";
      js.async = true;
      js.src = "//connect.facebook.net/en_US/sdk.js";

      // Insert the script into the document
      document.body.appendChild(js);

      // Initialize the SDK after it's loaded
      js.onload = () => {
        window.FB.init({
          appId: 2289204628093313,
          status: true,
          cookie: true,
          xfbml: true,
          version: "v10.0",
        });
      };
    };

    // Call the function to load the SDK
    loadFacebookSDK();
  }, []);

  // Function to share content on Facebook
  const postToFeed = () => {
    const obj = {
      method: "feed",
      display: "popup",
      link: attendeeLink, // Link to be shared
    };

    const callback = (response) => {
      if (typeof response !== "undefined") {
      } else {
        console.log("Post was not shared.");
      }
    };

    // Check if FB is defined before calling FB.ui
    if (window.FB) {
      window.FB.ui(obj, callback);
    } else {
      console.error("Facebook SDK is not loaded.");
    }
  };

  // twitter code start from here
  useEffect(() => {
    // Load Twitter widgets script
    const loadTwitterScript = () => {
      if (window.twttr) return;

      const script = document.createElement("script");
      script.id = "twitter-wjs";
      script.src = "//platform.twitter.com/widgets.js";
      document.getElementsByTagName("head")[0].appendChild(script);

      script.onload = () => {
        if (window.twttr && typeof window.twttr.ready === "function") {
          window.twttr.ready(() => {
            console.log("Twitter script loaded and ready.");
          });
        }
      };
    };

    loadTwitterScript();

    // Cleanup function to remove script if component unmounts
    return () => {
      const script = document.getElementById("twitter-wjs");
      if (script) {
        script.remove();
      }
    };
  }, []);

  const handleLinkedInShare = () => {
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      attendeeLink
    )}`;
    const width = 600;
    const height = 400;
    const x = window.screen.width / 2 - width / 2;
    const y = window.screen.height / 2 - height / 2;

    let childLinkedIn = window.open(
      linkedInUrl,
      "popupWindow",
      `width=${width},height=${height},left=${x},top=${y}`
    );
    if (childLinkedIn) {
      childLinkedIn.focus();
      const timer = setInterval(() => {
        if (childLinkedIn.closed) {
          clearInterval(timer);
          // Call a function to handle rewards or any post-share action
          showRewards();
        }
      }, 1000);
    }
  };


  const handleTwitterShare = () => {
    const tweetUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      attendeeLink
    )}`;
    const width = 600;
    const height = 400;
    const x = window.screen.width / 2 - width / 2;
    const y = window.screen.height / 2 - height / 2;

    let childTwitter = window.open(
      tweetUrl,
      "popupWindow",
      `width=${width},height=${height},left=${x},top=${y}`
    );
    if (childTwitter) {
      childTwitter.focus();
      const timer = setInterval(() => {
        if (childTwitter.closed) {
          clearInterval(timer);
          // Call a function to handle rewards or any post-share action
          showRewards();
        }
      }, 1000);
    }
  };

  const showRewards = () => {
    // Implement reward logic here
   
  };

  const toggleInvitePopup = (event: { preventDefault: () => void }) => {
    // event.preventDefault();
    setPopup2Open(!isPopup2Open);
  };

  const handleTabClick = (tabIndex: React.SetStateAction<number>) => {
    setActiveTab(tabIndex);
  };

  const canceInvite = () => {
    dispatch(updateisInvitePopUp(false));
  };
  const handleSendEmail = (event) => {
    event.preventDefault(); // Prevent form submission

    // Construct the mailto link
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}&cc=${encodeURIComponent(
      cc
    )}&bcc=${encodeURIComponent(bcc)}`;

    // Open the default mail client
    window.location.href = mailtoLink;
  };

  useEffect(() => {
    setBody(`You are invited to join a webinar now.
Join from PC, Mac, Linux(Chrome/Firefox recommended), iOS or Android:
${TabContent[activeTab]}
`);
  }, [activeTab, TabContent]);

  const [copied, setCopied] = useState(false);
  function CopyButton({ link, buttonText }) {
    const handleCopyClick = () => {
      navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000); // 2 seconds
    };

    return (
      <button type="button" onClick={handleCopyClick}>
        {copied ? "Copied!" : buttonText}
      </button>
    );
  }

  return (
    <div className={`modal invite-guests active`}>
      <div className="modal-background"></div>
      <div className="modal-container with-header">
        <div className="modal-header">
          <h3>Invite Guests</h3>
          <span className="close-btn" onClick={canceInvite}>
            &times;
          </span>
        </div>
        <div className="modal-content flex">
          <div className="tabs invite-tabs flex">
            <button
              className={`flex ${activeTab === 1 ? "active" : ""}`}
              onClick={() => handleTabClick(1)}
            >
              Attendee
            </button>
            <button
              className={`flex ${activeTab === 2 ? "active" : ""}`}
              onClick={() => handleTabClick(2)}
            >
              Presenter
            </button>
            <button
              className={`flex ${activeTab === 3 ? "active" : ""}`}
              onClick={() => handleTabClick(3)}
            >
              Moderator
            </button>
          </div>

          <div className="tab-content">
            {activeTab === 1 && (
              <div className="ew-invite-content">
                <div className="ew-form-field copy-link">
                  <span className="label-sub-heading">
                    Use this link to allow someone to join the current session
                    as attendee.
                  </span>
                  <CopyButton
                    link={stateData.publishStreamObj.attendeeLink}
                    buttonText=" Copy Attendee Invitation Link"
                  />
                </div>

                {/* <div className="ew-form-field">
                  <label htmlFor="">Invite by email</label>
                  <span className="label-sub-heading">
                    Enter email address of someone you would like to invite
                  </span>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    placeholder="someone@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{
                      padding: "8px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div> */}
                {/* <div className="modal-footer">
                  <button type="button" className="primary-button" onClick={handleSendEmail}>
                    Send Email
                  </button>
                </div> */}
                <div className="ew-form-field">
                  <label htmlFor="">Share event on Social Media</label>
                  <div className="social-share">
                    <ul className="flex">
                      <li onClick={postToFeed}>
                        <a href="#" className="flex flex-center">
                          <img src={facebook} alt="" />
                        </a>
                      </li>
                      <li onClick={handleTwitterShare}>
                        <a href="#" className="flex flex-center">
                          <img src={xIcon} alt="" />
                        </a>
                      </li>
                        <li onClick={handleLinkedInShare}>
                        <a href="#" className="flex flex-center">
                          <img src={lIcon} alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="modal-footer">
                  <button type="button" className="primary-button" onClick={handleSendEmail}>
                    Send Email
                  </button>
                </div> */}
              </div>
            )}
            {activeTab === 2 && (
              <div className="ew-invite-content">
                <div className="ew-form-field copy-link">
                  <span className="label-sub-heading">
                    Use this link to allow someone to join the current session
                    as Presenter.
                  </span>
                  <CopyButton
                    link={stateData.publishStreamObj.presentorLink}
                    buttonText="Copy Presenter Invitation Link"
                  />
                </div>

                {/* <div className="ew-form-field">
                  <label htmlFor="">Invite by email</label>
                  <span className="label-sub-heading">
                    Enter email address of someone you would like to invite
                  </span>
                  <input
                    type="email"
                    id="email"
                    placeholder="someone@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{
                      padding: "8px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div> */}
                {/* <div className="modal-footer">
                  <button type="button" className="primary-button" onClick={handleSendEmail}>
                    Send Email
                  </button>
                </div> */}
                <div className="ew-form-field">
                  <label htmlFor="">Share event on Social Media</label>
                  <div className="social-share">
                    <ul className="flex">
                      <li onClick={postToFeed}>
                        <a href="#" className="flex flex-center">
                          <img src={facebook} alt="" />
                        </a>
                      </li>
                      <li onClick={handleTwitterShare}>
                        <a href="#" className="flex flex-center">
                          <img src={xIcon} alt="" />
                        </a>
                      </li>
                        <li onClick={handleLinkedInShare}>
                        <a href="#" className="flex flex-center">
                          <img src={lIcon} alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 3 && (
              <div className="ew-invite-content">
                <div className="ew-form-field copy-link">
                  <span className="label-sub-heading">
                    Use this link to allow someone to join the current session
                    as Moderator.
                  </span>
                  <CopyButton
                    link={stateData.publishStreamObj.moderatorLink}
                    buttonText=" Copy Moderator Invitation Link"
                  />
                </div>

                {/* <div className="ew-form-field">
                  <label htmlFor="">Invite by email</label>
                  <span className="label-sub-heading">
                    Enter email address of someone you would like to invite
                  </span>
                  <input
                    type="email"
                    id="email"
                    placeholder="someone@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{
                      padding: "8px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div> */}
                {/* <div className="modal-footer">
                  <button type="button" className="primary-button" onClick={handleSendEmail}>
                    Send Email
                  </button>
                </div> */}
                <div className="ew-form-field">
                  <label htmlFor="">Share event on Social Media</label>
                  <div className="social-share">
                    <ul className="flex">
                      <li onClick={postToFeed}>
                        <a href="#" className="flex flex-center">
                          <img src={facebook} alt="" />
                        </a>
                      </li>
                      <li onClick={handleTwitterShare}>
                        <a href="#" className="flex flex-center">
                          <img src={xIcon} alt="" />
                        </a>
                      </li>
                        <li onClick={handleLinkedInShare}>
                        <a href="#" className="flex flex-center">
                          <img src={lIcon} alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          {/* <button
            type="button"
            className="primary-button"
            onClick={handleSendEmail}
          >
            Send Email
          </button> */}
        </div>
      </div>
    </div>
  );
}
