import React, { useState } from "react";
import Select from "react-select";
import "../../assets/styles/main.scss";

import cameraSettingsIcon from "../../assets/images/camera-settings.svg";
import audioSettingsIcon from "../../assets/images/audio-settings.svg";
import recordingSettingsIcon from "../../assets/images/recording-settings.svg";
import bgSettingsIcon from "../../assets/images/bg-settings.svg";
import chatSettingsIcon from "../../assets/images/chat-settings.svg";
import { ChatModeration } from "./ChatModeration";
import { AccessibiltyPermissions } from "./AccessibiltyPermissions";
import { BackgrounSettings } from "./BackgroundSettings";
import { AudioSettings } from "./AudioSettings";
import { CameraSettings } from "./CameraSettings";
import { useDispatch, useSelector } from "react-redux";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import {
    updateConferenceId,
  updateisSettingPopUp,
  updateRecord,
} from "../../Store/Actions/PublishStreamAction";
import { UPDATERECORD } from "../../Store/ActionTypes/PublishStreamActionType";
import { postRequest } from "../../libs/APIs/apiCall";
import { baseUrl, customCurrentEnv, dolbyRecordingUrl, easyWbinarBaseURL, recordingStatusUrl } from "../../config";
import { setAuthToken } from "../../libs/APIs/auth";

interface props {
  togglePopup: () => void;
  activeTabProp: number;
}

export const Settings = ({ togglePopup, activeTabProp }: props) => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  let obj: PublishStream = stateData?.publishStreamObj?.obj;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  // Invite popup
  const inviteTogglePopup = (event: { preventDefault: () => void }) => {
    setIsOpen(!isOpen);
  };

  // Settings popup tabs
  const [activeTab, setActiveTab] = useState(activeTabProp);

  const handleTabClick = (tabIndex: React.SetStateAction<number>) => {
    setActiveTab(tabIndex);
  };

  const options = [
    { value: "option1", label: "Integrated Webcam" },
    { value: "option2", label: "Integrated Webcam 2" },
    { value: "option3", label: "Integrated Webcam 3" },
  ];

  const [value, setValue] = useState(50); // Initial value for the slider

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const recordingStatusUpdateSQL = async () => {
    try {
      const res = await postRequest(recordingStatusUrl, {
        sid: stateData?.publishStreamObj?.sid,
        recording_status : true
      });
      // console.log(res);
    } catch (error) {
      console.log("error");
    }
  };

  const handleStartRecording = async () => {
    try {
      const webinarId = stateData?.publishStreamObj.webinarid;
      const sessionId = stateData?.publishStreamObj.sessionId;

      const ew_jwt_token = localStorage.getItem("ew_jwt_token");
      setAuthToken(ew_jwt_token);
      
      const response = await postRequest(
        `${dolbyRecordingUrl}`,
        {
          action: "start",
          webinarId: webinarId,
          sessionId: sessionId,
          confAlias: `${webinarId}_${sessionId}_${customCurrentEnv}`,
          recorderLink: `${easyWbinarBaseURL}${baseUrl}/recorder?stream_name=${stateData?.publishStreamObj.streamName}`
        }
      );
  
      if (response.data && response.data.conferenceId) {
        // Dispatch the action to update the conference ID in Redux and local storage
        localStorage.setItem("conferenceId", response.data.conferenceId)
        dispatch(updateConferenceId(response.data.conferenceId));
        recordingStatusUpdateSQL()
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  //Function to pause recording using conferenceID
  const pauseRecording = async () => {
    try {
      const webinarId = stateData?.publishStreamObj.webinarid;
      const sessionId = stateData?.publishStreamObj.sessionId;
      const conferenceId = stateData.publishStreamObj.conferenceId;
      
      const response = await postRequest(
        `${dolbyRecordingUrl}`,
        {
          action: "pause",
          webinarId: webinarId,
          sessionId: sessionId,
          conferenceId: conferenceId
        }
      );

    } catch (error) {
      console.error("Error pausing recording:", error);
    }
  };

  //Function to resume recording using conferenceID
  const resumeRecording = async () => {
    try {
      const webinarId = stateData?.publishStreamObj.webinarid;
      const sessionId = stateData?.publishStreamObj.sessionId;
      const conferenceId = stateData.publishStreamObj.conferenceId
      
      const response = await postRequest(
        `${dolbyRecordingUrl}`,
        {
          action: "resume",
          webinarId: webinarId,
          sessionId: sessionId,
          conferenceId: conferenceId
        }
      );

    } catch (error) {
      console.error("Error resuming recording:", error);
    }
  };

  // Toggle button
  const [isToggled, setIsToggled] = useState(
    stateData?.publishStreamObj?.recording
  );

  const handleToggle = async () => {
    setIsToggled(!isToggled);
    
    dispatch(updateRecord(!isToggled));
    
    if (!isToggled) {
      if (stateData?.publishStreamObj?.isLiveGo) {
        if (stateData?.publishStreamObj?.conferenceId) {
          await resumeRecording();
        } else {
          await handleStartRecording();
        }
      } 
      await obj.startRecording();
        
    } else {
        if (stateData?.publishStreamObj?.isLiveGo && stateData?.publishStreamObj?.conferenceId) {
            await pauseRecording();
        }
        await obj.stopRecording()
    }
  };

  return (
    <div className="modal session-settings active">
      <div className="modal-background"></div>
      <div className="modal-container">
        <div className="modal-content flex">
          <span
            className="close-btn"
            onClick={() => {
              dispatch(updateisSettingPopUp(false));
            }}
          >
            &times;
          </span>
          <div className="settings-left-sidebar">
            <div className="tabs flex">
              <h3>Settings</h3>
              <h4>Device Settings</h4>
              <button
                className={`flex ${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabClick(1)}
              >
                <span>
                  <img src={cameraSettingsIcon} alt="" />
                </span>
                Camera Settings
              </button>
              <button
                className={`flex ${activeTab === 2 ? "active" : ""}`}
                onClick={() => handleTabClick(2)}
              >
                <span>
                  <img src={audioSettingsIcon} alt="" />
                </span>
                Audio Settings
              </button>
              {stateData?.publishStreamObj?.isAdmin  &&
              <button
                className={`flex ${activeTab === 3 ? "active" : ""}`}
                onClick={() => handleTabClick(3)}
              >
                <span>
                  <img src={recordingSettingsIcon} alt="" />
                </span>
                Recording Settings
              </button>
              }
              {/* <button className={`flex ${activeTab === 4 ? 'active' : ''}`} onClick={() => handleTabClick(4)}>
                                <span><img src={bgSettingsIcon} alt="" /></span>
                                Background Settings
                            </button> */}

              <h4>Preferences</h4>
              <button
                className={`flex ${activeTab === 5 ? "active" : ""}`}
                onClick={() => handleTabClick(5)}
              >
                <span>
                  <img src={chatSettingsIcon} alt="" />
                </span>
                Chat Moderation
              </button>
              {/* <button className={`flex ${activeTab === 6 ? 'active' : ''}`} onClick={() => handleTabClick(6)}>
                                <span><img src={bgSettingsIcon} alt="" /></span>
                                Accessibility Permissions
                            </button> */}
            </div>
          </div>

          <div className="settings-right-area">
            <div className="tab-content">
              {activeTab === 1 && <CameraSettings />} 
              {activeTab === 2 && <AudioSettings />}
              {activeTab === 3 && (
                <div className="ew-setting-area-section recording-settings">
                  <div className="ew-form-field toggle-field">
                    <label htmlFor="dropdown">Auto Recording</label>
                    <span className="label-sub-heading">
                      {stateData?.publishStreamObj?.isLiveGo
                        ? isToggled
                          ? "Pause recording"
                          : "Turn on recording"
                        : "Turn on auto recording if you wish to record automatically at beginning of webinar"}
                      <div className="toggle-container">
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            checked={isToggled}
                            onChange={handleToggle}
                          />
                          <span className="toggle-slider"></span>
                        </label>
                      </div>
                    </span>
                  </div>
                </div>
              )}
              {activeTab === 4 && <BackgrounSettings />}
              {activeTab === 5 && <ChatModeration />}
              {activeTab === 6 && <AccessibiltyPermissions />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
