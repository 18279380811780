import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/main.scss";
import ViewerPre from "../../libs/view/ViewerPre";
import { useDispatch, useSelector } from "react-redux";
import {
  screenShareSourceId,
  updateIsLiveGo,
} from "../../Store/Actions/PublishStreamAction";
import { useNetworkStatus } from "../../libs/hooks/NetworkStabilityListner";
import { ParticipentForAttendeeScreen } from "./people-participant-Attendee-screen";
import { ScreenShareForAttendeeScreen } from "./share-screen-details-Attendee-screen";
import * as Sentry from '@sentry/react';
import PubNub from "pubnub";
import { getPFIleUrl } from "../../config";
import { getRequestWithAuth } from "../../libs/APIs/apiCall";
import { DocShareViewer } from "../GreenRoom/doc-share-viewer";
import offClickSoundIcon from "../../assets/images/off-sound-icon.svg";
import onClickSoundIcon from "../../assets/images/on-sound-icon.svg";

interface props {
  streamAccountId: string;
  streamName: string;
  subscriberToken: string;
  userName: string;
  type: string;
}

export const AttendeeRecorder = ({
  streamAccountId,
  streamName,
  subscriberToken,
  userName,
  type,
}: props) => {
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();


  const { remoteTrackSources, startViewer, startViewerAttendee, stopViewer } =
    ViewerPre({ streamAccountId, streamName, subscriberToken });

  //screen share by other presentor
  const [isScreenSharedByPresentor, setIsScreenSharedByPresentor] =
    React.useState(false);
  const [screenSharedStreamValue, setscreenSharedStreamValue] =
    React.useState<any>();
  const [screenSharedUserNameValue, setscreenSharedUserNameValue] =
    React.useState<any>();
  const [screenSharedSourceIDValue, setscreenSharedSourceIDValue] =
    React.useState<any>();

  const pptImgList = useRef<any>()
  const pptImgType = useRef<string>('ppt')
  const fetchPPTFileDetails = async (fileId) => {
    let imgList = null
    try {      
      const res = 
      await getRequestWithAuth(getPFIleUrl+encodeURIComponent(fileId))

      if (res?.status == "error") {
        // setSomeOneElseSharingPPT(false)
        Sentry.captureMessage('PPT-AR-fid-err-'+fileId+'-'+JSON.stringify(res));
        return;
      }
      if(res.data){
        imgList = res.data
        imgList.extn = res.data.file_extn ? res.data.file_extn : getFileExtension(res.data.file_name)
        pptImgList.current = imgList
        pptImgType.current = imgList.extn
      }
      setSomeOneElseSharingPPT(true)
    } catch (error) {
      Sentry.captureMessage('PPT-AR-fid-catch-'+fileId+'-'+JSON.stringify(error));
      // setSomeOneElseSharingPPT(false)
    }
  };

  const getFileExtension = (key) => {
    const extn = key.split('.')
    return extn[extn.length - 1]
  }

  const [someOneElseSharingPPT, setSomeOneElseSharingPPT] = React.useState(false);
  const someOneElseSharingPPTName = React.useRef<string>(null);
  const [someOneElseSharingPPTSlideNum, setSomeOneElseSharingPPTSlideNum] = React.useState(0);

  useEffect(() => {
    let isScreenSharePresentorAvailable = false;
    if (remoteTrackSources.size > 0) {
      if (muteAllStream == true) setUnMutePopup(true);
    }
    let isSomeOneElseSharingPPT = false

    Array.from(remoteTrackSources).map(([sourceId, { mediaStream }]) => {
      let splittedSourceId = sourceId.split(":");
      if (splittedSourceId[1] == "screenShare" || splittedSourceId[1] == "video") {
        isScreenSharePresentorAvailable = true;

        if(splittedSourceId.length > 2){
          someOneElseSharingPPTName.current = splittedSourceId[2] 
          fetchPPTFileDetails(splittedSourceId[2])
          isSomeOneElseSharingPPT = true
        }
        setscreenSharedUserNameValue(splittedSourceId[0]);
        setscreenSharedStreamValue(mediaStream);
        setIsScreenSharedByPresentor(true);
        setscreenSharedSourceIDValue(sourceId);

        //save screenshare source id
        dispatch(screenShareSourceId(sourceId));
      }
    });
    
    if(!isSomeOneElseSharingPPT) {
      setSomeOneElseSharingPPT(false)
      someOneElseSharingPPTName.current = null
      setSomeOneElseSharingPPTSlideNum(0)
    }

    if (!isScreenSharePresentorAvailable) {
      setscreenSharedUserNameValue(undefined);
      setscreenSharedStreamValue(undefined);
      setIsScreenSharedByPresentor(false);
      setscreenSharedSourceIDValue(undefined);
    }
  }, [remoteTrackSources]);

  const pubnub = new PubNub({
    publishKey: process.env.REACT_APP_PUBNUB_PUB_KEY,
    subscribeKey: process.env.REACT_APP_PUBNUB_SUBS_KEY,
    uuid: streamName,
    autoNetworkDetection: true,
    restore: true,
    heartbeatInterval: 300,
  });

  const str = streamName.split('_')
  const PPTPubnubChannel = `PPT-share-${str[3]}-${str[5]}`

  useEffect(() => {
    try{
      pubnub.subscribe({
        channels: [PPTPubnubChannel]
      }); 
      pubnub.addListener({
        message: (event) => {
          if(event.channel === PPTPubnubChannel && event.message) {
            const msgs = (event.message)['split'](':::')
            if(!someOneElseSharingPPTName.current || msgs[0] === someOneElseSharingPPTName.current){
              setSomeOneElseSharingPPTSlideNum(+msgs[1])
            }else{
              // someOneElseSharingPPTName.current = msgs[2] 
              // fetchPPTFileDetails(msgs[2])
            }
          }
        },
      });
      pubnub.fetchMessages(
        {
          channels: [PPTPubnubChannel],
          count: 1,
        },
        (status, response) => {
          if (status.error) {
            Sentry.captureMessage('PPT-AR-hist-err-'+PPTPubnubChannel+'-'+JSON.stringify(status["error_message"]));
          } else {
            const messages = response.channels[PPTPubnubChannel];
            const initialMessages = messages.map(msg => msg['message']);
            if(initialMessages && initialMessages[0]) {
              const msgs = (initialMessages[0])['split'](':::')
              if(!someOneElseSharingPPTName.current || msgs[0] === someOneElseSharingPPTName.current){
                setSomeOneElseSharingPPTSlideNum(+msgs[1])
              }else{
                // someOneElseSharingPPTName.current = msgs[2] 
                // fetchPPTFileDetails(msgs[2])
              }
            }
          }
        }
      )
    }
    catch (error) {
      Sentry.captureMessage('PPT-AR-catch-'+PPTPubnubChannel+'-'+JSON.stringify(error));
    }

    startViewer();
    return () => {
      pubnub.unsubscribe({
        channels: [PPTPubnubChannel]
      });
      stopViewer();
    };
  }, []);




 



  useEffect(() => {
    dispatch(updateIsLiveGo(true));
    // setIsLiveGo(true);
  }, []);




  //end

  // For mobile scss
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Function to detect if the device is mobile
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
        setIsMobile(true);
      } else if (
        /iPad|iPhone|iPod/.test(userAgent) &&
        !(window as any).MSStream
      ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check
    checkIfMobile();
  }, []);


  const [muteAllStream, setmuteAllStream] = useState(
    type == "recorder" ? false : true
  ); //mute all stream

  const [unMutePopup, setUnMutePopup] = useState(false);
  const onScreenShareDeniedClose = () => {
    setUnMutePopup(false);
    setmuteAllStream(false);
  };

  return (
    <>
      {unMutePopup && (
          <div id="onclicksound"
            // className="click-for-sound"
            onClick={onScreenShareDeniedClose}
          >
            {/* <span> */}
              {/* <img src={offClickSoundIcon} className="off" alt="" /> */}
              {/* <img src={onClickSoundIcon} className="on" alt="" /> */}
            {/* </span> */}
          </div>
       )}
      <div
        className={`recording-layout ew-container-layout attendee-layout`}
      >
        <div className="left-layout overflow-hidden">         

          {isScreenSharedByPresentor == true ? (
            <div className="ew-video-container attendee-layout overflow-hidden w-full h-full">
             <div
              className={ someOneElseSharingPPT
                ? ("presenters active-presenter-" +
                  (remoteTrackSources.size - 1) +
                  " screen_shared_fullscreen ppt-sharing")
                : ("presenters active-presenter-" +
                (remoteTrackSources.size - 1) +
                " screen_shared_fullscreen")
              }
            >
             
                <div className="ew-n-presenters">
                { isScreenSharedByPresentor && (someOneElseSharingPPT ? (<DocShareViewer 
                      imgList={pptImgList.current}
                      currentSlide={someOneElseSharingPPTSlideNum}
                      />
                  ) : (
                    <ScreenShareForAttendeeScreen
                      name={userName}
                      screenSharedUsername={screenSharedUserNameValue}
                      streams={screenSharedStreamValue}
                      sourceId={screenSharedSourceIDValue}
                      muteAllStream={muteAllStream}
                      remoteTrackSources={remoteTrackSources}
                    />
                  ))}
                  {(Array.from(remoteTrackSources))
                    .filter(([sourceId, { mediaStream }]) => {
                      return !(sourceId == screenSharedSourceIDValue || sourceId.includes('screenShare'));
                    })
                    .map(([sourceId, { mediaStream }], i) => {
                      // let muted = calculateIsMutedOrNot(sourceId);
                      return (
                        <ParticipentForAttendeeScreen
                          key={i}
                          name={sourceId}
                          streams={mediaStream}
                          sourceId={sourceId}
                          screenSharesourceId={screenSharedSourceIDValue}
                          mirror={false}
                          videoTrack={true}
                          remoteTrackSources={remoteTrackSources}
                          muteAllStream={muteAllStream}
                          // muted={muted}
                          recorder={true}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          ) : (
            <div className="ew-video-container attendee-layout overflow-hidden w-full h-full">
              <div
                className={
                  "presenters active-presenter-" + remoteTrackSources.size
                }
              >
                <div className="ew-n-presenters">                

                  {(Array.from(remoteTrackSources))
                  .filter(([sourceId, { mediaStream }]) => {
                    return !(sourceId == screenSharedSourceIDValue || sourceId.includes('screenShare'));
                  })
                  .map(
                    ([sourceId, { mediaStream }], i) => {
                      // let muted = calculateIsMutedOrNot(sourceId);
                      return (
                        <ParticipentForAttendeeScreen
                          key={i}
                          name={sourceId}
                          streams={mediaStream}
                          sourceId={sourceId}
                          screenSharesourceId={screenSharedSourceIDValue}
                          mirror={false}
                          // camera={true}
                          videoTrack={true}
                          remoteTrackSources={remoteTrackSources}
                          // muted={muted}
                          muteAllStream={muteAllStream}
                          recorder={true}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        

   



     
      </div>
    </>
  );
};
