export const awsTestAudioUrl = 'https://easywebinar-cdn.s3.amazonaws.com/livechat/audio/sample.mp3';

export const baseUrl=process.env.PUBLIC_URL

export const customCurrentEnv=process.env.REACT_APP_CUSTOM_CURRENT_ENV

export const easyWbinarBaseURL=process.env.REACT_APP_BASE_PATH

export const adminJoinUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/admin/join`

export const presenterJoinUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/presenter/join/?key=`

export const leaveAttendeeUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/attendee/leave`

export const attendeeJoinUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/attendee/join`

export const adminAjaxUrl=`${easyWbinarBaseURL}/wp-admin/admin-ajax.php`

export const webinarLiveEndUrl=`${easyWbinarBaseURL}/webinar-live-end`

export const exitGreenRoomUrl =`${easyWbinarBaseURL}/v1/easywebinar/v1/admin/exit-green-room`

export const endLiveSessionUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/admin/end-live-session`

export const webinarGoLiveUrl= `${easyWbinarBaseURL}/v1/easywebinar/v1/admin/webinar-go-live`

export const recordingStatusUrl= `${easyWbinarBaseURL}/v1/easywebinar/v1/admin/recording-status`

export const webinarStatusUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/owner/webinar-status`

export const moderatorJoinUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/moderator/join/?key=`

export const sessionPollsAnalyticsAdminUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/admin/session-poll-analytics`

export const attendeePollsViewUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/attendee/build-poll-view`

export const hoaWebinarSessionUrl=`${easyWbinarBaseURL}/hoa_webinar_sessions.php`

export const allChatUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/all/chat`

export const deleteChatUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/all/delete-chat`

export const blockedAttendeeUrl=process.env.REACT_APP_BLOCKED_PATH

export const lambdaPollAnsUrl=process.env.REACT_APP_POLL_ANSWER

export const lambdaOfferAnsUrl=process.env.REACT_APP_OFFER_ANSWER

export const pubnubResultsUrl=process.env.REACT_APP_PUBNUB_RESULT

export const presenceTestUrl=process.env.REACT_APP_ATTENDEE_PRESENCE

export const offerAnalyticsUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/admin/session-offer-analytics`

export const buildOfferViewUrl = `${easyWbinarBaseURL}/v1/easywebinar/v1/attendee/build-offer-view`

export const attendeeOfferClickUrl =  `${easyWbinarBaseURL}/v1/easywebinar/v1/attendee/offer-clicked`

export const dolbyRecordingUrl=`${easyWbinarBaseURL}/v1/dolby/recording`

export const sentryDSN=process.env.REACT_APP_SENTRY_DSN

export const sentryENV=process.env.REACT_APP_CUSTOM_CURRENT_ENV

export const listPFIlesUrl=`${easyWbinarBaseURL}/v1/presentation-files/list`

export const getPFIleUrl=`${easyWbinarBaseURL}/v1/presentation-files/get/`

export const savePFIlesUrl=`${easyWbinarBaseURL}/v1/presentation-files/save`

export const awsPFileS3AccessKey=process.env.REACT_APP_AWS_PFILE_S3_ACCESS_KEY

export const awsPFileS3AccessId=process.env.REACT_APP_AWS_PFILE_S3_ACCESS_ID

export const awsPFileS3AccessRegion=process.env.REACT_APP_AWS_PFILE_S3_ACCESS_REGION

export const awsPFileS3AccessBucket=process.env.REACT_APP_AWS_PFILE_S3_ACCESS_BUCKET

export const duplicateTab=`${easyWbinarBaseURL}/duplicate-tab`

export const recordingsGetUrl=`${easyWbinarBaseURL}/v1/easywebinar/v1/video/previous-session`

export const videoGetUrlFrom=`${easyWbinarBaseURL}/v1/easywebinar/v1/video/custom`

export const learnMorePlaybackUrl=`http://support.easywebinar.com/en/articles/10214031-live-room-resolving-playback-errors-for-videos-in-your-webinar`

