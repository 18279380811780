import React, { useState } from "react";
import warningIcon from "../../assets/images/warning-icon.svg";
import closeIcon from "../../assets/images/close-icon.svg";
import Draggable from 'react-draggable';
import { isMobile } from "react-device-detect";
const NetwotkUnstable = ({onCloseNetworkUnstable}) => {

 

  if(!isMobile){
    return (
      <Draggable
          // axis="x"
          // handle=".handle"
          // defaultPosition={{x: 50%, y: 0}}
          position={null}
          // scale={1}
          // onStart={this.handleStart}
          // onDrag={this.handleDrag}
          // onStop={this.handleStop}
      >
      <div className="flex flex-center toaster-mg newtwork-unstable-msg handle"
        style={{
          position: "absolute",  // Absolute positioning
          top: "20px",          // Distance from the top
          left: "calc(50% - 240px)",           // Horizontally centered
          transform: "translateX(-50%) !important"  // Align center horizontally
        }}
      >
        <div className="img-wrapper flex flex-center">
          <img src={warningIcon} alt="" />
        </div>
        <div className="message-text flex flex-center">
          <p>
            <span>Network Unstable:</span> Please Check Your Internet Connection.
          </p>
          <a href="#" className="close" onClick={()=>onCloseNetworkUnstable()}>
            <img src={closeIcon} alt="" />
          </a>
        </div>
      </div>
      </Draggable>
    );
  }

  if(isMobile){
    return (
      
      <div className="flex flex-center toaster-mg newtwork-unstable-msg">
        <div className="img-wrapper flex flex-center">
          <img src={warningIcon} alt="" />
        </div>
        <div className="message-text flex flex-center">
          <p>
            <span>Network Unstable:</span> Please Check Your Internet Connection.
          </p>
          <a href="#" className="close" onClick={()=>onCloseNetworkUnstable()}>
            <img src={closeIcon} alt="" />
          </a>
        </div>
      </div>
    );
  }
};

export default NetwotkUnstable;
