import React, { useState } from 'react';
import '../../assets/styles/main.scss';
import buttonLoader from '../../assets/images/button-loader.gif';

export const ConfirmationPopup = ({ isConfirmationOpen, onConfirmationClose }) => {
    
    return (

        <div className={`modal confirmation-popup ${isConfirmationOpen ? 'active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-container with-header">
                <div className="modal-header">
                    <h3 className='flex'>End session</h3>
                    <span className="close-btn" onClick={onConfirmationClose}>&times;</span>
                </div>
                <div className="modal-content flex">
                    <div className="confirmation-info">
                        <p>Are you sure you want to end the session? This will end the session for all participant's.</p>
                    </div>
                </div>
                <div className="modal-footer flex">
                    <div className="flex">
                        <button type="button" className="default-btn" onClick={onConfirmationClose}>Cancel</button>
                        <button type="button" className="warning-button loading">
                            End Session
                            <span className='btn-loader'><img src={buttonLoader} alt="" /></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
