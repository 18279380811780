import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import { sentryDSN, sentryENV } from "./config";

Sentry.init({
    dsn: sentryDSN,
    normalizeDepth: 10,
    environment: sentryENV,
    integrations: [
        Sentry.browserTracingIntegration(),
        // Sentry.replayIntegration({
        //     maskAllText: false,
        //     blockAllMedia: false,
        // }),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],  
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
});