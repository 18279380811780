import React, { useState } from "react";
import "../../assets/styles/main.scss";

export const ExitSessionConfirmationPop = ({
  isConfirmationOpen,
  onCloseConfirmationPopUp,
  leaveroom
}) => {
  if (!isConfirmationOpen) {
    return;
  }

  return (
    <div
      className={`modal confirmation-popup ${
        isConfirmationOpen ? "active" : ""
      }`}
    >
      <div className="modal-background"></div>
      <div className="modal-container with-header">
        <div className="modal-header">
          <h3 className="flex">
          Confirm End Green Room?
          </h3>
          <span className="close-btn" onClick={onCloseConfirmationPopUp}>
            &times;
          </span>
        </div>
        <div className="modal-content flex">
          <div className="confirmation-info">
           
              <p>
              Are you sure you want to exit? This will close the Green Room for everyone, and participants will no longer have access.
              </p>
           
            
          </div>
        </div>
        <div className="modal-footer flex">
          <div className="flex">
            <button
              type="button"
              className="default-btn"
              onClick={onCloseConfirmationPopUp}
            >
              Cancel
            </button>
            <button
              type="button"
              className="warning-button"
              onClick={()=>{
                leaveroom(true)
               }}
            >
             Yes, End Green Room
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
