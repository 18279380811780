import React from 'react';
import { useTimer } from 'react-timer-hook';

interface CountdownTimerProps {
    expiryTimestamp: any;
    closeOfferFunction?: () => void;
  }

const MyTimer: React.FC<CountdownTimerProps> = ({ expiryTimestamp, closeOfferFunction }) => {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => closeOfferFunction() });

  return (
    <div style={{textAlign: 'center'}}>
      {/* <h1>react-timer-hook </h1>
      <p>Timer Demo</p> */}
      {/* <div style={{fontSize: '40px', marginBottom: '12px'}}>
        <span>{String(hours).padStart(2, '0')}</span>:<span>{String(minutes).padStart(2, '0')}</span>:<span>{String(seconds).padStart(2, '0')}</span>
      </div> */}

      <p className="flex flex-center">
        <span className="flex">
          {`${hours > 0 ? String(hours).padStart(2, '0') : 0}`} <span>HOURS</span>
        </span>
        <span className="flex">
          {`${minutes > 0 ? String(minutes).padStart(2, '0') : 0}`} <span>MINUTES</span>
        </span>
        <span className="flex">
          {`${seconds > 0 ? String(seconds).padStart(2, '0') : 0}`} <span>SECONDS</span>
        </span>
      </p>

      {/* <p>{isRunning ? 'Running' : 'Not running'}</p>
      <button onClick={start}>Start</button>
      <button onClick={pause}>Pause</button>
      <button onClick={resume}>Resume</button>
      <button onClick={() => {
        // Restarts to 5 minutes timer
        const time = new Date();
        time.setSeconds(time.getSeconds() + 300);
        restart(time)
      }}>Restart</button> */}
    </div>
  );
}

export default function App({expiryTimestamp, closeOfferFunction}) {
  
  return (
    <div>
      <MyTimer expiryTimestamp={expiryTimestamp*1000} closeOfferFunction={closeOfferFunction}/>
    </div>
  );
}