import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setAuthToken } from "../../libs/APIs/auth";
import { postRequest } from "../../libs/APIs/apiCall";
import { PresentorStream } from "./PresentorStream";
import Loader from "../Loader/Loader";
import { blockedAttendeeUrl, presenterJoinUrl } from "../../config";
import ErrorMessage from "../CommonComponents/ErrorMessage";

export const Presenter = () => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();
   console.log("uuid",stateData.publishStreamObj.userUUID)
  //Loader
  const [loader, setLoader] = useState(true);

  //Routes parameter read
  const [params, setParams] = useSearchParams();
  const [pname, setpname] = useState(params.get("pname"));
  const [pemail, setpemail] = useState(params.get("pname"));
  const [key, setkey] = useState(params.get("key"));
  const [session_id, setSessionId] = useState(params.get("sid"));
  const [userName, setuserName] = useState("");
  const [token, settoken] = useState("");
  const [streamName, setstreamName] = useState("");
  const [subscription_token, setsubscription_token] = useState("");
  const [pubnub_access_token, setpubnub_access_token] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  //end

  // Backend API call start
  function updateReduxState(
    name,
    email,
    token,
    subscription_token,
    streamName,
    sessionId,
    webinarid,
    userUUID,
    event_name,
    attendeeLink,
    moderatorLink,
    presentorLink,
    userKey,
    adminUserId,
    webinar_version,
    did
  ) {
    dispatch({
      type: "ADD_USERNAME",
      payload: {
        ...stateData?.publishStreamObj,
        userName: name,
        userEmail: email,
        token: token,
        subscribeToken: subscription_token,
        streamName: streamName,
        sessionId: sessionId,
        webinarid: webinarid,
        userUUID: userUUID,
        isPresenter: true,
        event_name: event_name,
        attendeeLink: attendeeLink,
        moderatorLink: moderatorLink,
        presentorLink: presentorLink,
        userKey: userKey,
        adminUserId: adminUserId,
        webinar_version: webinar_version,
        did: did
      },
    });
  }

  useEffect(() => {
    (async () => {
      // localStorage.setItem(
      //   "ew_jwt_token",
      //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MjU1OTk5NzcsImV4cCI6MTcyNTYxNDM3NywiZGF0YSI6eyJ1c2VybmFtZSI6InRlc3QgdXNlciIsInVzZXJfaWQiOjc5MzksImV4cG9ydF9saW1pdCI6IjIwMCJ9fQ.s6QbWtqE9eHwSmE4AsHD1jbIoNMPAhEvflQlpXP2Fdo"
      // );

      if (localStorage.getItem("Blocked") &&  (localStorage.getItem("Blocked")== new URL(window.location.href).searchParams.get('pemail'))) {
        const baseUrl = blockedAttendeeUrl; // Target URL
        // const newUrl = `${baseUrl}?id=${stateData.publishStreamObj?.webinarid}`;
        window.location.href = baseUrl;
      }

      const ew_jwt_token = localStorage.getItem("ew_jwt_token");
      setAuthToken(ew_jwt_token);
      try {
        const res = await postRequest(
          presenterJoinUrl + key + "&pname=" + pname + "&pemail=" + pemail
        );

        //end

        if (res?.status == "error") {
          setErrorMessage(res.message);
          setLoader(false);
          return;
        }

        setuserName(res.data.name);
        settoken(res.data.token);
        setstreamName(res.data.stream[0].streamName);
        setsubscription_token(res.data.subscription_token);
        setpubnub_access_token(res.data.pubnub_accessToken);
        updateReduxState(
          res.data.name,
          res.data.email,
          res.data.token,
          res.data.subscription_token,
          res.data.stream[0].streamName,
          res.data.session_id,
          res.data.webinar_id,
          res.data.pn_user_id,
          res.data?.webinar_details?.event_name,
          res.data.attendee_link,
          res.data.moderator_link,
          res.data.presenter_link,
          res.data.user_key,
          res.data.adminUserId,
          res.data.webinar_version,
          res.data.domain_id
        );
        setLoader(false);
      } catch (error) {
        setErrorMessage(error.message);
        setLoader(false);
      }
    })();
  }, []);
  // Backend API call end

  return (
    <>
      {loader && <Loader />}
      {!loader && !errorMessage && (
        <PresentorStream
          userName={userName}
          streamName={streamName}
          token={token}
          subscription_token={subscription_token}
          pubnub_accessToken={pubnub_access_token}
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </>
  );
};
