import React from "react";
import { useEffect, useRef, useState } from "react";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import refreshIcon from "../../assets/images/refresh-icon.svg";
import { Spinner } from "@chakra-ui/react";
import refreshDarkIcon from "../../assets/images/refresh-dark-icon.png";
import verticallyDotsIcon from "../../assets/images/vertically-dots.svg";
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";

interface props {
  name: string;
  screenSharedUsername: string;
  streams: any;
  sourceId: string;
  muteAllStream: boolean;
  remoteTrackSources: any;
}

export const ScreenShareForAttendeeScreen = ({
  name,
  streams,
  sourceId,
  screenSharedUsername,
  muteAllStream,
  remoteTrackSources
}: props) => {
  //Redux start
  const stateData = useSelector((state: any) => state.PublishStreamReducer);

  const videoRef = useRef<HTMLVideoElement>(null);

  function stopScreenShare() {
    const obj: PublishStream = stateData?.publishStreamObj?.obj;
    obj.stopSreenShare();
  }

  // Initialise video
  const [loadingVideo, setLoadingVideo] = useState(true);
  const messageRef = useRef(false);

  useEffect(() => {
    console.log('screenshare remote track updated', remoteTrackSources); 
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
    }
  }, [remoteTrackSources]);


  useEffect(() => {
   
    const handleVisibilityChange = () => {
      console.log('screenshare handle visibility'); 
      const pageVisibility = document.visibilityState;
      
      if (pageVisibility === 'hidden') {       
        
      } else if (pageVisibility === 'visible') {
        reLoad();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // const handleBeforeUnload = (event) => {        
    // };
    // const handlePageHide = () => {       
    // };    
    const handlePageShow = () => {      
      console.log('screenshare handle page show'); 
      reLoad();
    };
  
    // window.addEventListener("pagehide", handlePageHide);
    window.addEventListener("pageshow", handlePageShow);  
    // window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      // window.removeEventListener("pagehide", handlePageHide);
      window.removeEventListener("pageshow", handlePageShow);
      // window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    
  }, []);

  const reLoad = () => {
    console.log('');
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
    }
  };

  // Presenter dropdwon
  const dropdownRef = useRef(null);
  const [presenterMenuOpen, setPresenterMenuOpen] = useState(false);

  const handlePresenterDropdownClick = () => {
    setPresenterMenuOpen(!presenterMenuOpen);
  };

  const handleClickOutsideDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setPresenterMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <div className="presenter-column screen-sharing-user-fullscreen">
      <div className="ew-video-wrapper">
        {loadingVideo && (
          <div className="flex flex-center loader-bg">
            <div className="blur-bg"></div>
            <div className="flex flex-center section-loader">
              <img src={blueCircleLoader} alt="" />
              <span>Loading...</span>
            </div>
          </div>
        )}

        <div className="presenter-options-dropdown" ref={dropdownRef}>
          <span
            className="flex flex-center"
            onClick={handlePresenterDropdownClick}
          >
            <img src={verticallyDotsIcon} alt="" />
          </span>
          {presenterMenuOpen && (
            <ul>
              <li className="flex" onClick={reLoad}>
                <img src={refreshDarkIcon} alt="" />
                <span>Refresh</span>
              </li>
            </ul>
          )}
        </div>
        {screenSharedUsername != name && (
          <div className="ew-live-video">
            <video
              controls={false}
              autoPlay
              loop
              muted={muteAllStream}
              ref={videoRef}
              id={sourceId}
              onError={() => {
                videoRef.current?.error &&
                  console.error(
                    `Video player error: ${videoRef.current?.error}`
                  );
              }}
              onLoadStart={() => setLoadingVideo(true)}
              onPlaying={() => {
                setLoadingVideo(false);
                messageRef.current = false;
              }}
              onStalled={() => {
                console.error("Video is on stalled");
              }}
              onWaiting={() => setLoadingVideo(true)}
              playsInline
            ></video>
          </div>
        )}
        <div className="presenter-name flex flex-center">
          {screenSharedUsername}
        </div>
      </div>
    </div>
  );
};
