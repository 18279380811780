import { useEffect, useState } from "react";
import { offerStatus } from "./offer-enum";
import { usePubNub } from "pubnub-react";
import { useSelector } from "react-redux";
import { Polls } from "../Polls/Polls";
import { postRequestWithFormdata } from "../../libs/APIs/apiCall";
import { setAuthToken } from "../../libs/APIs/auth";
import Offers from "./Offers";
import { title } from "process";
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";
import { hoaWebinarSessionUrl } from "../../config";

interface props {
  createPollHandler: () => void;
  updatePollHandler: (poll) => void;
  crossIconHandler?: ()=>void;
}

export const OffersMain = ({index,crossIconHandler}) => {
  // Settings popup tabs
  const stateData = useSelector((state: any) => state.PublishStreamReducer);

  //Backend API call start
  const pubnubobj = usePubNub();
  const [offersData, setOffersData] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
  if(index==2){

    const setAssignedOffers = (offerIdsArr) => {
      let globalOfferChannel =
        "Offers" +
        "." +
        stateData.publishStreamObj.webinarid +
        "." +
        stateData.publishStreamObj.sessionId;

      pubnubobj.objects
        .getChannelMetadata({
          channel: globalOfferChannel,
        })
        .then((response) => {
          console.log("response ::: ", response);
          let existingActiveOffer = response.data.custom.activeOffer;

          pubnubobj.objects
            .setChannelMetadata({
              channel: globalOfferChannel,
              data: {
                custom: {
                  assignedOffer: JSON.stringify(offerIdsArr),
                  activeOffer: existingActiveOffer,
                },
              },
            })
            .then((setofferidRes) => {
              setIsDataReady(true);
            })
            .catch((err) => {
              console.log("Error setting channel metadata 03 ::: ", err);
            });
        })
        .catch((error) => {
          /**
           * channel does not exists
           */
          if (
            error.status.error &&
            error.status.errorData &&
            error.status.errorData.status === 404
          ) {
            pubnubobj.objects
              .setChannelMetadata({
                channel: globalOfferChannel,
                data: {
                  custom: {
                    assignedOffer: JSON.stringify(offerIdsArr),
                    activeOffer: "",
                  },
                },
              })
              .then((r) => {
                setIsDataReady(true);
              })
              .catch((err) => {
                console.log("Error setting channel metadata 04 ::: ", err);
              });
          }
        });
    };

    let offerIds = [];

    const ew_jwt_token = localStorage.getItem("ew_jwt_token");
    (async () => {
      setAuthToken(ew_jwt_token);
      let bodyFormData = new FormData();
      bodyFormData.append("webinar_id", stateData.publishStreamObj.webinarid);

      const resAPI = await postRequestWithFormdata(
        hoaWebinarSessionUrl,
        bodyFormData
      );
     


      // let offersresp = [
      //   {
      //       "id": "2144",
      //       "title": "button offer 01 with timer",
      //       "countdown_time": 120,
      //       "publishTime": null,
      //       "content": "<div style=\"margin-bottom:5px;\" class=\"event_1 offer2144\"><span class=\"title-event-prv-common\"><b>headline here</b></span>sub headline here<a class=\\\"call_to_action event_image1\\\" href=\\\"https://helpdesk.everflow.io/en/articles/6103265-offer-urls\\\"target=\\\"_blank\\\"   webinar_id=\\\"8685\\\" is_event=\\\"1\\\" event_id=\\\"2144\\\" tag_id=\\\"\\\" tag_rid=\\\"\\\" button_id=\\\"0\\\" ><button class=\"custom-btn large-btn-offer\" style=\"color:#ffffff; background: #28493b; border-bottom-color:#28493b; align-items: center;\" ><span class=\"material-icons\">vpn_key</span><span>Get Access Now</span></button></a><div class=\"countdown clearfix\"  id=\"countdown2144\"><div class=\"common-part\"><div class=\"ce-digit ce-hours digit\"><div class=\"ce-hours-digit count_time\">00</div></div><div class=\"hours-per common-hours\">Hours</div></div><div class=\"common-part\"><div class=\"ce-digit ce-minutes digit\"><div class=\"ce-minutes-digit count_time\">02</div></div><div class=\"minute-per common-hours\">Minutes</div></div><div class=\"common-part\"><div class=\"ce-digit ce-seconds digit\"><div class=\"ce-seconds-digit count_time\">00</div></div><div class=\"second-per common-hours\">Seconds</div></div></div></div>",
      //       "btn_settings": "{\"offer_id\":\"2144\",\"btn_id\":\"0\",\"btn_txt\":\"Get Access Now\",\"btn_color\":\"#ffffff\",\"btn_bgcolor\":\"#28493b\",\"btn_img\":\"\",\"btn_icon\":\"<span class=\\\"material-icons\\\">vpn_key<\\/span>\",\"btn_url\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6103265-offer-urls\",\"tag_id\":\"\",\"tag_rid\":\"\",\"offer_txt\":\"sub headline here\",\"offer_heading\":\"headline here\",\"is_event\":1}",
      //       "offer_desc": "{\"offer_title\":\"button offer 01 with timer\",\"offer_heading\":\"<span class=\\\"title-event-prv-common\\\"><b>headline here<\\/b><\\/span>\",\"offer_subheading\":\"sub headline here\",\"offer_image\":\"\",\"offer_btn\":{\"txt\":\"Get Access Now\",\"color\":\"#ffffff\",\"bg\":\"#28493b\",\"size\":\"large-btn-offer\",\"icon\":\"vpn_key\"},\"offer_link\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6103265-offer-urls\",\"offer_type\":\"0\",\"offer_heading_toggle\":\"1\",\"description_toggle\":\"1\",\"publish_toggle\":\"1\",\"countdown_enable\":\"1\",\"countdown_time\":\"00:02:00\",\"offer_start_time\":\"00:00:00\",\"offer_end_time\":\"00:00:00\",\"replay_offer\":\"0\"}"
      //   },
      //   {
      //       "id": "2145",
      //       "title": "image offer with timer",
      //       "countdown_time": 122,
      //       "publishTime": null,
      //       "content": "<div style=\"margin-bottom:5px;\" class=\"event_2 offer2145\"><a class=\"call_to_action event_image2\" href=\"https://helpdesk.everflow.io/en/articles/6103265-offer-urls\"target=\"_blank\" webinar_id=\"8685\" is_event=\"1\" event_id=\"2145\"  tag_id=\"\" tag_rid=\"\" button_id=\"0\" ><img  src=\"https://uikit-test.easywebinar.com/assets/images/offer-image.jpg\"style=\"max-width:100%\" /></a><div class=\"countdown clearfix\"  id=\"countdown2145\"><div class=\"common-part\"><div class=\"ce-digit ce-hours digit\"><div class=\"ce-hours-digit count_time\">00</div></div><div class=\"hours-per common-hours\">Hours</div></div><div class=\"common-part\"><div class=\"ce-digit ce-minutes digit\"><div class=\"ce-minutes-digit count_time\">02</div></div><div class=\"minute-per common-hours\">Minutes</div></div><div class=\"common-part\"><div class=\"ce-digit ce-seconds digit\"><div class=\"ce-seconds-digit count_time\">02</div></div><div class=\"second-per common-hours\">Seconds</div></div></div></div>",
      //       "btn_settings": "{\"offer_id\":\"2145\",\"btn_id\":\"0\",\"btn_txt\":\"\",\"btn_color\":\"\",\"btn_bgcolor\":\"\",\"btn_img\":\"https:\\/\\/uikit-test.easywebinar.com\\/assets\\/images\\/offer-image.jpg\",\"btn_icon\":\"<span class=\\\"material-icons\\\">vpn_key<\\/span>\",\"btn_url\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6103265-offer-urls\",\"tag_id\":\"\",\"tag_rid\":\"\",\"offer_txt\":\"\",\"offer_heading\":\"\",\"is_event\":1}",
      //       "offer_desc": "{\"offer_title\":\"image offer with timer\",\"offer_heading\":\"\",\"offer_subheading\":\"\",\"offer_image\":\"https:\\/\\/uikit-test.easywebinar.com\\/assets\\/images\\/offer-image.jpg\",\"offer_btn\":{\"txt\":\"Get Access Now\",\"color\":\"#ffffff\",\"bg\":\"#28493b\",\"size\":\"large-btn-offer\",\"icon\":\"vpn_key\"},\"offer_link\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6103265-offer-urls\",\"offer_type\":\"1\",\"offer_heading_toggle\":\"0\",\"description_toggle\":\"0\",\"publish_toggle\":\"1\",\"countdown_enable\":\"1\",\"countdown_time\":\"00:02:02\",\"offer_start_time\":\"00:00:00\",\"offer_end_time\":\"00:00:00\",\"replay_offer\":\"0\"}"
      //   },
      //   {
      //       "id": "2153",
      //       "title": "button offer without timer",
      //       "countdown_time": 0,
      //       "publishTime": null,
      //       "content": "<div style=\"margin-bottom:5px;\" class=\"event_3 offer2153\"><span class=\"title-event-prv-common\"><b>get 50% of for first time</b></span>get first thousand users 50% off in this month<a class=\\\"call_to_action event_image3\\\" href=\\\"https://helpdesk.everflow.io/en/articles/6116741-offer-templates\\\"target=\\\"_blank\\\"   webinar_id=\\\"8685\\\" is_event=\\\"1\\\" event_id=\\\"2153\\\" tag_id=\\\"\\\" tag_rid=\\\"\\\" button_id=\\\"0\\\" ><button class=\"custom-btn medium-btn-offer\" style=\"color:#ffffff; background: #475851; border-bottom-color:#475851; align-items: center;\" ><span class=\"material-icons\">vpn_key</span><span>get offer now</span></button></a></div>",
      //       "btn_settings": "{\"offer_id\":\"2153\",\"btn_id\":\"0\",\"btn_txt\":\"get offer now\",\"btn_color\":\"#ffffff\",\"btn_bgcolor\":\"#475851\",\"btn_img\":\"\",\"btn_icon\":\"<span class=\\\"material-icons\\\">vpn_key<\\/span>\",\"btn_url\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6116741-offer-templates\",\"tag_id\":\"\",\"tag_rid\":\"\",\"offer_txt\":\"get first thousand users 50% off in this month\",\"offer_heading\":\"get 50% of for first time\",\"is_event\":1}",
      //       "offer_desc": "{\"offer_title\":\"button offer without timer\",\"offer_heading\":\"<span class=\\\"title-event-prv-common\\\"><b>get 50% of for first time<\\/b><\\/span>\",\"offer_subheading\":\"get first thousand users 50% off in this month\",\"offer_image\":\"\",\"offer_btn\":{\"txt\":\"get offer now\",\"color\":\"#ffffff\",\"bg\":\"#475851\",\"size\":\"medium-btn-offer\",\"icon\":\"vpn_key\"},\"offer_link\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6116741-offer-templates\",\"offer_type\":\"0\",\"offer_heading_toggle\":\"1\",\"description_toggle\":\"1\",\"publish_toggle\":\"1\",\"countdown_enable\":\"0\",\"countdown_time\":\"00:04:00\",\"offer_start_time\":\"00:00:00\",\"offer_end_time\":\"00:00:00\",\"replay_offer\":\"0\"}"
      //   },
      //   {
      //       "id": "2154",
      //       "title": "image offer without timer",
      //       "countdown_time": 0,
      //       "publishTime": null,
      //       "content": "<div style=\"margin-bottom:5px;\" class=\"event_4 offer2154\"><a class=\"call_to_action event_image4\" href=\"https://helpdesk.everflow.io/en/articles/6116741-offer-templates\"target=\"_blank\" webinar_id=\"8685\" is_event=\"1\" event_id=\"2154\"  tag_id=\"\" tag_rid=\"\" button_id=\"0\" ><img  src=\"https://uikit-test.easywebinar.com/assets/images/offer-image.jpg\"style=\"max-width:100%\" /></a></div>",
      //       "btn_settings": "{\"offer_id\":\"2154\",\"btn_id\":\"0\",\"btn_txt\":\"\",\"btn_color\":\"\",\"btn_bgcolor\":\"\",\"btn_img\":\"https:\\/\\/uikit-test.easywebinar.com\\/assets\\/images\\/offer-image.jpg\",\"btn_icon\":\"<span class=\\\"material-icons\\\">vpn_key<\\/span>\",\"btn_url\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6116741-offer-templates\",\"tag_id\":\"\",\"tag_rid\":\"\",\"offer_txt\":\"\",\"offer_heading\":\"\",\"is_event\":1}",
      //       "offer_desc": "{\"offer_title\":\"image offer without timer\",\"offer_heading\":\"\",\"offer_subheading\":\"\",\"offer_image\":\"https:\\/\\/uikit-test.easywebinar.com\\/assets\\/images\\/offer-image.jpg\",\"offer_btn\":{\"txt\":\"get offer now\",\"color\":\"#ffffff\",\"bg\":\"#475851\",\"size\":\"medium-btn-offer\",\"icon\":\"vpn_key\"},\"offer_link\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6116741-offer-templates\",\"offer_type\":\"1\",\"offer_heading_toggle\":\"0\",\"description_toggle\":\"0\",\"publish_toggle\":\"1\",\"countdown_enable\":\"0\",\"countdown_time\":\"00:02:00\",\"offer_start_time\":\"00:00:00\",\"offer_end_time\":\"00:00:00\",\"replay_offer\":\"0\"}"
      //   },
      //   {
      //     "id": "2149",
      //     "title": "image offer with timer",
      //     "countdown_time": 240,
      //     "publishTime": null,
      //     "content": "<div style=\"margin-bottom:5px;\" class=\"event_2 offer2145\"><a class=\"call_to_action event_image2\" href=\"https://helpdesk.everflow.io/en/articles/6103265-offer-urls\"target=\"_blank\" webinar_id=\"8685\" is_event=\"1\" event_id=\"2145\"  tag_id=\"\" tag_rid=\"\" button_id=\"0\" ><img  src=\"https://uikit-test.easywebinar.com/assets/images/offer-image.jpg\"style=\"max-width:100%\" /></a><div class=\"countdown clearfix\"  id=\"countdown2145\"><div class=\"common-part\"><div class=\"ce-digit ce-hours digit\"><div class=\"ce-hours-digit count_time\">00</div></div><div class=\"hours-per common-hours\">Hours</div></div><div class=\"common-part\"><div class=\"ce-digit ce-minutes digit\"><div class=\"ce-minutes-digit count_time\">02</div></div><div class=\"minute-per common-hours\">Minutes</div></div><div class=\"common-part\"><div class=\"ce-digit ce-seconds digit\"><div class=\"ce-seconds-digit count_time\">02</div></div><div class=\"second-per common-hours\">Seconds</div></div></div></div>",
      //     "btn_settings": "{\"offer_id\":\"2145\",\"btn_id\":\"0\",\"btn_txt\":\"\",\"btn_color\":\"\",\"btn_bgcolor\":\"\",\"btn_img\":\"https:\\/\\/uikit-test.easywebinar.com\\/assets\\/images\\/offer-image.jpg\",\"btn_icon\":\"<span class=\\\"material-icons\\\">vpn_key<\\/span>\",\"btn_url\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6103265-offer-urls\",\"tag_id\":\"\",\"tag_rid\":\"\",\"offer_txt\":\"\",\"offer_heading\":\"\",\"is_event\":1}",
      //     "offer_desc": "{\"offer_title\":\"image offer with timer\",\"offer_heading\":\"\",\"offer_subheading\":\"\",\"offer_image\":\"https:\\/\\/uikit-test.easywebinar.com\\/assets\\/images\\/offer-image.jpg\",\"offer_btn\":{\"txt\":\"Get Access Now\",\"color\":\"#ffffff\",\"bg\":\"#28493b\",\"size\":\"large-btn-offer\",\"icon\":\"vpn_key\"},\"offer_link\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6103265-offer-urls\",\"offer_type\":\"1\",\"offer_heading_toggle\":\"0\",\"description_toggle\":\"0\",\"publish_toggle\":\"1\",\"countdown_enable\":\"1\",\"countdown_time\":\"00:02:02\",\"offer_start_time\":\"00:00:00\",\"offer_end_time\":\"00:00:00\",\"replay_offer\":\"0\"}"
      //   },
      //   {
      //     "id": "2150",
      //     "title": "image offer with timer",
      //     "countdown_time": 240,
      //     "publishTime": null,
      //     "content": "<div style=\"margin-bottom:5px;\" class=\"event_2 offer2145\"><a class=\"call_to_action event_image2\" href=\"https://helpdesk.everflow.io/en/articles/6103265-offer-urls\"target=\"_blank\" webinar_id=\"8685\" is_event=\"1\" event_id=\"2145\"  tag_id=\"\" tag_rid=\"\" button_id=\"0\" ><img  src=\"https://uikit-test.easywebinar.com/assets/images/offer-image.jpg\"style=\"max-width:100%\" /></a><div class=\"countdown clearfix\"  id=\"countdown2145\"><div class=\"common-part\"><div class=\"ce-digit ce-hours digit\"><div class=\"ce-hours-digit count_time\">00</div></div><div class=\"hours-per common-hours\">Hours</div></div><div class=\"common-part\"><div class=\"ce-digit ce-minutes digit\"><div class=\"ce-minutes-digit count_time\">02</div></div><div class=\"minute-per common-hours\">Minutes</div></div><div class=\"common-part\"><div class=\"ce-digit ce-seconds digit\"><div class=\"ce-seconds-digit count_time\">02</div></div><div class=\"second-per common-hours\">Seconds</div></div></div></div>",
      //     "btn_settings": "{\"offer_id\":\"2145\",\"btn_id\":\"0\",\"btn_txt\":\"\",\"btn_color\":\"\",\"btn_bgcolor\":\"\",\"btn_img\":\"https:\\/\\/uikit-test.easywebinar.com\\/assets\\/images\\/offer-image.jpg\",\"btn_icon\":\"<span class=\\\"material-icons\\\">vpn_key<\\/span>\",\"btn_url\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6103265-offer-urls\",\"tag_id\":\"\",\"tag_rid\":\"\",\"offer_txt\":\"\",\"offer_heading\":\"\",\"is_event\":1}",
      //     "offer_desc": "{\"offer_title\":\"image offer with timer\",\"offer_heading\":\"\",\"offer_subheading\":\"\",\"offer_image\":\"https:\\/\\/uikit-test.easywebinar.com\\/assets\\/images\\/offer-image.jpg\",\"offer_btn\":{\"txt\":\"Get Access Now\",\"color\":\"#ffffff\",\"bg\":\"#28493b\",\"size\":\"large-btn-offer\",\"icon\":\"vpn_key\"},\"offer_link\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6103265-offer-urls\",\"offer_type\":\"1\",\"offer_heading_toggle\":\"0\",\"description_toggle\":\"0\",\"publish_toggle\":\"1\",\"countdown_enable\":\"1\",\"countdown_time\":\"00:02:02\",\"offer_start_time\":\"00:00:00\",\"offer_end_time\":\"00:00:00\",\"replay_offer\":\"0\"}"
      //   },
      //   {
      //     "id": "2151",
      //     "title": "image offer with timer",
      //     "countdown_time": 240,
      //     "publishTime": null,
      //     "content": "<div style=\"margin-bottom:5px;\" class=\"event_2 offer2145\"><a class=\"call_to_action event_image2\" href=\"https://helpdesk.everflow.io/en/articles/6103265-offer-urls\"target=\"_blank\" webinar_id=\"8685\" is_event=\"1\" event_id=\"2145\"  tag_id=\"\" tag_rid=\"\" button_id=\"0\" ><img  src=\"https://uikit-test.easywebinar.com/assets/images/offer-image.jpg\"style=\"max-width:100%\" /></a><div class=\"countdown clearfix\"  id=\"countdown2145\"><div class=\"common-part\"><div class=\"ce-digit ce-hours digit\"><div class=\"ce-hours-digit count_time\">00</div></div><div class=\"hours-per common-hours\">Hours</div></div><div class=\"common-part\"><div class=\"ce-digit ce-minutes digit\"><div class=\"ce-minutes-digit count_time\">02</div></div><div class=\"minute-per common-hours\">Minutes</div></div><div class=\"common-part\"><div class=\"ce-digit ce-seconds digit\"><div class=\"ce-seconds-digit count_time\">02</div></div><div class=\"second-per common-hours\">Seconds</div></div></div></div>",
      //     "btn_settings": "{\"offer_id\":\"2145\",\"btn_id\":\"0\",\"btn_txt\":\"\",\"btn_color\":\"\",\"btn_bgcolor\":\"\",\"btn_img\":\"https:\\/\\/uikit-test.easywebinar.com\\/assets\\/images\\/offer-image.jpg\",\"btn_icon\":\"<span class=\\\"material-icons\\\">vpn_key<\\/span>\",\"btn_url\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6103265-offer-urls\",\"tag_id\":\"\",\"tag_rid\":\"\",\"offer_txt\":\"\",\"offer_heading\":\"\",\"is_event\":1}",
      //     "offer_desc": "{\"offer_title\":\"image offer with timer\",\"offer_heading\":\"\",\"offer_subheading\":\"\",\"offer_image\":\"https:\\/\\/uikit-test.easywebinar.com\\/assets\\/images\\/offer-image.jpg\",\"offer_btn\":{\"txt\":\"Get Access Now\",\"color\":\"#ffffff\",\"bg\":\"#28493b\",\"size\":\"large-btn-offer\",\"icon\":\"vpn_key\"},\"offer_link\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6103265-offer-urls\",\"offer_type\":\"1\",\"offer_heading_toggle\":\"0\",\"description_toggle\":\"0\",\"publish_toggle\":\"1\",\"countdown_enable\":\"1\",\"countdown_time\":\"00:02:02\",\"offer_start_time\":\"00:00:00\",\"offer_end_time\":\"00:00:00\",\"replay_offer\":\"0\"}"
      //   },
      //   {
      //     "id": "2152",
      //     "title": "image offer with timer",
      //     "countdown_time": 240,
      //     "publishTime": null,
      //     "content": "<div style=\"margin-bottom:5px;\" class=\"event_2 offer2145\"><a class=\"call_to_action event_image2\" href=\"https://helpdesk.everflow.io/en/articles/6103265-offer-urls\"target=\"_blank\" webinar_id=\"8685\" is_event=\"1\" event_id=\"2145\"  tag_id=\"\" tag_rid=\"\" button_id=\"0\" ><img  src=\"https://uikit-test.easywebinar.com/assets/images/offer-image.jpg\"style=\"max-width:100%\" /></a><div class=\"countdown clearfix\"  id=\"countdown2145\"><div class=\"common-part\"><div class=\"ce-digit ce-hours digit\"><div class=\"ce-hours-digit count_time\">00</div></div><div class=\"hours-per common-hours\">Hours</div></div><div class=\"common-part\"><div class=\"ce-digit ce-minutes digit\"><div class=\"ce-minutes-digit count_time\">02</div></div><div class=\"minute-per common-hours\">Minutes</div></div><div class=\"common-part\"><div class=\"ce-digit ce-seconds digit\"><div class=\"ce-seconds-digit count_time\">02</div></div><div class=\"second-per common-hours\">Seconds</div></div></div></div>",
      //     "btn_settings": "{\"offer_id\":\"2145\",\"btn_id\":\"0\",\"btn_txt\":\"\",\"btn_color\":\"\",\"btn_bgcolor\":\"\",\"btn_img\":\"https:\\/\\/uikit-test.easywebinar.com\\/assets\\/images\\/offer-image.jpg\",\"btn_icon\":\"<span class=\\\"material-icons\\\">vpn_key<\\/span>\",\"btn_url\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6103265-offer-urls\",\"tag_id\":\"\",\"tag_rid\":\"\",\"offer_txt\":\"\",\"offer_heading\":\"\",\"is_event\":1}",
      //     "offer_desc": "{\"offer_title\":\"image offer with timer\",\"offer_heading\":\"\",\"offer_subheading\":\"\",\"offer_image\":\"https:\\/\\/uikit-test.easywebinar.com\\/assets\\/images\\/offer-image.jpg\",\"offer_btn\":{\"txt\":\"Get Access Now\",\"color\":\"#ffffff\",\"bg\":\"#28493b\",\"size\":\"large-btn-offer\",\"icon\":\"vpn_key\"},\"offer_link\":\"https:\\/\\/helpdesk.everflow.io\\/en\\/articles\\/6103265-offer-urls\",\"offer_type\":\"1\",\"offer_heading_toggle\":\"0\",\"description_toggle\":\"0\",\"publish_toggle\":\"1\",\"countdown_enable\":\"1\",\"countdown_time\":\"00:02:02\",\"offer_start_time\":\"00:00:00\",\"offer_end_time\":\"00:00:00\",\"replay_offer\":\"0\"}"
      //   },
      // ]

      // let resAPI = {
      //   data: {
      //     offers: offersresp
      //   }
      // }

      setOffersData([]);
     
 
      if (resAPI.data && Array.isArray(resAPI.data.offers) && resAPI.data.offers.length > 0) {
        setOffersData((offersData) => [
          // ...offersData,
           ...resAPI.data.offers])
        for (let i = 0; i < resAPI.data.offers.length; i++) {
          // setOffersData([]);
          let offer = resAPI.data.offers[i]
          let offerSpecificChannelName =
            "Offers." +
            stateData.publishStreamObj.sessionId +
            "." +
            offer.id;

          // let resultSpecificChannelName = "results." + stateData.publishStreamObj.sessionId + "." + offer.id;
          // pubnubobj.subscribe({ channels: [resultSpecificChannelName] });

          let defaultOfferStatus = offerStatus.saved;
          pubnubobj.objects
            .getChannelMetadata({
              channel: offerSpecificChannelName,
            })
            .then((res) => {
              if (
                res.data.status !== undefined ||
                res.data.status !== null ||
                res.data.status !== ""
              ) {
                defaultOfferStatus = res.data.status;

                setOffersData(() =>
                  offersData.map((item) =>
                    item.id === offer.id
                      ? { ...item, status: defaultOfferStatus }
                      : item
                  )
                );
              }

              pubnubobj.objects
                .setChannelMetadata({
                  channel: offerSpecificChannelName,
                  data: {
                    name: offer.title,
                    type: "Offer",
                    description: offer.title,
                    status: defaultOfferStatus,
                    custom: {
                      offer_id: offer.id,
                      session_id: stateData.publishStreamObj.sessionId,
                      offer_desc: resAPI.data.offers[i].offer_desc,
                      countdown_time: resAPI.data.offers[i].countdown_time,
                      content: resAPI.data.offers[i].content,
                      btn_settings: resAPI.data.offers[i].btn_settings,
                      publishTime: res?.data?.custom?.publishTime,
                    },
                  },
                })
                .then((res) => {
                })
                .catch((err) => {
                  console.log("Error ::: ", err);
                });
            })
            .catch((err) => {
              /**
               * if channel does not exists
               */
              if (
                err.status.error &&
                err.status.errorData &&
                err.status.errorData.status === 404
              ) {
                pubnubobj.objects
                  .setChannelMetadata({
                    channel: offerSpecificChannelName,
                    data: {
                      name: offer.title,
                      type: "Offer",
                      description: offer.title,
                      status: defaultOfferStatus,
                      custom: {
                        offer_id: offer.id,
                        session_id: stateData.publishStreamObj.sessionId,
                        offer_desc: resAPI.data.offers[i].offer_desc,
                        countdown_time: resAPI.data.offers[i].countdown_time,
                        content: resAPI.data.offers[i].content,
                        btn_settings: resAPI.data.offers[i].btn_settings,
                        publishTime: null,
                      },
                    },
                  })
                  .then((res) => {
                    console.log("catched error and set data again", res.data);
                  })
                  .catch((err) => {
                    console.log("still not able to set data, Error ::: ", err);
                  });
              } else {
                console.log("Error ::: >>> ", err);
              }
            });

          offerIds.push(offer.id);            
          if (i === resAPI.data.offers.length - 1) {
            // Call your function here
            setAssignedOffers(offerIds);
          }

        }


        /**
         * before setting channel metadata, get status first if its active or not
         */
        // for (let i = 0; i < offersArrData.length; i++) {
          

        // }

      } else {
        setAssignedOffers([]);
      }

    })();
  }

  }, [index]);
  //Backend api call end

  // function createPollMain() {
  //   createPollHandler();
  // }

  // function updatePollHandlerMain(poll) {
  //   updatePollHandler(poll);
  // }

  return (
    <div>
      {/* Conditionally render Polls component only if data is ready */}
      {isDataReady ? (
        <Offers offersList={offersData} index={index}  crossIconHandler={crossIconHandler} />
      ) : (
        <div className="loader">
          {/* Your loader component or HTML */}
          <div className="flex flex-center loader-bg">
            {/* <div className='blur-bg'></div> */}
            <div className='flex flex-center section-loader'>
              <img src={blueCircleLoader} alt="" />
              <span className="dark-text">Loading...</span>
            </div>
          </div>
        </div>
      )}
    </div>

  );
};
// export default Polls;
