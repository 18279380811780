
import { useState } from 'react';
import emptyPollsIcon from '../../assets/images/empty-polls.svg';
import pollsIcon from '../../assets/images/polls-icon-blue.png';
import deleteIcon from '../../assets/images/delete-icon.svg';
import editIcon from '../../assets/images/edit-icon.svg';
import backIcon from '../../assets/images/back-icon.svg';
import closeIcon from '../../assets/images/close-icon.svg';
import refreshDarkIcon from '../../assets/images/refresh-dark-icon.png';

export const Polls = () => {

// Settings popup tabs
const [activeTab, setActiveTab] = useState(1);
const [isCreatePollsOpen, setCreatePollsOpen] = useState(false);

const handleTabClick = (tabIndex: React.SetStateAction<number>) => {
    setActiveTab(tabIndex);
};

const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
};
const toggleCreatePollsPopup = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setCreatePollsOpen(!isCreatePollsOpen);
};



            return (
            <div className="Polls-area" style={{display: 'none'}}>
                <div className="sidebar-header">
                    <a href="#">
                        <img src={closeIcon} alt="" />
                    </a>
                    <span className='refresh-section'>
                        <img src={refreshDarkIcon} alt="" />
                    </span>
                    Polls
                </div>
                <div className="sidebar-tabs-header">
                    <div className="tabs flex sidebar-tabs">
                        <button className={`flex flex-center ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
                            Active
                        </button>
                        <button className={`flex flex-center ${activeTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>
                            Saved
                        </button>
                        <button className={`flex flex-center ${activeTab === 3 ? 'active' : ''}`} onClick={() => handleTabClick(3)}>
                            Closed
                        </button>
                    </div>
                </div>

                <div className="polls-content-area">
                    <div className="tab-content">
                        {activeTab === 1 && 
                            <div className='polls-inner-container thin-scrollbar'>
                                {/* empty Polls */}
                                <div className="empty-area-msg flex-center">
                                    <img src={emptyPollsIcon} alt="" />
                                    <h4>Polls</h4>
                                    <p>You’ve no active polls right now. Please publish from the saved/closed polls. 
                                        {/* <a href="#">create new</a> */}
                                    </p>
                                    {/* <button className='btn primary-button'  onClick={toggleCreatePollsPopup}>Create Poll</button> */}
                                </div>

                                <h4 className="tabs-inner-heading">Active Polls</h4>
                                <div className="polls-info-main">
                                    <h3>How would you rob a bank <span>2m ago</span></h3>
                                    <ul>
                                        <li className='flex'>
                                            <p>Go straight in with a gun
                                                <div className='polls-percntage'>
                                                    <span style={{width: '30%'}}></span>
                                                </div>
                                            </p>
                                            <span>12% Voted</span>
                                        </li>
                                        <li className='flex'>
                                            <p>Seduce the lady clerk to do your bidding
                                                <div className='polls-percntage'>
                                                    <span style={{width: '70%'}}></span>
                                                </div>
                                            </p>
                                            <span>50% Voted</span>
                                        </li>
                                        <li className='flex'>
                                            <p>Hack the bank managers phone and blackmail him
                                                <div className='polls-percntage'>
                                                    <span style={{width: '7%'}}></span>
                                                </div>
                                            </p>
                                            <span>7% Voted</span>
                                        </li>
                                        <li className='flex'>
                                            <p>Get back to your 9-5 job
                                                <div className='polls-percntage'>
                                                    <span style={{width: '10%'}}></span>
                                                </div>
                                            </p>
                                            <span>10% Voted</span>
                                        </li>
                                    </ul>
                                    <div className="flex votes-info">
                                        <span className='flex flex-center total-views'>202 views</span>
                                        162/202 voted
                                    </div>

                                    <button type='button' className='btn warning-button'>End Poll</button>
                                </div>
                            </div>
                        }

                        {activeTab === 2 && 
                            <div className='polls-inner-container thin-scrollbar'>
                                <h4 className="tabs-inner-heading">Saved Polls</h4>
                                <div className="saved-polls-outer">
                                    <div className="saved-polls-listing">
                                        <div className="flex saved-poll">
                                            <div className='polls-icon'>
                                                <img src={pollsIcon} alt="polls icon" />
                                            </div>
                                            <div className="polls-question-box">
                                                <p className='flex'>How would you rob a bank?
                                                    <span className='flex'>
                                                        <a href="#"><img src={deleteIcon} alt="delete icon" /></a>
                                                        <a href="#"><img src={editIcon} alt="edit icon" /></a>
                                                    </span>
                                                </p>
                                                <div className="flex polls-action">
                                                    <button type='button' className='flex flex-center button primary-button btn-sm'>Publish Now</button>
                                                    <button type='button' className='flex flex-center button outlined-button btn-sm'>Preview</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Polls Preview */}
                                <h4 className="tabs-inner-heading"><img src={backIcon} alt="" /> Preview Polls</h4>
                                <div className="polls-info-main polls-preview">
                                    <h3>How would you rob a bank</h3>
                                    <ul>
                                        <li className='flex'>
                                            <label className='radio-input'>
                                                <input
                                                type="radio"
                                                value="option1"
                                                checked={selectedOption === 'option1'}
                                                onChange={handleOptionChange}
                                                />
                                            </label>
                                            <p>Go straight in with a gun
                                                <div className='polls-percntage'>
                                                </div>
                                            </p>
                                        </li>
                                        <li className='flex'>
                                            <label className='radio-input'>
                                                <input
                                                type="radio"
                                                value="option2"
                                                checked={selectedOption === 'option2'}
                                                onChange={handleOptionChange}
                                                />
                                            </label>
                                            <p>Seduce the lady clerk to do your bidding
                                                <div className='polls-percntage'>
                                                </div>
                                            </p>
                                        </li>
                                        <li className='flex'>
                                            <label className='radio-input'>
                                                <input
                                                type="radio"
                                                value="option3"
                                                checked={selectedOption === 'option3'}
                                                onChange={handleOptionChange}
                                                />
                                            </label>
                                            <p>Hack the bank managers phone and blackmail him
                                                <div className='polls-percntage'>
                                                </div>
                                            </p>
                                        </li>
                                        <li className='flex'>
                                            <label className='radio-input'>
                                                <input
                                                type="radio"
                                                value="option4"
                                                checked={selectedOption === 'option4'}
                                                onChange={handleOptionChange}
                                                />
                                            </label>
                                            <p>Get back to your 9-5 job
                                                <div className='polls-percntage'>
                                                </div>
                                            </p>
                                        </li>
                                    </ul>
                                    <button type='button' className='btn disabled-button'>Submit</button>
                                </div>
                            </div>
                        }

                        {activeTab === 3 && 
                            <div className='polls-inner-container thin-scrollbar'>

                                <h4 className="tabs-inner-heading">Closed Polls</h4>
                                <div className="polls-info-main">
                                    <h3>How would you rob a bank <span>2m ago</span></h3>
                                    <ul>
                                        <li className='flex low-vote'>
                                            <p>Go straight in with a gun
                                                <div className='polls-percntage'>
                                                    <span style={{width: '30%'}}></span>
                                                </div>
                                            </p>
                                            <span>12% Voted</span>
                                        </li>
                                        <li className='flex high-vote'>
                                            <p>Seduce the lady clerk to do your bidding
                                                <div className='polls-percntage'>
                                                    <span style={{width: '70%'}}></span>
                                                </div>
                                            </p>
                                            <span>50% Voted</span>
                                        </li>
                                        <li className='flex low-vote'>
                                            <p>Hack the bank managers phone and blackmail him
                                                <div className='polls-percntage'>
                                                    <span style={{width: '7%'}}></span>
                                                </div>
                                            </p>
                                            <span>7% Voted</span>
                                        </li>
                                        <li className='flex low-vote'>
                                            <p>Get back to your 9-5 job
                                                <div className='polls-percntage'>
                                                    <span style={{width: '10%'}}></span>
                                                </div>
                                            </p>
                                            <span>10% Voted</span>
                                        </li>
                                    </ul>
                                    <div className="flex votes-info">
                                        <span className='flex flex-center total-views'>202 views</span>
                                        162/202 voted
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                
            </div>
            );
        }
// export default Polls;