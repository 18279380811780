import { Director, Publish } from "@millicast/sdk";
import { useRef } from "react";
import store from "../../Store/Store";
import { updateIsCanvasStream, updateIsScreen, updateIsScreenOther, updateISScreenSharedByYou } from "../../Store/Actions/PublishStreamAction";
import WebRTCStats, { OnStats } from "@dolbyio/webrtc-stats";

// This class will handle all stream publish related opeartion like -
// publish strea, stop stream, update stream etc
export default class PublishStream {
  mediaStreamm: any;
  broadCastOptions: any;
  userName: any;
  streamName: any;
  token: any;
  publisher: Publish;
  publisherScreenShare: any;
  mediaStreamScreenShare?: MediaStream;
  staticStream?: MediaStream;
  constructor() {}

  // initilize millicast publish object
  async initialize(
    name: string,
    streamName: string,
    token: string,
    cameraId: string,
    deviceId: string,
    speakerdeviceId: string,
    backgroundNone: boolean,
    mediaStreamRef: any,
    recording: boolean,
    isAdmin: boolean
  ) {
    this.userName = name;
    this.streamName = streamName;
    this.token = token;
    this.generateStaticImage()

    if (backgroundNone) {
      const audioConstraints = {
        deviceId: {
          exact: deviceId,
          sinkId: speakerdeviceId,
        },
        echoCancellation: true,
        noiseSuppression: true,
      };
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: audioConstraints,
        video: {
          deviceId: cameraId,
          aspectRatio: 16 / 9,
          frameRate: {
            ideal: 30
          }
        },
      });
      this.mediaStreamm = mediaStream;
    } else {
      this.mediaStreamm = mediaStreamRef;
    }

    if (isAdmin) {
      this.broadCastOptions = {
        mediaStream: this.mediaStreamm,
        //record: recording,
        simulcast: true,
        codec: "vp8",
        // bandwidth: 0,
        sourceId: this.userName,
      };
    } else {
      this.broadCastOptions = {
        mediaStream: this.mediaStreamm,
        sourceId: this.userName,
        //record: recording,
        simulcast: true,
        codec: "vp8",
        // bandwidth: 0,
      };
    }

    const tokenGenerator = () =>
      Director.getPublisher({
        token: this.token,
        streamName: this.streamName,
      });
    this.publisher = new Publish(this.streamName, tokenGenerator, true);
  }

  async lowerDownStreamQuality() {
    console.log('update stream quality lower -- publishStream file');
    const tracks = this.publisher.webRTCPeer?.getTracks();
      const videoTrack = tracks?.find(track => track.kind === 'video');
      if (videoTrack) {
          // Apply constraints or perform actions on the video track
          await videoTrack.applyConstraints({
              aspectRatio: 16 / 9,
              frameRate: {
                ideal: 30
              },
              width:{
                exact: 320
              },
              height: {
                exact:180
              },
          });
      }
    return true;
  }

  async resetStreamQuality() {
    console.log('update stream quality auto -- publishStream file');
    const tracks = this.publisher.webRTCPeer?.getTracks();
      const videoTrack = tracks?.find(track => track.kind === 'video');
      if (videoTrack) {
        await videoTrack.applyConstraints({
            aspectRatio: 16 / 9,
            frameRate: {
              ideal: 30
            },
            // width:{
            //   ideal: 1920
            // },
            // height: {
            //   ideal: 1080
            // },
        });
      }
    return true;
  }
  // Start dolby millicast stream
  async streamStart(
    bitrate: number,
    video: boolean,
    audio: boolean,
    isAdmin: boolean
  ) {
    try {
      await this.publisher.connect(this.broadCastOptions);

      // const collection = new WebRTCStats({
      //   getStatsInterval: 5000,
      //   getStats: () => this.publisher.webRTCPeer?.getRTCPeer().getStats(),
      // });
      // collection.on("stats", (event: OnStats) => {
      //   // handleStats(streamId, event);
      // });
      // collection.on("error", (reason: string) => {
      //   console.error("WebRTCStats-pub-error", reason);
      // });
      // collection.start();

      // if (video) {
      //   await this.publisher.webRTCPeer?.updateBitrate(3000);
      // }
      if (video == false) {
        await this.streamCameraUpdate(false);
      }
      if (audio == false) {
        await this.streamMicUpdate(false);
      }
    } catch (err) {
      console.log("Error in stream start", err);
      throw err;
    }
  }


  getInitials(name) {
    const words = name.split(" ");
    const firstInitial = words[0]?.charAt(0).toUpperCase();
    const lastInitial =
      words.length > 1 ? words[words.length - 1].charAt(0).toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };

  bgColors = ['blueviolet','brown','cadetblue','crimson','darkcyan','darkolivegreen','darkslateblue','darkslategrey','firebrick','limegreen','maroon','teal','plum','deeppink','darksalmon','darkgreen','darkred','goldenrod']
  getRandomColor() {
    if (this.bgColors && this.bgColors.length) {
      return this.bgColors[Math.floor(Math.random() * this.bgColors.length)];
    }
  }

  //Device handle change end
  generateStaticImage = async () => {
    const canvas = document.createElement('canvas');
    canvas.width = 1280
    canvas.height = 720
    // canvas.width = 640
    // canvas.height = 480
    const ctx = canvas.getContext("2d");
    ctx.globalAlpha = 0.99;
    // const fillColor = this.getRandomColor()
    const fillColor = '#1B1A1D'

    ctx.fillStyle = fillColor; 
    ctx.fillRect(0, 0, 1280, 720); //720p
    // ctx.fillRect(0, 0, 640, 480); //640x480

    // ctx.beginPath();
    // ctx.arc(640, 330, 130, 0, 2 * Math.PI); //720p
    // // ctx.arc(320, 240, 70, 0, 2 * Math.PI); //640x480
    // ctx.fillStyle = "white";
    // ctx.fill();
    // ctx.lineWidth = 4;
    // ctx.strokeStyle = "white";
    // ctx.stroke();

    ctx.font = "bold 150px arial"; //720p
    // ctx.font = "bold 80px arial"; //640x480
    ctx.fillStyle = "#000";
    ctx.fillStyle = "white";
    ctx.fillText(this.getInitials(store.getState()?.PublishStreamReducer?.publishStreamObj?.userName), 540, 390); //720p
    // ctx.fillText(this.getInitials(store.getState()?.PublishStreamReducer?.publishStreamObj?.userName), 268, 275); //640x480

    this.staticStream = canvas.captureStream()
    ctx.fillStyle = fillColor; 
    setInterval(() => {
      ctx.fillText('.', 10, 10);
    }, 500);
  }

  // Stream camera on/off
  streamCameraUpdate(status: boolean) {
    // const [videoTrack2] = this.mediaStreamm?.getVideoTracks() ?? [];
    // videoTrack2.enabled = status;
    // await this.publisher.webRTCPeer?.replaceTrack(videoTrack2);
    let videoTrack: MediaStreamTrack
    if(status){
      videoTrack = this.mediaStreamm?.getVideoTracks() ? this.mediaStreamm?.getVideoTracks()[0] : null;
    }else{
      videoTrack = this.staticStream?.getVideoTracks() ? this.staticStream?.getVideoTracks()[0] : null;
    }
    if(videoTrack) this.publisher.webRTCPeer?.replaceTrack(videoTrack);
  }

  // Stream mic on/off
  async streamMicUpdate(startus: boolean) {
    const [audeoTrack2] = this.mediaStreamm?.getAudioTracks() ?? [];
    audeoTrack2.enabled = startus;
    await this.publisher.webRTCPeer?.replaceTrack(audeoTrack2);
  }

  // Stream bitrate update
  async streamBitrateUpdate(bitrate: number) {
    try {
      //await this.publisher.webRTCPeer?.updateBitrate(bitrate);
    } catch (error) {
      console.log("error in  bitrate ");
    }
  }

  async screenShareBitrateUpdate(bitrate: number) {
    try {
      if(this.publisherScreenShare) {
        console.log("updating bitrate to ", bitrate);
        await this.publisherScreenShare.webRTCPeer?.updateBitrate(bitrate);
      }
    } catch (error) {
      console.log("error in  bitrate change", error);
    }
  }

  //Screen share start
  async startScreenShare(userName: string, bitrate: number, stream? : MediaStream, file_details?: string): Promise<boolean> {
    try {
      // this.mediaStreamScreenShare =
      //   await navigator.mediaDevices.getDisplayMedia({
      //     video: true,
      //     audio: true,
      //   });

      // const broadCastOptions = {
      //   mediaStream: this.mediaStreamScreenShare,
      //   sourceId: userName + ":screenShare",
      //   simulcast: true,
      //   codec: "h264",
      //   // bandwidth: 6000,
      // };

      const constraints = {
        audio: true,
        video: { 
          cursor: "always",
          frameRate: {
            ideal: 30
          }
        },
      } as MediaStreamConstraints;

      if(stream){
        this.mediaStreamScreenShare = stream
      }else{
        this.mediaStreamScreenShare =
          await navigator.mediaDevices.getDisplayMedia(constraints);
      }

      console.log('publishing screen share bit rate at ', bitrate)
      const broadCastOptions = {
        mediaStream: this.mediaStreamScreenShare,
        sourceId: userName + (stream ? file_details : ":screenShare"),
        simulcast: false,
        codec: "vp8",
        bandwidth: bitrate,
      };

      const tokenGenerator = () =>
        Director.getPublisher({
          token: this.token,
          streamName: this.streamName,
        });

      // let previousScreenPublish
      // if(this.publisherScreenShare) previousScreenPublish = this.publisherScreenShare

      this.publisherScreenShare = new Publish(
        this.streamName,
        tokenGenerator,
        true
      );
      await this.publisherScreenShare.connect(broadCastOptions);
      // setTimeout(() => {
      //   if(file_details && previousScreenPublish) previousScreenPublish.stop();
      // }, 1000);
      // await this.publisher.webRTCPeer?.updateBitrate(8000);

      this.mediaStreamScreenShare.getTracks().forEach((track) => {
        track.onended = async () => {
          await this.stopSreenShare(); // Handle the stop event
          if (this.mediaStreamScreenShare) {
            this.mediaStreamScreenShare
              .getTracks()
              .forEach((track) => track.stop());
            // mediaStreamm = null; // Clear the reference
          }
        };
      });


      // await this.publisher.webRTCPeer?.updateBitrate(500);
      const tracks = this.publisher.webRTCPeer?.getTracks();
      const videoTrack = tracks?.find(track => track.kind === 'video');
      if (videoTrack) {
          // Apply constraints or perform actions on the video track
          await videoTrack.applyConstraints({
              aspectRatio: 16 / 9,
              frameRate: {
                ideal: 30
              },
              width:{
                exact: 320
              },
              height: {
                exact:180
              },
          });
      }
      
      return true
    } catch (error) {
      if (error.name === "NotAllowedError") {
        console.error("Permission denied for screen sharing:", error);
        // Optionally show a user-friendly message or prompt
      } else {
        console.error("Error starting screen share:", error);
      }
      await this.stopSreenShare();
      return false
    }
  }

  onScreenShareStopped() {
    
    // Dispatch an action to update the global state isScreenByOtherMember
    store.dispatch(updateIsCanvasStream(false))
    store.dispatch(updateIsScreen(false));
    store.dispatch(updateISScreenSharedByYou(false))
    store.dispatch(updateIsScreenOther(false))
    
  }

  //stop screen share
  async stopSreenShare() {

    if (this.publisherScreenShare) {
      await this.publisherScreenShare.stop();
      this.publisherScreenShare = undefined;
    }
    if (this.mediaStreamScreenShare) {
      this.mediaStreamScreenShare
        .getTracks()
        .forEach((track) => track.stop());
      // mediaStreamm = null; // Clear the reference
      this.mediaStreamScreenShare = null
    }
    this.onScreenShareStopped();

    // await this.publisher.webRTCPeer?.updateBitrate(0);
    const tracks = this.publisher.webRTCPeer?.getTracks();
    const videoTrack = tracks?.find(track => track.kind === 'video');
    if (videoTrack) {
        // Apply constraints or perform actions on the video track
        await videoTrack.applyConstraints({
            aspectRatio: 16 / 9,
            frameRate: {
              ideal: 30
            },
            // width:{
            //   ideal: 1920
            // },
            // height: {
            //   ideal: 1080
            // },
        });
    }
    
  }

  //Leave green room
  async leaveGreenRoom() {
    await this.publisher.stop();
    const tracks = await this.mediaStreamm.getTracks();
    tracks.forEach((track) => {
      track.enabled = false;
      track.stop();
    });
  }

  //Get media stream
  getMediaStream() {
    return this.mediaStreamm;
  }

  //Update camera device id
  async updateStreamCameraId(
    cameraId: string, 
    micId: string,
    speakerId: string,
    video: boolean
  ) {
    if (video == false) {
      this.streamCameraUpdate(false)
    }
    const audioConstraints = {
      deviceId: {
        exact: micId,
        sinkId: speakerId,
      },
      echoCancellation: true,
      noiseSuppression: true,
    };
    const mediaStream = await navigator.mediaDevices.getUserMedia({
    audio: audioConstraints,
      video: {
        deviceId: cameraId,
        aspectRatio: 16 / 9,
        frameRate: {
          ideal: 30
        }
      },
    });

    this.mediaStreamm = mediaStream;
    if (video) {
      const [videoTrack] = this.mediaStreamm.getVideoTracks();
      this.publisher.webRTCPeer?.replaceTrack(videoTrack);
    }
  }

  //Update mic device id
  async updateStreamAudioId(
    cameraId: string,
    micId: string,
    speakerId: string,
    muted: boolean
  ) {
    const audioConstraints = {
      deviceId: {
        exact: micId,
        sinkId: speakerId,
      },
      echoCancellation: true,
      noiseSuppression: true,
    };
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: audioConstraints,
      video: {
        deviceId: cameraId,
        aspectRatio: 16 / 9,
        frameRate: {
          ideal: 30
        }
      },
    });

    this.mediaStreamm = mediaStream;
    const [audioTrack] = this.mediaStreamm.getAudioTracks();
    if (muted == true) {
      audioTrack.enabled = false;
    }
    await this.publisher.webRTCPeer?.replaceTrack(audioTrack);
  }

  //Update mic device id
  async noiseReductionUpdate(
    NoiseReduction: boolean,
    micId: string,
    speakerId: string,
    cameraId: string,
    muted: boolean
  ) {
    return;
    // if (this.mediaStreamm) {
    //   this.mediaStreamm.getTracks().forEach((track) => {
    //     track.stop(); // Stop each track (audio and video)
    //   });
    //   this.mediaStreamm = null; // Optionally, set the reference to null
    // }
    // const audioConstraints = {
    //   deviceId: {
    //     exact: micId,
    //     sinkId: speakerId,
    //   },
    //   echoCancellation: true,
    //   noiseSuppression: NoiseReduction,
    // };
    // const mediaStream = await navigator.mediaDevices.getUserMedia({
    //   audio: audioConstraints,
    //   video: { deviceId: cameraId },
    // });

    // this.mediaStreamm = mediaStream;
    // const [audioTrack] = this.mediaStreamm.getAudioTracks();
    // if (muted == true) {
    //   audioTrack.enabled = false;
    // }
    // await this.publisher.webRTCPeer?.replaceTrack(audioTrack);
  }

  // Start recording
  startRecording = async (): Promise<void> => {
    await this.publisher.record();
    return;
  };

  //stop recording
  stopRecording = async (): Promise<void> => {
    await this.publisher.unrecord();
    return;
  };
  

  async startLocalVideoShare( stream: any) {
  try {
    const broadCastOptions = {
      mediaStream: stream,
      sourceId: this.userName+":video",
      simulcast: true,
      codec: "vp8",
      // bandwidth: 0,
    };

    const tokenGenerator = () =>
      Director.getPublisher({
        token: this.token,
        streamName: this.streamName,
      });
    this.publisherScreenShare = new Publish(
      this.streamName,
      tokenGenerator,
      true
    );
    await this.publisherScreenShare.connect(broadCastOptions);
    
  } catch (error) {
    console.log("error",error)
    
  }
  
}



}
