import React, { useEffect, useState } from 'react';
import '../../assets/styles/main.scss';
import errorImg from "../../assets/images/403-forbidden.svg";
import errorBottomIllustrate from "../../assets/images/footer-illustrate.png";

export const ForbiddenError = ({message}) => {
    const [show,setShow]=useState(true)
    
    if(!show){
        return
    }

    return (
        <div className="forbidden-error">
            <div className="container flex flex-center">
                <div className="flex error-con">
                    <div className="forbidden-logo">
                        <img src={errorImg} alt="" />
                    </div>

                    <div className="flex flex-center forbidden-info">
                        <h2 className='flex flex-center'>Error 403! <br></br>
                        Forbidden Access</h2>
                        <p>We’re sorry. You’re not allowed to do that</p>
                    </div>

                    <div className="bottom-illustrate">
                        <img src={errorBottomIllustrate} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};
