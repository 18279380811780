import React, { useState } from 'react'
import warningIcon from "../../assets/images/warning-icon.svg";
import closeIcon from "../../assets/images/close-icon.svg";
const ErrorMessage = ({message}) => {

    const [show,setShow]=useState(true)

    if(!show){
        return
    }
    return (
        <div className="flex flex-center toaster-mg newtwork-unstable-msg">
          <div className="img-wrapper flex flex-center">
            <img src={warningIcon} alt="" />
          </div>
          <div className="message-text flex flex-center">
            <p>
              <span>Error</span> {message}
            </p>
            <a href="#" className="close" onClick={()=>setShow(false)}>
              <img src={closeIcon} alt="" />
            </a>
          </div>
        </div>
      );
    };


export default ErrorMessage