import speakerIcon from "../../assets/images/speaker-icon.png";
import microphoneIcon from "../../assets/images/microphone-icon.png";
import Select from "react-select";
import { useEffect, useState } from "react";
import React from "react";
import AudioOutputSelector from "../SelectDeviceComponents/AudioOutputSelector";
import AudioInputSelector from "../SelectDeviceComponents/AudioInputSelector";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import { useDispatch, useSelector } from "react-redux";
import { updateNoiseReduction } from "../../Store/Actions/PublishStreamAction";

export const AudioSettings = () => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();
  let obj: PublishStream = stateData?.publishStreamObj?.obj;

  // Toggle button
  const [isToggled, setIsToggled] = useState(
    stateData?.publishStreamObj?.isNoiseReductionEnabled
  );

  const handleToggle = async () => {
    setIsToggled(!isToggled);
    dispatch(updateNoiseReduction(!isToggled));

    await obj.noiseReductionUpdate(
      !isToggled,
      stateData?.publishStreamObj?.deviceId,
      stateData?.publishStreamObj?.speakerdeviceId,
      stateData?.publishStreamObj?.cameraId,
      stateData?.publishStreamObj?.muted
    );
  };

  const [volume, setVolume] = React.useState(1);

  //volume up and down
  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value) / 100;
    setVolume(newVolume);
    dispatch({
      type: "VIDEO_TOGGLE",
      payload: {
        ...stateData?.publishStreamObj,
        speakerVolume: newVolume,
      },
    });
  };

  //Mic/Speaker device id change
  // useEffect(() => {
  //   (async () => {
  //     await obj.updateStreamAudioId(
  //       stateData?.publishStreamObj?.cameraId,
  //       stateData?.publishStreamObj?.deviceId,
  //       stateData?.publishStreamObj?.speakerdeviceId,
  //       stateData?.publishStreamObj?.muted
  //     );
  //   })();
  // }, [
  //   stateData?.publishStreamObj?.deviceId,
  //   stateData?.publishStreamObj?.speakerdeviceId,
  // ]);
  useEffect(() => {
    if (obj) {
      (async () => {
        try {
          await obj.updateStreamAudioId(
            stateData?.publishStreamObj?.cameraId,
            stateData?.publishStreamObj?.deviceId,
            stateData?.publishStreamObj?.speakerdeviceId,
            stateData?.publishStreamObj?.muted
          );
        } catch (error) {
          console.error('Error updating stream audio ID:', error);
        }
      })();
    } else {
      console.warn('obj is not defined');
    }
  }, [
    stateData?.publishStreamObj?.deviceId,
    stateData?.publishStreamObj?.speakerdeviceId,
  ]);
  
  return (
    <div className="ew-setting-area-section ew-audio-settings">
      <AudioInputSelector isSetting={true}/>

      <div className="ew-form-field ">
        <label htmlFor="dropdown">Speakers</label>
        <span className="label-sub-heading">
          Select your preferred device for speakers
        </span>
        <div className="mic-volume">
          <span className="icon">
            <img src={speakerIcon} alt="" />
          </span>
          <input
            type="range"
            min="0"
            max="100"
            value={volume * 100}
            onChange={handleVolumeChange}
            className="custom-range"
            style={{ width: "100%", cursor: "ew-resize" }}
          />
        </div>

        <AudioOutputSelector />
      </div>
      <div className="ew-form-field toggle-field"     style={{ display: "none" }}>
        <label htmlFor="dropdown">Noise Reduction</label>
        <span className="label-sub-heading">
          Kill the background Noise
          <div className="toggle-container">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isToggled}
                onChange={handleToggle}
              />
              <span className="toggle-slider"></span>
            </label>
          </div>
        </span>
      </div>
    </div>
  );
};
