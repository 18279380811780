import React from 'react'
import loader from "../../assets/images/Liveroom-Loader.gif"
import barLoader from "../../assets/images/bar-loader.gif";

const Loader = () => {
  return (
    <div className='ew-loader-con fullscreen'>
          <div className='dummmy-loader-bg'></div>
          <div className="ew-blur"></div>
          <div className='loader-inner'>
              <div className='square-container'>
                  {/* <img src={loader} alt="" /> */}
                  {/* <div className='moving-element'></div> */}
                  {/* <div className="loading_box">
                    <div className="ewp_bar bar1"></div>
                    <div className="ewp_bar bar2"></div>
                    <div className="ewp_bar bar3"></div>
                  </div> */}
                  <img src={barLoader} alt="" />
              </div>
              <span>Configuring the webinar room</span>
          </div>
      </div>
  )
}

export default Loader