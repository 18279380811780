import { Center, Spinner } from "@chakra-ui/react";
import React, { memo } from "react";
import { useEffect, useRef, useState } from "react";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";
import fitScreenIcon from "../../assets/images/fit-screen-icon.png";
import fillScreenIcon from "../../assets/images/fill-screen-icon.png";
import refreshIcon from "../../assets/images/refresh-icon.svg";
import refreshDarkIcon from "../../assets/images/refresh-dark-icon.png";
import verticallyDotsIcon from "../../assets/images/vertically-dots.svg";
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";
import { useSelector } from "react-redux";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import muteIcon from '../../assets/images/microphone-slash.svg';
import { s } from "vite/dist/node/types.d-jgA8ss1A";
interface props {
  name: string;
  streams: any;
  sourceId: string;
  screenSharesourceId: string;
  mirror: boolean;
  videoTrack: boolean;
  remoteTrackSources: any;
}

export const Participent = ({
  name,
  streams,
  sourceId,
  mirror,
  videoTrack,
  remoteTrackSources,
}: // camera,
props) => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [activeSpeakerNames, setActiveSpeakerNames] = useState<string[]>([]);
const [mutedSources, setMutedSources] = useState<Set<string>>(new Set());
const [isMutedUser, setIsMutedUser] = useState(false);


  // Initialise video

  //video speaker update
  function attachSinkId(element: HTMLAudioElement, sinkId: string) {
    if ("sinkId" in element) {
      (element as any)
        .setSinkId(sinkId)
        .then(() => {
        })
        .catch((error: Error) => {
          let errorMessage: string = error.message;
          if (error.name === "SecurityError") {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error.message}`;
          }
        });
    } else {
      console.warn("Browser does not support output device selection.");
      // Handle unsupported browser
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      attachSinkId(
        videoRef.current,
        stateData?.publishStreamObj?.speakerdeviceId
      );
    }
  }, [stateData?.publishStreamObj?.speakerdeviceId]);

  // Initialise video
  const messageRef = useRef(true);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
    
    }
  }, [remoteTrackSources]);

  //speaker volume change  start
  useEffect(() => {
    const newVolume = stateData?.publishStreamObj?.speakerVolume;
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  }, [stateData?.publishStreamObj?.speakerVolume]);
  //end speaker volume change

  // const getInitials = (name) => {
  //   const words = name.split(" ");
  //   const firstInitial = words[0]?.charAt(0).toUpperCase();
  //   const lastInitial =
  //     words.length > 1 ? words[words.length - 1].charAt(0).toUpperCase() : "";
  //   return `${firstInitial}${lastInitial}`;
  // };

  const getInitials = (name) => {
    if (!name || typeof name !== "string" || name.trim() === "") {
      return "ew"; // Return "UN" for invalid or empty input
    }
  
    const words = name.trim().split(/\s+/); // Trim and split the name by spaces
    const getValidInitial = (word) =>
      word.match(/[A-Za-z]/) ? word.match(/[A-Za-z]/)[0].toUpperCase() : ""; // Extract the first alphabetic character
  
    const firstInitial = words[0] ? getValidInitial(words[0]) : ""; // Safely get the first valid initial
    const lastInitial =
      words.length > 1 ? getValidInitial(words[words.length - 1]) : ""; // Get the last valid initial if there are multiple words
  
    const initials = `${firstInitial}${lastInitial}`; // Combine the initials
  
    return initials || "ew"; // Return "UN" if no valid initials are found
  };

  const displayName = name === "main" ? "Host" : name;
  const initials = displayName && getInitials(displayName);

  // const [loadingVideo, setLoadingVideo] = useState(true);

  const checkMuted = () => {};

  const [isFillContent, setIsFillContent] = useState(false);

  // const handleClick = () => {
  //   setIsFillContent(!isFillContent);
  // };

  const reLoad = () => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
    }
  };

  const randomValue1 = Math.random();
  const randomValue2 = Math.random();

  // Presenter dropdwon
  const dropdownRef = useRef(null);
  const [presenterMenuOpen, setPresenterMenuOpen] = useState(false);

  const handlePresenterDropdownClick = () => {
    setPresenterMenuOpen(!presenterMenuOpen);
  };

  const handleClickOutsideDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setPresenterMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {

    try {
      if(stateData?.publishStreamObj?.obj && stateData?.publishStreamObj?.obj !== null) {
        // console.log('update stream quality stateData?.publishStreamObj?.screenSharingUUId', stateData?.publishStreamObj);
        if(stateData?.publishStreamObj?.isScreenByOtherMember) {    
          console.log('update stream quality lower', stateData?.publishStreamObj);
          const publishStreamObj = stateData?.publishStreamObj?.obj;
          publishStreamObj.lowerDownStreamQuality();               
        } else {
          // stateData?.publishStreamObj?.screenSharingUUId !== "" || 
          if(stateData?.publishStreamObj?.screenShareSourceID !== "") {
            if(!stateData?.publishStreamObj?.obj?.publisherScreenShare) {
              console.log('update stream quality auto', stateData?.publishStreamObj);
              const publishStreamObj = stateData?.publishStreamObj?.obj;
              publishStreamObj.resetStreamQuality();
            }
          }
        }
      }
    } catch (error) {
      console.log('unable to reset stream quality at the moment');
    }
    
    
  }, [stateData?.publishStreamObj?.isScreenByOtherMember]);


  useEffect(() => {
    const audioContext = new window.AudioContext();
    const analyserNodes = new Map<string, AnalyserNode>();
    const audioData = new Uint8Array(128); // Adjust size based on `fftSize`
    let detectionInterval: NodeJS.Timeout | null = null;

    if (remoteTrackSources instanceof Map) {
      Array.from(remoteTrackSources).forEach(([sourceId, { mediaStream }]) => {
        const audioTracks = mediaStream.getAudioTracks();
        if (audioTracks.length > 0) {
          const source = audioContext.createMediaStreamSource(mediaStream);
          const analyser = audioContext.createAnalyser();
          analyser.fftSize = 256; // Adjust for granularity
          source.connect(analyser);
          analyserNodes.set(sourceId, analyser);
        }
      });
      const detectActiveSpeakers = () => {
        const volumeThreshold = 1000; // Lower value for sensitivity
        const activeSpeakers: string[] = [];
      
        analyserNodes.forEach((analyser, sourceId) => {
          analyser.getByteFrequencyData(audioData);
          const volume = audioData.reduce((sum, value) => sum + value, 0);
          // Add users with volume above the threshold
          if (volume > volumeThreshold) {
            activeSpeakers.push(sourceId);
          }
        });
      
        // Update the list of active speakers
        if (activeSpeakers.length > 0) {
          const speakerNames = activeSpeakers.map((id) => id.split(":")[0]);
          setActiveSpeakerNames(speakerNames); // Adjust state to handle an array of names
        } else {
          setActiveSpeakerNames([]); // Clear active speakers if none are speaking
        }
      };
      

      if (analyserNodes.size > 0) {
        detectionInterval = setInterval(detectActiveSpeakers, 100);
      }
    }

    return () => {
      if (detectionInterval) clearInterval(detectionInterval);
      analyserNodes.forEach((_, sourceId) => analyserNodes.delete(sourceId));
      audioContext.close();
    };
  }, [remoteTrackSources]);


  //   const audioContext = new window.AudioContext();
  //   const analyserNodes = new Map<string, AnalyserNode>();
  //   const audioData = new Uint8Array(128); // Adjust size based on `fftSize`
  //   let detectionInterval: NodeJS.Timeout | null = null;
  //   const newMutedSources = new Set<string>();

  //   if (remoteTrackSources instanceof Map) {
  //     Array.from(remoteTrackSources).forEach(([sourceId, { mediaStream }]) => {
  //       console.log("mediaStream", mediaStream);
  //       const audioTracks = mediaStream.getAudioTracks();
  //       console.log("audioTracks", audioTracks);
  //       if (audioTracks.length > 0) {
  //         const source = audioContext.createMediaStreamSource(mediaStream);
  //         const analyser = audioContext.createAnalyser();
  //         analyser.fftSize = 256; // Adjust for granularity
  //         source.connect(analyser);
  //         analyserNodes.set(sourceId, analyser);
  //       }
  //     });

  //     const detectActiveSpeaker = () => {
  //       let maxVolume = 0;
  //       let activeSpeakerId: string | null = null;

  //       analyserNodes.forEach((analyser, sourceId) => {
  //         analyser.getByteFrequencyData(audioData);
  //         const volume = audioData.reduce((sum, value) => sum + value, 0);

  //         if (volume > maxVolume) {
  //           maxVolume = volume;
  //           activeSpeakerId = sourceId;
  //         }

  //         const isMuted = volume === 0; // You can tweak this condition if needed
  //         if (isMuted) {
  //           newMutedSources.add(sourceId);
  //         } else {
  //           newMutedSources.delete(sourceId);
  //         }

  //         setMutedSources(newMutedSources);
  //       });

  //       const volumeThreshold = 10; // Lower value for sensitivity

  //       if (maxVolume < volumeThreshold) {
  //         // console.log("No active speaker detected");
  //         activeSpeakerId = null;
  //       }

      
  //     };

  //     if (analyserNodes.size > 0) {
  //       detectionInterval = setInterval(detectActiveSpeaker, 0);
  //     }
  //   }

  //   return () => {
  //     if (detectionInterval) clearInterval(detectionInterval);
  //     analyserNodes.forEach((_, sourceId) => analyserNodes.delete(sourceId));
  //     audioContext.close();
  //   };
  // }, [remoteTrackSources]);
  
useEffect(()=>{


  stateData?.publishStreamObj?.peoples?.map((p) => {

    if(p?.state?.userName === sourceId && (p?.state?.isAdmin || p?.state?.isPresentor)){

      if(p?.state?.mic === false){
        // console.log('mic off',p.state.userName);
        setIsMutedUser(true);
      }else{
        // console.log('mic on',p?.state?.userName);
        setIsMutedUser(false);

    }

  }
}
)
    
 

},[stateData?.publishStreamObj?.peoples])
  
  return (
    <div
      className={`presenter-column ${
        isFillContent ? "fill-content" : "fit-content"
      } ${!videoTrack ? " cam-off" : ""} 
      ${activeSpeakerNames.includes(sourceId) ? "active-speaker" : ""}`}
    >
      <div className="ew-video-wrapper">
        {/* {loadingVideo && videoTrack && (
          <div className="flex flex-center loader-bg">
            <div className="blur-bg"></div>
            <div className="flex flex-center section-loader">
              <img src={blueCircleLoader} alt="" />
              <span>Loading...</span>
            </div>
          </div>
        )} */}

        {/* {loadingVideo && (
          <div className="refresh-screen" onClick={reLoad}>
            <img src={refreshIcon} alt="refresh" data-tooltip-place="top" />
          </div>
        )}
        <div className="video-aspect-ratio" onClick={handleClick}>
          <span
            className="flex flex-center fit-screen"
            data-tooltip-id={`tooltip-fit-screen-${randomValue1}`}
          >
            <img src={fitScreenIcon} alt="" />
          </span>
          <span
            className="flex flex-center fill-screen"
            data-tooltip-id={`tooltip-fill-screen-${randomValue2}`}
          >
            <img src={fillScreenIcon} alt="" />
          </span>
          <ReactTooltip
            id={`tooltip-fit-screen-${randomValue1}`}
            place="top"
            content="Fill to Screen"
          />
          <ReactTooltip
            id={`tooltip-fill-screen-${randomValue2}`}
            place="top"
            content="Fit to Screen"
          />
        </div> */}

        <div className="presenter-options-dropdown" ref={dropdownRef}>
          <span
            className="flex flex-center"
            onClick={handlePresenterDropdownClick}
          >
            <img src={verticallyDotsIcon} alt="" />
          </span>
          {presenterMenuOpen && (
            <ul>
              <li className="flex" onClick={reLoad}>
                <img src={refreshDarkIcon} alt="" />
                <span>Refresh</span>
              </li>
              {/* <li className="flex" onClick={handleClick}>
                 <img src={isFillContent ? fitScreenIcon : fillScreenIcon} alt="" />
                <span>{isFillContent ? "Fit to screen" : "Fill to screen"}</span>
              </li> */}
            </ul>
          )}
        </div>
        {!videoTrack && (
          <div className="ew-presenter-profile flex flex-center">
            <div className="img-wrapper flex flex-center">{initials}</div>
            <div className="ew-presenter-title">
              <div className="name">{name == "main" ? "Host" : name}</div>
            </div>
          </div>
        )}
        <div className="ew-live-video">
          <video
            controls={false}
            autoPlay
            loop
            ref={videoRef}
            id={sourceId}
            muted={
              sourceId == stateData?.publishStreamObj?.userName ? true : false
            }
            onError={() => {
              videoRef.current?.error &&
                console.error(`Video player error: ${videoRef.current?.error}`);
            }}
            // onLoadStart={() => setLoadingVideo(true)}
            // onPlaying={() => {
            //   setLoadingVideo(false);
            //   messageRef.current = false;
            // }}
            onStalled={() => {
              console.error("Video is on stalled");
            }}
            // onWaiting={() => setLoadingVideo(true)}
            playsInline
            style={{
              transform: (mirror && stateData?.publishStreamObj?.camera && sourceId == stateData?.publishStreamObj?.userName) ? "scaleX(-1)" : "",
              // display: camera ? "" : "none",
            }}
          ></video>
        </div>

        <div
          className="presenter-name flex flex-center"
          style={{
            display: !!videoTrack ? "" : "none",
          }}
        >
          {sourceId == "main" ? "Host" : sourceId}{}


                                        {isMutedUser && <span className='flex flex-center user-speaker-status mute'>
                                            <img className='mute' src={muteIcon} alt="mute icon" />
                                        </span>}
        </div>
      </div>
    </div>
  );
};




export default memo(Participent);
