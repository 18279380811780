import React, { useEffect, useState } from 'react';
import '../../assets/styles/main.scss';

export const RecordingLayout = () => {


    return (
      //to run the recording layout for attendee you need to add class "with-header" 
      <div className="ew-container-layout recording-layout">
            <div className='left-layout overflow-hidden'>
                {/* EW Header */}
                <div className='layout-header flex flex-align-center flex-justify-between' style={{ display : 'none' }}>
                    <div className='flex flex-align-center overflow-hidden'>
                        <span className='ew-logo hide-on-mobile'>
                            <svg width="57" height="55" viewBox="0 0 57 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Logo-Symbol" clip-path="url(#clip0_1147_18554)">
                                <path id="Rounded Rectangle 1" d="M49.875 0H7.125C3.18997 0 0 3.2119 0 7.17397V47.826C0 51.7881 3.18997 55 7.125 55H49.875C53.81 55 57 51.7881 57 47.826V7.17397C57 3.2119 53.81 0 49.875 0Z" fill="#22B060"/>
                                <g id="Group 694">
                                <path id="Shape 1" d="M39.5517 15L46.0574 15.0957C46.0574 15.0957 36.6654 27.9366 46.184 41.2393H39.4567C39.4567 41.2393 34.6716 37.053 34.5308 28.5672C34.5297 28.5684 34.3877 20.1829 39.5517 15Z" fill="white"/>
                                <path id="Shape 1 copy 3" d="M27.7883 15L34.294 15.0957C34.294 15.0957 24.902 27.9366 34.4206 41.2393H27.6933C27.6933 41.2393 22.9082 37.053 22.7675 28.5672C22.7675 28.5684 22.6256 20.1829 27.7883 15Z" fill="white"/>
                                <path id="Shape 1 copy 4" d="M16.0202 15L22.5259 15.0957C22.5259 15.0957 13.1339 27.9366 22.6526 41.2393H15.9264C15.9264 41.2393 11.1413 37.053 11.0006 28.5672C10.9994 28.5684 10.8575 20.1829 16.0202 15Z" fill="white"/>
                                </g>
                                </g>
                                <defs>
                                <clipPath id="clip0_1147_18554">
                                <rect width="57" height="55" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </span>
                        <div className='ew-event-details'>
                            <h3 className='ew-event-title'>Personality Development Session</h3>
                        </div>
                    </div>
                    <div className='flex flex-align-center'>

                        <div className='ew-event-actions flex flex-align-center flex-justify-center'>
                            <div className='flex exit-actions-btn'>
                                <button type='button' className='flex flex-center button warning-button exit-btn hide-on-mobile'>
                                    Leave
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* EW Video Conatiner */}
                <div className='ew-video-container overflow-hidden w-full h-full'>
                    <div className="presenters screen_shared_fullscreen active-presenter-1"> {/* add class according to presenters added in the screen */} 
                    {/* classes = "countdown", "active-presenter-1 and so on" */}
                        <div className="ew-n-presenters">


                            {/* Presenter 1 */}
                            {/* add class on screen div "screen-sharing-user-fullscreen" */}
                            <div className="presenter-column screen-sharing-user-fullscreen"> {/* Copy presenter div from here if there is more number of hosts */}
                                <div className="ew-video-wrapper">


                                    {/* use clas "for-polls" for polls notification */}

                                    <div className="ew-presenter-profile flex flex-center" style={{ display : 'none' }}>
                                        <div className="img-wrapper flex flex-center">
                                            CZ
                                        </div>
                                        <div className="ew-presenter-title">
                                            <div className="name">Amit K</div>
                                        </div>
                                    </div>
                                    <div className="ew-live-video">
                                        <video controls autoPlay loop>
                                            <source src="/video.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className="presenter-name flex flex-center">
                                        Marry Popkins
                                    </div>
                                </div>
                            </div>

                            {/* Presenter 2 */}
                            <div className="presenter-column"> {/* Copy presenter div from here if there is more number of hosts */}
                                <div className="ew-video-wrapper">
                                    <div className="ew-presenter-profile flex flex-center" style={{ display : 'none' }}>
                                        <div className="img-wrapper flex flex-center">
                                            CZ
                                        </div>
                                        <div className="ew-presenter-title">
                                            <div className="name">Amit K</div>
                                        </div>
                                    </div>

                                    <div className="ew-live-video">
                                        <video controls autoPlay loop>
                                            <source src="/video.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>

                            {/* Presenter 3 */}
                            <div className="presenter-column" style={{display: 'none'}}> {/* Copy presenter div from here if there is more number of hosts */}
                                <div className="ew-video-wrapper">
                                    <div className="ew-presenter-profile flex flex-center" style={{ display : 'none' }}>
                                        <div className="img-wrapper flex flex-center">
                                            CZ
                                        </div>
                                        <div className="ew-presenter-title">
                                            <div className="name">Amit K</div>
                                        </div>
                                    </div>
                                    <div className="ew-live-video">
                                        <video controls autoPlay loop>
                                            <source src="/video.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>

                            {/* Presenter 4 */}
                            <div className="presenter-column" style={{display: 'none'}}> {/* Copy presenter div from here if there is more number of hosts */}
                                <div className="ew-video-wrapper">
                                    <div className="ew-presenter-profile flex flex-center" style={{ display : 'none' }}>
                                        <div className="img-wrapper flex flex-center">
                                            CZ
                                        </div>
                                        <div className="ew-presenter-title">
                                            <div className="name">Amit K</div>
                                        </div>
                                    </div>
                                    <div className="ew-live-video">
                                        <video controls autoPlay loop>
                                            <source src="/video.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>

                            {/* Presenter 5 */}
                            <div className="presenter-column" style={{display: 'none'}}> {/* Copy presenter div from here if there is more number of hosts */}
                                <div className="ew-video-wrapper">
                                    <div className="ew-presenter-profile flex flex-center" style={{ display : 'none' }}>
                                        <div className="img-wrapper flex flex-center">
                                            CZ
                                        </div>
                                        <div className="ew-presenter-title">
                                            <div className="name">Amit K</div>
                                        </div>
                                    </div>
                                    <div className="ew-live-video">
                                        <video controls autoPlay loop>
                                            <source src="/video.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>

                            {/* Presenter 6 */}
                            <div className="presenter-column" style={{display: 'none'}}> {/* Copy presenter div from here if there is more number of hosts */}
                                <div className="ew-video-wrapper">
                                    <div className="ew-presenter-profile flex flex-center">
                                        <div className="img-wrapper flex flex-center">
                                            CZ
                                        </div>
                                        <div className="presenter-name flex flex-center">
                                            Marry Popkins
                                        </div>
                                    </div>
                                    <div className="ew-live-video" style={{ display : 'none' }}>
                                        <video controls autoPlay loop>
                                            <source src="/video.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
