import React, { useState } from "react";
import "../../assets/styles/main.scss";
import chatSettingsIcon from "../../assets/images/chat-settings.svg";
import { ChatModeration } from "./ChatModeration";
import { useDispatch, useSelector } from "react-redux";
import {updateisSettingPopUp,} from "../../Store/Actions/PublishStreamAction";

interface props {
  togglePopup: () => void;
  activeTabProp: number;
}

export const ModeratorSettings = ({  activeTabProp }: props) => {
  //redux
  const dispatch = useDispatch();
  // Settings popup tabs
  const [activeTab, setActiveTab] = useState(activeTabProp);
  const handleTabClick = (tabIndex: React.SetStateAction<number>) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="modal session-settings active">
      <div className="modal-background"></div>
      <div className="modal-container">
        <div className="modal-content flex">
          <span
            className="close-btn"
            onClick={() => {
              dispatch(updateisSettingPopUp(false));
            }}
          >
            &times;
          </span>
          <div className="settings-left-sidebar">
            <div className="tabs flex">
              <h3>Settings</h3>
              <h4>Preferences</h4>
              <button
                className={`flex ${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabClick(1)}
              >
                <span>
                  <img src={chatSettingsIcon} alt="" />
                </span>
                Chat Moderation
              </button>
            
            </div>
          </div>

          <div className="settings-right-area">
            <div className="tab-content">
              
              {activeTab === 1 && <ChatModeration />}
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
