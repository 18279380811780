import { useState } from "react";


export const AccessibiltyPermissions = () => {

    // Toggle button
    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };
    return (
        <div className='ew-setting-area-section chat-moderation'>
            <div className="ew-form-field toggle-field">
                <label htmlFor="dropdown">Pools</label>
                <span className="label-sub-heading">Turn this on to enable attendee list inside participant's panel
                    <div className="toggle-container">
                        <label className="toggle-switch">
                            <input type="checkbox" checked={isToggled} onChange={handleToggle} />
                            <span className="toggle-slider"></span>
                        </label>
                    </div>
                </span>
            </div>
            <div className="ew-form-field toggle-field">
                <label htmlFor="dropdown">Question/Answare</label>
                <span className="label-sub-heading">Enable or disable public chat for attendees
                    <div className="toggle-container">
                        <label className="toggle-switch">
                            <input type="checkbox" checked={isToggled} onChange={handleToggle} />
                            <span className="toggle-slider"></span>
                        </label>
                    </div>
                </span>
            </div>
            <div className="ew-form-field toggle-field">
                <label htmlFor="dropdown">Offers</label>
                <span className="label-sub-heading">Enable or disable the entire chat panel
                    <div className="toggle-container">
                        <label className="toggle-switch">
                            <input type="checkbox" checked={isToggled} onChange={handleToggle} />
                            <span className="toggle-slider"></span>
                        </label>
                    </div>
                </span>
            </div>
        </div>
    );
};


