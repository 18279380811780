import { useEffect, useRef, useState } from "react";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import { useDispatch, useSelector } from "react-redux";
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";
import refreshIcon from "../../assets/images/refresh-dark-icon.png";
import hideShowIcon from "../../assets/images/slide-hide-show.svg";
import { RootState } from "../../Store/Reducers/PublishStreamReducer";
import { updateCurrentFileId, updateCurrentSlide, updateIsCanvasStream, updateIsScreen } from "../../Store/Actions/PublishStreamAction";
import { url } from "inspector";
import closeIcon from "../../assets/images/close-icon.png";
import changeSlideIcon from "../../assets/images/change-slide-icon.png";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";
import { ScreenShareDeniedPopup } from "../PopUp/ScreenShareDeniedPopup/ScreenShareDeniedPopup";
import { ConfirmationPopup } from "../LiveRoomPopUp/ConfirmationPopup ";
import { GenericConfirmMessagePopup } from "../LiveRoomPopUp/GenericConfirmMessagePopup";
import { GenericInfoMessagePopup } from "../LiveRoomPopUp/GenericInfoMessagePopup";
import { usePubNub } from "pubnub-react";
import * as Sentry from '@sentry/react';

interface props {
  imgList?: string[]
}

export const DocShare = ({ imgList }) => {
  const stateData = useSelector(
      (state: RootState) => state.PublishStreamReducer
  );
  const [loadingImage, setLoadingImage] = useState(false);
  // const [loadingVideo, setLoadingVideo] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const canvasCTXRef = useRef<CanvasRenderingContext2D>(null) 
  const canvasStreamRef = useRef<MediaStream>(null)
  const canvasIntervalTimer = useRef<NodeJS.Timer>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const currentSlideRef = useRef<any>([])
  const currentMainSlideRef = useRef<any>([])
  const currentSlideNumRef = useRef<any>([])
  const [currImgIdx, setCurrImgIdx] =  useState<number>(0) 
  const maxImgIdx =  useRef<number>(1)
  const currImgIdxRef =  useRef<number>(0)
  const [showSlides, setShowSlides] =  useState<boolean>(true)
  const [loadThumbs, setLoadThumbs] =  useState<boolean>(false)
  const [currentSlide, setCurrentSlide] =  useState<number>(stateData?.currentSlide)
  const [currentFileId, setCurrentFileId] =  useState<string>(stateData?.currentFileId)
  const dispatch = useDispatch();
  const obj: PublishStream = stateData?.publishStreamObj?.obj;
  const scale = useRef<number>(1)
  const originX = useRef<number>(0)
  const originY = useRef<number>(0)
  const isDragging = useRef<boolean>(false)
  const lastX = useRef<number>(0)
  const lastY = useRef<number>(0)
  const mode = useRef<number>(0)
  const overRide = useRef<boolean>(false)
  const pubnubChnl = useRef<string>(null)
  const [isPotraitMode, setIsPotraitMode] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const lastKeyPressTime = useRef(0)
  const throttleInterval = 500

  const apiRes = {
    "thumb_urls": imgList && imgList.thumb_urls? imgList.thumb_urls : [],
    "image_urls": imgList && imgList.image_urls? imgList.image_urls : [],
    "file_id": imgList && imgList.file_id
  }
  
  useEffect(() => {
    pubnubChnl.current = 'PPT-share-'+stateData.publishStreamObj.webinarid+'-'+stateData.publishStreamObj.sessionId
    maxImgIdx.current = apiRes.image_urls.length     
    const csid = currentSlide
    loadFirstImage(0)
    checkMode(0)
    checkCurrentSlide(csid, apiRes.file_id)
    return () => {
      stopCanvasStream();
    };
  }, [])

  const checkMode = (idx: number) => {
    const img = new Image()
    img.onload = (event) => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      if (height > width) {
        setIsPotraitMode(true)
      }else {
        setIsPotraitMode(false)
      }
    }
    img.src = apiRes.image_urls[idx];
  }

  const checkCurrentSlide = (idx: number, file_id: string) => {
    if(file_id == currentFileId && idx > 0){
      setIsConfirmationOpen(true)
    }
    // setCurrentFileId(apiRes.file_id)
    dispatch(updateCurrentFileId(apiRes.file_id));
  }

  const handleScrollToCurrentSlide = (idx) => {
    if (currentSlideRef.current && currentSlideNumRef.current && showSlides && loadThumbs) {
        // Scroll to the previous slide if it exists
        if (idx > 0) {
            currentSlideRef.current[idx - 1].scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        } else {
            currentSlideRef.current[idx].scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }
        
        // Scroll to the current slide number if necessary
        if (idx <= 0 || idx >= (maxImgIdx.current - 1)) {
            currentSlideNumRef.current[idx].scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        } else {
            currentSlideNumRef.current[idx + 1].scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }
    }
};

const loadFirstImage = (idx: number) => {
    setCurrImgIdx(idx);
    currImgIdxRef.current = idx
    dispatch(updateCurrentSlide(idx));
    inputRef.current.value = (idx + 1).toString();
    setLoadThumbs(true);
  
    const canvas = document.querySelector('canvas')
    canvasRef.current = canvas
    const ctx = canvas.getContext("2d")
    canvasCTXRef.current = ctx
    canvasStreamRef.current = canvas.captureStream()
    // const file_details = ':screenShare:' + apiRes.file_id + ':' + idx + ':' + pubnubChnl.current;
    const file_details = ':screenShare:' + apiRes.file_id;
      (async () => {
          await obj.startScreenShare(stateData?.publishStreamObj?.userName, 0, canvasStreamRef.current, file_details)
      })()
    canvasIntervalTimer.current = setInterval(() => {
        canvasCTXRef.current.fillText('.', 1, 1);
    }, 500);
    // Scroll to the current slide
    handleScrollToCurrentSlide(idx);
};

const pubnubobj = usePubNub();

const sendPubNubMsg = async (msg, channel) => {
    try {
        pubnubobj.publish(
        {
          channel,
          message : msg,
        },
        (status, response) => {
            if (status.error) {
              Sentry.captureMessage('PPT-DS-pubnub-err-'+msg+'-'+channel+'-'+JSON.stringify({...status, ...response}));
            } else {
            //   console.log('Pubnub-publish-success', msg, response, channel)
            }
        }
      )
    } catch (error) {
      Sentry.captureMessage('PPT-DS-pubnub-catch-'+msg+'-'+channel+'-'+JSON.stringify(error));
    }
}

const loadImage = (idx: number) => {
  if (idx < 0 || idx >= maxImgIdx.current || !stateData.isCanvasStream) return;
  setCurrImgIdx(idx);
  currImgIdxRef.current = idx
  // setCurrentSlide(idx)
  dispatch(updateCurrentSlide(idx));
  inputRef.current.value = (idx + 1).toString();

  // const file_details = ':screenShare:' + apiRes.file_id + ':' + idx + ':' + pubnubChnl.current
  const msg = apiRes.file_id + ':::' + idx;
  (async () => {
    // await obj.startScreenShare(stateData?.publishStreamObj?.userName, 0, canvasStreamRef.current, file_details)
    await sendPubNubMsg(msg, pubnubChnl.current)
  })()
  handleScrollToCurrentSlide(idx);
};

  const stopCanvasStream = () => {
    setLoadingImage(false)
    imageRef.current = null
    // await obj.stopSreenShare()
  }

  const openPPTSelectPopup = () => {
    // dispatch(updateOpenPPTSelectPopup(true))
  }

  const stopStream = async () => {
    await obj.stopSreenShare()
  }

  const activeImgStyle = {
    border: '3px solid #FFF',
    borderRadius: '10px',
    width: '100%',
    height: 'auto',
    // margin: '5px 0px 5px 0px',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)',
  }
  const InActiveImgStyle = {    
    // border: '5px solid #000',
    borderRadius: '10px',
    // boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)',
    width: '100%',
    height: 'auto'
  }

  const handleKeyUp = (event) => {
    if (event.key === 'ArrowLeft') {
      const now = Date.now();
      if (now - lastKeyPressTime.current >= throttleInterval) {
        loadImage(currImgIdxRef.current - 1)
        lastKeyPressTime.current = now;
      }
    }
    if (event.key === 'ArrowRight') {
      const now = Date.now();
      if (now - lastKeyPressTime.current >= throttleInterval) {
        loadImage(currImgIdxRef.current + 1)
        lastKeyPressTime.current = now;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // const elementRef = useRef([]);
  return (
    <>
    <div className="presenter-column screen-sharing-user-fullscreen">
      <div className="ew-video-wrapper">
        {/* {loadingImage && (
          <div className="flex flex-center loader-bg">
            <div className="blur-bg"></div>
            <div className="flex flex-center section-loader">
              <img src={blueCircleLoader} alt="" />
              <span>Loading...</span>
            </div>
          </div>
        )} */}
        <div className="ew-live-video">
            <canvas hidden style={{ backgroundColor: "aliceblue", display: 'none'}} id="canvas"></canvas>
            <button type="button" disabled={currImgIdx === 0} className={currImgIdx === 0 ? "disabled-button previous" : "default-btn previous"} onClick={() => loadImage(currImgIdx - 1)}>❮</button>
            <button type="button" disabled={currImgIdx === maxImgIdx.current} className={currImgIdx === maxImgIdx.current - 1 ? "disabled-button next" : "default-btn next"} onClick={() => loadImage(currImgIdx + 1)}>❯</button> 
            
            
            <div className="ppt-slideshare" style={showSlides ? { flex: '0 0 85%', overflow: 'hidden', justifyContent: 'center' }: { flex: '0 0 100%', overflow: 'hidden', justifyContent: 'center' }}>
              {/* <div> */}
                { apiRes.image_urls.map((img, i) => {
                    return (
                    <div key={i} style={ (currImgIdx === i )? { display : "flex", alignItems: "center", position: 'relative', height: '100%' } : { display : "none", position: 'relative', height: '100%' } }>                             
                        <img ref={ref => {currentMainSlideRef.current[i] = ref}} style={ { height: '100%', width: 'auto'} } src={img} ></img>
                        {/* <div ref={ref => {currentMainSlideRef.current[i] = ref}} style={ (currImgIdx === i )? { display : "flex", backgroundImage: img } : { display : "none", backgroundImage: img } } ></div> */}                            
                    </div>
                    )
                })}
              {/* </div> */}

              <div className="modal-footer ppt-pagination flex">
                
                <div style={{ overflow: 'auto', display: 'none', width: '20rem', margin: '0rem 1rem 0rem 1rem' }}>
                  { apiRes.image_urls.map((url, i) => {
                      return (
                          <button key={i} ref={ref => {currentSlideNumRef.current[i] = ref}} type="button" disabled={currImgIdx === i} className={currImgIdx === i ? "disabled-button" : "default-btn"} onClick={() => loadImage(i)}>{i+1}</button>
                      )
                  })}
                
                </div>
                
                <div className="file-name">
                  <span>{imgList.file_name.length > 12 ? (imgList.file_name.slice(0, 12) + '...') : imgList.file_name}</span>
                </div>

                <div className="flex flex-center pagination-controls">
                  <button type="button" disabled={currImgIdx === 0} className={currImgIdx === 0 ? "disabled-button previous" : "default-btn previous"} onClick={() => loadImage(currImgIdx - 1)}>❮</button>
                  <label>Slide</label>
                  <input  
                    onKeyDown={(e) => {
                      if (e.key === "Enter"){
                        e.preventDefault()
                        loadImage(+e?.currentTarget?.value-1)
                      }
                    }} 
                    ref={inputRef}
                    type="number" min={1} max={maxImgIdx.current}
                    defaultValue={currImgIdx+1} 
                    className='input-sm'
                  />
                  <label> of {maxImgIdx.current}</label>
                  <button type="button" disabled={currImgIdx === maxImgIdx.current} className={currImgIdx === maxImgIdx.current - 1 ? "disabled-button next" : "default-btn next"} onClick={() => loadImage(currImgIdx + 1)}>❯</button>
                  
                </div>

                <div className="flex flex-center extra-controls">
                {/* <button type="button" className="change-slide flex flex-center" data-tooltip-id="change-slide" onClick={openPPTSelectPopup}>
                    <img src={changeSlideIcon} alt="" />
                      <ReactTooltip
                          id="change-slide"
                          place="bottom"
                          content="Change file"
                          className= "refresh-tooltip"
                      />
                  </button>
                  <button type="button" className="close-slide flex flex-center" data-tooltip-id="close-slide"  onClick={stopStream}>
                    <img src={closeIcon} alt="" />
                      <ReactTooltip
                          id="close-slide"
                          place="bottom"
                          content="Close file"
                          className= "refresh-tooltip"
                      />
                  </button> */}
                  {/* <button type="button" className={"default-btn flex flex-center"} onClick={() => loadImage(currImgIdx)}><img src={refreshIcon} /> <span>Reset Zoom</span></button> */}
                  {/* <button type="button" className={"default-btn flex flex-center"} onClick={() => {overRide.current = !overRide.current; loadImage(currImgIdx)}}><img src={refreshIcon} /> <span>Fill/Fit</span></button> */}
                  {/* <button type="button" className={"default-btn flex flex-center"} onClick={() => setShowSlides(!showSlides)}><img src={hideShowIcon} /> <span>Show/Hide Slides</span></button> */}
                </div>
                
            </div>

              
            </div>
                


            <div className="flex ppt-slides-area">
            {loadThumbs &&
                <div className="slides-inner" style={{ display: !showSlides? 'none' : ''}}>
                  <ul className="flex">
                      { apiRes.image_urls.map((url, i) => {
                          return (
                            <li key={i} style={{position: 'relative'}}>
                              <div onClick={() => loadImage(i)}>
                                <div className="flex flex-center loader-bg">
                                  <div className="flex flex-center section-loader">
                                    {loadingImage && (currImgIdx === i) && 
                                      <img src={blueCircleLoader} alt="" />
                                    }
                                  </div>
                                </div>                              
                                <img ref={ref => {currentSlideRef.current[i] = ref}} style={ (currImgIdx === i )? activeImgStyle : InActiveImgStyle } src={url} ></img>
                              </div>                              
                            </li>
                          )
                      })}
                    </ul>
                </div>
              }
          </div>
        </div>
      </div>
    </div>
      {isConfirmationOpen && (
        <GenericConfirmMessagePopup
          onAnswerPositive={() => {
            setIsConfirmationOpen(false);
            loadImage(currentSlide);
          } }
          onCloseConfirmationPopUp={() => setIsConfirmationOpen(false)}
          onAnswerNegative={() => {
            setIsConfirmationOpen(false);
            loadImage(0);
          } } 
          positiveButtonText={'Continue Where You Left Off'} 
          negativeButtontext={'Start from Beginning'}
          msgHeader={'Resume Presentation?'}
          msgText={'Would you like to continue from where you left off, or start from the beginning?'}     
        />
      )}
      {isPotraitMode && (
        <GenericInfoMessagePopup 
          onButtonClick={() => setIsPotraitMode(false)}
          onCloseInfoMsgPopUp={() => setIsPotraitMode(false)}          
          msgHeader={'Presentability Notice'} 
          msgText={"This document is in portrait mode and may not display optimally for presentations. For better clarity, we recommend using a landscape-oriented document."} 
          buttonText={'Dismiss'}        
        />
      )}
    </>
  );
};
