import React, { useState } from 'react';
import '../../assets/styles/main.scss';
import { Tab } from '@chakra-ui/react';

import mp4Icon from '../../assets/images/mp4-icon.png';
import listNextIcon from '../../assets/images/list-next.png';
import uploadIcon from '../../assets/images/upload-icon.png';
import checkedIcon from '../../assets/images/checked-icon.png';


export const ShareVideoPopup = ({ isShareVideoOpen, onShareVideoClose }) => {

    const [selectedFile, setSelectedFile] = useState(null);

    // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle form submission (optional)
  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedFile) {
      console.log('Selected file:', selectedFile);
      // You can upload the file to a server here
    } else {
      console.log('No file selected');
    }
  };

  // Tabs
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
    
    return (

        <div className={`modal file-share-popup video-share ${isShareVideoOpen ? 'active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-container with-header">
                <div className="flex modal-header">
                    <h3 className='flex'>Share Video</h3>
                    {/* Tab Buttons */}
                    <div className="tabs sharing-tabs flex flex-center">
                        <button className={`flex ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>
                          Select from Library
                        </button>
                        <button className={`flex ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
                          Upload a new Video
                        </button>
                    </div>
                    <span className="close-btn" onClick={onShareVideoClose}>&times;</span>
                </div>
                {/* <form onSubmit={handleSubmit}> */}
                    <div className="modal-content flex">

                      {/* Tab Content */}
                      <div className="tab-content" style={{ marginTop: '20px' }}>
                        {activeTab === 0 && 
                          <div className='library-tab'>
                            <div className='library-search'>
                              <input type="search" className='search-input' placeholder='Search' />
                            </div>

                            <div className="library-listing">
                              <ul className='flex'>
                                <li className='flex flex-center active'>
                                  <img src={checkedIcon} className='checked' alt="" />
                                  <span className='img'>
                                    <img src={mp4Icon} alt="" />
                                  </span>
                                  <span className='file-name'>Live-Demo-webinar.mp4</span>
                                </li>
                                <li className='flex flex-center'>
                                  <span className='img'>
                                    <img src={mp4Icon} alt="" />
                                  </span>
                                  <span className='file-name'>Live-Demo-webinar.mp4</span>
                                </li>
                                <li className='flex flex-center'>
                                  <span className='img'>
                                    <img src={mp4Icon} alt="" />
                                  </span>
                                  <span className='file-name'>Live-Demo-webinar.mp4</span>
                                </li>
                                <li className='flex flex-center'>
                                  <span className='img'>
                                    <img src={mp4Icon} alt="" />
                                  </span>
                                  <span className='file-name'>Live-Demo-webinar.mp4</span>
                                </li>
                                <li className='flex flex-center'>
                                  <span className='img'>
                                    <img src={mp4Icon} alt="" />
                                  </span>
                                  <span className='file-name'>Live-Demo-webinar.mp4</span>
                                </li>
                                <li className='flex flex-center'>
                                  <span className='img'>
                                    <img src={mp4Icon} alt="" />
                                  </span>
                                  <span className='file-name'>Live-Demo-webinar.mp4</span>
                                </li>
                                <li className='flex flex-center'>
                                  <span className='img'>
                                    <img src={mp4Icon} alt="" />
                                  </span>
                                  <span className='file-name'>Live-Demo-webinar.mp4</span>
                                </li>
                              </ul>
                            </div>

                            <div className="flex flex-center library-pagination">
                              <ul className='flex'>
                                <li className='flex flex-center previous'><span><img src={listNextIcon} alt="" /></span></li>
                                <li className='flex flex-center'><span>1</span></li>
                                <li className='flex flex-center'><span>2</span></li>
                                <li className='flex flex-center'><span>3</span></li>
                                <li className='flex flex-center'><span>4</span></li>
                                <li className='flex flex-center'><span>5</span></li>
                                <li className='flex flex-center'><span>...</span></li>
                                <li className='flex flex-center'><span>13</span></li>
                                <li className='flex flex-center'><span><img src={listNextIcon} alt="" /></span></li>
                              </ul>
                            </div>
                          </div>
                        }

                        {activeTab === 1 && 
                          <div className='upload-tab'>
                            <div className="file-share-info">
                                {/* <label htmlFor="">Select a local file</label> */}
                                <div className="flex flex-center file-upload">
                                        <img src={uploadIcon} alt="" />                                    
                                        <label htmlFor="file-input" className='flex'>Select a file from your computer
                                          <span>(MP4 format only)</span>
                                        </label>
                                        <span className='select-file'>
                                          <input
                                          type="file"
                                          id="file-input"
                                          onChange={handleFileChange}
                                          />
                                          <label htmlFor="">Choose File</label>
                                        </span>
                                        {selectedFile && (
                                        <p><span>Selected file:</span> {selectedFile.name}</p>
                                        )}
                                </div>
                            </div>

                            <div className="file-upload-form">
                              <div className="ew-form-field">
                                  <label>Enter video title below <span className='sub-text'>(optional)</span></label>
                                  <input type="text" placeholder='Please enter a Title' />
                              </div>

                              <div className="ew-form-field">
                                  <label>Enter the video URL below <span className='sub-text'>(optional)</span></label>
                                  <input type="text" placeholder='Please enter a URL' />
                              </div>

                              <div className="ew-form-field">
                                <label className="checkbox-label">
                                  <input type="checkbox" />
                                  Save this video in your Library
                                </label>
                              </div>
                            </div>
                          </div>
                        }
                      </div>

                      
                        
                    </div>
                    <div className="modal-footer flex">
                        <div className="flex">
                            <button type="button" className="default-btn" onClick={onShareVideoClose}>Cancel</button>
                            <button type="button" className="primary-button">Share</button>
                        </div>
                    </div>
                {/* </form> */}
            </div>
        </div>
    );
};
