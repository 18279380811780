import "../../assets/styles/main.scss";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { ExitSessionConfirmationPop } from "../LiveRoomPopUp/ExitSessionConfirmationPop";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";
import ViewerPre, { RemoteTrackSources } from "../../libs/view/ViewerPre";
import { useDispatch, useSelector } from "react-redux";
import { SidebarLayout } from "../SidebarLayout/SidebarLayout";
import { Header } from "../Header/Header";
import ChatModule from "../PubNubChat/ChatModule";
import { Offers } from "../Offers/Offers";
import { Polls } from "../Polls/Polls";
import LaunchEventPopUp from "../LiveRoomPopUp/LaunchEventPopUp";
import TimerPopUp from "../LiveRoomPopUp/TimerPopUp";
// import { PubNubProvider } from "pubnub-react";
// import PubNub from "pubnub";
import ChatComponent from "../PubNubChat/ChatComponent";

import { ScreenShare } from "./share-screen-details";
import { PeopleList } from "../PeopleList/PeopleLists";
import { Participent } from "./people-participant";
import infoIcon from "../../assets/images/info-icon-sm.svg";
import closeIcon from "../../assets/images/close-icon.svg";
import { PollsMain } from "../Polls/pollsMainComponent";
import loader from "../../assets/images/Liveroom-Loader.gif";
import barLoader from "../../assets/images/bar-loader.gif";
import classNames from "classnames";
import {
  screenShareSourceId,
  updateIsScreen,
  updateIsScreenOther,
  updateIsSideBar,
  cameraToggle,
  muteToggle,
  updateISScreenSharedByYou,
} from "../../Store/Actions/PublishStreamAction";
import { getRequestWithAuth, postRequest, postRequestWithFormdata } from "../../libs/APIs/apiCall";
import { ConfirmationPopupMakeCoshotOrBlock } from "../LiveRoomPopUp/ConfirmationPopupMakeCoshotOrBlock";
import { usePubNub } from "pubnub-react";
import { MSG_ENM, signalEnum } from "../../libs/signal/signalEnum";
import { act } from "react-dom/test-utils";
import { useNetworkStatus } from "../../libs/hooks/NetworkStabilityListner";
import NetwotkUnstable from "../CommonComponents/NetwotkUnstable";
import { OffersMain } from "../Offers/offersMainComponent";

import refreshIcon from "../../assets/images/refresh-icon.svg";
import { Tooltip } from "react-tooltip";
import Invite from "../Invite/Invite";
import { adminAjaxUrl, easyWbinarBaseURL, getPFIleUrl, learnMorePlaybackUrl} from "../../config";
import { Settings } from "../Settings/Index";
import { ConfirmationPopup } from "../LiveRoomPopUp/ConfirmationPopup ";
import {
  blockedAttendeeUrl,
  leaveAttendeeUrl,
  webinarLiveEndUrl,
  endLiveSessionUrl,
  exitGreenRoomUrl,
} from "../../config";
import { pollStatus } from "../Polls/poll-enum";
import { useNavigate } from "react-router-dom";
import { LocalVideoShare } from "./LocalVideoShare";
import { LocalFileShareNotAllowed } from "../PopUp/ScreenShareDeniedPopup/LocalFileShareNotAllowed";
import { FileShare } from "./file-share-details";
import { FileSharePDF } from "./file-share-details-pdf";
import ErrorBoundary from "./ErrorBoundary";
import { ConfimationPopupToPresenterToJoinAsAttendee } from "../LiveRoomPopUp/ConfimationPopupToPresenterToJoinAsAttendee";
import LoaderEnd from "../Loader/LoaderEnd";
import { close } from "@sentry/react";
import MakeAttendeeDeclineMessage from "../CommonComponents/MakeAttendeeDeclineMessage";
import { DocShare } from "./doc-share";
import { DocShareViewer } from "./doc-share-viewer";
import * as Sentry from '@sentry/react';
import { GenericInfoMessagePopup } from "../LiveRoomPopUp/GenericInfoMessagePopup";
import { GenericConfirmMessagePopup } from "../LiveRoomPopUp/GenericConfirmMessagePopup";

interface props {
  CanvasDisplayComponenet?: any;
  backgrounds?: any;
  subscription_token?: any;
  stream_name?: any;
  stopStreamTotally?: () => void;
}

interface PollFormState {
  [key: string]: any; // This allows any number of keys with any type of value
}

export const GreenRoom = ({ 
  CanvasDisplayComponenet,
  backgrounds,
  subscription_token,
  stream_name,
  stopStreamTotally,
}: props) => {
  //Redux start
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const [isEndSessionOrLeave, setIsEndSessionOrLeave] = useState<string>("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const networkStatus = useNetworkStatus();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLive = useSelector((state) => stateData.publishStreamObj.isLive);
  const isTimer = useSelector((state) => stateData.publishStreamObj.isTimer);
  const obj: PublishStream = stateData?.publishStreamObj?.obj;
  const [isMuteIconClosed,setIsMuteIconClosed]=useState<boolean>(false)

  const [isFileShareOpen,setIsFileShareOpen]=useState(false)
  const channelName = `${
    "Chat." +
    stateData.publishStreamObj.webinarid +
    "." +
    stateData.publishStreamObj.sessionId
  }`;
  //Redux end
  const pubnub = usePubNub();
  const [status, setStatus] = React.useState(true);
  const videoRefLocal = useRef<HTMLVideoElement | null>(null);
  const [newVideoUrl,setNewVideoUrl]=useState("")


  const [isFullScreenGreenRoom,setIsFullScreenGreenRoom]=useState(false)

  const [showCannotMakeAttendeeMessage,setShowCannotMakeAttendeeMessage]=useState(false)

  //Speaker volume
  const [speakerVolume, setSpeakerVolume] = React.useState(1);

  const [showLauchPopup,setShowLaunchPopup]=useState(false)

  const [muted, setMuted] = useState(stateData?.publishStreamObj?.muted);
  const [video, setVideo] = useState(stateData?.publishStreamObj?.camera);

  const [isShowNetworkPopup,setIsShowNetworkPopup]=useState(true)

  const [isLoading,setIsLoading]=useState(false)
  

const [isExitConfirmationPopup,setIsExitConfrimationPopup]=useState(false)
const [enableScreenSharebutton, setEnableScreenSharebutton] = React.useState(true);

const [isGenericMessage,setIsGenericMessage]=useState(false)
const [activeIndex, setActiveIndex] = useState<number | null>(null);

 const openExitConfirmationPopup=()=>{
   setIsExitConfrimationPopup(true)

 }
 const closeExitConfirmationPopup=()=>{
   setIsExitConfrimationPopup(false)
 }

  useEffect(() => {
    backgrounds();
  }, [status]);

  const [isConfirmationOpenAction, setIsConfirmationOpenAction] =
    useState<boolean>(false);

    const [isConfirmationOpenLocalShare, setIsConfirmationOpenLocalShare] =
    useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [state, setState] = useState({
    uuid: "",
    action: "",
    email: "",
  });

  const [dropdownOpenAttendee, setDropdownOpenAttendee] = useState(null);
  const [submenuOpenAttendee, setSubmenuOpenAttendee] = useState(false);
  
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [isActive, setIsChatActive] = useState(false);
  const chatClick = () => {
    setIsChatActive(!isActive);
  };

  //Green room start
  const [isUserListActive, setIsUserListActive] = useState(true);
  const streamAccountId = stateData.publishStreamObj.accountId;
  const streamName = stream_name;
  const subscriberToken = subscription_token;
  const { remoteTrackSources, startViewer, stopViewer, setSourceQuality } =
    ViewerPre({
      streamAccountId,
      streamName,
      subscriberToken,
    });

  const onCloseConfirmationPopUp = () => {
    setIsConfirmationOpen(false);
  };

  
 
  const [isConfirmationOpenJoinAsAttendee, setIsConfirmationOpenJoinAsAttendee] =
    useState<boolean>(false);

  const [newUrl, setNewUrl] = useState("");

  const onOpenConfirmationModelJoinAsAttendee = () => {
    setIsConfirmationOpenJoinAsAttendee(true);
  };
  const onCloseConfirmationPopUpJoinAsAttendee = () => {
    setIsConfirmationOpenJoinAsAttendee(false);
  };

  const pubnubobj = usePubNub();

  const ChatchannelName =
    "Webinar." +
    stateData.publishStreamObj.webinarid +
    "." +
    stateData.publishStreamObj.sessionId;
  //screen share by other presentor
  const [isScreenSharedByPresentor, setIsScreenSharedByPresentor] =
    React.useState(false);
  const [screenSharedStreamValue, setscreenSharedStreamValue] =
    React.useState<any>();
  const [screenSharedUserNameValue, setscreenSharedUserNameValue] =
    React.useState<any>();
  const [screenSharedSourceIDValue, setscreenSharedSourceIDValue] =
    React.useState<any>();

  const [screenSharedMyself, setscreenSharedMyself] = React.useState(false);
  const [pollFormState, setPollFormState] = useState<PollFormState>({});

  useEffect(() => {
    setVideo(stateData?.publishStreamObj?.camera);
  }, [stateData?.publishStreamObj?.camera]);

  useEffect(() => {
    setMuted(stateData?.publishStreamObj?.muted);
  }, [stateData?.publishStreamObj?.muted]);

  useEffect(() => {
    if (!stateData.publishStreamObj.isScreen)
      setscreenSharedMyself(stateData.publishStreamObj.isScreen);
  }, [stateData.publishStreamObj.isScreen]);

  const [someOneElseSharingPPT, setSomeOneElseSharingPPT] = React.useState(false);
  const someOneElseSharingPPTName = React.useRef<string>(null);
  const [someOneElseSharingPPTSlideNum, setSomeOneElseSharingPPTSlideNum] = React.useState(0);

  const getFileExtension = (key) => {
    const extn = key.split('.')
    return extn[extn.length - 1]
  }

  const fetchPPTFileDetails = async (fileId) => {
    let imgList = null
    try {      
      const res = 
      await getRequestWithAuth(getPFIleUrl+encodeURIComponent(fileId))

      if (res?.status == "error") {
        // setSomeOneElseSharingPPT(false)
        Sentry.captureMessage('PPT-GR-fid-err-'+fileId+'-'+JSON.stringify(res));
        return;
      }
      if(res.data){
        imgList = res.data
        imgList.extn = res.data.file_extn ? res.data.file_extn : getFileExtension(res.data.file_name)
        pptImgList.current = imgList
        pptImgType.current = imgList.extn
      }
      setSomeOneElseSharingPPT(true)
    } catch (error) {
      Sentry.captureMessage('PPT-GR-fid-catch-'+fileId+'-'+JSON.stringify(error));
      // setSomeOneElseSharingPPT(false)
    }
  };

  useEffect(() => {
    let isScreenSharePresentorAvailable = false;
    if (remoteTrackSources.size > 0) {
      setIsUserListActive(false);
    }
    let isSomeOneElseSharingPPT = false

    Array.from(remoteTrackSources).map(([sourceId, { mediaStream }]) => {
      //Ana:screenShare : Ana is user name
      let splittedSourceId = sourceId.split(":");
      if (splittedSourceId[1] == "screenShare" || splittedSourceId[1] == "video") {
        console.log('ss-ready...............',splittedSourceId, someOneElseSharingPPTName.current)
        isScreenSharePresentorAvailable = true;

        if(splittedSourceId.length > 2){
          someOneElseSharingPPTName.current = splittedSourceId[2]            
          fetchPPTFileDetails(splittedSourceId[2])
          isSomeOneElseSharingPPT = true
        }

        setscreenSharedUserNameValue(splittedSourceId[0]);
         setscreenSharedStreamValue(mediaStream);
        setIsScreenSharedByPresentor(true);
        setscreenSharedSourceIDValue(sourceId);
        if(stateData.isCanvasStream) {
          setIsScreenSharedByPresentor(false);
        }
        //save screenshare source id
        dispatch(screenShareSourceId(sourceId));

        if (splittedSourceId[0] !== stateData.publishStreamObj.userName) {
          dispatch(updateIsScreenOther(true));
        } else {
          setscreenSharedMyself(true);
          dispatch(updateIsScreen(true)); 
        }
      }else{
        dispatch(updateIsScreenOther(false));
      }
    });

    if(!isSomeOneElseSharingPPT) {
      setSomeOneElseSharingPPT(false)
      someOneElseSharingPPTName.current = null
      setSomeOneElseSharingPPTSlideNum(0)
    }

    if (!isScreenSharePresentorAvailable) {
      setscreenSharedUserNameValue(undefined);
      setscreenSharedStreamValue(undefined);
      setIsScreenSharedByPresentor(false);
      setscreenSharedSourceIDValue(undefined);
      dispatch(updateIsScreen(false)); 
    }
  }, [remoteTrackSources]);

  //end
  const PPTPubnubChannel = 'PPT-share-'+stateData.publishStreamObj.webinarid+'-'+stateData.publishStreamObj.sessionId
  useEffect(() => {
    try{
      pubnubobj.subscribe({
        channels: [PPTPubnubChannel]
      }); 
      pubnubobj.addListener({
        message: (event) => {
          if(event.channel === PPTPubnubChannel && event.message) {
            const msgs = (event.message)['split'](':::')
            if(!someOneElseSharingPPTName.current || msgs[0] === someOneElseSharingPPTName.current){
              setSomeOneElseSharingPPTSlideNum(+msgs[1])
            }else{
              // someOneElseSharingPPTName.current = msgs[2] 
              // fetchPPTFileDetails(msgs[2])
            }
          }
        },
      })
      pubnub.fetchMessages(
        {
          channels: [PPTPubnubChannel],
          count: 1,
        },
        (status, response) => {
          if (status.error) {
            Sentry.captureMessage('PPT-GR-hist-err-'+PPTPubnubChannel+'-'+JSON.stringify(status["error_message"]));
          } else {
            const messages = response.channels[PPTPubnubChannel];
            const initialMessages = messages.map(msg => msg['message']);
            if(initialMessages && initialMessages[0]) {
              const msgs = (initialMessages[0])['split'](':::')
              if(!someOneElseSharingPPTName.current || msgs[0] === someOneElseSharingPPTName.current){
                setSomeOneElseSharingPPTSlideNum(+msgs[1])
              }else{
                // someOneElseSharingPPTName.current = msgs[2] 
                // fetchPPTFileDetails(msgs[2])
              }
            }
          }
        }
      )
    }
    catch (error) {
      Sentry.captureMessage('PPT-GR-catch-'+PPTPubnubChannel+'-'+JSON.stringify(error));
    }

    startViewer();
    return () => {
      pubnubobj.unsubscribe({
        channels: [PPTPubnubChannel]
      });
      stopViewer();
    };
  }, []);
  //Green room end

  // Sidebar Layout start
  const [sideBarActive, setSideBarActive] = useState(false);
  const [index, setIndex] = useState(0);
  // const sideClickHandler = (index: number, value?: string) => {
  //   if (value === "hide") {
  //     setSideBarActive((pre) => false);
  //   } else {
  //     if (index == 0) {
  //       setIndex(0);
  //       setSideBarActive(true);
  //     } else {
  //       setIndex(index);
  //       setSideBarActive(true);
  //     }
  //   }
  // };
  const onCloseNetworkUnstable=()=>{

    setIsShowNetworkPopup(false)

    setTimeout(() => {
      setIsShowNetworkPopup(true);
    }, 10000); // 10 seconds

}
  const [isSettingPopupOpen, setIsSettingPopupOpen] = React.useState(false);
  const [activeTabProp, setactiveTabProp] = React.useState(1);
  const settingTogglePopup = () => {
    setIsSettingPopupOpen(false);
  };

  useEffect(() => {
    setSideBarActive(stateData.publishStreamObj.sideBarActive);
  }, [stateData.publishStreamObj.sideBarActive]);

  const apiRequestAtEndSession = async () => {
    try {
      const res = await postRequest(endLiveSessionUrl, {
        sid: stateData?.publishStreamObj?.sid,
        webinar_id: stateData?.publishStreamObj.webinarid,
        did: stateData?.publishStreamObj?.did,
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  const leaveSessionHandle = async () => {
    // onCloseConfirmationPopUp()

    const channelName = `${
      "Chat." +
      stateData.publishStreamObj.webinarid +
      "." +
      stateData.publishStreamObj.sessionId
    }`;

    if (stateData?.publishStreamObj?.isAdmin) {
      apiRequestAtEndSession();
    }
    stopStreamTotally();

    //Publish signal
    stateData?.publishStreamObj?.obj?.mediaStreamm
      ?.getTracks()
      .forEach((track) => track.stop());
    await obj.leaveGreenRoom();

    pubnubobj.signal({
      message: {
        type: signalEnum.ENDSESSION,
        status: true,
      },
      channel: channelName,
    });

    const liveRoomChannel = `${
      "live-room-" + stateData.publishStreamObj.webinarid
    }`;
    pubnubobj.objects
      .setChannelMetadata({
        channel: liveRoomChannel,
        data: {
          status: "ended",
          custom: {
            session_id: stateData.publishStreamObj.sessionId,
          },
        },
      })
      .then((response) => {
      })
      .catch((error) => {
        console.log("Unable to set channel status to live", error);
      });

    //update pubnub channel state start
    const ChatchannelName =
      "Webinar." +
      stateData.publishStreamObj.webinarid +
      "." +
      stateData.publishStreamObj.sessionId;

    pubnubobj.signal({
      message: {
        type: signalEnum.ENDSESSION,
        status: true,
      },
      channel: ChatchannelName,
    });
    //signal end session end

    //update pubnub channel state start
    pubnubobj.objects.setChannelMetadata({
      channel: ChatchannelName,
      data: {
        name: "Webinar",
        description: "Webinar Status",
        custom: {
          webinarId: stateData.publishStreamObj.webinarid,
          sessionId: stateData.publishStreamObj.sessionId,
          satus: "end",
        },
      },
    });

    //setting pubnub meta deta for closed polls status

    let globalPollChannel =
      "Polls" +
      "." +
      stateData.publishStreamObj.webinarid +
      "." +
      stateData.publishStreamObj.sessionId;

    pubnubobj.objects
      .getChannelMetadata({
        channel: globalPollChannel,
      })
      .then((response) => {
        let activePoll = response.data.custom.activePoll;
        if (activePoll) {
          pubnubobj.objects.setChannelMetadata({
            channel: `Polls.${stateData.publishStreamObj.sessionId}.${activePoll}`,
            data: {
              status: pollStatus.closed,
            },
          });
        }
      });

    // setLoading(false)
    //end
  };

  const leaveroom = async (isExitGreenRoom=false) => {
    closeExitConfirmationPopup()
    setIsLoading(true)
    await obj.leaveGreenRoom();
    localStorage.removeItem("Blocked");
    if (stateData.publishStreamObj.isAdmin) {
      stopStreamTotally();
      const baseUrl = webinarLiveEndUrl; // Target URL
      let newUrl = `${baseUrl}?id=${stateData.publishStreamObj?.webinarid}`;

     


      try {
        const res = await postRequest(exitGreenRoomUrl, {
          sid: stateData?.publishStreamObj?.sid,
          webinar_id: stateData?.publishStreamObj.webinarid,
        });


           if(isExitGreenRoom){
              if(stateData?.publishStreamObj.webinar_version==1){
                newUrl=`${easyWbinarBaseURL}/v2/events/${stateData.publishStreamObj?.webinarid}/schedule`
      
              }else{
              
                 newUrl=`${easyWbinarBaseURL}/webinar-insight/?webinar=${stateData.publishStreamObj?.webinarid}`
      
              }
            }
        
      } catch (error) {
        console.log("error");
        //  window.location.href = newUrl;
      }

      //If admin then end the session on green room
      const ChatchannelName =
        "Webinar." +
        stateData.publishStreamObj.webinarid +
        "." +
        stateData.publishStreamObj.sessionId;
      if (!stateData.publishStreamObj.isLiveGo) {
        pubnubobj.signal({
          message: {
            type: signalEnum.ENDSESSION,
            status: true,
            isExitGreenRoom
          },
          channel: ChatchannelName,
        }).then((response) => {
          console.log('Signal sent successfully:', response);
          window.location.href = newUrl;
          setIsLoading(false)
          // Handle any post-signal logic here
        })
        .catch((error) => {
          console.error('Error sending signal:', error);
        });

      


      }
    } else 
    {
      if (stateData.publishStreamObj.isPresenter) {
        stateData?.publishStreamObj?.obj?.mediaStreamm
          ?.getTracks()
          .forEach((track) => track.stop());
        stopStreamTotally();
      }

      navigate("/Thanks");
    }
  };

  const videoToggle = async () => {
    const newVideoState = !video;
    setVideo(newVideoState);
    await obj.streamCameraUpdate(newVideoState);

    dispatch(cameraToggle(!stateData?.publishStreamObj?.camera));

    pubnub
      .signal({
        message: {
          camera: newVideoState,
        },
        channel: channelName,
      })
      .catch((error) => {
        console.error("Error sending mute signal:", error);
        console.error("Error status:", error.status); // Log the status code or status object
        console.error("Error message:", error.message);
      });
  };

  const muteToggle1 = async () => {
    setMuted((pre) => !pre);
    await obj.streamMicUpdate(muted);
    // dispatch({
    //   type: "AUDIO_TOGGLE",
    //   payload: {
    //     ...stateData?.publishStreamObj,
    //     muted: !stateData?.publishStreamObj?.muted,
    //   },
    // });

    dispatch(muteToggle(!stateData?.publishStreamObj?.muted));
    pubnub
      .signal({
        message: {
          // uuid: stateData?.publishStreamObj?.userUUID,
          mic: muted,
        },
        channel: channelName,
      })
      .catch((error) => {
        console.error("Error sending mute signal:", error);
        console.error("Error status:", error.status); // Log the status code or status object
        console.error("Error message:", error.message);
      });
  };

  const sideClickHandler = (clickedIndex: number, value?: string) => {
    if (value === "hide") {
      setSideBarActive(false);
      dispatch(updateIsSideBar(false));
    } else {
      // Check if the same tab is clicked
      if (index === clickedIndex) {
        // Toggle the sidebar when clicking the same tab
        setSideBarActive((prevActive) => {
          const newActive = !prevActive; // Toggle active state
          dispatch(updateIsSideBar(newActive));
          return newActive;
        });
      } else {
        // Handle different tab clicks
        setIndex(clickedIndex);
        setSideBarActive(true);
        dispatch(updateIsSideBar(true));
      }
    }
  };
  // Sidebar Layout end

  // Polls popup
  const [isCreatePollsOpen, setCreatePollsOpen] = useState(false);
  const toggleCreatePollsPopup = (event: { preventDefault: () => void }) => {
    setCreatePollsOpen(!isCreatePollsOpen);
  };

  function createPoll() {
    setCreatePollsOpen(true);
  }

  const [isEditPollsOpen, setEditPollsOpen] = useState(false);
  const [pollsData, setPollsData] = useState({});
  const [pollTitle, setPollTitle] = useState("");
  const [pollOptions, setPollOptions] = useState([]);
  const isInvitePopUp = stateData?.publishStreamObj?.isInvitePopUp;
  const isSettingPopUp = stateData.publishStreamObj.isSettingPopUp;
  const [newPollOptions, setNewPollOptions] = useState([
    {
      id: "3850",
      val: "test opt 3",
    },
  ]);

  function updatePollHandlerGreen(poll) {
    setCreatePollsOpen(true);
    setEditPollsOpen(true);
    setPollsData(poll);
    setPollTitle(poll.poll_question);
    setPollOptions(poll.options);
  }
  // For mobile scss
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Function to detect if the device is mobile
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
        setIsMobile(true);
      } else if (
        /iPad|iPhone|iPod/.test(userAgent) &&
        !(window as any).MSStream
      ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check
    checkIfMobile();
  }, []);
 
  //Chat message handler start
  const childRef = useRef(null);
  const messageEventHandler = (event) => {
    childRef.current.handleMessageHandler(event);
  };
  //chat message handler end

  //Video status check
  // function checkVideoStatus(userName) {
  //   const people = stateData.publishStreamObj.peoples.filter(
  //     (ele) =>
  //       (ele?.state?.isPresentor || ele?.state?.isAdmin) &&
  //       ele?.state?.userName == userName
  //   );
  //   if (people.length > 0) {
  //     return people[0]?.state?.camera;
  //   } else {
  //     return false;
  //   }
  // }
  function checkVideoStatus(userName) {
    let splittedSourceId = userName.split(":");
    if (splittedSourceId[1] == "screenShare") return true;
    if (userName == "main") {
      const peopleDetails = stateData.publishStreamObj.peoples.filter(
        (ele) => ele?.state?.isAdmin == true
      );
      return peopleDetails[0]?.state?.camera;
    }
    const peopleDetails = stateData.publishStreamObj.peoples.filter(
      (ele) => ele?.state?.userName == userName
    );
    return peopleDetails[0]?.state?.camera;
  }

  const addNewOption = () => {
    let newArr = [...newPollOptions, { id: "3851", val: "Test" }];
    setNewPollOptions(newArr);
  };

  const removeSelectedOption = (option) => {
    let newArr = newPollOptions.filter((item) => item.id !== option.id);
    setNewPollOptions(newArr);
  };

  const saveEditedData = async () => {
    try {
      let bodyFormData = new FormData();
      bodyFormData.append("action", "edit_poll");
      bodyFormData.append("webinar_id", "");
      bodyFormData.append("session_id", "");
      bodyFormData.append("question", "");
      bodyFormData.append("id", "");
      bodyFormData.append("changedOptions[0][id]", "");
      bodyFormData.append("changedOptions[0][option]", "");
      bodyFormData.append("changedOptions[1][id]", "");
      bodyFormData.append("changedOptions[1][option]", "");

      const res = await postRequestWithFormdata(adminAjaxUrl, bodyFormData);
    } catch (error) {
      console.log("error");
    }
  };

  const onConfirmationOpenAction = () => {
    setIsConfirmationOpenAction(true);
  };
  const onConfirmationCloseAction = () => {
    setIsConfirmationOpenAction(false);
  };

  const onConfirmationOpenLocalShare = () => {
    setIsConfirmationOpenLocalShare(true);
  };
  const onConfirmationCloseLocalShare = () => {
    setIsConfirmationOpenLocalShare(false);
  };

  const handleMenuItemClick = (state) => {

    pubnub.publish(
      {
        channel: ChatchannelName,
        message: {
          type: state.action,
          uuid: state.uuid,
          email: state.email,
          presentorLink: stateData.publishStreamObj.presentorLink,
          attendeeLink: stateData.publishStreamObj.attendeeLink,
        },
      },
      (status, response) => {
        if (status.error) {
          console.log("Publish Error:", status);
        } else {
          setDropdownOpenAttendee(null);
          setSubmenuOpenAttendee(false);
        }
      }
    );

    setDropdownOpen(null);
    setSubmenuOpen(false);
    onConfirmationCloseAction();
  };


  //video code share start



  // useEffect(() => {
  //   if (videoRefLocal.current) {
  //     videoRefLocal.current.src = newVideoUrl || ''; // Set video source
  //     const playVideo = () => {
  //       videoRefLocal.current?.play().catch(error => {
  //         console.error("Error playing video:", error); // Handle any errors while trying to play
  //       });
  //     };
     
  //     // Add event listener for when video is loaded and ready to play
  //     videoRefLocal.current.addEventListener('loadeddata', playVideo);

  //     // Cleanup: remove event listener on component unmount or when videoURL changes
  //     return () => {
  //       videoRefLocal.current?.removeEventListener('loadeddata', playVideo);
  //     };
  //   }
  // }, [newVideoUrl]); 
  
  const handleLocalVideoStream=(newVideoUrl)=>{

    dispatch(updateISScreenSharedByYou(true))

    setNewVideoUrl(newVideoUrl)
  
    // videoRefLocal.current.onloadeddata = () => {
    //     const stream = (videoRefLocal.current as any).captureStream?.();
    //     if (stream) {

        
    //       // Use the Dolby API to send the stream
    //         obj.startLocalVideoShare(stream); // Replace with actual Dolby method
    //       console.log("Stream captured and sent to Dolby:", stream);
    //     } else {
    //       console.error("Failed to capture video stream.");
    //     }
    //   };
  

  }

  //local video share end

  const handleClickForConfirmationPopup = (
    uuid,
    action,
    isPresenterOrAttendee,
    index,
    email
  ) => {
    setState((prevState) => ({
      ...prevState, // Keep existing state
      uuid,
      action,
      email, // Update with new values
    }));
    if (action === MSG_ENM.MAKE_ATTENDEE) {
      setTitle("Make Attendee");
      setMessage("Are you sure, You want to convert this Co-host to attendee");
    } else if (action === MSG_ENM.MAKE_COHOST) {
      setTitle("Make Co-host");
      setMessage("Are you sure? You want to convert  this attendee to Co-host");
    } else if (action === MSG_ENM.BLOCK) {
      setTitle("Block");
      if (isPresenterOrAttendee == "presenter") {
        setMessage("Are  you sure? You  want to Block this presenter");
      } else {
        setMessage("Are  you sure? You  want to Block this attendee");
      }
    } else if (action === MSG_ENM.REMOVE) {
      setTitle("Remove");
      if (isPresenterOrAttendee == "presenter") {
        setMessage("Are  you sure? You  want to Remove this presenter");
      } else {
        setMessage("Are  you sure? You  want to Remove this attendee");
      }
    }

    onConfirmationOpenAction();
  };
  // the below code is to show the notification while you are on mute "You are on mute"
  const isMuted = stateData.publishStreamObj.muted;
  const [showMuteMessage, setShowMuteMessage] = useState(false);
  const [hasShownMessage, setHasShownMessage] = useState(false);
  useEffect(() => {
    let audioContext: AudioContext | null = null;
    let analyser: AnalyserNode | null = null;
    let microphone: MediaStreamAudioSourceNode | null = null;
    let javascriptNode: ScriptProcessorNode | null = null;

    const initAudioStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            autoGainControl: false, // Optional: Disable auto gain control if needed
          },
        });

        // Create the audio context
        audioContext = new AudioContext();

        // Create an analyser node
        analyser = audioContext.createAnalyser();
        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        // Connect the microphone stream to the analyser
        microphone = audioContext.createMediaStreamSource(stream);
        microphone.connect(analyser);

        // Create a JavaScript node to process the audio data
        javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
        javascriptNode.connect(audioContext.destination);

        analyser.connect(javascriptNode);

        javascriptNode.onaudioprocess = () => {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);

          const volume = array.reduce((a, b) => a + b) / array.length;

          // If volume exceeds a threshold and the mic is muted
          if (volume > 20 && isMuted && !hasShownMessage) {
            setShowMuteMessage(true);
            setHasShownMessage(true);
            setTimeout(() => {
              setShowMuteMessage(false);
              setHasShownMessage(false)
            }, 8000); 
          }
        };
      } catch (err) {
        console.error("Error accessing microphone:", err);
      }
    };

    // Delay the initialization by 2 seconds
    const initTimer = setTimeout(() => {
      initAudioStream();
    }, 1000);

    return () => {
      // Cleanup audio context and timer when component unmounts
      if (audioContext) {
        audioContext.close();
      }
      clearTimeout(initTimer);
    };
  }, [isMuted, hasShownMessage]);
  useEffect(() => {
    if (isMuted) {
      setHasShownMessage(false);
      setIsMuteIconClosed(false)
    }
  }, [isMuted]);

  const formHandle = (e) => {
    const { name, value } = e.target;

    setPollFormState((prevState) => ({
        ...prevState,
        [name]: value
    }));
  };

  // console.log("pollFormState >>>", pollFormState)

  const [IsCanvasStream, setIsCanvasStream] = useState(
    stateData?.isCanvasStream
  );

  useEffect(() => {
    setIsCanvasStream(stateData?.isCanvasStream)
  }, [stateData?.isCanvasStream]);

  const pptImgList = useRef<any>()
  const pptImgType = useRef<string>('ppt')
  function handlePptShare(imgList: any){
    pptImgList.current = imgList
    pptImgType.current = imgList.extn
    dispatch(updateISScreenSharedByYou(true))
    setIsScreenSharedByPresentor(false)
  }


  const closeIconClicked=()=>{
    setIndex(null)
    setActiveIndex(null)
  }


if (isLoading){
 return  <LoaderEnd isExitGreenRoom={true}/>

}


  //end
  return (
    // <div
    //   className={`ew-container-layout ${sideBarActive ? "open-sidebar" : ""}`}
    // >

    <div
      className={classNames("ew-container-layout", {
        "open-sidebar": sideBarActive,
        mobile: isMobile,
        "full-screen-mode": isFullScreenGreenRoom,
      })}
    >
      <div className="left-layout overflow-hidden">
        {/* <PubNubProvider client={pubnub}> */}
        <ErrorBoundary component="Header">
          <Header
            stopStreamTotally={stopStreamTotally}
            isScreenSharedByPresentor={isScreenSharedByPresentor}
            videoToggle={videoToggle}
            muteToggle1={muteToggle1}
            muted={muted}
            setMuted={setMuted}
            video={video}
            setVideo={setVideo}
            handleLocalVideoStream={handleLocalVideoStream}
            onConfirmationOpenLocalShare={onConfirmationOpenLocalShare}
            onConfirmationCloseLocalShare={onConfirmationOpenLocalShare}
            setIsFileShareOpen={setIsFileShareOpen}
            handlePptShare={handlePptShare}
            openExitConfirmationPopup={openExitConfirmationPopup}
            enableScreenSharebutton={enableScreenSharebutton}
            setEnableScreenSharebutton={setEnableScreenSharebutton}
            setShowLaunchPopup={setShowLaunchPopup}
            setIsFullScreenGreenRoom={setIsFullScreenGreenRoom}
            isFileShareOpen={isFileShareOpen}
            
          />
        </ErrorBoundary>

        {/* </PubNubProvider> */}
        {isTimer && <TimerPopUp />}
        <div style={{ display: isTimer ? "none" : "block" }}>
          <div className={`modal launch-event ${showLauchPopup ? "active" : ""}`}>
            {showLauchPopup && <LaunchEventPopUp setShowLaunchPopup={setShowLaunchPopup}/>}
          </div>
          {/* EW Video Conatiner */}
          {isUserListActive && (
            <div className="ew-loader-con fullscreen">
              <div className="dummmy-loader-bg"></div>
              <div className="ew-blur"></div>
              <div className="loader-inner">
                <div className="square-container">
                  {/* <div className="loading_box">
                      <div className="ewp_bar bar1"></div>
                      <div className="ewp_bar bar2"></div>
                      <div className="ewp_bar bar3"></div>
                    </div> */}
                  <img src={barLoader} alt="" />
                  {/* <div className='moving-element'></div> */}
                </div>
                <span>Configuring the webinar room</span>
              </div>
            </div>
          )}
        </div>
        {stateData?.publishStreamObj.isScreenSharedByYou == true &&
        isUserListActive == false ? (
          <div
            className="ew-video-container overflow-hidden w-full h-full"
            style={{ display: isTimer ? "none" : "block" }}
          >
            <div
              className={
                (IsCanvasStream || someOneElseSharingPPT)
                  ? "presenters active-presenter-" +
                    (remoteTrackSources.size == 0
                      ? 1
                      : remoteTrackSources.size-1) +
                    " screen_shared_fullscreen ppt-sharing"
                  : "presenters active-presenter-" +
                    (remoteTrackSources.size == 0
                      ? 1
                      : remoteTrackSources.size-1) +
                    " screen_shared_fullscreen"
              }
            >
              {/* all toaster msg in this div */}
              {(showCannotMakeAttendeeMessage ||
                (showMuteMessage && isMuted && !isFileShareOpen) ||
                ((networkStatus === "unstable" ||
                  networkStatus === "offline") &&
                  isShowNetworkPopup &&
                  !isFileShareOpen) ||( stateData.publishStreamObj.isScreen))  && (
                <div className="toaster-msg-outer-con">
                  {showCannotMakeAttendeeMessage && !isFileShareOpen && (
                    <MakeAttendeeDeclineMessage
                      showCannotMakeAttendeeMessage={
                        showCannotMakeAttendeeMessage
                      }
                      setShowCannotMakeAttendeeMessage={
                        setShowCannotMakeAttendeeMessage
                      }
                      attendeeLink={stateData.publishStreamObj.attendeeLink}
                    />
                  )}
                  {showMuteMessage && isMuted && !isFileShareOpen && !isMuteIconClosed &&(
                    <div className="flex toaster-mg audio-unmute-msg">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9978 2.40479C10.9748 2.40479 9.99371 2.81116 9.27036 3.53452C8.547 4.25787 8.14062 5.23895 8.14062 6.26193V13.1191C8.14062 13.6256 8.24039 14.1272 8.43423 14.5951C8.62807 15.0631 8.91219 15.4883 9.27036 15.8465C9.62852 16.2047 10.0537 16.4888 10.5217 16.6826C10.9897 16.8764 11.4912 16.9762 11.9978 16.9762C12.5043 16.9762 13.0059 16.8764 13.4738 16.6826C13.9418 16.4888 14.367 16.2047 14.7252 15.8465C15.0833 15.4883 15.3675 15.0631 15.5613 14.5951C15.7551 14.1272 15.8549 13.6256 15.8549 13.1191V6.26193C15.8549 5.23895 15.4485 4.25787 14.7252 3.53452C14.0018 2.81116 13.0207 2.40479 11.9978 2.40479ZM6.42634 12.4762C6.42634 12.3057 6.35861 12.1422 6.23805 12.0216C6.11749 11.9011 5.95398 11.8334 5.78348 11.8334C5.61299 11.8334 5.44947 11.9011 5.32891 12.0216C5.20835 12.1422 5.14063 12.3057 5.14063 12.4762V13.1191C5.14058 14.8264 5.77748 16.4724 6.92677 17.7351C8.07607 18.9977 9.65506 19.7861 11.3549 19.9462V22.3334C11.3549 22.5039 11.4226 22.6674 11.5432 22.7879C11.6638 22.9085 11.8273 22.9762 11.9978 22.9762C12.1683 22.9762 12.3318 22.9085 12.4523 22.7879C12.5729 22.6674 12.6406 22.5039 12.6406 22.3334V19.9462C14.3405 19.7861 15.9195 18.9977 17.0688 17.7351C18.2181 16.4724 18.855 14.8264 18.8549 13.1191V12.4762C18.8549 12.3057 18.7872 12.1422 18.6666 12.0216C18.5461 11.9011 18.3825 11.8334 18.2121 11.8334C18.0416 11.8334 17.878 11.9011 17.7575 12.0216C17.6369 12.1422 17.5692 12.3057 17.5692 12.4762V13.1191C17.5692 14.5967 16.9822 16.0138 15.9374 17.0587C14.8925 18.1035 13.4754 18.6905 11.9978 18.6905C10.5201 18.6905 9.10302 18.1035 8.05817 17.0587C7.01333 16.0138 6.42634 14.5967 6.42634 13.1191V12.4762Z"
                          fill="#F5AB25"
                        />
                        <path
                          d="M3.63281 3.24463L20.5411 22.4074"
                          stroke="white"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                        <path
                          d="M3.63281 3.24463L20.5411 22.4074"
                          stroke="#F5AB25"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                      <p className="flex">
                        <span>You’re on mute:</span> Please unmute yourself to
                        speak
                      </p>
                      <a
                        href="#"
                        className="close"
                        onClick={() => {
                          setShowMuteMessage(false);
                          setHasShownMessage(true)
                          setIsMuteIconClosed(true)
                        }}
                      >
                        <img src={closeIcon} alt="close icon" />
                      </a>
                    </div>
                  )}
                  {/* {(networkStatus === "unstable" ||
                    networkStatus === "offline") &&
                    isShowNetworkPopup &&
                    !isFileShareOpen && (
                      <NetwotkUnstable
                        onCloseNetworkUnstable={onCloseNetworkUnstable}
                      />
                    )} */}
                  {/* {
                stateData.publishStreamObj.isScreen && (
                  <div className="flex flex-center toaster-mg screen-sharing-msg">
                    <div className="img-wrapper flex flex-center">
                      <img src={infoIcon} alt="" />
                    </div>
                    <div className="message-text flex flex-center">
                      <p>
                        You are sharing your screennn1
                      </p>
                    </div>
                  </div>
                )
                } */}
                </div>
              )}

              <div className="ew-n-presenters">
                {IsCanvasStream == false  && newVideoUrl ? (
                  <LocalVideoShare
                    name={stateData?.publishStreamObj?.userName}
                    screenSharedUsername={screenSharedUserNameValue}
                    streams={screenSharedStreamValue}
                    sourceId={screenSharedSourceIDValue}
                    newVideoUrl={newVideoUrl}
                    setEnableScreenSharebutton={setEnableScreenSharebutton}
                    setIsGenericMessage={setIsGenericMessage}
                    setNewVideoUrl={setNewVideoUrl}
                  />
                ) : 
                (
                  pptImgType.current.toLowerCase() === 'pdf' ? 
                  <DocShare imgList={pptImgList.current}/> : 
                  <DocShare imgList={pptImgList.current}/>
                )
              }
                {(Array.from(remoteTrackSources))
                  .filter(([sourceId, { mediaStream }]) => {
                    return !(sourceId == screenSharedSourceIDValue || sourceId.includes('screenShare'));
                  })
                  .map(([sourceId, { mediaStream }], i) => {
                    return (
                      <ErrorBoundary key={i} component="Participent">
                        <Participent
                          key={i}
                          name={sourceId}
                          streams={mediaStream}
                          sourceId={sourceId}
                          screenSharesourceId={screenSharedUserNameValue}
                          mirror={stateData?.publishStreamObj?.mirroCamera}
                          videoTrack={true}
                          remoteTrackSources={remoteTrackSources}
                        />
                      </ErrorBoundary>
                    );
                  })}
              </div>
            </div>
          </div>
        ) : isUserListActive == false && isScreenSharedByPresentor ? (
          <div className="ew-video-container overflow-hidden w-full h-full">
            <div
              className={
                (IsCanvasStream || someOneElseSharingPPT)
                  ? "presenters active-presenter-" +
                    (remoteTrackSources.size == 0
                      ? 1
                      : remoteTrackSources.size-1) +
                    " screen_shared_fullscreen ppt-sharing"
                  : "presenters active-presenter-" +
                    (remoteTrackSources.size == 0
                      ? 1
                      : remoteTrackSources.size-1) +
                    " screen_shared_fullscreen"
              }
            >
               {(showCannotMakeAttendeeMessage ||
                (showMuteMessage && isMuted && !isFileShareOpen) ||
                ((networkStatus === "unstable" ||
                  networkStatus === "offline") &&
                  isShowNetworkPopup &&
                  !isFileShareOpen)||( stateData.publishStreamObj.isScreen)) && (
                <div className="toaster-msg-outer-con">
                  {showCannotMakeAttendeeMessage && (
                    <MakeAttendeeDeclineMessage
                      showCannotMakeAttendeeMessage={
                        showCannotMakeAttendeeMessage
                      }
                      setShowCannotMakeAttendeeMessage={
                        setShowCannotMakeAttendeeMessage
                      }
                      attendeeLink={stateData.publishStreamObj.attendeeLink}
                    />
                  )}
                  {showMuteMessage && isMuted && !isFileShareOpen && !isMuteIconClosed &&(
                    <div className="flex toaster-mg audio-unmute-msg">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9978 2.40479C10.9748 2.40479 9.99371 2.81116 9.27036 3.53452C8.547 4.25787 8.14062 5.23895 8.14062 6.26193V13.1191C8.14062 13.6256 8.24039 14.1272 8.43423 14.5951C8.62807 15.0631 8.91219 15.4883 9.27036 15.8465C9.62852 16.2047 10.0537 16.4888 10.5217 16.6826C10.9897 16.8764 11.4912 16.9762 11.9978 16.9762C12.5043 16.9762 13.0059 16.8764 13.4738 16.6826C13.9418 16.4888 14.367 16.2047 14.7252 15.8465C15.0833 15.4883 15.3675 15.0631 15.5613 14.5951C15.7551 14.1272 15.8549 13.6256 15.8549 13.1191V6.26193C15.8549 5.23895 15.4485 4.25787 14.7252 3.53452C14.0018 2.81116 13.0207 2.40479 11.9978 2.40479ZM6.42634 12.4762C6.42634 12.3057 6.35861 12.1422 6.23805 12.0216C6.11749 11.9011 5.95398 11.8334 5.78348 11.8334C5.61299 11.8334 5.44947 11.9011 5.32891 12.0216C5.20835 12.1422 5.14063 12.3057 5.14063 12.4762V13.1191C5.14058 14.8264 5.77748 16.4724 6.92677 17.7351C8.07607 18.9977 9.65506 19.7861 11.3549 19.9462V22.3334C11.3549 22.5039 11.4226 22.6674 11.5432 22.7879C11.6638 22.9085 11.8273 22.9762 11.9978 22.9762C12.1683 22.9762 12.3318 22.9085 12.4523 22.7879C12.5729 22.6674 12.6406 22.5039 12.6406 22.3334V19.9462C14.3405 19.7861 15.9195 18.9977 17.0688 17.7351C18.2181 16.4724 18.855 14.8264 18.8549 13.1191V12.4762C18.8549 12.3057 18.7872 12.1422 18.6666 12.0216C18.5461 11.9011 18.3825 11.8334 18.2121 11.8334C18.0416 11.8334 17.878 11.9011 17.7575 12.0216C17.6369 12.1422 17.5692 12.3057 17.5692 12.4762V13.1191C17.5692 14.5967 16.9822 16.0138 15.9374 17.0587C14.8925 18.1035 13.4754 18.6905 11.9978 18.6905C10.5201 18.6905 9.10302 18.1035 8.05817 17.0587C7.01333 16.0138 6.42634 14.5967 6.42634 13.1191V12.4762Z"
                          fill="#F5AB25"
                        />
                        <path
                          d="M3.63281 3.24463L20.5411 22.4074"
                          stroke="white"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                        <path
                          d="M3.63281 3.24463L20.5411 22.4074"
                          stroke="#F5AB25"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                      <p className="flex">
                        <span>You’re on mute:</span> Please unmute yourself to
                        speak
                      </p>
                      <a
                        href="#"
                        className="close"
                        onClick={() => {
                          setShowMuteMessage(false);
                          setHasShownMessage(true)
                          setIsMuteIconClosed(true)

                        }}
                      >
                        <img src={closeIcon} alt="close icon" />
                      </a>
                    </div>
                  )}
                  {/* {(networkStatus === "unstable" ||
                    networkStatus === "offline") &&
                    isShowNetworkPopup &&
                    !isFileShareOpen && (
                      <NetwotkUnstable
                        onCloseNetworkUnstable={onCloseNetworkUnstable}
                      />
                    )} */}
                  {
                stateData.publishStreamObj.isScreen && (
                  <div className="flex flex-center toaster-mg screen-sharing-msg">
                    <div className="img-wrapper flex flex-center">
                      <img src={infoIcon} alt="" />
                    </div>
                    <div className="message-text flex flex-center">
                      <p>
                        You are sharing your screen
                      </p>
                    </div>
                  </div>
                )
                }
                </div>
              )}
              <div className="ew-n-presenters">
              {someOneElseSharingPPT 
                ? <DocShareViewer 
                  imgList={pptImgList.current}
                  currentSlide={someOneElseSharingPPTSlideNum}
                  />
                : <ScreenShare
                  name={stateData?.publishStreamObj?.userName}
                  screenSharedUsername={screenSharedUserNameValue}
                  streams={screenSharedStreamValue}
                  sourceId={screenSharedSourceIDValue}
                />}
                {(Array.from(remoteTrackSources))
                  .filter(([sourceId, { mediaStream }]) => {
                    return !(sourceId == screenSharedSourceIDValue || sourceId.includes('screenShare'));
                  })
                  .map(([sourceId, { mediaStream }], i) => {
                    return (
                      <ErrorBoundary key={i} component="Participent">
                        <Participent
                          key={i}
                          name={sourceId}
                          streams={mediaStream}
                          sourceId={sourceId}
                          screenSharesourceId={screenSharedUserNameValue}
                          mirror={stateData?.publishStreamObj?.mirroCamera}
                          videoTrack={true}
                          remoteTrackSources={remoteTrackSources}
                        />
                      </ErrorBoundary>
                    );
                  })}
              </div>
            </div>
          </div>
        ) : (
          <div className="ew-video-container overflow-hidden w-full h-full">
            <div
              className={
                (IsCanvasStream || someOneElseSharingPPT)
                  ? "presenters active-presenter-" +
                    (remoteTrackSources.size === 0
                      ? 1
                      : screenSharedMyself
                      ? remoteTrackSources.size - 1
                      : remoteTrackSources.size) +
                    " ppt-sharing"
                  : "presenters active-presenter-" +
                    (remoteTrackSources.size === 0
                      ? 1
                      : screenSharedMyself
                      ? remoteTrackSources.size - 1
                      : remoteTrackSources.size)
              }
            >
              {" "}
              {(showCannotMakeAttendeeMessage ||
                (showMuteMessage && isMuted && !isFileShareOpen) ||
                ((networkStatus === "unstable" ||
                  networkStatus === "offline") &&
                  isShowNetworkPopup &&
                  !isFileShareOpen)) && (
                <div className="toaster-msg-outer-con">
                  {showCannotMakeAttendeeMessage && (
                    <MakeAttendeeDeclineMessage
                      showCannotMakeAttendeeMessage={
                        showCannotMakeAttendeeMessage
                      }
                      setShowCannotMakeAttendeeMessage={
                        setShowCannotMakeAttendeeMessage
                      }
                      attendeeLink={stateData.publishStreamObj.attendeeLink}
                    />
                  )}
                  {showMuteMessage && isMuted && !isFileShareOpen && !isMuteIconClosed && (
                    <div className="flex toaster-mg audio-unmute-msg">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9978 2.40479C10.9748 2.40479 9.99371 2.81116 9.27036 3.53452C8.547 4.25787 8.14062 5.23895 8.14062 6.26193V13.1191C8.14062 13.6256 8.24039 14.1272 8.43423 14.5951C8.62807 15.0631 8.91219 15.4883 9.27036 15.8465C9.62852 16.2047 10.0537 16.4888 10.5217 16.6826C10.9897 16.8764 11.4912 16.9762 11.9978 16.9762C12.5043 16.9762 13.0059 16.8764 13.4738 16.6826C13.9418 16.4888 14.367 16.2047 14.7252 15.8465C15.0833 15.4883 15.3675 15.0631 15.5613 14.5951C15.7551 14.1272 15.8549 13.6256 15.8549 13.1191V6.26193C15.8549 5.23895 15.4485 4.25787 14.7252 3.53452C14.0018 2.81116 13.0207 2.40479 11.9978 2.40479ZM6.42634 12.4762C6.42634 12.3057 6.35861 12.1422 6.23805 12.0216C6.11749 11.9011 5.95398 11.8334 5.78348 11.8334C5.61299 11.8334 5.44947 11.9011 5.32891 12.0216C5.20835 12.1422 5.14063 12.3057 5.14063 12.4762V13.1191C5.14058 14.8264 5.77748 16.4724 6.92677 17.7351C8.07607 18.9977 9.65506 19.7861 11.3549 19.9462V22.3334C11.3549 22.5039 11.4226 22.6674 11.5432 22.7879C11.6638 22.9085 11.8273 22.9762 11.9978 22.9762C12.1683 22.9762 12.3318 22.9085 12.4523 22.7879C12.5729 22.6674 12.6406 22.5039 12.6406 22.3334V19.9462C14.3405 19.7861 15.9195 18.9977 17.0688 17.7351C18.2181 16.4724 18.855 14.8264 18.8549 13.1191V12.4762C18.8549 12.3057 18.7872 12.1422 18.6666 12.0216C18.5461 11.9011 18.3825 11.8334 18.2121 11.8334C18.0416 11.8334 17.878 11.9011 17.7575 12.0216C17.6369 12.1422 17.5692 12.3057 17.5692 12.4762V13.1191C17.5692 14.5967 16.9822 16.0138 15.9374 17.0587C14.8925 18.1035 13.4754 18.6905 11.9978 18.6905C10.5201 18.6905 9.10302 18.1035 8.05817 17.0587C7.01333 16.0138 6.42634 14.5967 6.42634 13.1191V12.4762Z"
                          fill="#F5AB25"
                        />
                        <path
                          d="M3.63281 3.24463L20.5411 22.4074"
                          stroke="white"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                        <path
                          d="M3.63281 3.24463L20.5411 22.4074"
                          stroke="#F5AB25"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                      <p className="flex">
                        <span>You’re on mute:</span> Please unmute yourself to
                        speak
                      </p>
                      <a
                        href="#"
                        className="close"
                        onClick={() => {
                          setShowMuteMessage(false);
                          setHasShownMessage(true)
                          setIsMuteIconClosed(true)
                        }}
                      >
                        <img src={closeIcon} alt="close icon" />
                      </a>
                    </div>
                  )}
                  {/* {(networkStatus === "unstable" ||
                    networkStatus === "offline") &&
                    isShowNetworkPopup &&
                    !isFileShareOpen && (
                      <NetwotkUnstable
                        onCloseNetworkUnstable={onCloseNetworkUnstable}
                      />
                    )} */}
                  {/* {
                stateData.publishStreamObj.isScreen && (
                  <div className="flex flex-center toaster-mg screen-sharing-msg">
                    <div className="img-wrapper flex flex-center">
                      <img src={infoIcon} alt="" />
                    </div>
                    <div className="message-text flex flex-center">
                      <p>
                        You are sharing your screennn1
                      </p>
                    </div>
                  </div>
                )
                } */}
                </div>
              )}
              {/* add class according to presenters added in the screen */}
              <div className="ew-n-presenters">
                {(Array.from(remoteTrackSources))
                  .filter(([sourceId, { mediaStream }]) => {
                    return !(sourceId == screenSharedSourceIDValue || sourceId.includes('screenShare'));
                  })
                  .map(([sourceId, { mediaStream }], i) => {
                    return (
                      <>
                        <ErrorBoundary key={i} component="Participent">
                          <Participent
                            key={i}
                            name={sourceId}
                            streams={mediaStream}
                            sourceId={sourceId}
                            screenSharesourceId={screenSharedSourceIDValue}
                            mirror={stateData?.publishStreamObj?.mirroCamera}
                            videoTrack={true}
                            remoteTrackSources={remoteTrackSources}
                          />
                        </ErrorBoundary>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        )}

        {/* <div
          className="refresh-screen"
          onClick={() => window.location.reload()}
        >
          <img src={refreshIcon} alt="refresh" data-tooltip-place="top" />
          <Tooltip anchorSelect=".refresh-screen">Refresh</Tooltip>
        </div> */}
      </div>
      {/* Ew Sidebar Layout */}
      {/* <PubNubProvider client={pubnub}> */}
      <div className="right-layout">
        <ErrorBoundary component="Sidebar">
          <SidebarLayout
            sideClick={sideClickHandler}
            onLeaveClick={""}
            messageHandle={messageEventHandler}
            onOpenConfirmationModelFunc={null}
            videoToggle={videoToggle}
            muteToggle1={muteToggle1}
            muted={muted}
            setMuted={setMuted}
            video={video}
            setVideo={setVideo}
            stopStreamTotally={stopStreamTotally}
            leaveAttendee={null}
            setIsEndSessionOrLeave={setIsEndSessionOrLeave}
            setIsConfirmationOpen={setIsConfirmationOpen}
            onOpenConfirmationModelJoinAsAttendee={
              onOpenConfirmationModelJoinAsAttendee
            }
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        </ErrorBoundary>
        <div className="sidebar-info-box">
          {index == 0 && (
            // <ChatModule />
            <ErrorBoundary component="ChatComponent">
              <ChatComponent ref={childRef} crossIconHandler={closeIconClicked}/>
            </ErrorBoundary>
          )}
          {index == 1 && (
            <ErrorBoundary component="PeopleList">
              <PeopleList
                onConfirmationCloseAction={onConfirmationCloseAction}
                setTitle={setTitle}
                setMessage={setMessage}
                onConfirmationOpenAction={onConfirmationOpenAction}
                setDropdownOpenAttendee={setDropdownOpenAttendee}
                setSubmenuOpenAttendee={setSubmenuOpenAttendee}
                setDropdownOpen={setDropdownOpen}
                setSubmenuOpen={setSubmenuOpen}
                dropdownOpen={dropdownOpen}
                dropdownOpenAttendee={dropdownOpenAttendee}
                handleClickForConfirmationPopup={
                  handleClickForConfirmationPopup
                }
                setShowCannotMakeAttendeeMessage={
                  setShowCannotMakeAttendeeMessage
                }
                setIsScreenSharedByPresentor={setIsScreenSharedByPresentor}
                isScreenSharedByPresentor={isScreenSharedByPresentor}
                crossIconHandler={closeIconClicked}

              />
            </ErrorBoundary>
          )}
        <div style={{ display: index === 2 ? 'block' : 'none' }}>
    <OffersMain index={index}  crossIconHandler={closeIconClicked}/>
  </div>
        
            <ErrorBoundary component="Polls">
                <div style={{ display: index === 3 ? 'block' : 'none' }}>
                <PollsMain
                createPollHandler={createPoll}
                updatePollHandler={updatePollHandlerGreen}
                crossIconHandler={closeIconClicked}
              
              />
  </div>
             
            </ErrorBoundary>
          
        </div>
      </div>

      {isConfirmationOpenAction && (
        <ConfirmationPopupMakeCoshotOrBlock
          isConfirmationOpen={isConfirmationOpenAction}
          onConfirmationClose={onConfirmationCloseAction}
          title={title}
          message={message}
          handleAction={handleMenuItemClick}
          state={state}
        />
      )}

      {isConfirmationOpen && (
        <ConfirmationPopup
          isEndSessionOrLeave={isEndSessionOrLeave}
          isConfirmationOpen={isConfirmationOpen}
          onCloseConfirmationPopUp={onCloseConfirmationPopUp}
          SessionHandle={
            isEndSessionOrLeave === "EndSession"
              ? leaveSessionHandle
              : leaveroom
          }
          isLiveRoom={stateData?.publishStreamObj?.isLiveGo}
          isAdmin={stateData?.publishStreamObj.isAdmin}
        />
      )}
      {isExitConfirmationPopup && (
        <ExitSessionConfirmationPop
          isConfirmationOpen={isExitConfirmationPopup}
          onCloseConfirmationPopUp={closeExitConfirmationPopup}
          leaveroom={leaveroom}
        />
      )}

      {isConfirmationOpenJoinAsAttendee && (
        <ConfimationPopupToPresenterToJoinAsAttendee
          isConfirmationOpen={isConfirmationOpenJoinAsAttendee}
          onCloseConfirmationPopUp={onCloseConfirmationPopUpJoinAsAttendee}
        />
      )}

{/* {isGenericMessage && (
        <GenericInfoMessagePopup 
          onButtonClick={() => setIsGenericMessage(false)}
          onCloseInfoMsgPopUp={() => setIsGenericMessage(false)}          
          msgHeader={'Playback Alert'} 
          msgText={"This video may not play due to compatibility issues or missing permissions. Please update the necessary permissions or try using a supported format."} 
          buttonText={'Dismiss'}        
        />
      )} */}
      {isGenericMessage && !IsCanvasStream && (
        <GenericConfirmMessagePopup
        onAnswerPositive={() => {
          setIsGenericMessage(false);
        } }
        onCloseConfirmationPopUp={() => setIsGenericMessage(false)}
        onAnswerNegative={() => {
        
          window.open(learnMorePlaybackUrl, '_blank');
          setIsGenericMessage(false);
         
        } } 
        positiveButtonText={'Dismiss'} 
        negativeButtontext={'Learn More'}
        msgHeader={'Playback Alert'}
        msgText={'This video may not play due to compatibility issues or missing permissions. Please update the necessary permissions or try using a supported format.'}     
      />
               
        
      )}

      {/* </PubNubProvider>

      {/* Create Polls Popup */}
      <div
        className={`modal create-polls ${isCreatePollsOpen ? "active" : ""}`}
      >
        <div className="modal-background"></div>
        <div className="modal-container with-header">
          <div className="modal-header">
            <h3>{isEditPollsOpen ? "Edit a Poll" : "Create a Poll"}</h3>
            <span className="close-btn" onClick={toggleCreatePollsPopup}>
              &times;
            </span>
          </div>
          <div className="modal-content flex create-poll-info">
            <div className="ew-form-field">
              <label htmlFor="">Poll Title</label>
              <textarea
                name="title"
                value={pollFormState.title}
                id=""
                rows={5}
                cols={50}
                placeholder="Please provide a poll title"
                onChange={(e) => formHandle(e)}
              ></textarea>
            </div>

            <div className="polls-options">
              <h3 className="flex">
                Options{" "}
                <button className="text-button" onClick={() => addNewOption()}>
                  Add New
                </button>
              </h3>
              {pollOptions.map((option) => (
                <div className="create-option">
                  <input
                    type="text"
                    className="input-md"
                    value={option.val}
                    // onChange={(e) => formHandle(e)}
                  />
                </div>
              ))}
              {newPollOptions.map((option, index) => (
                <div className="create-option remove" key={index}>
                  <input
                    type="text"
                    className="input-md"
                    name={`option_${index}`}
                    value={pollFormState[`option_${index}`]}
                    onChange={(e) => formHandle(e)}
                  />
                  <span className="remove">
                    <img
                      src={closeIcon}
                      alt="close"
                      onClick={() => removeSelectedOption(option)}
                    />
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex modal-footer">
            <button
              type="button"
              className="outlined-button"
              onClick={() => saveEditedData()}
            >
              Save
            </button>
            <button type="button" className="primary-button">
              Publish
            </button>
          </div>
        </div>
      </div>
      {isInvitePopUp && <Invite />}

      {isSettingPopUp && (
        <ErrorBoundary component="Settings">
          <Settings
            togglePopup={settingTogglePopup}
            activeTabProp={activeTabProp}
          />
        </ErrorBoundary>
      )}

      <ErrorBoundary component="LocalFileShare">
        <LocalFileShareNotAllowed
          isConfirmationOpen={isConfirmationOpenLocalShare}
          onConfirmationClose={onConfirmationCloseLocalShare}
        />
      </ErrorBoundary>
    </div>
  );
};
