import React, { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import mp4Icon from "../../assets/images/mp4-icon.png";
import listNextIcon from "../../assets/images/list-next.png";
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";

import {
  getRequestWithAuthToken,
} from "../../libs/APIs/apiCall";
import { useSelector } from "react-redux";
import checkedIcon from "../../assets/images/checked-icon.png";
import debounce from "lodash.debounce";
import {videoGetUrlFrom } from "../../config";
import { Flex } from "@chakra-ui/react";

const ShareVideoFromLibraryVideos = ({ setSelectedFile }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 0,
    totalPages: 1,
    perPage: 15,
  });
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const stateData = useSelector((state:any) => state.PublishStreamReducer);
  const domainId = stateData.publishStreamObj.did;
  const webinar_id = stateData.publishStreamObj.webinarid;

  const fetchVideos = async (
    currentPage = 0,
    perPage = 15,
    sortBy = "time_desc",
    searchValue = ""
  ) => {
    try {
      setLoading(true);
      const url = `${videoGetUrlFrom}?domain_id=${domainId}&per_page=${perPage}&current_page=${currentPage + 1}&sort_by=${sortBy}&video_title=${searchValue}&call_from=live_room`;
      const data = (await getRequestWithAuthToken(url)).data
      console.log("Fetched videos:", data);

      setVideos(data?.records || []);
      setPagination({
        currentPage: data.pagination.page - 1,
        totalPages: data.pagination.total_pages,
        perPage: perPage,
      });
      setLoading(false);
      return data;
    } catch (error) {
      console.error("Error fetching videos:", error);
      setLoading(false);
    }
  };

  const handleVideoClick = (index, videoUrl) => {
    setSelectedVideoIndex(index);
    console.log("videoUrl", videoUrl);
    setSelectedFile(videoUrl);
  };

  const handlePageClick = (selectedItem) => {
    console.log("selected Item",selectedItem)
    const selectedPage = selectedItem.selected;
    fetchVideos(selectedPage, pagination.perPage);
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      fetchVideos(0, pagination.perPage, "time_desc", searchValue);
    }, 500),
    [pagination.perPage]
  );

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
    debouncedSearch(searchValue);
  };

  // if(!videos.length){
  //   return <div>No videos found</div>
  // }

  return (
    <div className="library-tab">
      <div className="library-search">
        <input
          type="search"
          className="search-input"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <div className="library-listing">
        <ul className="flex">
          {loading ? (
            <div className="flex flex-center loader-bg">
              <div className="flex flex-center section-loader">
                <img src={blueCircleLoader} alt="Loading..." />
                <span>Loading...</span>
              </div>
            </div>
          ) : (
            videos.map((video, index) => {
              // if (!video.video_select_link) {
              //   return null;
              // }
              return (
                <li
                  key={index}
                  className={`flex flex-center ${
                    selectedVideoIndex === index ? "active" : ""
                  }`}
                  onClick={() => handleVideoClick(index, video.video_url)}
                >
                  {selectedVideoIndex === index && (
                    <img src={checkedIcon} className="checked" alt="Checked" />
                  )}
                  <span className="img">
                    <img src={mp4Icon} alt="Video Icon" />
                  </span>
                  <span className="file-name">{video.video_title}.mp4</span>
                </li>
              );
            })
          )}
        </ul>
      </div>

      {!loading && pagination.totalPages > 1 && (
        <div className="flex flex-center library-pagination" >
          <ReactPaginate
            previousLabel={<img src={listNextIcon} alt="Previous" />}
            nextLabel={<img src={listNextIcon} alt="Next" />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pagination.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"flex flex-center pagination"}
            activeClassName={"active"}
            forcePage={pagination.currentPage}
          />
        </div>
      )}
    </div>
  );
};

export default ShareVideoFromLibraryVideos;
