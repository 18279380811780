import {
  UPDATE_IS_LIVEGO,
  UPDATE_IS_SCREEN,
  UPDATE_IS_TIMER,
  UPDATE_IS_LIVE,
  SPEAKER_SELECTED_DEVICE_ID,
  SPEAKER_SELECTED_LABEL,
  ADD_CONNECTION,
  ADD_USERNAME,
  AUDIO_TOGGLE,
  MIRROR_CAMERA_TOGGLE,
  UPDATEVIDEOQUALITY,
  UPDATE_SELECTED_DEVICE_ID,
  UPDATE_SELECTED_LABEL,
  VIDEO_TOGGLE,
  CAMERA_ID,
  CAMERA_LABEL,
  ADD_PEOPLES,
  ADD_NEW_PEOPLE,
  ADD_NUMBER_OF_PEOPLES,
  REMOVE_PEOPLE,
  PARTIPANT_MIC_STATUS,
  UPDATE_IS_INVITEPOPUP,
  UPDATE_IS_SETTINGPOPUP,
  UPDATE_NOISE_REDUCTION,
  ATTENDEE_LIST,
  PUBLIC_CHAT,
  CHAT_PANEL,
  PRIVATE_CHAT,
  UPDATE_CAMERA,
  PARTIPANT_VIDEO_STATUS,
  UPDATE_SCREEN_OTHER,
  UPDATE_ACTIVE_POLL_STATUS,
  UPDATE_ACTIVE_OFFER_STATUS,
  SHOW_ACTIVE_OFFER_NOIFICATION,
  SHOW_ACTIVE_POLL_NOIFICATION,
  UPDATE_POLLS_ACTIVE_TAB,
  UPDATE_OFFERS_ACTIVE_TAB,
  SIDE_BAR,
  UPDATE_ATTENDEE_DATA_FOR_ALL,
  MUTE_TOGGLE,
  CAMERA_TOGGLE,
  WHO_IS_SHARING_SCREEN,
  SCREENSHARESOURCEID,
  UPDATERECORD,
  UPDATE_CONFERENCE_ID,
  UPDATE_MIC,
  IS_SCREEN_SHARED_BY_YOU,
  UPDATE_IS_CANVAS_STREAM,
  UPDATE_IS_SCREEN_SHARE_WINDOW_MUTED,
  UPDATE_CURRENT_SLIDE,
  UPDATE_CURRENT_FILEID,
  IS_SHOW_ERROR,
} from "../ActionTypes/PublishStreamActionType";

const addConnection = () => {
  return {
    type: ADD_CONNECTION,
  };
};

const videoToggleOnOff = () => {
  return {
    type: VIDEO_TOGGLE,
  };
};

const audioToggleOnOff = () => {
  return {
    type: AUDIO_TOGGLE,
  };
};

const addUserName = () => {
  return {
    type: ADD_USERNAME,
  };
};

const mirrorCameraOnOff = () => {
  return {
    type: MIRROR_CAMERA_TOGGLE,
  };
};

const updateVideoQuality = () => {
  return {
    type: UPDATEVIDEOQUALITY,
  };
};

const updateSelectedDeviceId = (deviceId: string) => ({
  type: UPDATE_SELECTED_DEVICE_ID,
});

const updateSelectedLabel = (label: string) => ({
  type: UPDATE_SELECTED_LABEL,
});

const speakerdeviceId = (deviceId: string) => ({
  type: SPEAKER_SELECTED_DEVICE_ID,
});

const speakerDeviceLabel = (label: string) => ({
  type: SPEAKER_SELECTED_LABEL,
});

const cameraId = (deviceId: string) => ({
  type: CAMERA_ID,
});

const cameraLabel = (label: string) => ({
  type: CAMERA_LABEL,
});

const updateIsLive = (isLive: boolean) => ({
  type: UPDATE_IS_LIVE,
  payload: isLive,
});

const updateIsTimer = (isTimer: boolean) => ({
  type: UPDATE_IS_TIMER,
  payload: isTimer,
});
const updateIsLiveGo = (isLiveGo: boolean) => ({
  type: UPDATE_IS_LIVEGO,
  payload: isLiveGo,
});
const updateIsSideBar = (sideBarActive: boolean) => ({
  type: SIDE_BAR,
  payload: sideBarActive,
});
const updateIsScreen = (isScreen: boolean) => ({
  type: UPDATE_IS_SCREEN,
  payload: isScreen,
});

const updateIsScreenOther = (isScreenByOtherMember: boolean) => ({
  type: UPDATE_SCREEN_OTHER,
  payload: isScreenByOtherMember,
});

const updateisInvitePopUp = (isInvitePopUp: boolean) => ({
  type: UPDATE_IS_INVITEPOPUP,
  payload: isInvitePopUp,
});
const updateisSettingPopUp = (isSettingPopUp: boolean) => ({
  type: UPDATE_IS_SETTINGPOPUP,
  payload: isSettingPopUp,
});

const addPeoples = (peoples) => ({
  type: ADD_PEOPLES,
  payload: peoples,
});

const addnewPeople = (people) => ({
  type: ADD_NEW_PEOPLE,
  payload: people,
});

const addNumberOfPeople = (value) => ({
  type: ADD_NUMBER_OF_PEOPLES,
  payload: value,
});

const removePeople = (uuid) => ({
  type: REMOVE_PEOPLE,
  payload: uuid,
});

const participantMicStatus = (data) => ({
  type: PARTIPANT_MIC_STATUS,
  payload: data,
});

const participantVideoStatus = (data) => ({
  type: PARTIPANT_VIDEO_STATUS,
  payload: data,
});

const updateNoiseReduction = (data) => ({
  type: UPDATE_NOISE_REDUCTION,
  payload: data,
});

// Chat moderation
const updateIsAttendeeList = (isAttendeeList: boolean) => ({
  type: ATTENDEE_LIST,
  payload: isAttendeeList,
});
const updateIsPublicChat = (isPublicChat: boolean) => ({
  type: PUBLIC_CHAT,
  payload: isPublicChat,
});
const updateIsPrivatChat = (isPrivateChat: boolean) => ({
  type: PRIVATE_CHAT,
  payload: isPrivateChat,
});
const updateIsChatPanel = (isChatPanel: boolean) => ({
  type: CHAT_PANEL,
  payload: isChatPanel,
});

const updateCamera = (cameraIdData: string, cameraNameData: string) => ({
  type: UPDATE_CAMERA,
  payload: {
    cameraId: cameraIdData,
    cameraName: cameraNameData,
  },
});

 
const updateMic = (deviceId: string, label: string) => ({
  type: UPDATE_MIC,
  payload: {
    deviceId: deviceId,
    label: label,
  },
});
 
const updateActivePollStatus = (newActivePoll: boolean) => ({
  type: UPDATE_ACTIVE_POLL_STATUS,
  payload: newActivePoll,
});

const updatePollsActiveTab = (activeTab: number) => ({
  type: UPDATE_POLLS_ACTIVE_TAB,
  payload: activeTab,
})

const updateActiveOfferStatus = (newActiveOffer: boolean) => ({
  type: UPDATE_ACTIVE_OFFER_STATUS,
  payload: newActiveOffer,
});

const showOfferNotificationFunction = (activeOffer: boolean) => ({
  type: SHOW_ACTIVE_OFFER_NOIFICATION,
  payload: activeOffer,
});

const showPollsNotificationFunction = (activePoll: boolean) => ({
  type: SHOW_ACTIVE_POLL_NOIFICATION,
  payload: activePoll,
});

const updateOffersActiveTab = (activeTab: number) => ({
  type: UPDATE_OFFERS_ACTIVE_TAB,
  payload: activeTab,
})

const updateAttendeeDataForAll = (attendeeData: any) => ({
  type: UPDATE_ATTENDEE_DATA_FOR_ALL,
  payload: attendeeData,
});

const muteToggle = (muted: boolean) => {
  return {
    type: MUTE_TOGGLE,
    payload: muted,
  };
};

const cameraToggle = (camera: boolean) => {
  return {
    type: CAMERA_TOGGLE,
    payload: camera,
  };
};

const updateWhoIsSharingScreen = (uuid: string) => {
  return {
    type: WHO_IS_SHARING_SCREEN,
    payload: uuid,
  };
};

const screenShareSourceId = (sourceId: string) => {
  return {
    type: SCREENSHARESOURCEID,
    payload: sourceId,
  };
};

const updateRecord = (record: boolean) => {
  return {
    type: UPDATERECORD,
    payload: record,
  };
};

const updateConferenceId = (conferenceId: string) => ({
  type: UPDATE_CONFERENCE_ID,
  payload: conferenceId,
});

const updateISScreenSharedByYou = (isScreenSharedBYYou: boolean) => ({
  type: IS_SCREEN_SHARED_BY_YOU,
  payload: isScreenSharedBYYou,
});

const updateIsCanvasStream = (isCanvasStream: boolean) => {
  return {
    type: UPDATE_IS_CANVAS_STREAM,
    payload: isCanvasStream,
  }
}

const updateIsScreenShareWindowMuted = (isScreenShareWindowMuted: boolean) => {
  return {
    type: UPDATE_IS_SCREEN_SHARE_WINDOW_MUTED,
    payload: isScreenShareWindowMuted,
  }
}

const updateCurrentSlide = (currentSlide: number) => {
  return {
    type: UPDATE_CURRENT_SLIDE,
    payload: currentSlide,
  }
}

const updateCurrentFileId = (currentFileId: string) => {
  return {
    type: UPDATE_CURRENT_FILEID,
    payload: currentFileId,
  }
}

const updateisShowError = (isShowError: boolean) => {
  return {
    type: IS_SHOW_ERROR,
    payload: isShowError,
  }
}

export {
  updateIsLiveGo,
  updateIsScreen,
  cameraId,
  cameraLabel,
  speakerdeviceId,
  speakerDeviceLabel,
  updateSelectedDeviceId,
  updateSelectedLabel,
  addConnection,
  mirrorCameraOnOff,
  addUserName,
  audioToggleOnOff,
  addnewPeople,
  videoToggleOnOff,
  updateVideoQuality,
  updateIsLive,
  updateIsTimer,
  addPeoples,
  addNumberOfPeople,
  removePeople,
  participantMicStatus,
  updateisInvitePopUp,
  updateisSettingPopUp,
  updateNoiseReduction,
  // Chat modertion
  updateIsAttendeeList,
  updateIsPublicChat,
  updateIsChatPanel,
  updateIsPrivatChat,
  updateCamera,
  participantVideoStatus,
  updateIsScreenOther,
  updateActivePollStatus,
  updatePollsActiveTab,
  updateOffersActiveTab,
  updateActiveOfferStatus,
  showOfferNotificationFunction,
  showPollsNotificationFunction,
  updateIsSideBar,
  updateAttendeeDataForAll,
  muteToggle,
  cameraToggle,
  updateWhoIsSharingScreen,
  screenShareSourceId,
  updateRecord,
  updateConferenceId,
  updateMic,
  updateISScreenSharedByYou,
  updateIsCanvasStream,
  updateIsScreenShareWindowMuted,
  updateCurrentSlide,
  updateCurrentFileId,
  updateisShowError
};
