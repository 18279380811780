import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import ErrorMessage from "../CommonComponents/ErrorMessage";
import { AttendeeRecorder } from "./AttendeeRecorder";

export const Recorder = () => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);

  const [params, setParams] = useSearchParams();
  const [type, setType] = useState(params.get("type"));
  const [stream_name, setStream_name] = useState(params.get("stream_name"));
  const [loader, setLoader] = useState(true);

  //Routes parameter read
  const [streamAccountId, setStreamAccountId] = useState<any>(
    stateData?.publishStreamObj?.accountId
  );
  const [subscriberToken, setsubscriberToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  //end

  useEffect(() => {
    setLoader(false);
  }, []);
  // Backend API call end

  return (
    <>
      {loader && (type != "recorder") &&<Loader />}
      {!loader && !errorMessage && ( 
        <AttendeeRecorder
          streamAccountId={streamAccountId}
          streamName={stream_name}
          subscriberToken={subscriberToken}
          userName={'recorder'}
          type={type}
        />
      )}

      {errorMessage && <ErrorMessage message={errorMessage} />}
    </>
  );
};
