import React, { useEffect } from "react";
import { usePubNub } from "pubnub-react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateIsAttendeeList,
  updateIsChatPanel,
  updateIsPrivatChat,
  updateIsPublicChat,
} from "../../Store/Actions/PublishStreamAction";

export const ChatModeration = () => {
  const pubnub = usePubNub();
  const dispatch = useDispatch();
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const channelMetadata = useSelector(
    (state: any) => state.PublishStreamReducer.publishStreamObj
  );
  const channelName = `chatModeration.${stateData.publishStreamObj.webinarid}.${stateData.publishStreamObj.sessionId}`;

  // const updateChannelMetadata = (newSettings) => {
  //   pubnub.objects
  //     .setChannelMetadata({
  //       channel: channelName,
  //       data: {
  //         custom: newSettings,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("Metadata updated:", response);
  //     })
  //     .catch((error) => {
  //       console.error("Error updating metadata:", error);
  //     });
  // };

  const handleToggleAttendeeList = () => {
    const newValue = !channelMetadata.isAttendeeList;
    dispatch(updateIsAttendeeList(newValue)); // Update Redux state
    // updateChannelMetadata({
    //   // ...channelMetadata,
    //   isAttendeeList: newValue,
    // });
    pubnub
      .publish({
        message: {
          people: newValue,
          PubChat: stateData?.publishStreamObj?.isPublicChat,
          ChatPanel: stateData?.publishStreamObj?.isChatPanel,
        },
        channel: channelName,
      })
      .catch((error) => {
        console.error("Error sending mute signal:", error);
        console.error("Error status:", error.status); // Log the status code or status object
        console.error("Error message:", error.message);
      });
  };

  const handleTogglePublicChat = () => {
    const newValue = !channelMetadata.isPublicChat;
    dispatch(updateIsPublicChat(newValue))
    if (!newValue) {
      dispatch(updateIsPrivatChat(newValue)); // Update Redux stat
      pubnub
        .publish({
          message: {
            PvtChat: newValue,
            PubChat: newValue,
            ChatPanel: stateData?.publishStreamObj?.isChatPanel,
            people: stateData?.publishStreamObj?.isAttendeeList,
          },
          channel: channelName,
        })
        .catch((error) => {
          console.error("Error sending mute signal:", error);
          console.error("Error status:", error.status); // Log the status code or status object
          console.error("Error message:", error.message);
        });
    } else {
      // dispatch(updateIsPublicChat(newValue)); // Update Redux stat
      pubnub
        .publish({
          message: {
            PubChat: newValue,
            PvtChat: stateData?.publishStreamObj?.isPrivateChat,
            ChatPanel: stateData?.publishStreamObj?.isChatPanel,
            people: stateData?.publishStreamObj?.isAttendeeList,
          },
          channel: channelName,
        })
        .catch((error) => {
          console.error("Error sending mute signal:", error);
          console.error("Error status:", error.status); // Log the status code or status object
          console.error("Error message:", error.message);
        });
    }
  };

  const handleToggleChatPanel = () => {
    const newValue = !channelMetadata.isChatPanel;
    dispatch(updateIsChatPanel(newValue)); // Update Redux state
    // updateChannelMetadata({
    //   // ...channelMetadata,
    //   isChatPanel: newValue,
    // });
    pubnub
      .publish({
        message: {
          ChatPanel: newValue,
          people: stateData?.publishStreamObj?.isAttendeeList,
          PubChat: stateData?.publishStreamObj?.isPublicChat,
          PvtChat: stateData?.publishStreamObj?.isPrivateChat,
        },
        channel: channelName,
      })
      .catch((error) => {
        console.error("Error sending mute signal:", error);
        console.error("Error status:", error.status); // Log the status code or status object
        console.error("Error message:", error.message);
      });

      dispatch(updateIsChatPanel(newValue));
  };

  const handleTogglePrivateChat = () => {

    if(channelMetadata?.isPublicChat){
    const newValue = !channelMetadata.isPrivateChat;
    dispatch(updateIsPrivatChat(newValue)); // Update Redux state
    pubnub
      .publish({
        message: {
          PvtChat: newValue,
          people: stateData?.publishStreamObj?.isAttendeeList,
          PubChat: stateData?.publishStreamObj?.isPublicChat,
          ChatPanel: stateData?.publishStreamObj?.isChatPanel,
        },
        channel: channelName,
      })
      .catch((error) => {
        console.error("Error sending mute signal:", error);
        console.error("Error status:", error.status); // Log the status code or status object
        console.error("Error message:", error.message);
      });
    }
  };

  // useEffect(() => {
  //   pubnub.objects.getChannelMetadata({
  //     channel: channelName
  //   }).then((response) => {
  //     console.log('Channel metadata:', response);
  //     if (response.data && response.data.custom) {
  //       dispatch(updateIsAttendeeList(response.data.custom.isAttendeeList || false));
  //       dispatch(updateIsPublicChat(response.data.custom.isPublicChat || false));
  //       dispatch(updateIsChatPanel(response.data.custom.isChatPanel || false));
  //       dispatch(updateIsPrivatChat(response.data.custom.isPrivateChat || false));
  //     }
  //   }).catch((error) => {
  //     console.error('Error getting metadata:', error);
  //   });
  // }, []);
  return (
    <div className="ew-setting-area-section chat-moderation">
      <div className="ew-form-field toggle-field">
        <label htmlFor="dropdown">Attendee List</label>
        <span className="label-sub-heading">
          Turn this on to enable attendee list inside participant's panel
          <div className="toggle-container">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={channelMetadata.isAttendeeList}
                onChange={handleToggleAttendeeList}
              />
              <span className="toggle-slider"></span>
            </label>
          </div>
        </span>
      </div>
      <div className="ew-form-field toggle-field">
        <label htmlFor="dropdown">Chat Panel</label>
        <span className="label-sub-heading">
          Enable or disable the entire chat panel
          <div className="toggle-container">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={channelMetadata.isChatPanel}
                onChange={handleToggleChatPanel}
              />
              <span className="toggle-slider"></span>
            </label>
          </div>
        </span>
      </div>
      {/* <div className="ew-form-field toggle-field" style={{display: 'none'}}> */}
      <div className="ew-form-field toggle-field" style={{ display: channelMetadata.isChatPanel ? 'block' : 'none' }}
 >
        <label htmlFor="dropdown">Public Chat</label>
        <span className="label-sub-heading">
          Enable or disable public chat for attendees
          <div className="toggle-container">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={channelMetadata.isPublicChat}
                onChange={handleTogglePublicChat}
              />
              <span className="toggle-slider"></span>
            </label>
          </div>
        </span>
      </div>

   { channelMetadata?.isPublicChat &&  <div className="ew-form-field toggle-field" style={{ display: channelMetadata.isChatPanel ? 'block' : 'none' }}>
        <label htmlFor="dropdown">Private Chat</label>
        <span className="label-sub-heading">
          Allow participant's to privately tag each other
          <div className="toggle-container">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={channelMetadata.isPrivateChat}
                onChange={()=>handleTogglePrivateChat()}
              />
              <span className="toggle-slider"></span>
            </label>
          </div>
        </span>
      </div>}
    </div>
  );
};
