import { useEffect, useState } from "react";
import emptyPollsIcon from "../../assets/images/empty-polls.svg";
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";
import { usePubNub } from "pubnub-react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  updateActiveOfferStatus,
  updateIsSideBar,
} from "../../Store/Actions/PublishStreamAction";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import NewCountdownTimer from "../CountdownTimer/NewCountdownTimer";
import { lambdaOfferAnsUrl, buildOfferViewUrl, attendeeOfferClickUrl } from "../../config";
import { toast } from "react-toastify";
import closeIcon from "../../assets/images/close-icon.svg";
import emptyOffersIcon from '../../assets/images/empty-offers-icon.svg';

// Types and Interfaces
interface PollOption {
  id: number;
  val: string;
}

interface OfferButton {
  text: string;
  color: string;
  background: string;
  icon: string;
  size: string;
  url: string;
}

interface ActiveOffer {
  offer_id?: string;
  offer_title?: string;
  status?: string;
  countdown_time?: number;
  offer_heading?: string;
  offer_subheading?: string;
  offer_image?: string;
  offer_type?: string;
  // offer_type: number;
  offer_button?: OfferButton;
  countdown_enable?: number;
  offer_start_time?: string;
  offer_end_time?: string;
  is_event?: number;
  offer_desc?: string;
  publishTime?: number;
  content?: any;
  id?: string;
  btn_settings?: any;
  session_id?: any;
  updated?: any;
  type?: any;
  eTag?: any;
}

interface Props {
  attendeeData: any,
  crossIconHandler:()=>void;
}

export const OffersAttendee = ({ attendeeData ,crossIconHandler}: Props) => {

  const dispatch = useDispatch();
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const pubnubobj = usePubNub();
  const [activeOffer, setActiveOffer] = useState(null);
  const [showLoader, setShowLoader] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(null);

  const attendeeOfferClick = async (offer) => {
    try {
        const response = await axios({
            method: "post",
            url: attendeeOfferClickUrl,
            data: {
                webinar_id: stateData.publishStreamObj.webinarid,
                sid: stateData.publishStreamObj.sessionId.toString(),
                offer_id: offer.offer_id,
                attendee_key: attendeeData?.attendee_data?.key
            },
            headers: { "Content-Type": "application/json" }
          });
    }   catch (error) {
        console.error("Failed to update offer analytics:", error);
    }
  };

  const attendeeOfferPublished = async (offerId) => {
    try {
        const response = await axios({
            method: "post",
            url: buildOfferViewUrl,
            data: {
                webinar_id: stateData.publishStreamObj.webinarid,
                sid: stateData.publishStreamObj.sessionId.toString(),
                offer_id: offerId,
                attendee_key: attendeeData?.attendee_data?.key
            },
            headers: { "Content-Type": "application/json" }
          });
    }   catch (error) {
        console.error("Failed to update offer analytics:", error);
    }
  };

  // for timer 
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining((prevTime) => {
          if (prevTime === 0) {
            clearInterval(timerInterval);
            return 0;
          } else {
          
            return prevTime - 1;
          }
        });
      }
    }, 1000);


    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, [timeRemaining]);


  useEffect(() => {
    dispatch(updateActiveOfferStatus(false));
    const globalOfferChannel = "Offers." + stateData.publishStreamObj.webinarid + "." + stateData.publishStreamObj.sessionId;
    pubnubobj.subscribe({ channels: [globalOfferChannel] });

    pubnubobj.addListener({
      message: function (event) {
        const { message } = event;
        if (message['type'] == 'activate_offer') {
          // Fetch offer details using the received offer ID
          fetchOfferDetails('event_captured');
          dispatch(updateActiveOfferStatus(true));
        }
        if (message['type'] == 'deactivate_Offer') {
          setActiveOffer(null);
          dispatch(updateActiveOfferStatus(false));
          // toast.success(`Offer has been deactivated`, {
          //   autoClose: 3000,
          // });
        }
      }
    });

    const fetchOfferDetails = async (type) => {
      setShowLoader(true)
      try {
        const res = await pubnubobj.objects.getChannelMetadata({ channel: globalOfferChannel });
        setShowLoader(false)

        if (res.data && res.data.custom.activeOffer) {
          const activeOfferId = res.data.custom.activeOffer;
          
          let alreadyPublishedOffers = JSON.parse(localStorage.getItem(`already_published_offers_${stateData.publishStreamObj.sessionId}_${attendeeData?.attendee_data?.key}`)) || []
          if (!alreadyPublishedOffers.includes(activeOfferId)) {
            localStorage.setItem(`already_published_offers_${stateData.publishStreamObj.sessionId}_${attendeeData?.attendee_data?.key}`, JSON.stringify([...alreadyPublishedOffers, activeOfferId]))
            attendeeOfferPublished(String(activeOfferId))
          }

          const activeOfferChannel = "Offers." + stateData.publishStreamObj.sessionId + "." + activeOfferId;
          setShowLoader(true)
          const offerRes = await pubnubobj.objects.getChannelMetadata({ channel: activeOfferChannel });
          setShowLoader(false)
          
          if (offerRes.data) {
            const { custom: customData, ...activeOfferData } = offerRes.data;

            if (customData) {
                const {
                    btn_settings,
                    content,
                    offer_desc,
                    offer_id,
                    countdown_time,
                    publishTime,
                    session_id
                } = customData;

                // Merge custom data into activeOfferData
                Object.assign(activeOfferData, {
                    btn_settings,
                    content,
                    offer_desc,
                    offer_id,
                    countdown_time,
                    publishTime,
                    session_id
                });
            }

            console.log("activeOfferData 222>>>>", activeOfferData);
            setActiveOffer(activeOfferData);

            
            
            
            
            
            // let offerDescData: any = null;

            // try {
            //   offerDescData = JSON.parse(String(customData.offer_desc));
            // } catch (error) {
            //   console.error("Failed to parse offer_desc JSON:", error);
            // }

            // if (offerDescData) {
            //   setActiveOffer({
            //     offer_id: String(customData?.offer_id),
            //     offer_title: offerDescData?.offer_title,
            //     offer_type: offerDescData?.offer_type,
            //     status: offerRes?.data?.status,
            //     countdown_time: Number(customData?.countdown_time),
            //     offer_heading: offerDescData?.offer_heading,
            //     offer_subheading: offerDescData?.offer_subheading || '',
            //     // offer_heading: String(offerDescData.offer_heading),
            //     offer_image: String(offerDescData?.offer_image || ''),
            //     // offer_type: Number(JSON.parse(String(offerDescData.offer_type)).is_event),
            //     offer_button: offerDescData?.offer_btn ? {
            //       text: String(offerDescData?.offer_btn?.txt || ""),
            //       color: String(offerDescData?.offer_btn?.color || ""),
            //       background: String(offerDescData?.offer_btn?.bg || ""),
            //       icon: String(offerDescData?.offer_btn?.icon || ""),
            //       size: String(offerDescData?.offer_btn?.size || ""),
            //       url: String(JSON.parse(String(customData?.btn_settings))?.btn_url || ""),
            //     } : {  // If offer_btn is null, set default values
            //       text: "",
            //       color: "",
            //       background: "",
            //       icon: "",
            //       size: "",
            //       url: ""
            //     },
            //     countdown_enable: offerDescData?.countdown_enable === "1" ? 1 : 0,
            //     offer_start_time: offerDescData?.offer_start_time || '',
            //     offer_end_time: offerDescData.offer_end_time || '',
            //     is_event: Number(JSON.parse(String(customData.btn_settings))?.is_event || 0),
            //     offer_desc: String(customData?.offer_desc),
            //     publishTime: Number(customData?.publishTime)
            //   });

            //   setTimeRemaining(activeOffer.countdown_time)
            // } else {
            //   console.error("offer_desc is invalid or failed to parse");
            // }
          } else {
            console.error("offerRes.data is null or undefined");
          }
        }
      } catch (error) {
        console.error("Error fetching poll data:", error);
        setShowLoader(false)
      }
    };

    fetchOfferDetails('initial');
  }, [stateData.publishStreamObj.webinarid, stateData.publishStreamObj.sessionId]);

  const postOfferToLambda = () => {
    try {
      let headers = {
        "content-type": "application/json",
        Authorization: `${attendeeData?.Authorization}`,
        "X-EW-WEBINAR-ID": `${stateData.publishStreamObj.webinarid}`,
        "X-EW-KEY": `${attendeeData?.attendee_data?.key}`,
        "X-EW-TIMESTAMP": `${attendeeData?.anstimestamp}`,
      };

      const webinar_time = attendeeData?.attendee_data?.webinar_time;
      const webinar_date = attendeeData?.attendee_data?.webinar_date;
      let webinarDateTime = new Date(`${webinar_date}T${webinar_time}`);

      let lambdaPayload = {
        action: "store_event_in_infusionsoft",
        attendee_email: attendeeData?.attendee_data?.attendee_email_id,
        attendee_name: attendeeData?.attendee_data?.attendee_name,
        attendee_id: attendeeData?.attendee_data?.registration_id_pk,
        owner_id: attendeeData?.meta?.owner_id,
        session_id: stateData.publishStreamObj.sessionId,
        webinar_id: attendeeData?.webinarid,
        global_session_id: attendeeData?.attendee_data?.global_session_id,
        offer_id: activeOffer.offer_id,
        is_event: activeOffer.is_event,
        offer_desc: activeOffer?.offer_desc,
        is_live: attendeeData?.isLive,
        webinar_date: webinar_date,
        webinar_time: webinar_time,
        attendee_data: attendeeData?.attendee_data
      }

      const res = axios({
        method: "post",
        url: lambdaOfferAnsUrl,
        data: lambdaPayload,
        headers: headers,
      });
    } catch (e) {
      console.error("Error publishing offer results to lambda:", e);
    }
  };

  const submitOffer = (offer, url) => {
    // postOfferToLambda();
    const alreadyClickedOffers = JSON.parse(localStorage.getItem(`offer_analytics_${stateData?.publishStreamObj?.userUUID}`)) || []
    if (!alreadyClickedOffers.includes(offer.offer_id)) {
      localStorage.setItem(`offer_analytics_${stateData.publishStreamObj.userUUID}`, JSON.stringify([...alreadyClickedOffers, offer.offer_id]))
      attendeeOfferClick(offer)
    }
    window.open(url, '_blank');
  }

  // const extractImageSrc = (htmlString) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlString, 'text/html');
  //   const imgElement = doc.querySelector('img');
  //   return imgElement ? imgElement.src : '';
  // };

  function extractHref(content) {
    const hrefMatch = content.match(/href="([^"]*)"/);
    return hrefMatch ? hrefMatch[1] : null;
}

  const extractImageSrc = (htmlString) => {
    // Remove backslashes used for escaping in the string
    const cleanHtmlString = htmlString.replace(/\\+/g, '');
    
    // Parse the cleaned HTML string
    const parser = new DOMParser();
    const doc = parser.parseFromString(cleanHtmlString, 'text/html');
    
    // Get all img elements
    const imgElements = doc.querySelectorAll('img');
    
    // Extract the src attributes
    const imgSrcs = [...imgElements].map(img => img.src);
    
    return imgSrcs.length > 0 ? imgSrcs : [];
  };

  const getCleanedSubheading = (html) => {
    // Create a temporary DOM element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Remove all <img> tags from the parsed content
    const images = tempDiv.querySelectorAll('img');
    images.forEach(img => img.remove());

    // Return the cleaned HTML
    return tempDiv.innerHTML;
};

useEffect(() => {
  // Select all images and buttons in the .offer-subheading container
  const imgElements = document.querySelectorAll('.offer-subheading img, .offer-subheading a, .offer-subheading button ');

  // Function to extract href from an anchor tag or return null
  const extractHrefImg = (content) => {
    const hrefMatch = content.match(/href="([^"]*)"/);
    return hrefMatch ? hrefMatch[1] : null;
  };

  const handleClick = (event) => {
    console.log("event", event);
    event.preventDefault(); // Prevent default behavior

    const target = event.target; // The clicked element

    // If it's an anchor (<a>) tag, handle the URL
    if (target.tagName === 'A') {
      const anchorHref = target.getAttribute('href'); // Get the href of the anchor tag
      if (anchorHref) {
        // You can modify this part as per your requirement, like opening the URL or passing to a function
        console.log("Anchor clicked with href:", anchorHref);
        submitOffer(activeOffer, anchorHref);
        // window.open(anchorHref, '_blank'); // Opens the URL in a new tab (or you can handle it differently)
      }else{

      }
    }
    // If it's an image (<img>) tag, fallback to activeOffer URL
    else if (target.tagName === 'IMG') {
      // Check if an anchor tag is associated with the image
      const anchor = target.closest('a'); // Find the closest anchor element, if any
      const anchorHref = anchor ? anchor.getAttribute('href') : null;

      if (anchorHref) {
        // If an anchor exists, use its href (it can be a fallback)
        console.log("Image clicked with anchor href:", anchorHref);
        submitOffer(activeOffer, anchorHref);
        // window.open(anchorHref, '_blank'); // Open the anchor's href if available
      } else {
        // If no anchor, fall back to activeOffer URL
        const anchorHref = extractHrefImg(activeOffer?.content);
        const fallbackUrl = activeOffer?.offer_link || 'https://defaulturl.com'; // You can replace the default URL
        submitOffer(activeOffer, anchorHref);
        // console.log("Image clicked, using fallback URL:", fallbackUrl);
        // window.open(anchorHref, '_blank'); // Open the fallback URL
      }
    }
    else if (target.tagName === 'SPAN') {
      // Check if an anchor tag is associated with the image
      const anchor = target.closest('a'); // Find the closest anchor element, if any
      const anchorHref = anchor ? anchor.getAttribute('href') : null;

      if (anchorHref) {
        // If an anchor exists, use its href (it can be a fallback)
        console.log("Image clicked with anchor href:", anchorHref);
        // window.open(anchorHref, '_blank'); // Open the anchor's href if available

        submitOffer(activeOffer, anchorHref);
      } else {
        // If no anchor, fall back to activeOffer URL
        const anchorHref = extractHrefImg(activeOffer?.content);
        const fallbackUrl = activeOffer?.offer_link || 'https://defaulturl.com'; // You can replace the default URL
        // console.log("Image clicked, using fallback URL:", fallbackUrl);
        submitOffer(activeOffer, anchorHref);
        // window.open(anchorHref, '_blank'); // Open the fallback URL
      }
    }
    else if (target.tagName === 'BUTTON') {
      // Check if an anchor tag is associated with the image
      const anchor = target.closest('a'); // Find the closest anchor element, if any
      const anchorHref = anchor ? anchor.getAttribute('href') : null;

      if (anchorHref) {
        // If an anchor exists, use its href (it can be a fallback)
        console.log("Image clicked with anchor href:", anchorHref);
        submitOffer(activeOffer, anchorHref);
        // window.open(anchorHref, '_blank'); // Open the anchor's href if available
      } else {
        // If no anchor, fall back to activeOffer URL
        const anchorHref = extractHrefImg(activeOffer?.content);
        const fallbackUrl = activeOffer?.offer_link || 'https://defaulturl.com'; // You can replace the default URL
        submitOffer(activeOffer, anchorHref);
        // console.log("Image clicked, using fallback URL:", fallbackUrl);
        // window.open(anchorHref, '_blank'); // Open the fallback URL
      }
    }
  };

  // Attach click event listeners
  imgElements.forEach((element) => {
    // Attach listener if it's not already attached
    // if (!element.hasAttribute('data-click-listener')) {
      element.addEventListener('click', handleClick);
      element.setAttribute('data-click-listener', 'true'); // Mark as attached
    // }
  });

  // Cleanup the event listeners on unmount
  return () => {
    imgElements.forEach((element) => {
      element.removeEventListener('click', handleClick);
    });
  };
}, [activeOffer?.content]);

const webinarVersion = stateData.publishStreamObj.webinar_version;

  return (
    <div className="Polls-area">
      <div className="sidebar-header">
        <a href="#" onClick={() => {
          dispatch(updateIsSideBar(false))
          crossIconHandler()
        }}>
          <img src={closeIcon} alt="" />
        </a>
        Offers
      </div>
      <div className="polls-content-area">
        <div className="polls-inner-container thin-scrollbar">
          {showLoader ? <div className="flex flex-center loader-bg">
            <div className='flex flex-center section-loader'>
              <img src={blueCircleLoader} alt="" />
              <span>Loading...</span>
            </div>
          </div> : activeOffer ? (
             <div className='offers-inner-container thin-scrollbar'>
              <h4 className="tabs-inner-heading">Active Offers</h4>
              <div className="offers-info-main">
                <div className="offer-preview">
                  <h3 dangerouslySetInnerHTML={{ __html: JSON.parse(activeOffer.offer_desc).offer_heading }}></h3>
                    {(() => {
                      try {
                        const offerDesc = JSON.parse(activeOffer.offer_desc);
                        const cleanedOfferSubheading = getCleanedSubheading(offerDesc?.offer_subheading?offerDesc.offer_subheading:" ");
                        const subHeadingContent = activeOffer.content
                        .replace(/\\\"/g, '"')
                        .replace(/<div class="common-part[^>]*>.*?<\/div>/g, '')
                        .replace(/<div[^>]*class="[^"]*common-hours[^"]*"[^>]*>[\s\S]*?<\/div>/g, '')
                        .replace(/<img/g, '<img data-type="offer-image"')
                        .replace(/<p/g, '<p data-type="offer-button"')
                        .replace(/<a(?!\s+[^>]*target=)/g, '<a target="_blank"');  // Add target="_blank" to <a> tags if not already set
                      
                        return cleanedOfferSubheading ? (
                          (<>
                            {webinarVersion === 0 ? <div
                              className="offer-subheading"
                              dangerouslySetInnerHTML={{ __html: subHeadingContent }}
                            ></div> :
                              <h4 dangerouslySetInnerHTML={{ __html: cleanedOfferSubheading }} />}</>)
                        ) : null;
                      } catch (error) {
                        return <h4>Error loading offer subheading.</h4>;
                      }
                    })()}
                  {/* {extractImageSrc(activeOffer.content) && <img src={extractImageSrc(activeOffer.content)[0]} alt={activeOffer.offer_id} style={{ width: "100%", marginBottom: "12px", cursor: timeRemaining !== 0 ? "pointer" : "not-allowed" }}
                    // onClick={() => window.open(JSON.parse(activeOffer.content).href, '_blank')}
                    onClick={() => {
                      if (timeRemaining !== 0) {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(activeOffer.content, 'text/html');
                        const anchor = doc.querySelector('a');
                        submitOffer(activeOffer, anchor.href);
                      }
                    }}
                  />} */}

                    {webinarVersion !== 0 &&  extractImageSrc(activeOffer.content).map((imgSrc, index) => (
                      <img
                        key={index}
                        src={imgSrc}
                        alt={`Offer ${activeOffer.id}`}
                        style={{ width: "100%", marginBottom: "12px", cursor: "pointer" }}
                        onClick={() => {
                          if (timeRemaining !== 0) {
                            // this code is commented because code is causing issue in the offer image click

                            // const parser = new DOMParser();
                            // const doc = parser.parseFromString(activeOffer.content, 'text/html');
                            // const anchor = doc.querySelector('a');
                            const anchorHref = extractHref(activeOffer?.content);
                            submitOffer(activeOffer,anchorHref);
                          
                          }
                        }}
                      />
                    ))}
                  
                  {/* {activeOffer.countdown_enable === 1 ? (
                    <div className="countdown-timer-offer">
                      <CountdownTimer countdownTime={activeOffer.countdown_time} removeOffer={() => { }} publishTime={activeOffer.publishTime}/>
                    </div>
                  ) : null} */}

                  {activeOffer && activeOffer.countdown_time && activeOffer.countdown_time > 0 ? <div className="countdown-timer-offer">
                    <NewCountdownTimer expiryTimestamp={Math.floor((activeOffer.publishTime / 1000) + activeOffer.countdown_time)} closeOfferFunction={() => {}} />
                  </div> : null}

                  {webinarVersion !== 0 && !extractImageSrc(activeOffer.content)[0] && <button type='button' className={`flex flex-center widget-action-registration medium-btn-offer ${JSON.parse(activeOffer.offer_desc).size}`}
                    style={{
                        marginBottom: "12px",
                        backgroundColor: JSON.parse(activeOffer.btn_settings)?.btn_bgcolor,
                        color: JSON.parse(activeOffer.btn_settings)?.btn_color
                    }} onClick={() => submitOffer(activeOffer, JSON.parse(activeOffer.btn_settings)?.btn_url)}>
                    <span className='material-icons' dangerouslySetInnerHTML={{ __html: JSON.parse(activeOffer.btn_settings)?.btn_icon }}></span>
                    <span>{JSON.parse(activeOffer.btn_settings)?.btn_txt}</span>
                  </button>}

                  {/* <button
                    type='button'
                    className={`flex flex-center widget-action-registration ${activeOffer.offer_button.size}`}
                    style={{
                      color: activeOffer.offer_button.color,
                      backgroundColor: activeOffer.offer_button.background
                    }}
                    disabled={timeRemaining === 0}
                    onClick={() => submitOffer(activeOffer)}
                  >
                    <span className='material-icons'>{activeOffer.offer_button.icon}</span>
                    <span>{activeOffer.offer_button.text}</span>
                  </button> */}
                </div>
              </div>
            </div>) : (
            <div className="empty-area-msg flex-center">
              <img src={emptyOffersIcon} alt="No Active Offer" />
              <h4>Offers</h4>
              <p>You’ve no active offers right now.</p>
            </div>
          )}
        </div>
      </div>
    </div >
  );
};

