
import '../../../assets/styles/main.scss';

export const LocalFileShareNotAllowed = ({ isConfirmationOpen, onConfirmationClose }) => {
    
    return (

        <div className={`modal confirmation-popup ${isConfirmationOpen ? 'active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-container with-header">
                <div className="modal-header">
                    <h3 className='flex'>Not Allowed</h3>
                    <span className="close-btn" onClick={onConfirmationClose}></span>
                </div>
                <div className="modal-content flex">
                    <div className="confirmation-info">
                        <p>Stream Local File Is Not Allowed Before Going Live</p>
                    </div>
                </div>
                <div className="modal-footer flex">
                    <div className="flex">
                        {/* <button type="button" className="default-btn" onClick={onConfirmationClose}>Cancel</button> */}
                        <button type="button" className="warning-button " onClick={onConfirmationClose}>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
