import React, { useState } from 'react';
import '../../assets/styles/main.scss';

export const ConfirmationPopupAttendee = ({ isConfirmationOpen, onCloseConfirmationPopUp,SessionHandle ,isLiveRoom}) => {

    if(!isConfirmationOpen){
        return
    }


    
    return (

        <div className={`modal confirmation-popup ${isConfirmationOpen ? 'active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-container with-header">
                <div className="modal-header">
                    <h3 className='flex'>{isLiveRoom?"Leaving So Soon?":"Leave Green Room?"}</h3>
                    <span className="close-btn" onClick={onCloseConfirmationPopUp}>&times;</span>
                </div>
                <div className="modal-content flex">
                    <div className="confirmation-info">
                        {isLiveRoom? <p> The session will stay active, and you can rejoin whenever you're ready. Do you still want to leave?</p>
                        :
                        <p>
                            Are you sure you want to leave the Green Room? The Green Room will remain active, and you can rejoin anytime.
                        </p>}
                   
                        
                    </div>
                </div>
                <div className="modal-footer flex">
                    <div className="flex">
                        <button type="button" className="default-btn" onClick={onCloseConfirmationPopUp}>{isLiveRoom?"No, Stay Here":"Cancel"}</button>
                        <button type="button" className="warning-button" onClick={SessionHandle}>{isLiveRoom?"Yes, Leave Session":"Leave Green Room"}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
