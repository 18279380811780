import React, { useState } from 'react';
import '../../assets/styles/main.scss';
import accessWarning from '../../assets/images/access-warning.png';
import inUseIcon from '../../assets/images/in-use-icon.svg';
import permissionIcon from '../../assets/images/permission-icon.svg';
import updateIcon from '../../assets/images/not-updated-icon.svg';
import restartIcon from '../../assets/images/restart-icon.svg';
import { useLocation } from 'react-router-dom';

export const AccessibilityPopup = ({ showPopup, setShowPopup,status='status' }) => {
    const location = useLocation();
    const isModerator = location.pathname.includes('/moderator');
    const isAttendee = location.pathname.includes('/attendee');
    const recorder = location.pathname.includes('/recorder');

    if(isModerator || isAttendee || recorder){
        return
    }
    
    return (

        <div className={`modal accessibility-popup ${showPopup ? 'active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-container">
                <div className="modal-content flex">
                    <div className="accessibility-heading">
                        <span className="close-btn" onClick={()=>{
                            if(status === 'setup'){
                                window.location.reload()
                            }
                            
                           
                            setShowPopup(false)}}>&times;</span>
                        <h3>Oops! We can’t seem to access your camera and microphone.</h3>
                    </div>
                    <div className="flex accessibility-info">
                        <div className="img-content">
                            <img src={accessWarning} alt="" />
                        </div>
                        <div className="accessibility-content">
                            <ul>
                                <li>
                                    <span className='icon'>
                                        <img src={inUseIcon} alt="" />
                                    </span>
                                    <h4>Already in use
                                        <span>Your camera or microphone is currently in use by another application (Zoom/Hangouts etc.) Please close it to proceed.</span>
                                    </h4>
                                </li>
                                <li>
                                    <span className='icon'>
                                        <img src={permissionIcon} alt="" />
                                    </span>
                                    <h4>Browser Permissions
                                        <span>Oops! Your browser needs access to your camera and microphone. Update your settings to get started.</span>
                                    </h4>
                                </li>
                                <li>
                                    <span className='icon'>
                                        <img src={updateIcon} alt="" />
                                    </span>
                                    <h4>Browser not upto date
                                        <span> Uh-oh! Your browser's a bit outdated. Update it now to keep things running smoothly.</span>
                                    </h4>
                                </li>
                                <li>
                                    <span className='icon'>
                                        <img src={restartIcon} alt="" />
                                    </span>
                                    <h4>Restart Required
                                        <span>Consider giving your computer a quick restart to refresh your microphone and camera access.</span>
                                    </h4>
                                </li>
                            </ul>

                            <div className="access-action flex">
                                <button type="button" className="primary-button" onClick={()=>window.location.reload()} >Retry</button>
                                {/* <button type="button" className="default-btn">Settings</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
