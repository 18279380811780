import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setAuthToken } from "../../libs/APIs/auth";
import { postRequest } from "../../libs/APIs/apiCall";
import { Attendee } from "./Attendee";
import Loader from "../Loader/Loader";
import { updateAttendeeDataForAll } from "../../Store/Actions/PublishStreamAction";
import { attendeeJoinUrl, leaveAttendeeUrl } from "../../config";
import ErrorMessage from "../CommonComponents/ErrorMessage";
import store from "../../Store/Store";
import AttendeeLimitExceeded from "./AttendeeLimitExceeded";

export const AttendeeMain = () => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();

  const [params, setParams] = useSearchParams();
  const [email, setemail] = useState(params.get("email"));
  const [attendee, setattendee] = useState(params.get("attendee"));
  const [type, setType] = useState(params.get("type"));
  const [loader, setLoader] = useState(true);

  //Routes parameter read
  const [userName, setUserName] = useState<any>("");
  const [streamName, setstreamName] = useState<any>("");
  const [streamAccountId, setStreamAccountId] = useState<any>(
    stateData?.publishStreamObj?.accountId
  );
  const [subscriberToken, setsubscriberToken] = useState("");
  const [pubnub_access_token, setpubnub_access_token] = useState("");
  const [attendeeData, setAttendeeData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
 const [webinarVersion,setWebinarVersion]=useState<number>(-1)
 const [isAttendeeLimitExceed, setIsAttendeeLimitExceed] = useState(false);
  //end

  useEffect(() => {
    (async () => {
      const ew_jwt_token = localStorage.getItem("ew_jwt_token");
      setAuthToken(ew_jwt_token);
      try {
        const res = 
        await postRequest(attendeeJoinUrl, {
          attendee: attendee,
          email: email,
        });

        if (res?.status == "error") {
          setErrorMessage(res.message);
          setLoader(false);
          return;
        }
        setWebinarVersion(res?.data?.webinar_version)
        
        setUserName(res.data.name);
        setstreamName(res.data.stream[0].streamName);
        setsubscriberToken(res.data.subscription_token);
        setpubnub_access_token(res.data.pubnub_accessToken);
        setAttendeeData(res?.data);
        localStorage.setItem(`url_after_session_${res.data.session_id}_${res.data.webinar_id}`, res.data.event_redirect_url)
        dispatch({
          type: "ADD_USERNAME",
          payload: {
            ...stateData?.publishStreamObj,
            userName: res.data.name,
            userEmail: res.data.email,
            subscribeToken: res.data.subscription_token,
            streamName: res.data.stream[0].streamName,
            webinarid: res?.data?.webinar_id,
            sessionId: res?.data?.session_id,
            userUUID: res?.data?.pn_user_id,
            isAttendee: true,
            // attendeeKey: res.data.attendee_data?.key,
            // isModerator: true,
            event_name: res.data?.webinar_details?.event_name,
            webinar_version: res.data?.webinar_version,
          },
        });

        dispatch(updateAttendeeDataForAll(res.data.attendee_data?.key));
        setLoader(false);
      } catch (error) {
        setErrorMessage(error.message);
        setLoader(false);
      }
    })();
  }, []);
  // Backend API call end



 if(isAttendeeLimitExceed){
  return <AttendeeLimitExceeded />

 }

  return (
    <>
      {loader && (type != "recorder") &&<Loader />}
      {!loader && !errorMessage && ( 
        <Attendee
          attendeeData={attendeeData}
          streamAccountId={streamAccountId}
          streamName={streamName}
          subscriberToken={subscriberToken}
          userName={userName}
          type={type}
          pubnub_accessToken={pubnub_access_token}
          webinarVersion={webinarVersion}
          setIsAttendeeLimitExceed={setIsAttendeeLimitExceed}
        />
      )}

      {errorMessage && <ErrorMessage message={errorMessage} />}
    </>
  );
};
