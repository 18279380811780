import axios from "axios";

export async function postRequest(url: string, body = {}) {
  try {
    const res = await axios.post(url, body);
    return await res.data;
  } catch (error) {
    if (error.response) {
      // Server responded with a status outside the 2xx range
      const { status, data } = error.response;
      throw new Error(
        `Error ${status}: ${data.message || "Something went wrong"}`
      );
    } else if (error.request) {
      // Request was made, but no response was received
      throw new Error(
        "No response from the server. Please check your network."
      );
    } else {
      // Other errors (e.g., config issues)
      throw new Error(`Request error: ${error.message}`);
    }
  }
}

export async function postRequestWithFormdata(url: string, form) {
  try {
    const res = await axios({
      method: "post",
      url: url,
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return await res;
  } catch (e) {
    console.log(e);
  }  
}

export async function postRequestWithAuth(url: string, body = {}) {
  try {
    const res = await axios.post(url, body, {
      headers: {
        Authorization: 'BearerToken ' + localStorage.getItem("ew_jwt_token")
      }
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      // Server responded with a status outside the 2xx range
      const { status, data } = error.response;
      throw new Error(
        `Error ${status}: ${data.message || "Something went wrong"}`
      );
    } else if (error.request) {
      // Request was made, but no response was received
      throw new Error(
        "No response from the server. Please check your network."
      );
    } else {
      // Other errors (e.g., config issues)
      throw new Error(`Request error: ${error.message}`);
    }
  }
}

export async function getRequestWithAuth(url: string) {
  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: 'BearerToken ' + localStorage.getItem("ew_jwt_token")
      }
    });
    return res.data;
  } catch (e) {
    console.log(e);
  }
}
export async function getRequestWithAuthToken(url: string) {
  try {
    const token = localStorage.getItem("ew_jwt_token");
    const res = await axios.get(url, {
      headers: {
        Authorization: token
      }
    });
    return res.data;
  } catch (e) {
    console.log(e);
  }
}

