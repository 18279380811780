import React, { useState } from 'react';
import '../../assets/styles/main.scss';
import { useSelector } from 'react-redux';

export const ConfimationPopupToPresenterToJoinAsAttendee= ({isConfirmationOpen, onCloseConfirmationPopUp}) => {
    const stateData = useSelector((state: any) => state.PublishStreamReducer);
     let attendeeUrl=localStorage.getItem(`urlForAttendee-${stateData?.publishStreamObj?.userUUID}`)
     console.log("attend")
   
    // let attendeeUrl= sessionStorage.getItem("urlForAttendee")
    console.log("isConfirmationOpen",isConfirmationOpen)
console.log("called pop up")
    
  
    return (

        <div className={`modal confirmation-popup ${isConfirmationOpen ? 'active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-container with-header">
                <div className="modal-header">
                    <h3 className='flex'>Join as Attendee</h3>
                    {/* <span className="close-btn" onClick={onCloseConfirmationPopUp}>&times;</span> */}
                </div>
                <div className="modal-content flex">
                    <div className="confirmation-info">
                        <p>Admin is inviting you to join as Attendee.</p>
                    </div>
                </div>
                <div className="modal-footer flex">
                    <div className="flex">
                        {/* <button type="button" className="default-btn" onClick={onCloseConfirmationPopUp}>Cancel</button> */}
                        <button type="button" className="warning-button" onClick={()=>  window.location.href = attendeeUrl?.toString()}>Join As Attendee</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
