import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setAuthToken } from "../../libs/APIs/auth";
import { postRequest } from "../../libs/APIs/apiCall";
import { Attendee } from "../Attendee/Attendee";
import Loader from "../Loader/Loader";
import { moderatorJoinUrl } from "../../config";
import ErrorMessage from "../CommonComponents/ErrorMessage";

export const Moderator = () => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [params, setParams] = useSearchParams();
  const [mname, setmname] = useState(params.get("mname"));
  const [session_id, setSessionId] = useState(params.get("sid"));
  const [key, setkey] = useState(params.get("key"));
  const [loader, setLoader] = useState(true);

  //Routes parameter read
  const [userName, setUserName] = useState<any>("");
  const [streamName, setstreamName] = useState<any>("");
  const [streamAccountId, setStreamAccountId] = useState<any>(
    stateData?.publishStreamObj?.accountId
  );
  const [subscriberToken, setsubscriberToken] = useState("");
  const [pubnub_access_token, setpubnub_access_token] = useState("");
  //end

  // Backend API call start
  useEffect(() => {
    (async () => {
      const ew_jwt_token = localStorage.getItem("ew_jwt_token");
      setAuthToken(ew_jwt_token);
      try {
        const res = await postRequest(
          moderatorJoinUrl + key + "&mname=" + mname + "&sid=" + session_id
        );

        dispatch({
          type: "ADD_USERNAME",
          payload: {
            ...stateData?.publishStreamObj,
            userName: res.data.name,
            userEmail: res?.data?.email,
            subscribeToken: res.data.subscription_token,
            streamName: res.data.stream[0].streamName,
            webinarid: res?.data?.webinar_id,
            sessionId: res?.data?.session_id,
            userUUID: res?.data?.pn_user_id,
            isModerator: true,
            event_name: res.data?.webinar_details?.event_name,
            userKey: res.data.user_key,
            webinar_version: res.data?.webinar_version,
            presentorLink:res.data.presenter_link,
            attendeeLink: res.data.attendee_link
          },
        });
        if (res?.status == "error") {
          setErrorMessage(res.message);
          setLoader(false);
          return;
        }
        setUserName(res.data.name);
        setstreamName(res.data.stream[0].streamName);
        setsubscriberToken(res.data.subscription_token);
        setpubnub_access_token(res.data.pubnub_accessToken);
        setLoader(false);
      } catch (error) {
        setErrorMessage(error.message);
        setLoader(false);
      }
    })();
  }, []);
  // Backend API call end

  return (
    <>
      {loader && <Loader />}
      {!loader && !errorMessage && (
        <Attendee
          streamAccountId={streamAccountId}
          streamName={streamName}
          subscriberToken={subscriberToken}
          userName={userName}
          type={""}
          pubnub_accessToken={pubnub_access_token}
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </>
  );
};
