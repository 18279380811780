import { useEffect, useState } from 'react';
import emptyPollsIcon from '../../assets/images/empty-polls.svg';
import deleteIcon from '../../assets/images/delete-icon.svg';
import editIcon from '../../assets/images/edit-icon.svg';
import offerIcon from '../../assets/images/offers-img.png';
import CountdownTimer from '../CountdownTimer/CountdownTimer';
import NewCountdownTimer from '../CountdownTimer/NewCountdownTimer'
import closeIcon from '../../assets/images/close-icon.svg';
import { updateIsSideBar, updateActiveOfferStatus, updateOffersActiveTab } from '../../Store/Actions/PublishStreamAction';
import { useDispatch, useSelector } from 'react-redux';
import { usePubNub } from 'pubnub-react';
import { toast } from 'react-toastify';
import { offerStatus } from "./offer-enum";
import backIcon from '../../assets/images/back-icon.svg';
import blueCircleLoader from "../../assets/images/blue-circle-loader.gif";
import buttonLoader from '../../assets/images/button-loader.gif';
import emptyOffersIcon from '../../assets/images/empty-offers-icon.svg';
import { offerAnalyticsUrl } from "../../config";

import axios from "axios";

export const Offers = ({ offersList,index , crossIconHandler}) => {

    const dispatch = useDispatch();
    const pubnubobj = usePubNub();
    const stateData = useSelector((state: any) => state.PublishStreamReducer);
    const isAdmin = stateData.publishStreamObj.isAdmin;
    const isModerator = stateData.publishStreamObj.isModerator;
    const {isNewOfferPublished, offersActiveTab} = stateData.publishStreamObj;

    const [activeOffer, setActiveOffer] = useState(null)
    const [showLoader, setShowLoader] = useState(false)
    const [activeTab, setActiveTab] = useState(1);
    const [offersData, setOffersData] = useState([]);
    const [publishingOfferId, setPublishingOfferId] = useState(null);

    const [isOfferPublisher, setIsOfferPublisher] = useState(false)

    const offerIds = [];

    const updateOfferAnalytics = async (offerId, offerAction) => {
        try {
            const response = await axios({
                method: "post",
                url: offerAnalyticsUrl,
                data: {
                    webinar_id: stateData.publishStreamObj.webinarid,
                    sid: stateData.publishStreamObj.sessionId.toString(),
                    offer_id: offerId,
                    offer_action: offerAction // 1 for publish offer, 0 for remove offer
                },
                headers: { "Content-Type": "application/json" }
              });

        }   catch (error) {
            console.error("Failed to update offer analytics:", error);
        }
    };

    const initialFetchData = () => {
        setShowLoader(true)
        dispatch(updateActiveOfferStatus(false));
        setOffersData([])
        pubnubobj.objects
            .getChannelMetadata({
                channel:
                    "Offers" +
                    "." +
                    stateData.publishStreamObj.webinarid +
                    "." +
                    stateData.publishStreamObj.sessionId,
            })
            .then((res) => {
                if (res.data) {
                    let assignedOffers: string = res.data.custom.assignedOffer + "";
                    if (assignedOffers !== undefined) {
                        assignedOffers = JSON.parse(assignedOffers) || [];
                        for (let i = 0; i < assignedOffers.length; i++) {
                            offerIds.push(Number(assignedOffers[i]));
                        }
                    }
                    const transformOfferData = (data) => ({
                        id: data.custom.offer_id,
                        status: data.status,
                        description: data.description,
                        title: data.name,
                        content: data.custom.content,
                        offer_desc: data.custom.offer_desc,
                        btn_settings: data.custom.btn_settings,
                        countdown_time: data.custom.countdown_time,
                        publishTime: data.custom.publishTime,
                    });

                    // Function to fetch and update offers data
                    const fetchAndUpdateOffersData = async (offerIds, pubnubobj, stateData) => {
                        try {
                            // Construct the list of promises for fetching offer data
                            const offerPromises = offerIds.map((offerId) =>
                                pubnubobj.objects.getChannelMetadata({
                                    channel: `Offers.${stateData.publishStreamObj.sessionId}.${offerId}`,
                                })
                            );

                            // Resolve all promises
                            const responses = await Promise.all(offerPromises); // not receiving correct data

                            // Transform and update state with all offer data
                            setOffersData((prevOffersData) => [
                                // ...prevOffersData, // commented to resolve duplicacy
                                ...responses.map((res) => transformOfferData(res.data)),
                            ]);

                            // Hide the loader after data is fetched and state is updated
                            setShowLoader(false);
                        } catch (error) {
                            console.error("Error fetching offers data:", error);
                            // Handle error case if needed
                            setShowLoader(false);
                        }
                    };

                    // Usage example
                    fetchAndUpdateOffersData(offerIds, pubnubobj, stateData);
                }
            });
    }

    useEffect(() => {
        if(index==2){

        initialFetchData()
        }
    }, [index])

    useEffect(() => {
        if (isNewOfferPublished){
            initialFetchData()
        }
        setIsOfferPublisher(false)
    }, [isNewOfferPublished])

    useEffect(() => {
        let activeOffer: any = offersData.filter(offer => offer.status === offerStatus.open).filter((obj1, i, arr) =>
            arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
        )
        setActiveOffer(activeOffer[0])
    }, [offersData])

    const handleTabClick = (tabIndex: number) => {
        // setActiveTab(tabIndex);
        dispatch(updateOffersActiveTab(tabIndex))
    };

    const publishOffers = (offer) => {
        setShowLoader(true)
        setPublishingOfferId(offer.id);
        /**
         * updating main channel metadata for active offer update
         */
        let globalOfferChannel =
            "Offers" +
            "." +
            stateData.publishStreamObj.webinarid +
            "." +
            stateData.publishStreamObj.sessionId;

        
        pubnubobj.objects
            .getChannelMetadata({
                channel: globalOfferChannel,
            })
            .then((response) => {
                // setShowLoader(false)
                let activeOffer = response.data.custom.activeOffer;
                if (activeOffer) {
                    toast.error(`Only one offer can be active at once`, {
                        autoClose: 3000,
                    });
                    setShowLoader(false)
                } else {
                    let offerIds = response.data.custom.assignedOffer;
                    // setShowLoader(true)
                    pubnubobj.objects
                        .setChannelMetadata({
                            channel: globalOfferChannel,
                            data: {
                                custom: {
                                    assignedOffer: offerIds,
                                    activeOffer: offer.id,
                                },
                            },
                        })
                        .then((res) => {
                            const updateOfferStatus = (offersData, updatedOffer) =>
                                offersData.map((offerItem) =>
                                    offerItem.id === updatedOffer.id
                                        ? {
                                            ...offerItem, status: offerStatus.open,
                                            publishTime: Date.now()
                                        }
                                        : offerItem
                                );
                            const updatedOffersData = updateOfferStatus(offersData, offer);
                            const activatedOffer = updatedOffersData.filter(offer => offer.status === offerStatus.open)
                            setActiveOffer(activatedOffer[0])
                           

                            setIsOfferPublisher(true)

                            setOffersData(updatedOffersData);

                            // setShowLoader(false)

                            pubnubobj.publish({
                                channel: globalOfferChannel,
                                message: { offerId: offer.id, type: "activate_offer" },
                            });

                            updateOfferAnalytics(offer.id, 1)

                            setPublishingOfferId(null);
                            // toast.success(`Offer has been activated`, {
                            //     autoClose: 3000,
                            // });
                            // useDispatchupdateActiveOfferStatus

                            // setActiveTab(1);
                            dispatch(updateOffersActiveTab(1))

                            pubnubobj.objects
                                .getChannelMetadata({
                                    channel: `Offers.${stateData.publishStreamObj.sessionId}.${offer.id}`,
                                })
                                .then((response) => {
                                    // setShowLoader(false)
                                    pubnubobj.objects
                                        .setChannelMetadata({
                                            channel: `Offers.${stateData.publishStreamObj.sessionId}.${offer.id}`,
                                            data: {
                                                status: offerStatus.open,
                                                custom: {
                                                    ...response.data.custom,
                                                    publishTime: Date.now()
                                                }
                                            },
                                        })
                                        .then((response) => {
                                            setShowLoader(false)
                                        })
                                        .catch((error) => {
                                            setShowLoader(false)
                                            console.log("channel data get Error:", error);
                                        });
                                })
                                .catch((error) => {
                                    setShowLoader(false)
                                    console.log("channel data get Error:", error);
                                });
                        })
                        .catch((err) => {
                            setShowLoader(false)
                            console.log("Error updating channel data ::: ", err);
                        });
                }
            })
            .catch((error) => {
                setShowLoader(false)
                console.error("Error publishing offer:", error);
            });
    };

    const removeOffer = () => {
        dispatch(updateOffersActiveTab(2))
        setShowLoader(true)

        pubnubobj.objects
            .setChannelMetadata({
                channel: `Offers.${stateData.publishStreamObj.sessionId}.${activeOffer.id}`,
                data: {
                    status: offerStatus.saved,
                },
            })
            .then((response) => {
                // setShowLoader(false)
                let globalOfferChannel =
                    "Offers" +
                    "." +
                    stateData.publishStreamObj.webinarid +
                    "." +
                    stateData.publishStreamObj.sessionId;

                // setShowLoader(true)

                pubnubobj.objects
                    .getChannelMetadata({
                        channel: globalOfferChannel,
                    })
                    .then((response) => {
                        // setShowLoader(false)
                        let offerIds = response.data.custom.assignedOffer;

                        pubnubobj.objects
                            .setChannelMetadata({
                                channel: globalOfferChannel,
                                data: {
                                    custom: {
                                        assignedOffer: offerIds,
                                        activeOffer: "",
                                    },
                                },
                            })
                            .then((res) => {
                                const updatedPollsData = offersData.map((offerItem) =>
                                    offerItem.id === activeOffer.id
                                        ? { ...offerItem, status: offerStatus.saved }
                                        : offerItem
                                );


                                setOffersData(updatedPollsData);
                                setShowLoader(false);
                                setIsOfferPublisher(true)
                                // setActiveTab(2)

                                updateOfferAnalytics(activeOffer.id, 0)

                                pubnubobj.publish({
                                    channel: globalOfferChannel,
                                    message: { offerId: activeOffer.id, type: "deactivate_Offer" },
                                });

                                // toast.success(`Offer has been deactivated`, {
                                //     autoClose: 3000,
                                // });

                            })
                            .catch((err) => {
                                console.log("Error updating channel data ::: ", err);
                                setShowLoader(false)
                            });
                    })
                    .catch((error) => {
                        console.log("channel data get Error:", error);
                        setShowLoader(false)
                    });
            })
            .catch((error) => {
                console.error("Error publishing poll:", error);
                setShowLoader(false)
            });
    }

    // const extractImageSrc = (htmlString) => {
    //     const parser = new DOMParser();
    //     const doc = parser.parseFromString(htmlString, 'text/html');
    //     const imgElement = doc.querySelector('img');
    //     return imgElement ? imgElement.src : '';
    // };

    const extractImageSrc = (htmlString) => {
        // Remove backslashes used for escaping in the string
        const cleanHtmlString = htmlString.replace(/\\+/g, '');

        // Parse the cleaned HTML string
        const parser = new DOMParser();
        const doc = parser.parseFromString(cleanHtmlString, 'text/html');

        // Get all img elements
        const imgElements = doc.querySelectorAll('img');

        // Extract the src attributes
        const imgSrcs = [...imgElements].map(img => img.src);

        return imgSrcs.length > 0 ? imgSrcs : [];
    };

    const getCleanedSubheading = (html) => {
        // Create a temporary DOM element to parse the HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        // Remove all <img> tags from the parsed content
        const images = tempDiv.querySelectorAll('img');
        images.forEach(img => img.remove());

        // Return the cleaned HTML
        return tempDiv.innerHTML;
    };

    const submitOffer = () => {
        window.open(JSON.parse(activeOffer.btn_settings).btn_url, '_blank');
    }

    const uniqueSavedOffers = [];
    const seenIds = new Set();
    useEffect(() => {
    
        // Single pass through offersData
        offersData.forEach(offer => {
            if (offer.status === "saved" && !seenIds.has(offer.id)) {
                uniqueSavedOffers.push(offer);
                seenIds.add(offer.id); // Mark id as seen
            }
        });
    }, [offersData])

    function extractHref(content) {
        const hrefMatch = content.match(/href="([^"]*)"/);
        return hrefMatch ? hrefMatch[1] : null;
    }

    const webinarVersion = stateData.publishStreamObj.webinar_version;

    return (
        <div className="offers-area" >
            <div className="sidebar-header">
                <a href="#" onClick={() => {
                    dispatch(updateIsSideBar(false))
                    crossIconHandler()
                }}>
                    <img src={closeIcon} alt="" />
                </a>
                Offers
            </div>
            <div className="sidebar-tabs-header">
                <div className="tabs flex sidebar-tabs offers-tabs-header">
                    <button className={`flex flex-center ${offersActiveTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
                        Active
                    </button>
                    <button className={`flex flex-center ${offersActiveTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>
                        Saved
                    </button>
                </div>
            </div>

            <div className="offers-content-area">
                <div className="tab-content">
                    {offersActiveTab === 1 &&
                        <div className='offers-inner-container thin-scrollbar'>

                            {showLoader ?
                                <div className="flex flex-center loader-bg">
                                    {/* <div className='blur-bg'></div> */}
                                    <div className='flex flex-center section-loader'>
                                        {/* <div className="circle-loader"></div> */}
                                        <img src={blueCircleLoader} alt="" />
                                        <span className='dark-text'>Loading...</span>
                                    </div>
                                </div> : activeOffer ? <>
                                    {/* <h4 className="tabs-inner-heading">Active Offers</h4> */}
                                    <div className="offers-info-main">
                                        <div className="offer-preview">
                                            <h3 dangerouslySetInnerHTML={{ __html: JSON.parse(activeOffer.offer_desc).offer_heading }}></h3>
                                            {/* Extract offer_subheading and remove <img> tags */}
                                            {(() => {
                                                try {
                                                    const offerDesc = JSON.parse(activeOffer.offer_desc);
                                                    const cleanedOfferSubheading = getCleanedSubheading(offerDesc?.offer_subheading?offerDesc.offer_subheading:" ");
                                                    const subHeadingContent = activeOffer.content.replace(/\\\"/g, '"').replace(/<div class="common-part[^>]*>.*?<\/div>/g, '').replace(/<div[^>]*class="[^"]*common-hours[^"]*"[^>]*>[\s\S]*?<\/div>/g, '')
                                                    
                                                    return cleanedOfferSubheading ? (
                                                        (<>
                                                            {webinarVersion === 0 ? <div
                                                                className="offer-subheading"
                                                                dangerouslySetInnerHTML={{ __html: subHeadingContent }}
                                                            ></div> :
                                                                <h4 dangerouslySetInnerHTML={{ __html: cleanedOfferSubheading }} />}</>)
                                                    ) : null;
                                                } catch (error) {
                                                    return <h4>Error loading offer subheading.</h4>;
                                                }
                                            })()}
                                            {webinarVersion !== 0 && extractImageSrc(activeOffer.content).map((imgSrc, index) => (
                                                <img
                                                    key={index}
                                                    src={imgSrc}
                                                    alt={`Offer ${activeOffer.id}`}
                                                    style={{ width: "100%", marginBottom: "12px", cursor: "pointer" }}
                                                    onClick={() => window.open(extractHref(activeOffer.content), '_blank')}
                                                />
                                            ))}
                                            {/* {activeOffer && activeOffer.countdown_time && activeOffer.countdown_time > 0 ? <div className="countdown-timer-offer">
                                                <CountdownTimer countdownTime={activeOffer.countdown_time} removeOffer={removeOffer} publishTime={activeOffer.publishTime} />
                                            </div> : null} */}
                                            {activeOffer && activeOffer.countdown_time && activeOffer.countdown_time > 0 ? <div className="countdown-timer-offer">
                                                <NewCountdownTimer expiryTimestamp={Math.floor((activeOffer.publishTime / 1000) + activeOffer.countdown_time)} closeOfferFunction={removeOffer} />
                                            </div> : null}
                                            {webinarVersion !== 0 && !extractImageSrc(activeOffer.content)[0] && <button type='button' className={`flex flex-center widget-action-registration medium-btn-offer ${JSON.parse(activeOffer.offer_desc).size}`}
                                                style={{
                                                    marginBottom: "12px",
                                                    backgroundColor: JSON.parse(activeOffer.btn_settings).btn_bgcolor,
                                                    color: JSON.parse(activeOffer.btn_settings).btn_color
                                                }} onClick={() => submitOffer()}>
                                                <span className='material-icons' dangerouslySetInnerHTML={{ __html: JSON.parse(activeOffer.btn_settings).btn_icon }}></span>
                                                <span>{JSON.parse(activeOffer.btn_settings).btn_txt}</span>
                                            </button>}
                                            {(isAdmin || isModerator) && <button type='button' style={{ width: "100%" }} className={`${isAdmin || isModerator ? "btn warning-button" : "btn disabled-button"}`} disabled={!((isAdmin || isModerator))} onClick={() => removeOffer()}>
                                                <span>Remove Offer</span>
                                            </button>}

                                            {/* <div className="flex flex-center offers-numbers">
                                                <span className='flex flex-center offer-views'>41 Views</span>
                                                <span className='offer-clicks'>16/41 Clicked</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </> : <div className="empty-area-msg flex-center"  >
                                    <img src={emptyOffersIcon} alt="" />
                                    <h4>Active Offers</h4>
                                    {
                                        stateData.publishStreamObj?.isPresenter?
                                        <p>You’ve no active offers right now.
                                    </p>: <p>You’ve no active offers right now. Please publish from the saved offers.
                                      </p>
                                    }
                                   
                                  
                                </div>
                            }
                        </div>
                    }

                    {offersActiveTab === 2 &&
                        <div className='offers-inner-container saved-offers-con thin-scrollbar'>
                            {/* <h4 className="tabs-inner-heading">Saved Offers</h4> */}
                            <div className="">
                                <div className="saved-offers-listing">
                                    {showLoader ?
                                        <div className="flex flex-center loader-bg">
                                            {/* <div className='blur-bg'></div> */}
                                            <div className='flex flex-center section-loader'>
                                                <img src={blueCircleLoader} alt="" />
                                                <span className="dark-text">Loading...</span>
                                            </div>
                                        </div> : (offersData && offersData.filter(offer => offer.status === offerStatus.saved).length > 0) ? (
                                            [...new Map(offersData.map(offer => [offer.id, offer])).values()].filter(offer => offer.status === offerStatus.saved).map((offer) => {
                                                // const imageSrc = extractImageSrc(offer.content)[0];

                                                const offerDesc = JSON.parse(offer.offer_desc);
                                                const cleanedOfferSubheading = getCleanedSubheading(offerDesc.offer_subheading);

                                                const subHeadingContent = offer.content.replace(/\\\"/g, '"').replace(/<div class="common-part[^>]*>.*?<\/div>/g, '').replace(/<div[^>]*class="[^"]*common-hours[^"]*"[^>]*>[\s\S]*?<\/div>/g, '')

                                                return (
                                                    <div className="flex saved-offers" key={offer.id}>
                                                        <div className='offer-icon'>
                                                            <img src={offerIcon} alt="offer icon" />
                                                        </div>
                                                        <div className="offers-question-box">
                                                            <p className='flex' style={{ marginBottom: "10px" }}>{offer.title}</p>
                                                            <p className='offers-text'>
                                                                {/* {JSON.parse(offer.offer_desc).offer_subheading} */}
                                                                {/* <p dangerouslySetInnerHTML={{ __html: cleanedOfferSubheading }} /> */}

                                                                {/* if v1 */}
                                                                {webinarVersion === 0 ? <div
                                                                    className="offer-subheading"
                                                                    dangerouslySetInnerHTML={{ __html: subHeadingContent }}
                                                                ></div> : 
                                                                <p className='v2 subheading' dangerouslySetInnerHTML={{ __html: cleanedOfferSubheading }} />}
                                                                
                                                                {webinarVersion !== 0 && extractImageSrc(offer.content).map((imgSrc, index) => (
                                                                    <img
                                                                        key={index}
                                                                        src={imgSrc}
                                                                        alt={`Offer ${offer.id}`}
                                                                        style={{ width: "100%", marginBottom: "12px"}}
                                                                    />
                                                                ))}
                                                            </p>
                                                            <div className="flex offers-action">
                                                                {(isAdmin || isModerator) && (
                                                                    <button
                                                                        type='button'
                                                                        className={`flex flex-center button primary-button btn-sm ${isAdmin || isModerator ? 'primary-button' : 'disabled-button'}`}
                                                                        disabled={!((isAdmin || isModerator))}
                                                                        onClick={() => publishOffers(offer)}
                                                                    >
                                                                        Publish Now
                                                                        {/* {publishingOfferId === offer.id && !activeOffer &&
                                                                            <span className='btn-loader'><img src={buttonLoader} alt="" /></span>
                                                                        } */}
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div style={{ height: "calc(100vh - 302px)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <div className="empty-area-msg flex-center">
                                                    <img src={emptyPollsIcon} alt="" />
                                                    <h4>Saved Offers</h4>
                                                    <p>You’ve no offers available right now.</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    );
}
export default Offers;
