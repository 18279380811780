import React, { Component, ReactNode } from 'react';
import * as Sentry from '@sentry/react';

type ErrorBoundaryProps = {
  children: ReactNode;
  component: string; // Prop to accept the name of the component
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error(`${this.props.component} failed:`, error, info);
    try {
      Sentry.captureMessage( 'blank-screen-'+ this.props.component + JSON.stringify(error)); 
    } catch (error) {
      
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong in {this.props.component}</h1>;
    }

    // If no error, render the children component
    return this.props.children;
  }
}

export default ErrorBoundary;
